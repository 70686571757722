import {getApiUrl, getAuthHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';


export class SemanticGraderApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.CA_ENDPOINT, '/api');

  public async getSemanticGraderList(pageSize, currentPage ) {
    const param ={page_size: pageSize, page: currentPage};
    try {
      const response = await this.axios.get(`${SemanticGraderApi.baseUrl}/v2/semantic-grader/`, {
        params: param,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async deleteSemanticGrader(id) {
    try {
      const response = await this.axios.delete(`${SemanticGraderApi.baseUrl}/v2/semantic-grader/${id}/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getSemanticGraderDetail(id) {
    try {
      const response = await this.axiosWithoutCamelCase.get(`${SemanticGraderApi.baseUrl}/v2/semantic-grader/${id}/`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async createSemanticGrader(param) {
    try {
      const response = await this.axios.post(`${SemanticGraderApi.baseUrl}/v2/semantic-grader/`, param, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export const SEMANTIC_GRADER_API = new SemanticGraderApi();
