import React, {useEffect, useRef, useState} from 'react';
import {
  aiModelOptions,
  colSpan,
  contentLengthOptions,
  pointOfViewOptions,
  readingLevel,
  rowgutter,
  shortToneOfVoiceOptions,
  threeColSpan,
  threeColgutter,
  writingLeftOptions,
  writingOptionsColSpan,
  writingOptionsRowgutter,
  writingRightOptions,
} from './commonFolderSettingsUtils';
import {Col, Divider, Popover, Row, Spin, Tooltip} from 'antd';
import {languages} from '../../../page-editor/Drawers/AiContentToolsDrawer/languages';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import {useStore} from '@/store/root-store';
import {faCircleInfo} from '@fortawesome/pro-regular-svg-icons';
import useIntersectionObserver from './useIntersectionObserver';
import {
  Container,
  Input,
  InputCustomVoiceBtn,
  Label,
  SectionTitle,
  Select,
  SelectContainer,
  StyledFormItem,
  SwitchStyled,
  ToneOfVoice,
  ToneOfVoiceSingleOption,
} from './styles';
import {LargeBadge} from '@/components/common-components/components/badge';

const antIcon = <LoadingOutlined style={{fontSize: 16, marginLeft: 7, color: '#7f4ead'}} spin />;

const WritingSection = ({form, selectedToneOfVoice, setSelectedToneOfVoice, selectedSection, useCustomToneOfVoice, setUseCustomToneOfVoice, setActiveOption}) => {
  const [languageSearchTxt, setLanguageSearchTxt] = useState('');
  const [languagesOptions, setLanguagesOptions] = useState(languages);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const {
    contentOptimizer: {
      aiOutline: {
        getUserAiSettings,
        loadingUserAiSettings,
      },
    },
  } = useStore('');
  const {settings: {customer: {profile: {isLinkgraph}}}} = useStore('');
  const faqOptions = [
    {value: '', label: 'No FAQ'},
    {value: 'short', label: 'Short FAQ'},
    {value: 'long', label: 'Long FAQ'},
  ];

  useEffect(() => {
    getUserAiSettings();
  }, []);

  const blockref = useRef(null);
  const blockEntry = useIntersectionObserver(blockref, {
    threshold: [0.3, 0.5, 0.7, 0.9, 1.0],
  });

  const isVisible = !!blockEntry?.isIntersecting;

  useEffect(() => {
    if (isVisible) {
      setActiveOption('writing');
    }
  }, [isVisible]);

  const handleSearch = value => {
    setLanguageSearchTxt(value);
    const filteredOptions = languages.filter(d =>
      d.language.toLowerCase().includes(value.toLowerCase()),
    );
    setLanguagesOptions(filteredOptions);
  };

  const onToneOfVoiceChange = value => {
    setSelectedToneOfVoice(value);
    const obj = {};
    obj['tone_of_voice'] = value;
    form.setFieldsValue(obj);
  };

  const settingsLoader = loadingUserAiSettings ? <Spin indicator={antIcon} /> : null;

  return (
    <Container id='writing' ref={blockref}>
      <div className={selectedSection === 'writing' ? 'selected-bg' : ''} id='container'>
        <SectionTitle>WRITING</SectionTitle>
        <Row gutter={rowgutter}>
          <Col span={colSpan}>
            <Label>AI Model</Label>
            <StyledFormItem
              name='llm'
              rules={[
                {required: true, message: 'This field is required'},
              ]}
            >
              <Select
                onChange={value => {
                  const obj = {};
                  obj['llm'] = value;
                  form.setFieldsValue(obj);
                }}
                placeholder='AI Model'
                suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#4E5156' />}
              >
                {aiModelOptions.map(item => (
                  (isLinkgraph || item.value !== 'groq') && (
                    <Select.Option value={item.value} key={item.value}>
                      <div style={{display: 'flex'}}>
                        {item.label}
                        {item.value === 'groq' && isLinkgraph && <LargeBadge alpha />}
                      </div>
                    </Select.Option>
                  )
                ))}
              </Select>
            </StyledFormItem>
          </Col>
          <Col span={colSpan}>
            <Label>Language</Label>
            <StyledFormItem
              name='language'
              rules={[
                {required: true, message: 'This field is required'},
              ]}
            >
              <Select
                onChange={value => {
                  const obj = {};
                  obj['language'] = value;
                  form.setFieldsValue(obj);
                }}
                placeholder='Language'
                showSearch
                filterOption={false}
                searchValue={languageSearchTxt}
                onSearch={handleSearch}
                suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#4E5156' />}
              >
                {languagesOptions.map((item, idx) => {
                  if (item.language !== 'Automatic Detection' && item.code !== null) {
                    return (<Select.Option key={idx} value={item.code}>
                      {item.language}
                    </Select.Option>);
                  }
                })}
              </Select>
            </StyledFormItem>
          </Col>
        </Row>
        <Row gutter={rowgutter}>
          <Col span={colSpan}>
            <Label>Voice</Label>
            <StyledFormItem name='tone_of_voice' initialValue={'Professional'}>
              <Popover
                overlayInnerStyle={{
                  width: 190,
                }}
                trigger='click'
                placement='rightTop'
                visible={isPopoverVisible}
                onVisibleChange={setIsPopoverVisible}
                content={<div>
                  <div style={{fontSize: 12, color: '#4E5156'}}>Input any voice or auto-match based on your topic.</div>
                  <Divider style={{marginTop: 10, marginBottom: 10}} />
                  <InputCustomVoiceBtn onClick={() => {
                    setUseCustomToneOfVoice(true);
                    setSelectedToneOfVoice('custom');
                    setIsPopoverVisible(false);
                  }}>+ Input a Custom Voice</InputCustomVoiceBtn>
                  {shortToneOfVoiceOptions.map((item, idx) => {
                    return <ToneOfVoiceSingleOption style={{cursor: 'pointer', fontSize: 12}} key={idx} onClick={() => {
                      setUseCustomToneOfVoice(false);
                      onToneOfVoiceChange(item);
                      setIsPopoverVisible(false);
                    }}>{item}</ToneOfVoiceSingleOption>;
                  })}
                </div>}>
                <ToneOfVoice>
                  {selectedToneOfVoice == 'custom' ? 'Custom (input below)' : selectedToneOfVoice}
                  <FontAwesomeIcon icon={faCaretDown} color='#4E5156' style={{marginLeft: 'auto', marginTop: 3}}/>
                </ToneOfVoice>
              </Popover>
            </StyledFormItem>
            {useCustomToneOfVoice &&
          <SelectContainer>
            <Label>{'Custom voice'}</Label>
            <Input onChange={e => {
              const obj = {};
              obj['tone_of_voice'] = e.target.value;
              form.setFieldsValue(obj);
            }}/>
            {settingsLoader}
          </SelectContainer>}
          </Col>
          <Col span={colSpan}>
            <Label>Point of view</Label>
            <StyledFormItem
              name='point_of_view'
              initialValue={'Third Person'}
              rules={[
                {required: true, message: 'This field is required'},
              ]}
            >
              <Select
                onChange={value => {
                  const obj = {};
                  obj['point_of_view'] = value;
                  form.setFieldsValue(obj);
                }}
                placeholder='Point of view'
                suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#4E5156' />}
              >
                {pointOfViewOptions.map((item, idx) => (
                  <Select.Option key={idx} value={item.toLowerCase()}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </StyledFormItem>
          </Col>
        </Row>
        <Row gutter={threeColgutter}>
          <Col span={threeColSpan}>
            <Label>Act as who? <Tooltip title='Act as who effects the system text and its meant to be the practitioner of the niche. Legal niche = Lawyer. A remote work productivity coach.'><FontAwesomeIcon icon={faCircleInfo} color='#A3A4A4' /></Tooltip></Label>
            <StyledFormItem
              name='act_as_who'
            >
              <Input placeholder='Act as who?'/>
            </StyledFormItem>
          </Col>
          <Col span={threeColSpan}>
            <Label>Reading level <Tooltip title='Indicates how easy the text is to read based on the Flesch–Kincaid scale.'><FontAwesomeIcon icon={faCircleInfo} color='#A3A4A4' /></Tooltip></Label>
            <StyledFormItem
              name='reading_level'
              initialValue={'College'}
            >
              <Select
                onChange={value => {
                  const obj = {};
                  obj['reading_level'] = value;
                  form.setFieldsValue(obj);
                }}
                placeholder='Reading level'
                suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#4E5156' />}
              >
                {readingLevel.map((item, idx) => (
                  <Select.Option key={idx} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </StyledFormItem>
          </Col>
          <Col span={threeColSpan}>
            <Label>Content length</Label>
            <StyledFormItem
              name='length'
              initialValue={'Medium'}
              rules={[
                {required: true, message: 'This field is required'},
              ]}
            >
              <Select
                onChange={value => {
                  const obj = {};
                  obj['length'] = value;
                  form.setFieldsValue(obj);
                }}
                placeholder='Content length'
                suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#4E5156' />}
              >
                {contentLengthOptions.map((item, idx) => (
                  <Select.Option key={idx} value={item.toLowerCase()}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </StyledFormItem>
          </Col>
        </Row>
        <Row gutter={rowgutter}>
          <Col span={colSpan}>
            <Label>Writing style <Tooltip title='Writing style would effect system text variables.'><FontAwesomeIcon icon={faCircleInfo} color='#A3A4A4' /></Tooltip></Label>
            <StyledFormItem
              name='writing_style'
              rules={[
                {max: 100, message: 'Writing Style cannot exceed 100 characters'},
              ]}
            >
              <Input
                placeholder='Writing style'
              />
            </StyledFormItem>
          </Col>
          <Col span={colSpan}>
            <Label>FAQ Section</Label>
            <StyledFormItem
              name='enable_faq'
              initialValue={'short'}
            >
              <Select
                onChange={value => {
                  form.setFieldsValue({enable_faq: value});
                }}
                placeholder='FAQ'
                suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#4E5156' />}
              >
                {faqOptions.map((item, index)=> {
                  return <Select.Option key={index} value={item.value} >{item.label}</Select.Option>;
                })}
              </Select>
            </StyledFormItem>
          </Col>
        </Row>
        <Label>Writing options</Label>
        <Row gutter={writingOptionsRowgutter} style={{flexWrap: 'nowrap'}}>
          <Col span={writingOptionsColSpan}>
            {writingLeftOptions.map((item, index) => (
              <Col key={index}>
                <StyledFormItem style={{marginBottom: '0px', whiteSpace: 'nowrap'}} name={item.key} initialValue={false}>
                  <SwitchStyled
                    onChange={value => {
                      const obj = {};
                      obj[item.key] = value;
                      form.setFieldsValue(obj);
                    }}
                    defaultChecked={['table_of_contents', 'bold_terms_to_include'].includes(item.key) ? form.getFieldValue(item.key) : form.getFieldValue(item.key) === false ? form.getFieldValue(item.key) : true}
                  />
                  {item.label} {item.tooltipText && <Tooltip title={item.tooltipText}><FontAwesomeIcon icon={faCircleInfo} color='#A3A4A4' /></Tooltip>}
                </StyledFormItem>
              </Col>
            ))}
          </Col>
          <Col span={writingOptionsColSpan}>
            {writingRightOptions.map((item, index) => (
              <Col key={index}>
                <StyledFormItem style={{marginBottom: '0px', whiteSpace: 'nowrap'}} name={item.key} initialValue={false}>
                  <SwitchStyled
                    onChange={value => {
                      const obj = {};
                      obj[item.key] = value;
                      form.setFieldsValue(obj);
                    }}
                    defaultChecked={(['use_tease', 'disable_conclusion'].includes(item.key)) ? form.getFieldValue(item.key) : form.getFieldValue(item.key) === false ? form.getFieldValue(item.key) : true}
                  />
                  {item.label} {item.tooltipText && <Tooltip title={item.tooltipText}><FontAwesomeIcon icon={faCircleInfo} color='#A3A4A4' /></Tooltip>}
                </StyledFormItem>
              </Col>
            ))}
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default WritingSection;
