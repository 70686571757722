import {Col, Form, Row} from 'antd';
import {SectionDescription, SectionTitle, ApiInput, Footer, UpdateAiSettings} from './styledComponents';
import FreezeWrapper from '../../otto-page-detail/Component/freezTooltip';
import {useStore} from '@/store/root-store';
import {useState} from 'react';
import {observer} from 'mobx-react';

interface Props {
  setModalVisible: (val: boolean) => void;
}

export const APIKeysSection = observer(({setModalVisible}: Props) => {
  const {ottoV2Store: {genesysApiKey, postGenesysApiKey, updateGenesysApiKey}} = useStore('');
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const updateKeys = async values => {
    setLoading(true);
    if (genesysApiKey?.length) {
      await updateGenesysApiKey(values);
    } else {
      await postGenesysApiKey(values);
    }
    setLoading(false);
    setModalVisible(false);
  };

  return (
    <Form
      form={form}
      onFinish={updateKeys}
      initialValues={{api_key: genesysApiKey || ''}}
    >
      <Row style={{marginTop: 26}}>
        <Col span={11}>
          <SectionTitle style={{marginBottom: 3}}>Signal Genesys API Key</SectionTitle>
          <SectionDescription style={{minWidth: 324}}>Enter your Signal Genesys API key to seamlessly sync and display your Press Releases directly in your account.</SectionDescription>
        </Col>
        <Col span={13}>
          <Form.Item
            name='api_key'
            rules={[
              {required: true, message: 'Please provide your API key.'},
              {whitespace: true, message: 'API key cannot be empty or just spaces.'},
            ]}
          >

            <ApiInput defaultValue={genesysApiKey} />
          </Form.Item>
        </Col>
        <Footer style={{width: '100%'}}>
          <button className='cancel-button' type='button' onClick={() => {
            setModalVisible(false);
          }}>Cancel</button>
          <FreezeWrapper>
            <div>
              <UpdateAiSettings
                loading={loading}
                htmlType='submit'
              >
                  Update Settings
              </UpdateAiSettings>
            </div>
          </FreezeWrapper>
        </Footer>
      </Row>
    </Form>
  );
});
