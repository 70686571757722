import React, {useEffect, useState} from 'react';
import {Modal} from '@/components/common-components/components';
import styled from 'styled-components';
import ButtonComponent from '@/components/common-components/components/button-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark, faFeather, faListTimeline} from '@fortawesome/pro-duotone-svg-icons';
import {Checkbox, DatePicker, Input, Radio, RadioChangeEvent, Slider, Spin, Switch, Tooltip} from 'antd';
import {CaretDownOutlined, LoadingOutlined} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import {automationOptions, ButtonOptions} from '../Constants';
import {observer} from 'mobx-react';
import {useStore} from '@/store/root-store';
import {SwitchWrapper} from '../style';
import classNames from 'classnames';
import {apiError} from '@/utils/api';
import {notification as notificationV2} from '@/utils/notification-v2';

interface GBPAutomationModalProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  setOpenGbpLibraryModal: React.Dispatch<React.SetStateAction<boolean>>;
}


export const GBPAutomationModal: React.FC<GBPAutomationModalProps> = observer(({openModal, setOpenModal, setOpenGbpLibraryModal}) => {
  const {ottoV2Store: {
    gbpAutomatedPostSettings,
    getGbpAutomatedPostSettings,
    updatedGbpAutomatedPostSettings,
    getGbpMediaLibraryImages,
    getGbpMediaLibrary,
  }} = useStore('');
  const [futureDate, setFutureDate] = useState<any>(moment().add(1, 'day'));
  const [onlyContainKeywords, setOnlyContainKeywords] = useState(true);
  const [isEnabled, setIsEnabled] = useState(false);
  const [customFrequency, setCustomFrequency] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [contentType, setContentType] = useState('AI_GENERATE');
  const [automatedGbpPostSettingLoader, setAutomatedGbpPostSettingLoader] = useState(false);
  const [imageCount, setImageCount] = useState(0);
  const [selectOptionStates, setSelectOptionStates] = useState<Record<ButtonOptions, {option: string}>>({
    frequencyOption: {option: 'OPTIMIZED'},
    startDateOption: {option: 'today'},
    imageOption: {option: 'AI_GENERATE'},
    approvalOption: {option: 'AUTO_PUBLISH'},
    publishOptions: {option: 'positiveReview'},
  });
  const [aiWritingTips, setAiWritingTips] = useState<string>('');
  useEffect(() => {
    if (gbpAutomatedPostSettings) {
      setSelectOptionStates({
        frequencyOption: {option: gbpAutomatedPostSettings?.frequencyType},
        startDateOption: {option: moment(gbpAutomatedPostSettings?.startAt) <= moment().subtract(0, 'days') ? 'today' : 'separately'},
        imageOption: {option: gbpAutomatedPostSettings?.imageSourceType},
        approvalOption: {option: gbpAutomatedPostSettings?.approvalType},
        publishOptions: {option: gbpAutomatedPostSettings?.contentType == 'REVIEWS_ONLY' ? 'REVIEWS_AND_AI' : gbpAutomatedPostSettings?.contentType},
      });
      setCustomFrequency(gbpAutomatedPostSettings?.frequency);
      setIsEnabled(gbpAutomatedPostSettings?.isActive);
      setAiWritingTips(gbpAutomatedPostSettings?.helpPrompt);
      setKeyword(gbpAutomatedPostSettings?.reviewFilter?.contentIcontains?.toString());
      setOnlyContainKeywords(gbpAutomatedPostSettings?.reviewFilter?.isEnabled);
      gbpAutomatedPostSettings?.contentType && setContentType(gbpAutomatedPostSettings?.contentType == 'REVIEWS_ONLY' ? 'REVIEWS_AND_AI' : gbpAutomatedPostSettings?.contentType);
      if (moment(gbpAutomatedPostSettings?.startAt) <= moment().subtract(0, 'days')) {
        setFutureDate(moment().add(1, 'day'));
      } else {
        setFutureDate(moment(gbpAutomatedPostSettings?.startAt));
      }
    } else {
      getGbpAutomatedPostSettings();
    }
  }, [gbpAutomatedPostSettings]);
  useEffect(() => {
    if (getGbpMediaLibrary) {
      setImageCount(getGbpMediaLibrary?.count);
    } else {
      getGbpMediaLibraryImages({page: 1, pageSize: 7}, false);
    }
  }, [openModal, getGbpMediaLibrary]);
  const updateAutomatedPostSettingStatus = async () => {
    try {
      setAutomatedGbpPostSettingLoader(true);
      const payload = {
        count: 1,
        frequency_type: selectOptionStates?.frequencyOption?.option,
        image_source_type: selectOptionStates?.imageOption?.option,
        approval_type: selectOptionStates?.approvalOption?.option,
        ...(selectOptionStates?.startDateOption?.option === 'separately' ? {start_at: futureDate.format('YYYY-MM-DD')} : {start_at: moment().format('YYYY-MM-DD')}),
        ...((selectOptionStates?.frequencyOption?.option !== 'OPTIMIZED' && customFrequency) && {frequency: customFrequency}),
        ...(isEnabled !== undefined && {is_active: isEnabled}),
        help_prompt: aiWritingTips,
        ...(contentType === 'REVIEWS_AND_AI' ? {
          content_type: contentType,
          review_filter: {
            is_enabled: onlyContainKeywords ?? false,
            ...(keyword && {content__icontains: [keyword]}),
          }} : {
          content_type: contentType,
        }),
      };
      await updatedGbpAutomatedPostSettings(payload);
      setAutomatedGbpPostSettingLoader(false);
      setOpenModal(false);
      notificationV2.success('', 'Automate GBP posting settings updated successfully');
    } catch (e) {
      setAutomatedGbpPostSettingLoader(false);
      const errorMessage = apiError(e);
      notificationV2.error('', errorMessage);
    }
  };
  const optionChangeHandler = (e: RadioChangeEvent, optionKey: ButtonOptions) => {
    const newValue = e.target.value;
    setSelectOptionStates(prevStates => ({
      ...prevStates,
      [optionKey]: {...prevStates[optionKey], option: newValue},
    }));
  };

  const onChangeFrequency = (newValue: number) => {
    setCustomFrequency(newValue);
  };
  const onChangeKeyword = e => {
    const value = e?.target?.value;
    setKeyword(value);
  };

  const tipFormatter = value => {
    return <div style={{color: '#FFFFFF'}}>{`Post every ${value} day${value > 1 ? 's' : ''}`}</div>;
  };

  return (
    <StyledTopicalMapsModal
      width={'780px'}
      closable={true}
      onClose={() => setOpenModal(false)}
      closeIcon={<div><FontAwesomeIcon color={'#ffffff'} fontSize={22} icon={faXmark} /></div>}
      visible={openModal}
    >
      <StyledModalHeader>
        <div className='modal-heading'>Automate GBP Posting</div>
        <div className='description'>
          OTTO creates SEO-optimized Google Business posts and publishes them on your profile,<br />
          complete with engaging, SEO-friendly images.
        </div>
      </StyledModalHeader>
      <FieldWrapper style={{alignItems: 'flex-start'}}>
        <div className='label'>
          <FontAwesomeIcon icon={faListTimeline} color='#2D6CCA' fontSize={20} />Automated posts
        </div>
        <SwitchWrapper>
          <Switch loading={false} className='switch-button' checked={isEnabled} onChange={value => setIsEnabled(value)} disabled={automatedGbpPostSettingLoader} />
          <div className={classNames('enabled-disabled-txt', isEnabled ? 'add-green-color' : '')}>{isEnabled ? 'Enabled' : 'Disabled'}</div>
        </SwitchWrapper>
      </FieldWrapper>
      <div className='scroll-fields'>
        <FieldWrapper style={{alignItems: 'flex-start'}}>
          <div className='label' style={{marginTop: 7}}>
            <FontAwesomeIcon icon={automationOptions.frequencyOption.icon} color='#2D6CCA' fontSize={20} />{automationOptions.frequencyOption.label}
          </div>
          <div className='field-container'>
            <div className='body' style={{padding: 0}}>
              <div className='domain-input-wrapper' style={{display: 'block'}}>
                <StyledRadioGroup onChange={e => optionChangeHandler(e, 'frequencyOption')} value={selectOptionStates.frequencyOption.option} disabled={automatedGbpPostSettingLoader}>
                  {automationOptions.frequencyOption.options.map((option, index) => (
                    <Tooltip key={index} title={(option?.showTooltip && option?.tooltipMessage) ? option?.tooltipMessage : ''} placement='topLeft'>
                      <StyledRadio value={option.value} isSelected={selectOptionStates.frequencyOption.option === option.value} disabled={option?.disabled}>
                        <div className='option-label'>{option?.label}</div>
                        {option?.description ? <div className='option-description'>{option?.description}</div> : <></>}
                        {(option.custom && selectOptionStates.frequencyOption.option === option.value) ? <div style={{marginTop: 10}}>
                          <div style={{marginLeft: 8}}>
                            <img src='/img/sliderGraph.svg'/>
                          </div>
                          <StyledSlider
                            disabled={automatedGbpPostSettingLoader}
                            min={1}
                            max={10}
                            onChange={onChangeFrequency}
                            defaultValue={customFrequency}
                            value={customFrequency}
                            marks={{1: '1', 2: '2', 3: '3', 4: '4', 5: '5', 6: '6', 7: '7', 8: '8', 9: '9', 10: '10'}}
                            tipFormatter={tipFormatter}
                          />
                        </div> : <></>}
                      </StyledRadio>
                    </Tooltip>
                  ))}
                </StyledRadioGroup>
              </div>
            </div>
          </div>
        </FieldWrapper>
        <FieldWrapper style={{alignItems: 'flex-start'}}>
          <div className='label' style={{marginTop: 7}}>
            <FontAwesomeIcon icon={automationOptions.startDateOption.icon} color='#2D6CCA' fontSize={20} />{automationOptions.startDateOption.label}
          </div>
          <div className='field-container'>
            <div className='body' style={{padding: 0}}>
              <div className='domain-input-wrapper' style={{display: 'block'}}>
                <StyledRadioGroup onChange={e => optionChangeHandler(e, 'startDateOption')} value={selectOptionStates.startDateOption.option} disabled={automatedGbpPostSettingLoader}>
                  {automationOptions.startDateOption.options.map((option, index) => (
                    <Tooltip key={index} title={(option?.showTooltip && option?.tooltipMessage) ? option?.tooltipMessage : ''} placement='topLeft'>
                      <StyledRadio value={option.value} isSelected={selectOptionStates.startDateOption.option === option.value} disabled={option?.disabled}>
                        <div className='option-label'>{option?.label}</div>
                        {option?.description ? <div className='option-description'>{option?.description}</div> : <></>}
                        {(option.custom && selectOptionStates.startDateOption.option === option.value) ?
                          <StyledDatePicker
                            placeholder='Date'
                            suffixIcon={<CaretDownOutlined />}
                            size={'small'}
                            format={'MMM D, YYYY'}
                            className='date-input w-125'
                            showToday={false}
                            disabledDate={currentDate => currentDate <= moment().subtract(0, 'days')}
                            value={futureDate ? futureDate : moment().add(1, 'day')}
                            onChange={(e: any) => setFutureDate(e)}
                            allowClear={false}
                            disabled={automatedGbpPostSettingLoader}
                            getPopupContainer={(trigger: HTMLElement) => trigger?.parentNode as HTMLElement}
                          /> : <></>}
                      </StyledRadio>
                    </Tooltip>
                  ))}
                </StyledRadioGroup>
              </div>
            </div>
          </div>
        </FieldWrapper>
        <FieldWrapper style={{alignItems: 'flex-start'}}>
          <div className='label' style={{marginTop: 7}}>
            <FontAwesomeIcon icon={automationOptions.imageOption.icon} color='#2D6CCA' fontSize={20} />{automationOptions.imageOption.label}
          </div>
          <div className='field-container'>
            <div className='body' style={{padding: 0}}>
              <div className='domain-input-wrapper' style={{display: 'block'}}>
                <StyledRadioGroup onChange={e => optionChangeHandler(e, 'imageOption')} value={selectOptionStates.imageOption.option} disabled={automatedGbpPostSettingLoader}>
                  {automationOptions.imageOption.options.map((option, index) => (
                    <Tooltip key={index} title={(option?.showTooltip && option?.tooltipMessage) ? option?.tooltipMessage : ''} placement='topLeft'>
                      <StyledRadio value={option.value} isSelected={selectOptionStates.imageOption.option === option.value} disabled={option?.disabled}>
                        <div className='option-label'>{option.value === 'MEDIA_LIBRARY' ? `Select only from Media Library (${imageCount} images)` : option.label}{(option?.showHeaderButton && selectOptionStates.imageOption.option === option.value) ? (
                          <div
                            onClick={() => {
                              if (automatedGbpPostSettingLoader) {
                                return;
                              } else {
                                setOpenModal(false);
                                setOpenGbpLibraryModal(true);
                              }
                            }} style={{color: '#2D6CCA', fontSize: '12px', fontWeight: 400, cursor: 'pointer'}}>Open Media Library</div>
                        ) : <></>}</div>
                        {option?.description ? <div className='option-description'>{option?.description}</div> : <></>}
                      </StyledRadio>
                    </Tooltip>
                  ))}
                </StyledRadioGroup>
              </div>
            </div>
          </div>
        </FieldWrapper>
        <FieldWrapper style={{alignItems: 'flex-start'}}>
          <div className='label' style={{marginTop: 7}}>
            <FontAwesomeIcon icon={faFeather} color='#2D6CCA' fontSize={20} />AI writing tips (optional)
          </div>
          <div className='field-container'>
            <div className='body' style={{padding: 0}}>
              <div className='domain-input-wrapper' style={{display: 'block'}}>
                <StyledPostContainer>
                  <TextArea
                    disabled={automatedGbpPostSettingLoader}
                    style={{resize: 'none', height: '110px', padding: '0px'}}
                    bordered={false}
                    value={aiWritingTips}
                    onChange={e => setAiWritingTips(e.target.value)}
                    placeholder={'What should the post be about? e.g ABC Corp is organizing a meeting in the Central Park...'}
                  />
                </StyledPostContainer>
              </div>
            </div>
          </div>
        </FieldWrapper>
        <FieldWrapper style={{alignItems: 'flex-start'}}>
          <div className='label' style={{marginTop: 7}}>
            <FontAwesomeIcon icon={automationOptions.approvalOption.icon} color='#2D6CCA' fontSize={20} />{automationOptions.approvalOption.label}
          </div>
          <div className='field-container'>
            <div className='body' style={{padding: 0}}>
              <div className='domain-input-wrapper' style={{display: 'block'}}>
                <StyledRadioGroup onChange={e => optionChangeHandler(e, 'approvalOption')} value={selectOptionStates.approvalOption.option} disabled={automatedGbpPostSettingLoader}>
                  {automationOptions.approvalOption.options.map((option, index) => (
                    <Tooltip key={index} title={(option?.showTooltip && option?.tooltipMessage) ? option?.tooltipMessage : ''} placement='topLeft'>
                      <StyledRadio value={option.value} isSelected={selectOptionStates.approvalOption.option === option.value} disabled={option?.disabled}>
                        <div className='option-label'>{option?.label}</div>
                        {option?.description ? <div className='option-description'>{option?.description}</div> : <></>}
                      </StyledRadio>
                    </Tooltip>
                  ))}
                </StyledRadioGroup>
              </div>
            </div>
          </div>
        </FieldWrapper>
        <FieldWrapper style={{alignItems: 'flex-start'}}>
          <div className='label' style={{marginTop: 7}}>
            <FontAwesomeIcon icon={automationOptions.publishOptions.icon} color='#2D6CCA' fontSize={20} />{automationOptions.publishOptions.label}
          </div>
          <div className='field-container'>
            <div className='body' style={{padding: 0}}>
              <div className='domain-input-wrapper' style={{display: 'block'}}>
                <StyledRadioGroup onChange={e => {
                  optionChangeHandler(e, 'publishOptions');
                  setContentType(e.target.value);
                }}
                disabled={automatedGbpPostSettingLoader}
                value={selectOptionStates.publishOptions.option}
                >
                  {automationOptions.publishOptions.options.map((option, index) => (
                    <Tooltip key={index} title={(option?.showTooltip && option?.tooltipMessage) ? option?.tooltipMessage : ''} placement='topLeft'>
                      <StyledRadio value={option.value} isSelected={selectOptionStates.publishOptions.option === option.value} disabled={option?.disabled}>
                        <div className='option-label'>{option?.label}</div>
                        {option?.description ? <div className='option-description'>{option?.description}</div> : <></>}
                        {(option.custom && selectOptionStates.publishOptions.option === option.value) ? <ContentCheckbox>
                          <Checkbox disabled={automatedGbpPostSettingLoader} checked={onlyContainKeywords} onChange={e => setOnlyContainKeywords(e?.target?.checked)}/>Only reviews that contain the keyword
                          <KeywordInput
                            disabled={automatedGbpPostSettingLoader}
                            style={{maxWidth: '150px', border: 'none'}}
                            placeholder={'Keyword'}
                            onChange={onChangeKeyword}
                            value={keyword}
                          />
                        </ContentCheckbox> : <></>}
                      </StyledRadio>
                    </Tooltip>
                  ))}
                </StyledRadioGroup>
              </div>
            </div>
          </div>
        </FieldWrapper>
      </div>
      <StyledModalFooter>
        <div className='cancel-btn' onClick={() => setOpenModal(false)}>
          Cancel
        </div>
        <ButtonComponent
          disabled={automatedGbpPostSettingLoader}
          color='blue'
          style='solid'
          type='button'
          className='update-button'
          onClick={updateAutomatedPostSettingStatus}
        >
          {automatedGbpPostSettingLoader ? <Spin indicator={<LoadingOutlined style={{fontSize: '16px', color: 'white', marginRight: 5}} spin />} /> : <></>}
          {automatedGbpPostSettingLoader ? 'Updating settings' : 'Update settings'}
        </ButtonComponent>
      </StyledModalFooter>
    </StyledTopicalMapsModal>
  );
});

const StyledTopicalMapsModal = styled(Modal)`
   .rc-dialog-content {
    .rc-dialog-body {
      background-color: #fff;
      padding: 25px !important;
      border-radius: 10px;
      .scroll-fields {
        max-height: 576px;
        overflow: hidden;
        overflow-y: auto;
        padding: 0px 6px;
        &::-webkit-scrollbar {
          height: 5px;
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(138, 138, 138, 0.32);
          border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
          background: #F9F9FB;
          border-radius: 10px;
        }
      }
    }
    .rc-dialog-close {
    font-size: 21px;
    right: -34px;
    top: 0px;
    font-weight: 700;
    color: #fff !important;
    &:hover {
      opacity: 1 !important;
    }
    }
  }
`;
const StyledModalHeader = styled.div`
  padding: 0px !important;
  margin-bottom: 16px;
  .modal-heading {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #121212;
  }
  .description {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #4E5156;
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  flex-wrap: wrap;
  margin: 14px 0;
  .label {
    width: 160px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #121212;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .field-container {
    flex: 1;
    .header {
      display: flex;
      justify-content: space-between;
      .right-section {
        align-items: center;
        display: flex;
        gap: 10px;
        .button {
          align-items: center;
          display: flex;
          gap: 10px;
          font-family: Inter;
          font-size: 12px;
          font-weight: 400;
          color:#2D6CCA;
          cursor: pointer;
        }
      }
    }
    .body {
      border: 1px solid #D8D8D8;
      border-radius: 8px;
      padding: 0px 0px 10px 0px;
      width: 100%;
      background-color: #ffffff;

      .date {
        width: 105px;
      }
      .time {
        width: 85px;
      }
      .ant-picker-small {
        padding: 0px 0px 0px !important;
      }
      .replace-image {
        padding: 6px;
        background: #4E5156;
        border-radius: 6px;
        position: absolute;
        right: 33px;
        top: 131px;
      }

      .domain-input-wrapper {
        display: flex;
        align-items: center;
        .domain {
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.94px;
          text-align: left;
          color: #A3A4A4;
          padding-left: 10px;
        }
        .slug {
          padding: 5px 0px;
        }
      }
    }
    .error-message {
      color: red;
    }

  }
`;

const StyledModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 15px;
  .cancel-btn {
    width: 47px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #4E5156;
    cursor: pointer;
  }
`;
const StyledRadioGroup = styled(Radio.Group)`
  display: block;
  .ant-radio-button-wrapper {
    display: block;
    padding: 0px 10px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #121212;
    &.ant-radio-button-wrapper-checked {
      background: #2D6CCA26;
      :focus {
        outline: none;
        box-shadow: none;
      }
      :focus-within {
        outline: none;
        box-shadow: none;
      }
      :hover {
        color: #121212;
      }
    }
    :focus {
        outline: none;
        box-shadow: none;
      }
      :focus-within {
        outline: none;
        box-shadow: none;
      }
      :hover {
        color: #121212;
      }
  }
  .ant-radio-button-wrapper:first-child {
    border-radius: 8px 0px 0px 8px;
  }
  .ant-radio-button-wrapper:last-child {
    border-radius: 0px 8px 8px 0px;
  }
`;
const StyledRadio = styled(Radio)<{isSelected: boolean}>`
  border: ${p => p?.isSelected ? '1px solid #2D6CCA' : ''};
  background-color: ${p => p?.isSelected ? 'rgba(45, 108, 202, 0.08)' : ''};
  margin-right: 0px;
  border-radius: 8px;
  width: 100%;
  padding: 12px 12px;
  span:not(.ant-radio) {
      .option-label {
        width: 478px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-weight: ${p => p?.isSelected ? 500 : 400};
        color:  ${p => p?.isSelected ? '#121212' : '#4E5156'};
      }
      .option-description {
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        font-weight: 400;
        color: #4E5156;
      }
  }
  .ant-radio {
    .ant-radio-inner{
      background-color: #fff;
    &::after {
      background-color: #FFFFFF;
    }
  }
  &.ant-radio-checked .ant-radio-inner {
    border-color: #2D6CCA;
    background-color: #2D6CCA;
  }
}
`;
const StyledPostContainer = styled.div`
  padding: 10px 14px;
  width: 480px; 
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  .single-input {
    display: flex;
    align-items: center;
    gap: 6px;
    .date-input {
      border: 1px solid #D8D8D8;
      border-radius: 8px;
      width: 130px !important;
      .ant-picker-input {
        padding: 4px 10px !important;
      }
    }
  }
  .all-together-wrapper {
    display: flex;
    gap: 7px;
    .date-input {
      border: 1px solid #D8D8D8;
      border-radius: 8px;
      width: 145px !important;
      .ant-picker-input {
        padding: 4px 10px !important;
      }
    }
    .w-125 {
      width: 125px !important;
    }
    .time-input {
      border: 1px solid #D8D8D8;
      border-radius: 8px;
      width: 130px !important;
    }
  }
`;
const StyledSlider = styled(Slider)`
margin-top: 5px;
margin-bottom: 40px;
width: 464px;
.ant-slider {
  margin: 0px;
}
.ant-slider-rail {
  background: #E2E8EE !important;
  height: 6px !important;
  border-radius: 10px !important;
}
.ant-slider-step {
  height: 6px !important;
  top: 16px;
}
.ant-slider-track {
  height: 6px !important;
  background: linear-gradient(85.22deg, #2D6CCA 0%, #2D6CCA 100%) !important;
  border-radius: 23px !important;
}
.ant-slider-handle {
  width: 22px;
  height: 22px;
  background: #2D6CCA;
  box-shadow: 0 0 0 5px rgba(45, 108, 202, 0.4) !important;
  border: 0px !important;
  margin-top: -8px !important;
  position: relative;
  background-image: url('/icons/sliderArrows.png');
  background-repeat: no-repeat;
  background-position: center;
}
.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 2px;
  height: 8px;
  background-color: #fff;
  border: 1px solid #A3A4A4;
  cursor: pointer;
  border-radius: 20px;
  &.ant-slider-dot-active {
    position: absolute;
    top: -2px;
    width: 2px;
    height: 8px;
    background-color: #fff;
    border: 1px solid #A3A4A4;
    cursor: pointer;
    border-radius: 20px;
  }
}
.ant-slider-mark {
  top: 24px;
}
.ant-slider-dot-active {
  border: 0px;
  width: 2px;
  height: 12px;
  background-color: rgba(226, 232, 238, 0.30);
}
.ant-tooltip-content {
  .ant-tooltip-inner {
    border-radius: 6px;
    background-color: #2D6CCA;
  }
  .ant-tooltip-arrow-content {
    display: none;
  }
}
`;
const StyledDatePicker = styled(DatePicker)`
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  width: 145px !important;
  .ant-picker-input {
    padding: 4px 10px !important;
  }
`;
const ContentCheckbox = styled.div`
  width: 466px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #4E5156;
  .ant-checkbox-wrapper {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-radius: 2px !important;
        overflow: hidden;
      }
      &.ant-checkbox-checked::after {
        border-radius: 2px !important;
      }
    }
  }
`;
const KeywordInput = styled(Input)`
  margin-left: 6px;
  border: 1px solid #D8D8D8 !important;
  border-radius: 8px !important;
  height: 39px;
  &.ant-input-affix-wrapper-focused {
    box-shadow: none;
  }
`;
