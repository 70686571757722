import React from 'react';
import {CommonInput, ConfigrationCard, ConfigrationTitle, FieldsContainer, StyledFormItem} from './styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip} from 'antd';

const ConfigrationFields = ({fields, title}) => {
  // eslint-disable-next-line no-useless-escape
  const pattern = /^(https?:\/\/)?(www\.)?([-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))?$/;

  const validate = fieldType => {
    const obj = {
      email: [
        {type: 'email', message: 'Enter valid email'},
        {required: true, message: 'This field is required'},
      ],
      url: [
        {pattern: pattern, message: 'Enter a valid domain'},
        {required: true, message: 'This field is required'},
      ],
      password: [
        {required: true, message: 'This field is required'},
      ],
    };
    return obj[fieldType];
  };

  return (
    <div>
      <ConfigrationTitle>{title}</ConfigrationTitle>
      {fields.map((item, index) => (
        <ConfigrationCard key={index}>
          <div className='label'>
            {item?.label}
          </div>
          <FieldsContainer BorderTopRadius={index === 0} borderBottomRadius={index === fields?.length - 1}>
            {item?.notRequired ?
              <CommonInput
                BorderTopRadius={index === 0}
                borderBottomRadius={index === fields?.length - 1}
                type={item?.field}
                placeholder={item?.placeholder}
                autoComplete={'off'}
              />:
              <StyledFormItem name={item?.fieldKey} rules={validate(item?.field)}>
                <CommonInput
                  BorderTopRadius={index === 0}
                  borderBottomRadius={index === fields?.length - 1}
                  type={item?.field}
                  placeholder={item?.placeholder}
                  autoComplete={'off'}
                  disabled={item.disabled}
                  suffix={<Tooltip overlayStyle={{background: 'black', fontSize: '13px', padding: '0', borderRadius: '8px'}} overlayInnerStyle={{width: '297px'}} title='Currently only Google Servers are supported.'><FontAwesomeIcon icon={item.icon} color='gray' style={{cursor: 'pointer'}}/></Tooltip>}
                />
              </StyledFormItem>
            }
          </FieldsContainer>
        </ConfigrationCard>
      ))}
    </div>
  );
};

export default ConfigrationFields;
