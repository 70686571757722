import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Divider, Dropdown} from 'antd';
import {ReactNode, useEffect, useState} from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {faEye, faListCheck, faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {useStore} from '@/store/root-store';
import FreezeWrapper from '../../otto-page-detail/Component/freezTooltip';
import {notification} from '@/utils/notification-v2';

interface Props {
  articleStatus?: string;
  customLabel?: ReactNode | string;
  isMinified?: boolean;
  opportunityId?: number;
  campaignId?: number;
  isActive?: boolean;
  isInOTTO?: Boolean;
}

export const StatusSelectorOpportunity = observer(({
  articleStatus,
  opportunityId,
  campaignId,
  isInOTTO,
  isActive,
}: Props) => {
  const statusesSetup = {
    'DISCOVERED': {
      apiKey: 'discovered',
      label: 'Discovered',
      icon: faEye,
      iconColor: '#A3A4A4',
    },
    'OUTREACH': {
      apiKey: 'outreach',
      label: 'Outreach',
      icon: faListCheck,
      iconColor: '#FF8536',
    },
    // 'EMAILED': {
    //   apiKey: 'emailed',
    //   label: 'Emailed',
    //   icon: faInboxOut,
    //   iconColor: '#2AC155',
    // },
    // 'REPLIED': {
    //   apiKey: 'replied',
    //   label: 'Replied',
    //   icon: faInboxIn,
    //   iconColor: '#34AEF3',
    // },
  };

  const {campaigns: {updateOpportunityStatus, getCampaignSettings, campaignSettings, toogleStatus, chartData}} = useStore('');
  const [visible, setVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(articleStatus);
  useEffect(() => {
    if (articleStatus) setSelectedStatus(articleStatus);
  }, [articleStatus]);
  const statusChangeHandler = async (status: string, oldStatus: string) => {
    if (status !== oldStatus) {
      setSelectedStatus(status);
      await updateOpportunityStatus(opportunityId, campaignId);
      notification.success('Success', 'Opportunity Status has been changed to ' + status[0] + status?.slice(1)?.toLowerCase());
    }
    await getCampaignSettings(campaignId);
    if (campaignSettings?.outreachOpportunities === 0 && chartData?.isActive === true) {
      await toogleStatus(campaignId, 'opportunity');
    }
    setVisible(false);
  };
  const menuRender = () => {
    return <>
      {Object.keys(statusesSetup).map((item, idx) => {
        return <>
          <SingleStatusOption key={idx} onClick={() => statusChangeHandler(item, articleStatus)}>
            <FontAwesomeIcon icon={statusesSetup[item].icon} color={statusesSetup[item].iconColor} fontSize={14}/>
            <SingleStatusLabel>{statusesSetup[item].label}</SingleStatusLabel>
          </SingleStatusOption>
          {idx < Object.keys(statusesSetup).length-1 ? <Divider style={{marginTop: 0, marginBottom: 0}}/> : null}
        </>;
      })}
    </>;
  };

  return <Wrapper>
    <FreezeWrapper removeTooltip={!isInOTTO || isActive}>
      <Dropdown
        overlay={
          <> <Menu> {menuRender()} </Menu></>
        }
        open={visible}
        onOpenChange={val => setVisible(val)}
        trigger={['hover']}
        placement='bottomLeft'>
        <CurrentStatus color={statusesSetup[selectedStatus]?.iconColor}>
          <FontAwesomeIcon icon={statusesSetup[selectedStatus]?.icon} color={statusesSetup[selectedStatus]?.iconColor} fontSize={14} />
          <CurrentStatusLabel>{statusesSetup[selectedStatus]?.label}</CurrentStatusLabel>
          <FontAwesomeIcon icon={faCaretDown} fontSize={10} style={{marginLeft: '10px'}} />
        </CurrentStatus>
      </Dropdown>
    </FreezeWrapper>
  </Wrapper>;
});

const Wrapper = styled.div`

`;
const CurrentStatus = styled.div<{color?: string}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: ${p => `${p.color}12`};
  padding: 4px 12px;
  border-radius: 6px;
  width: fit-content;
`;
const CurrentStatusLabel = styled.div`
  margin-left: 5px;
`;

const Menu = styled.div`
  background-color: white;
  z-index: 1;
  border-radius: 5px;
  box-shadow:  0px 3px 8px rgba(0, 0, 0, 0.25);
`;
const SingleStatusOption = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 8px; 
`;
const SingleStatusLabel = styled.div`
  color: #121212;
  margin-left: 8px;
`;
