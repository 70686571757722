import {Checkbox, Dropdown, Empty, Input, Rate, Table, Tooltip} from 'antd';
import {Modal} from '@/components/common-components';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {faArrowLeft, faXmark, faCircleNotch, faMagnifyingGlass, faCheck} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useState, useEffect} from 'react';
import {faCaretDown, faLocationDot, faPen} from '@fortawesome/pro-solid-svg-icons';
import {ApproveModal, buttonOptions} from './modals/ApproveModal';
import {EditPostModal} from './modals/EditPostModal';
import {useStore} from '@/store/root-store';
import {OTTO_V2_API} from '@/api/otto-v2';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';
import moment from 'moment';
import {notification} from '@/utils/notification-v2';
import {GBPMediaLibraryModal} from '../GbpPosts/GbpPosts/gbpMediaLibraryModal';
import {getStandAloneLocations} from '@/utils/functions';
import {toJS} from 'mobx';
import {truncate} from '@/utils/string';

export const MyTasks = observer(({isInGBPStandAlone=false}) => {
  const [selectedTitle, setSelectedTitle] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [refresh, setRefresh] = useState(null);
  const [loader, setLoader]= useState(null);
  const [cursor, setCursor] = useState(null);
  const [openGbpLibraryModal, setOpenGbpLibraryModal] = useState(false);
  const [showGbpDropdown, setShowGbpDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [edit, setEdit] = useState('');
  const [showMoreList, setShowMoreList] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [isCopied, setIsCopied]=useState(false);
  const {ottoV2Store: {getMyTasks, myTasksLocations, setMyTasksLocations, selectedCategory, getGBPLocations, gbpLocations}} = useStore('');
  const activeArray = getStandAloneLocations(toJS(gbpLocations), searchValue)?.filter(location => myTasksLocations?.map(taskLocation => taskLocation?.id).includes(location?.id)) ?? [];

  useEffect(() => {
    setCursor(null);
    getData();
  }, [JSON.stringify(myTasksLocations)]);

  useEffect(() => {
    if (refresh) {
      getData();
    }
  }, [refresh]);

  useEffect(() => {
    setSearchValue('');
  }, [selectedCategory]);

  useEffect(() => {
    getGBPLocations(false, isInGBPStandAlone);
  }, []);

  useEffect(() => {
    if (modalData?.callToActionType == 'CALL') {
      setPhoneNumbers(gbpLocations?.find(location => location?.id == modalData?.location)?.phoneNumbers);
    }
  }, [modalData]);

  useEffect(() => {
    if (!isModalOpen && modalType===null) {
      setModalData(null);
    }
  }, [isModalOpen]);

  const getData = async (loadMore=false) => {
    if (myTasksLocations?.length) {
      await setLoader('data');
      const data = await getMyTasks(loadMore ? cursor : null, isInGBPStandAlone);
      if (loadMore) {
        setTableData(prevState => ([...prevState, ...data.tasks]));
      } else {
        setTableData(data.tasks);
      }
      if (data?.meta?.next) {
        setCursor(data?.meta?.next?.split('?')[1]?.split('&')[0]?.split('=')[1]);
      } else {
        setCursor(null);
      }
      setRefresh(false);
      await setLoader(null);
    } else {
      setTableData([]);
    }
  };

  const openModal = async (task, type) => {
    try {
      if (type !== 'edit') {
        setLoader(task.id);
        const response = await OTTO_V2_API.getModalDataByTypeAndId((type?.toLowerCase() + 's'), task?.id, isInGBPStandAlone);
        setModalData({...response, businessName: myTasksLocations?.find(item => item.id == response?.location)?.businessName ?? ''});
        setLoader(null);
        const title = type == 'POST' ? 'Approve Post' : type == 'QUESTION' ? 'Approve Q&A' : 'Approve Review Reply';
        if (type != 'edit') {
          const modalTitle = (
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginLeft: 15}}>
              <div>
                <div style={{fontSize: 24}}>{title}</div>
                {response?.schedulePublishingAt && <div style={{fontSize: 14, fontWeight: 400, marginTop: 8}}>Scheduled for {moment(response?.schedulePublishingAt).format('MMM DD, YYYY')}</div>}
              </div>
              {type == 'POST' && <div style={{fontSize: 12, color: '#2D6CCA', fontWeight: 400, cursor: 'pointer'}} onClick={() => openModal(task, 'edit')}>
                <FontAwesomeIcon icon={faPen}/> Edit post
              </div>}
            </div>
          );
          setSelectedTitle(modalTitle);
        }
        setModalType(type);
        setIsModalOpen(true);
      } else if (type == 'edit') {
        const modalTitle = (
          <div style={{marginLeft: 15}}>
            <div style={{fontSize: 14, fontWeight: 400, color: '#4E5156', cursor: 'pointer'}} onClick={() => openModal(task, 'POST')}><FontAwesomeIcon icon={faArrowLeft}/> Back to Post Preview</div>
            <div style={{fontSize: 24, marginTop: 10, marginRight: 5}} onClick={() => openModal(task, 'edit')}>Edit Post</div>
          </div>
        );
        setSelectedTitle(modalTitle);
        setModalType(type);
        setIsModalOpen(true);
      }
    } catch {
      setLoader(null);
      notification.error('Failed', 'Failed to Fetch Data');
    }
  };

  const showMoreHandler = (id, type) => {
    if (showMoreList?.some(item => item.id == id)) {
      setShowMoreList(prevState => prevState?.filter(item => item.id !== id));
    } else {
      setShowMoreList(prevState => [...prevState, {id, type}]);
    }
  };

  const isShowMore = (id, type) => {
    return showMoreList?.some(item => (item.id == id && item.type == type));
  };

  const columns = [
    {
      title: 'BUSINESS',
      dataIndex: 'location',
      key: 'name',
      width: '25%',
      render: locationId => (
        <div style={{marginRight: 20}}>
          <div style={{fontWeight: 600}}>{myTasksLocations?.find(item => item.id == locationId)?.businessName}</div>
          <p style={{marginBottom: 'unset', fontSize: 12, color: '#4E5156'}}>{myTasksLocations?.find(item => item.id == locationId)?.businessAddress}</p>
        </div>
      ),
    },
    {
      title: 'PREVIEW',
      dataIndex: 'data',
      key: 'data',
      render: (data, task) => (
        <>
          {data ? <>
            {task.type == 'POST' && <PreviewContainer>
              <div className='image-container'>
                {data?.imageUrl ?
                  <img referrerPolicy='no-referrer' key={data?.imageUrl} src={data?.imageUrl} className='image' style={{width: '-webkit-fill-available', objectFit: 'cover'}}/> :
                  <Tooltip title={'Image not available'}>
                    <img src='/img/icon/broken-image.svg' className='image' style={{width: 50, objectFit: 'cover'}}/>
                  </Tooltip>}
              </div>
              <div className='post'>
                {(data?.topicType !== 'STANDARD' && data?.title) && <div className='content-title'>{data?.title}</div>}
                {(data?.content?.length > 300 && !isShowMore(task.id, 'post')) ? data?.content?.slice(0, 300) : <>
                  {data?.content}
                  {((data?.callToActionType === 'CALL') || (data?.callToActionType !== 'CALL' && data?.callToActionUrl)) && (
                    <Tooltip
                      title={data?.callToActionType == 'CALL' && (gbpLocations?.find(location => location?.id == data?.location)?.phoneNumbers?.length ? isCopied ? <span><FontAwesomeIcon icon={faCheck} style={{color: '#2AC155'}}/>{` Phone number copied`}</span> : 'Copy phone number' : 'No phone number available')}
                      placement='bottom'
                      overlayInnerStyle={{backgroundColor: 'black'}}
                      onVisibleChange={visible => {
                        if (!visible) {
                          setTimeout(() => {
                            setIsCopied(false);
                          }, 300);
                        }
                      }}><div className='learn-more' style={{width: 'fit-content'}}>
                        <a
                          style={data?.callToActionType === 'CALL' && !gbpLocations?.find(location => location?.id == data?.location)?.phoneNumbers?.length ? {cursor: 'not-allowed'} : {}}
                          href={data?.callToActionType !== 'CALL' && data?.callToActionUrl}
                          onClick={() => {
                            if (data?.callToActionType === 'CALL') {
                              navigator.clipboard.writeText(gbpLocations?.find(location => location?.id == data?.location)?.phoneNumbers[0]);
                              setIsCopied(true);
                            }
                          }}
                          target={data?.callToActionType === 'CALL' ? '_self' : '_blank'}
                          rel='noreferrer'
                        >
                          {buttonOptions?.find(item => item.value === data?.callToActionType)?.label}
                        </a>
                      </div></Tooltip>
                  )}
                </>}
                {data?.content?.length > 300 && <div className='show-more' onClick={() => showMoreHandler(task?.id, 'post')}>{!isShowMore(task.id, 'post') ? 'Show More' : 'Show Less'}</div>}
              </div>
            </PreviewContainer>}
            {task.type == 'QUESTION' && <PreviewContainer style={{flexDirection: 'column'}}>
              <div className='question'>
                <div className='label'>Question:</div>
                <div className='question-content'>
                  {data?.content}
                </div>
              </div>
              <div className='qa-answer'>
                <div className='label'>Answer:</div>
                <div className='answer-content'>
                  {(data?.ownersAnswer?.content?.length > 250 && !isShowMore(task.id, 'qa-answer')) ? `${data?.ownersAnswer?.content?.slice(0, 250)}...` : data?.ownersAnswer?.content}
                  {data?.ownersAnswer?.content?.length > 250 && <div className='show-more' onClick={() => showMoreHandler(task?.id, 'qa-answer')}>{!isShowMore(task.id, 'qa-answer') ? 'Show More' : 'Show Less'}</div>}
                </div>
              </div>
            </PreviewContainer>}
            {task.type == 'REVIEW' && <PreviewContainer>
              <div className='review' style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  Rating: <Rate style={{fontSize: 12, marginRight: 'unset', marginLeft: 10, marginBottom: 3}} disabled value={data?.starRating} />
                </div>
                {(data?.content?.length > 150 && !isShowMore(task.id, 'review')) ? data?.content?.slice(0, 150) : data?.content}
                {data?.content?.length > 150 && <div className='show-more' onClick={() => showMoreHandler(task?.id, 'review')}>{!isShowMore(task.id, 'review') ? 'Show More' : 'Show Less'}</div>}
              </div>
              <div className='answer'>{(data?.reply?.content?.length > 150 && !isShowMore(task.id, 'review-answer')) ? data?.reply?.content?.slice(0, 150) : data?.reply?.content}
                {data?.reply?.content?.length > 150 && <div className='show-more' onClick={() => showMoreHandler(task?.id, 'review-answer')}>{!isShowMore(task.id, 'review-answer') ? 'Show More' : 'Show Less'}</div>}
              </div>
            </PreviewContainer>}
          </> : <div>No Preview Available</div>}
        </>
      ),
    },
    {
      title: 'ACTION',
      dataIndex: 'type',
      key: 'Action',
      width: '150px',
      render: (text, task) => (
        <div onClick={() => openModal(task, text)} style={{background: '#2D6CCA26', width: 'fit-content', padding: '7px 12px', color: '#2D6CCA', borderRadius: 8, cursor: 'pointer'}}>
          <FontAwesomeIcon style={{marginRight: 2, display: loader !== task.id && 'none'}} spin={loader == task.id} icon={faCircleNotch} />
          Review
        </div>
      ),
    },
  ];

  const selectImageFromMediaLibrary = image => {
    setLoader('image-file');
    if (image?.url) {
      try {
        setModalData(prevState => {
          return ({...prevState, imageUrl: image?.url});
        });
        setOpenGbpLibraryModal(false);
        setIsModalOpen(true);
        setLoader(null);
      } catch (e) {
        setLoader(null);
        return Promise.reject(e);
      }
    } else {
      setLoader(null);
    }
  };
  // const filteredLocations = getOttoV2Project?.connectedData?.gbpDetailsV2?.filter(d => searchValue ? d?.businessName?.toLowerCase()?.includes(searchValue?.toLowerCase()) : true);
  // console.log('filteredLocations', filteredLocations);
  const myTaskLocationHandler = (location, event) => {
    let updatedLocations = JSON.parse(JSON.stringify(myTasksLocations));
    if (location == 'all') {
      if (updatedLocations?.length == getStandAloneLocations(toJS(gbpLocations), searchValue)?.length) {
        updatedLocations = [];
      } else {
        updatedLocations = JSON.parse(JSON.stringify(getStandAloneLocations(toJS(gbpLocations), searchValue)));
      }
    } else {
      if (event.target.checked) {
        updatedLocations?.push(location);
      } else {
        updatedLocations = updatedLocations?.filter(item => item.id != location?.id);
      }
    }
    setMyTasksLocations(updatedLocations);
  };

  const myTasksLocationList = (
    <DropdownContainer>
      <GbpSearchInput onChange={e => setSearchValue(e.target.value)} value={searchValue} placeholder='Search...'
        prefix={<FontAwesomeIcon icon={faMagnifyingGlass} fontSize={'12px'} color='#A3A4A4' />}
      />
      <DropdownListContainer>
        {getStandAloneLocations(toJS(gbpLocations), searchValue)?.length > 1 && <Checkbox key='all' checked={activeArray?.length == getStandAloneLocations(toJS(gbpLocations), searchValue)?.length} onClick={event => myTaskLocationHandler('all', event)}>All</Checkbox>}
        {getStandAloneLocations(toJS(gbpLocations), searchValue)?.length ? getStandAloneLocations(toJS(gbpLocations), searchValue)?.map(business => (
          <Checkbox key = {business?.id} style={{width: '100%'}} checked={myTasksLocations?.some(item => item.id == business?.id)} value={business} onClick={event => myTaskLocationHandler(business, event)}>
            <div>
              <div style={{fontWeight: 'bold', fontSize: '12px'}}>{business?.businessName}</div>
              <p style={{color: '#4E5156', fontSize: '12px', fontFamily: 'Inter', fontWeight: '400'}}>{business?.businessAddress}</p>
            </div>
          </Checkbox>
        )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </DropdownListContainer>
    </DropdownContainer>
  );
  return (
    <>
      {loader == 'data' ? <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}><RingLoaderV2 width='500px' height='500px' fontSize={50} /></div> :
        <div>
          {isInGBPStandAlone && <Tooltip title={activeArray?.length ? <div dangerouslySetInnerHTML={{__html: activeArray?.map(item => `• ${item?.businessName}`)?.join('<br/>')}}/> : ''} overlayInnerStyle={{backgroundColor: '#121212', borderRadius: '6px'}}>
            <Dropdown trigger={['click']} overlay={myTasksLocationList} visible={showGbpDropdown} onVisibleChange={e => setShowGbpDropdown(e)}>
              <DropdownWrapper>
                <FontAwesomeIcon icon={faLocationDot} fontSize={14}/>
                  Business: &nbsp;{activeArray?.length ? activeArray?.length == 1 ? truncate(activeArray?.[0]?.businessName, 30) : (+ activeArray?.length + ' selected'): 'Select'}
                <FontAwesomeIcon icon={faCaretDown} fontSize={14}/>
              </DropdownWrapper>
            </Dropdown>
          </Tooltip>}
          <StyledTable
            columns={columns}
            dataSource={tableData}
            pagination={false}
          />
        </div>}
      {cursor && <div onClick={() => getData(true)} style={{textAlign: 'center', marginTop: 12, cursor: 'pointer', color: '#2d6cca'}}>Load More</div>}
      {isModalOpen && <ModalStyled
        width={620}
        visible={isModalOpen}
        closable={true}
        onClose={() => {
          setIsModalOpen(false);
          setModalData(null);
          setModalType(null);
        }}
        closeIcon={faXmark && <FontAwesomeIcon icon={faXmark} />}
        mask={true}
        title={selectedTitle}
      >
        {modalType != 'edit' && <ApproveModal modalType={modalType} modalData={modalData} setIsModalOpen={setIsModalOpen} setRefresh={setRefresh} edit={edit} setEdit={setEdit} phoneNumbers={phoneNumbers} isInGBPStandAlone={isInGBPStandAlone} />}
        {modalType == 'edit' && <EditPostModal postData={modalData} setPostData={setModalData} setRefresh={setRefresh} setIsModalOpen={setIsModalOpen} setOpenGbpLibraryModal={setOpenGbpLibraryModal} openModal={openModal} phoneNumbers={phoneNumbers} isInGBPStandAlone={isInGBPStandAlone} />}
      </ModalStyled>}
      {openGbpLibraryModal && <GBPMediaLibraryModal
        openGbpLibraryModal={openGbpLibraryModal}
        setOpenGbpLibraryModal={setOpenGbpLibraryModal}
        setOpenGBPAutomationModal={setIsModalOpen}
        isLocalSEOMyTask={true}
        selectImageFromMediaLibrary={selectImageFromMediaLibrary}
        modalData={modalData}
        isInGBPStandAlone={isInGBPStandAlone}
      />
      }
    </>
  );
});


const StyledTable = styled(Table)`
  .ant-table-cell {
    padding: 10px 8px 13px 8px !important;
    vertical-align: top;
  }
  th::before {
    content: null;
  }
`;

const ModalStyled = styled(Modal)`
  .rc-dialog-content {
    padding: 10px;
  }
  .rc-dialog-title {
    font-size: 24px !important;
    font-weight: 600 !important;
  }
  .rc-dialog-wrap {
    align-items: center !important;
  }
  .ant-divider-horizontal {
    margin: 0 !important;
  }
  .rc-dialog-body {
    padding: 20px 30px 20px 30px !important;
    border-radius: 12px !important;
  }
  .rc-dialog-close {
    font-size: 28px !important;
    color: white !important;
    left: 102% !important;
    top: -3px !important;
    opacity: 1 !important;
  }
  .ant-btn-submit {
    background-color:  #2D6CCA !important;
    color: white;
    padding: 13px 25px 14px 25px;
    border-radius: 8px;
    height: 44px;
    border: none;
    margin-top: 15px;
    &:hover {
      background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),#2D6CCA !important;
      background-color:  '#7f4ead2E';
      border: 1px solid transparent !important;
      color: #fff;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #F9F9FB;
    border-radius: 10px;
  }

  .rc-dialog-header {
  background: var(--Table-row-stripe, #F9F9FB);
  padding: 19px 19px 2px 19px;
  }
  .rc-dialog-content {
  background: var(--Table-row-stripe, #F9F9FB);
  }
`;
const DropdownWrapper = styled.div`
  padding: 0 8px;
  font-size: 14px;
  border: 1px solid #E8E8E8;
  border-radius: 8px;
  color: #4E5156;
  display: flex;
  align-items: center;  
  gap: 9px;
  cursor: pointer;
  background: #fff;
  width: fit-content;
  max-width: 500px;
  height: 40px;
  margin-top: 10px;
`;
const DropdownContainer = styled.div`
  width: 400px;
  border-radius: 12px;
  background: white;
  padding: 14px 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;
const GbpSearchInput = styled(Input)`
  width: 380px;
  height: 32px;
  margin-top: 9px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #CDCDCD;
`;
const DropdownListContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #F9F9FB;
    border-radius: 10px;
  }
  .ant-checkbox-wrapper {
    margin-left: unset;
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  .image-container {
    width: 55px;
    margin-right: 10px
  }
  .show-more {
    cursor: pointer;
    color: #2d6cca
  }
  .question {
    display: flex;
    .label {
      font-weight: 600;
      width: 68px;
    }
    .question-content {
      font-weight: 600;
      max-width: 91%;
    }
  }
  .qa-answer {
    display: flex;
    .label {
      font-weight: 600;
      width: 68px;
    }
    .answer-content {
      max-width: 91%;
    }
  }
  .answer {
    width: 50%;
  }
  .post {
    width: calc(100% - 55px);
  }
  .review {
    font-weight: 600;
    margin-right: 10px;
    width: 50%;
  }
  .learn-more > a {
    cursor: pointer;
    font-style: italic;
    color: #2D6CCA;
    }
`;
