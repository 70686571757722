import {CaretDownFilled, CaretUpFilled} from '@ant-design/icons';
import {Input} from 'antd';
import React from 'react';
import styled from 'styled-components';

interface CustomNumberInputProps {
  label: string;
  valueState: string;
  setValueState: React.Dispatch<React.SetStateAction<string>>;
  onInputChange?: React.ChangeEventHandler<HTMLInputElement>;
  onInputKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  min?: number;
  max?: number;
  style?: React.CSSProperties;
  hasError?: boolean;
  errorMessage?: string;
  arrowWrapperStyles?: React.CSSProperties;
  disabled?: boolean;
}

export const CustomNumberInput:React.FC<CustomNumberInputProps> = ({label, valueState, setValueState, onInputChange, onInputKeyDown, min, max, style, hasError, errorMessage, arrowWrapperStyles, disabled}) => {
  const onChange = e => {
    const inputValue = e?.target?.value;
    const replacedValue = inputValue.replace(/[^0-9]/g, '');
    if (replacedValue === '') {
      setValueState('0');
      return;
    }
    const numericValue = Number(replacedValue);
    if (max !== undefined && numericValue > max) {
      setValueState('10');
    } else if (min !== undefined && numericValue < min) {
      setValueState(String(min));
      return;
    } else {
      setValueState(replacedValue);
    }
  };
  const onKeyDown = e => {
    if (e.keyCode === 38 && (max === undefined || Number(valueState) < max)) {
      setValueState(prevState => String(Number(prevState)+1));
    } else if (e.keyCode === 40 && (min === undefined || Number(valueState) > min)) {
      setValueState(prevState => String(Number(prevState)-1));
    }
  };
  return (
    <StyledWrapper style={style}>
      <div className='input-label'>{label}</div>
      <StyledNumberInput
        status={hasError && 'error'}
        defaultValue={String(valueState)}
        value={String(valueState)}
        type='text'
        onChange={onInputChange ? onInputChange : onChange}
        onKeyDown={onInputKeyDown ? onInputKeyDown : onKeyDown}
        className='number-input'
        disabled={disabled}
      />
      <ControlsWrapper style={arrowWrapperStyles}>
        <CaretUpFilled color='#121212' className='up-arrow' onClick={() => setValueState(prevState => Number(prevState) < max ? String(Number(prevState)+1) : prevState)}/>
        <CaretDownFilled color='#121212' className='up-down' onClick={() => setValueState(prevState => Number(prevState) > min ? String(Number(prevState)-1) : prevState)}/>
      </ControlsWrapper>
      {(hasError && errorMessage) ? <div className='error-message'>{errorMessage}</div> : <></>}
    </StyledWrapper>
  );
};

const StyledNumberInput = styled(Input)`
  height: 38px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #121212;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
`;
const ControlsWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 10px;
  z-index: 950;
  .anticon {
    display: block;
  }
`;
const StyledWrapper = styled.div`
   width: 130px;
   position: relative;
    .error-message {
      width: max-content;
      color: red;
      font-size: 14px;
      font-family: 'Inter', sans-serif;
      padding-left: 4px;
      position: absolute;
      top: 40px;
    }
    .input-label {
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-weight: 400;
      color: #4E5156;
    }
    .number-input {
      border: 1px solid #D8D8D8;
      border-radius: 8px;
      overflow: hidden;
      .ant-input-number-handler-wrap {
        z-index: 1050 !important;
        transition: none !important;
        height: 86% !important;
      }
      &:hover {
        .ant-input-number-handler-wrap {
          .ant-input-number-handler {
            border-left: 0px transparent !important;
          }
          .ant-input-number-handler-up {
            .ant-input-number-handler-up-inner {
              margin-top: 0px !important;
            }
            &:hover {
              height: 50% !important;
            }
          }
          .ant-input-number-handler-down {
            border-top: 0px transparent !important;
            &:hover {
              height: 50% !important;
            }
          }
          &:hover {
            .ant-input-number-handler-up {
              height: 50% !important;
          }
          }
        }
      }
    }
`;
