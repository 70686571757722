import React, {useEffect, useState} from 'react';
import {Alert, Collapse, Divider, Form, Input, Popover, Progress, Select, Tooltip} from 'antd';
import styled from 'styled-components';
import {Button} from '@/components/common-components/v2/Button';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';
import {UrlExclusionSection, StyledModal, StyledSlider} from '@/components/dashboard/pages/site-audit-v2/common/CrawlSettingModal';
import styles from '@/components/dashboard/pages/site-audit-v2/audited-sites/siteCard/index.module.scss';
import {COLORS} from './colors';
import {formatNumber, numberWithCommas} from '@/utils/number';
import {isSAPublicPage} from '@/utils/url';
import {faSquareQuestion} from '@fortawesome/pro-duotone-svg-icons';
import {getInversePercentageColor} from '@/utils/colors';
import {formatCrawlRules, getPercentage, StyledSelect} from '@/components/dashboard/pages/site-audit-v2/audited-sites/siteCard';
import {notification} from '@/utils/notification-v2';
import {faChevronDown, faChevronUp, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {LargeBadge} from '../components/badge';

const {Option} = Select;

const openLink = url => {
  if (url) {
    window.open(url, '_blank');
  }
};

export const parseCrawlRules = crawlRules => {
  return crawlRules?.split('\n')?.map(rule => {
    const [action, value] = rule.split(': ');
    const parsedAction = action === 'Disallow' ? 'Exclude' : 'Include';
    const parsedRule = value?.startsWith('/*') ? 'Contain' : 'Start with';
    const parsedValue = value?.startsWith('/*') ? value?.replace('/*', '') : value?.substr(1);

    return {
      action: parsedAction,
      rule: parsedRule,
      value: parsedValue,
    };
  });
};

interface Props {
  showCrawlModal: boolean;
  setShowCrawlModal: (value: boolean) => void;
  data?: any;
}
export const CrawlSettingsModal: React.FC<Props> = observer(({showCrawlModal, setShowCrawlModal, data}) => {
  const {
    siteAuditorV2: {
      loadAuditedSitesList,
      getAuditSitesList,
      loadingCrawlUpdate,
      crawlBudgetError,
      setCrawlBudgetError,
      handleCrwalSettings,
      setSpecificIDResponse,
    },
    settings: {
      customer: {setAuthModal,
        profile: {
          quotaUtilization,
        },
      },
    },
  } = useStore('');

  const [crawlSpeedVal, setCrawlSpeedVal] = useState(data?.crawlConcurrency || 5);
  const [userAgent, setUserAgent] = useState(data?.crawlAgent);
  const [crawlBudgetVal, setCrawlBudgetVal] = useState(data?.crawlBudget);
  const [crawlSelectOption, setCrawlSelectOption] = useState(isSAPublicPage() ? 'daily' : (data?.nextProcessingDeltaDays == 1 ? 'daily' : data?.nextProcessingDeltaDays == 7 ? 'weekly' : data?.nextProcessingDeltaDays == 30 ? 'monthly' : [0, '0', null, 'null'].includes(data?.nextProcessingDeltaDays) ? 'never' : 'Custom'));
  const [crawlFrequency, setCrawlFrequency] = useState(`${data?.nextProcessingDeltaDays || ''}`);
  const [isActive, setIsActive] = useState(null);
  const [userAgentDetail, setUserAgentDetail] = useState('SearchAtlas Bot (https://www.searchatlas.com)');
  const [conditions, setConditions] = useState(parseCrawlRules(data?.crawlRules) ?? [
    {action: 'Exclude', rule: 'Contain', value: ''},
  ]);

  const userAgentData = {
    google_chrome_desktop: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/60.0.3112.113 Safari/537.36',
    google_chrome_mobile: 'Mozilla/5.0 (iPhone; CPU iPhone OS 10_3 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) CriOS/56.0.2924.75 Mobile/14E5239e Safari/602.1',
    googlebot_desktop: 'Mozilla/5.0 (compatible; Googlebot/2.1)',
    googlebot_mobile: 'Mozilla/5.0 (Linux; Android 6.0.1; Nexus 5X Build/MMB29P) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/41.0.2272.96 Mobile Safari/537.36 (compatible; Googlebot/2.1)',
    bingbot_desktop: 'Mozilla/5.0 (compatible; bingbot/2.0)',
    bingbot_mobile: 'Mozilla/5.0 (iPhone; CPU iPhone OS 7_0 like Mac OS X) AppleWebKit/537.51.1 (KHTML, like Gecko) Version/7.0 Mobile/11A465 Safari/9537.53 (compatible; bingbot/2.0)',
    slurp: 'Mozilla/5.0 (compatible; Yahoo! Slurp)',
    yandexbot: 'Mozilla/5.0 (compatible; YandexBot/3.0)',
    baiduspider: 'Baiduspider+',
    screaming_frog: 'Screaming Frog SEO Spider/2.11',
    duckduckgo: 'Mozilla/5.0 (compatible; DuckDuckGo-Favicons-Bot/1.0)',
    searchatlas: 'SearchdhsjAtlas Bot (https://www.searchatlas.com)',
  };

  const utilizedQuota = isSAPublicPage() ? getAuditSitesList?.length || 0 : quotaUtilization?.ca?.allowedSiteAuditorProjects?.consumed;
  const totalQuota = isSAPublicPage() ? 2 : quotaUtilization?.ca?.allowedSiteAuditorProjects?.total;
  const pagesQuotaDiff = totalQuota - utilizedQuota;
  const projectQuotaDiff = quotaUtilization?.ca?.allowedSiteAuditorPages?.total - quotaUtilization?.ca?.allowedSiteAuditorPages?.consumed;
  useEffect(() => {
    if (showCrawlModal) {
      setCrawlSpeedVal(data?.crawlConcurrency);
      setCrawlBudgetVal(data?.crawlBudget);
      setUserAgent(data?.crawlAgent);
      setUserAgentDetail(data?.crawlAgentFull);
      setCrawlFrequency(`${data?.nextProcessingDeltaDays || '0'}`);
      setCrawlSelectOption(isSAPublicPage() ? 'daily' : (data?.nextProcessingDeltaDays == 1 ? 'daily' : data?.nextProcessingDeltaDays == 7 ? 'weekly' : data?.nextProcessingDeltaDays == 30 ? 'monthly' : [0, '0', null, 'null'].includes(data?.nextProcessingDeltaDays) ? 'never' : 'Custom'));
      setConditions(parseCrawlRules(data?.crawlRules));
    }
  }, [showCrawlModal]);

  useEffect(() => {
    if (crawlSelectOption) {
      switch (crawlSelectOption) {
        case 'daily':
          setCrawlFrequency('1');
          break;
        case 'weekly':
          setCrawlFrequency('7');
          break;
        case 'monthly':
          setCrawlFrequency('30');
          break;
        case 'never':
          setCrawlFrequency('0');
          break;
        default:
          break;
      }
    }
  }, [crawlSelectOption]);

  const validateConditions = (array: { action: string; rule: string; value: string }[]) => {
    const seen: string[] = [];
    if (!array?.length) {
      return {isValid: true, message: ''};
    }
    for (const obj of array) {
      const key = JSON.stringify({action: obj?.action, rule: obj?.rule, value: obj?.value});
      if (seen.includes(key)) {
        return {
          isValid: false,
          message: 'Duplicate conditions detected. Please ensure each condition is unique.',
        };
      }
      for (const seenKey of seen) {
        const parsedSeenObj = JSON.parse(seenKey);
        if (parsedSeenObj?.rule === obj?.rule && parsedSeenObj?.value === obj?.value && parsedSeenObj?.action !== obj?.action) {
          return {
            isValid: false,
            message: `Opposite actions for the same url detected. Please choose one action to prevent conflicts.`,
          };
        }
      }
      seen.push(key);
    }
    return {isValid: true, message: ''};
  };

  const onCrawlFinish = async (value: any) => {
    const dataToPatch = {
      ...value,
      selected_user_agent: userAgent,
      crawl_concurrency: crawlSpeedVal,
      crawl_rules: formatCrawlRules(conditions),
    };
    const validation = validateConditions(conditions);
    if (!validation?.isValid) {
      notification.error('Error', validation?.message, false, 'OK');
      return;
    }
    if (!isSAPublicPage()) {
      dataToPatch['crawl_budget'] = crawlBudgetVal;
      dataToPatch['next_processing_delta_days'] = crawlFrequency;
    }
    await handleCrwalSettings(data.id, dataToPatch);
    loadAuditedSitesList(true);
    await setSpecificIDResponse(data?.id);
    if (crawlBudgetError?.length == 0) {
      setShowCrawlModal(false);
      setConditions([
        {action: 'Exclude', rule: 'Contain', value: ''},
      ]);
    }
  };

  const onCrawlBudgetChange = (value: any) => {
    if (!isSAPublicPage()) {
      setCrawlBudgetVal(value);
    }
  };

  const onCrawlBudgetInputChange = (value: any) => {
    const val = Number(value.target.value);
    if (value.target.value?.match(/^[0-9]+$/)) {
      if (val < 10001) {
        setCrawlBudgetVal(val);
      }
    } else if (value.target.value == '') {
      setCrawlBudgetVal(0);
    }
  };

  const onFrequencyChange = (value: any) => {
    const val = Number(value);
    if (val == 0) {
      setCrawlFrequency('');
    } else {
      if (val < 31 && val > -1) {
        setCrawlFrequency(val?.toString());
      }
    }
  };

  const onUserAgentChange = (value: any) => {
    setUserAgent(value);
    setUserAgentDetail(userAgentData[value]);
  };

  const onCrawlSpeedChange = (value: any) => {
    setCrawlSpeedVal(value);
  };

  const onCrawlSpeedInputChange = (value: any) => {
    const val = Number(value.target.value);
    if (value.target.value?.match(/^[0-9]+$/)) {
      if (val < 51) {
        setCrawlSpeedVal(val);
      }
    } else if (value.target.value == '') {
      setCrawlSpeedVal(0);
    }
  };

  const handleConditionChange = (index: number, key: string, value: string) => {
    const updatedConditions = [...conditions];
    updatedConditions[index][key] = value;
    setConditions(updatedConditions);
  };

  const handleDeleteCondition = (index: number) => {
    const updatedConditions = conditions.filter((_, i) => i !== index);
    const excludeCondition = (conditions?.filter(condition => condition?.action === 'Exclude')?.length > 1);
    if (updatedConditions?.length && !excludeCondition) {
      const excludeUpdatedCondition = [...updatedConditions];
      excludeUpdatedCondition[0] = {...excludeUpdatedCondition[0], action: 'Exclude'};
      setConditions(excludeUpdatedCondition);
    } else {
      setConditions(updatedConditions);
    }
  };


  const handleAddCondition = () => {
    if (conditions?.length) {
      setConditions([...conditions, {action: 'Exclude', rule: 'Contain', value: ''}]);
    } else {
      setConditions([{action: 'Exclude', rule: 'Contain', value: ''}]);
    }
  };

  const onCloseModal = () => {
    setShowCrawlModal(false);
  };

  const excludeCondition = (conditions?.filter(condition => condition?.action === 'Exclude')?.length > 1);
  return (
    <StyledModal
      className={styles.modalIconStyle}
      visible={showCrawlModal}
      width={550}
      onClose={() => {
        setShowCrawlModal(false);
        setCrawlBudgetError('');
      }}
    >
      <div>
        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 20}}>
          <p style={{fontSize: 24, fontWeight: 600, marginBottom: 0}}>Crawl Settings</p>
          <><QuotaContainer><QuotaDivSA>
            <span style={{display: 'flex', color: '#4E5156', alignItems: 'baseline'}} >Projects:<QuotaValues>{formatNumber(utilizedQuota) || 0} </QuotaValues> <QuotaSubValue>/{formatNumber(totalQuota) || 100}</QuotaSubValue>
              <Popover
                trigger={'hover'}
                overlayInnerStyle={{
                  width: 157,
                  background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #2D2F34',
                  boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.08)',
                  borderRadius: 8,
                }}
                overlayClassName={styles.overlay}
                placement='bottom'
                zIndex={9999}
                content={<>
                  <TooltipTitle>Total number of Site Audit projects</TooltipTitle>
                  <Divider style={{background: '#4E5156', margin: '10px 0px'}} />
                  <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
                    <> <span>Used:</span><span>{numberWithCommas(utilizedQuota)}</span></>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
                    <> <span>Remaining:</span><span style={{color: pagesQuotaDiff > 0 ? '#15ac47' : '#f44343'}}>{numberWithCommas(pagesQuotaDiff)}</span></>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
                    <> <span>Total:</span><span>{numberWithCommas(totalQuota)}</span></>
                  </div>
                </>} >
                <FontAwesomeIcon icon={faSquareQuestion} color='#A3A4A4' fontSize={12} style={{marginLeft: 6, marginTop: 5, cursor: 'pointer'}} />
              </Popover>
            </span>
            <ProgressStyled
              trailColor={'#4E5156'}
              strokeColor={getInversePercentageColor(getPercentage(Number(utilizedQuota), Number(totalQuota)))}
              percent={getPercentage(Number(utilizedQuota), Number(totalQuota))}
              showInfo={false} />
          </QuotaDivSA>
          {!isSAPublicPage() && <QuotaDivSA>
            <span style={{display: 'flex', color: '#4E5156', alignItems: 'baseline'}} >Pages:<QuotaValues>{formatNumber(quotaUtilization?.ca?.allowedSiteAuditorPages?.consumed) || 0} </QuotaValues> <QuotaSubValue>/{formatNumber(quotaUtilization?.ca?.allowedSiteAuditorPages?.total) || 100}</QuotaSubValue>
              <StyledPopover
                trigger={'hover'}
                overlayInnerStyle={{
                  width: 210,
                  background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #2D2F34',
                  boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.08)',
                  borderRadius: 8,
                }}
                overlayClassName={styles.overlay}
                zIndex={9999}

                placement='bottom'
                content={<>
                  <TooltipTitle>Maximum number of monitored pages across all projects.</TooltipTitle>
                  <Divider style={{background: '#4E5156', margin: '10px 0px'}} />
                  <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
                    <> <span>Used:</span><span>{numberWithCommas(quotaUtilization?.ca?.allowedSiteAuditorPages?.consumed)}</span></>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
                    <> <span>Remaining:</span><span style={{color: projectQuotaDiff > 0 ? '#15ac47' : '#f44343'}}>{numberWithCommas(projectQuotaDiff)}</span></>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
                    <> <span>Total:</span><span>{numberWithCommas(quotaUtilization?.ca?.allowedSiteAuditorPages?.total)}</span></>
                  </div>
                </>} >
                <FontAwesomeIcon icon={faSquareQuestion} color='#A3A4A4' fontSize={12} style={{marginLeft: 6, marginTop: 5, cursor: 'pointer'}} />
              </StyledPopover>
            </span>
            <ProgressStyled
              trailColor={'#4E5156'}
              strokeColor={getInversePercentageColor(getPercentage(Number(quotaUtilization?.ca?.allowedSiteAuditorPages?.consumed), Number(quotaUtilization?.ca?.allowedSiteAuditorPages?.total)))}
              percent={getPercentage(Number(quotaUtilization?.ca?.allowedSiteAuditorPages?.consumed), Number(quotaUtilization?.ca?.allowedSiteAuditorPages?.total))}
              showInfo={false} />
          </QuotaDivSA>}</QuotaContainer></>
        </div>
        <Form onFinish={onCrawlFinish}>
          <div className={'content-container'}>
            {data.propertyUrl && <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 9}}>
              <p style={{fontSize: 12, color: '#121212', marginBottom: 0}}>Site</p>
              <p style={{color: '#A3A4A4', width: 258, marginBottom: 0}}><a style={{color: '#2D6CCA', cursor: 'pointer', fontSize: 14}} href={'#'} onClick={() => openLink(data?.propertyUrl)}>{data.propertyUrl}</a></p>
            </div>}
            {crawlBudgetError &&<div style={{paddingTop: '10px', paddingBottom: '20px'}}> <Alert
              // message='Error Text'
              description={crawlBudgetError}
              type='error'
              closable
              onClose={()=> {
                setCrawlBudgetError('');
                setConditions([
                  {action: 'Exclude', rule: 'Contain', value: ''},
                ]);
              }}
            /> </div>}
            <Form.Item name='crawl_budget' style={isSAPublicPage() ? {marginBottom: 14, pointerEvents: 'none', filter: 'opacity(0.5)'} : {marginBottom: 14}}>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <p style={{fontSize: 12, color: '#121212', marginBottom: 0}}>Pages to Crawl</p>
                <StyledSlider
                  style={{width: 258, margin: 0}}
                  value={crawlBudgetVal}
                  className={styles.sliderStyle}
                  defaultValue={crawlBudgetVal}
                  max={10000}
                  min={1}
                  onChange={onCrawlBudgetChange}
                />
              </div>
              <div style={{display: 'flex', marginLeft: 225}}>
                <span style={{marginTop: 4, fontSize: 12, color: '#4E5156'}}>Top </span>
                <span>
                  <div style={{width: '80px', height: 25, marginLeft: 10, marginRight: 10}}>
                    <RoundInput
                      tabIndex={-1}
                      onChange={onCrawlBudgetInputChange}
                      value={crawlBudgetVal}
                      defaultValue={data?.crawlBudgetVal}
                    />
                  </div>
                </span>
                <span style={{fontSize: 12, marginTop: 4, color: '#4E5156'}}>pages</span>
              </div>
              <div style={{fontSize: 12, marginTop: 4, marginLeft: 100, color: '#4E5156'}}>
                {/* Quota: {`${numberWithCommas(utilizedQuota)}/ ${numberWithCommas(totalQuota)}`} */}
              </div>
              {/* <CenterContent style={{marginBottom: 0}}>Top {crawlBudgetVal} Pages</CenterContent> */}
            </Form.Item>

            <Form.Item name='crawl_frequency' style={isSAPublicPage() ? {marginBottom: 14, pointerEvents: 'none', filter: 'opacity(0.5)'} : {marginBottom: 14}}>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <p style={{fontSize: 12, color: '#121212', marginBottom: 0}}>Crawl Frequency</p>
                <Select
                  getPopupContainer={node=> node}
                  style={{width: 258}}
                  className={styles.selectStyle}
                  defaultValue={crawlSelectOption}
                  showSearch
                  tabIndex={-1}
                  disabled={isSAPublicPage()}
                  placeholder='Select Crawl Frequency'
                  optionFilterProp='children'
                  onChange={value => setCrawlSelectOption(value)}
                  value={isSAPublicPage() ? `Daily (Registered only)` : crawlSelectOption}
                  suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#121212'/>}
                >
                  <Option value='daily'>Daily</Option>
                  <Option value='weekly'>Weekly</Option>
                  <Option value='monthly'>Monthly</Option>
                  <Option value='Custom'>Custom</Option>
                  <Option value='never'>Never</Option>
                </Select>
              </div>
              {crawlSelectOption == 'Custom' &&
              <div style={{display: 'flex', marginLeft: 90}}>
                <span style={{marginTop: 4, fontSize: 12, color: '#4E5156'}}>Every  </span>
                <span>
                  <div style={{width: '65px', marginLeft: 10, marginRight: 10}}>
                    <RoundInput
                      crawlFrequency={crawlFrequency}

                      value={crawlFrequency}
                      onChange={e => {
                        onFrequencyChange(e?.target?.value);
                      }
                      }
                    />
                  </div>
                </span>
                <span style={{fontSize: 12, marginTop: 4, color: '#4E5156'}}>Days</span>
              </div>}
              {/* {isError && <div style={{color: 'red', marginLeft: 90}}> kindly enter number from 0 to 30</div>} */}
            </Form.Item>
            <CollapseDiv
              ghost
              accordion
              expandIconPosition='right'
              activeKey={isActive}
              onChange={key => setIsActive(key)}
              expandIcon={({isActive}) => <FontAwesomeIcon style={{width: 12, height: 12}} icon={isActive ? faChevronUp : faChevronDown} />}
            >
              <Collapse.Panel header={<><span className='span' style={{color: '#2D6CCA'}}>{isActive ? 'Show less' : 'Show more'}</span></>} key={'1'}>
                <Form.Item style={{marginBottom: 14}} name='selected_user_agent' >
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <p style={{fontSize: 12, color: '#121212', marginBottom: 0}}>User Agent</p>
                    <StyledSelect
                      getPopupContainer={node=> node}
                      style={{width: 258}}
                      className={styles.selectStyle}
                      showSearch
                      value={userAgent}
                      placeholder='Select user agent'
                      optionFilterProp='children'
                      onChange={onUserAgentChange}
                      filterOption={(input, option) =>
                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                      }
                      suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#121212'/>}
                    >
                      <Option value='google_chrome_desktop'>Google Chrome Desktop</Option>
                      <Option value='google_chrome_mobile'>Google Chrome Mobile</Option>
                      <Option value='googlebot_desktop'>Googlebot Desktop</Option>
                      <Option value='googlebot_mobile'>Googlebot Mobile</Option>
                      <Option value='bingbot_desktop'>Bingbot Desktop</Option>
                      <Option value='bingbot_mobile'>Bingbot Mobile</Option>
                      <Option value='slurp'>Slurp</Option>
                      <Option value='yandexbot'>Yandexbot</Option>
                      <Option value='baiduspider'>Baiduspider</Option>
                      <Option value='screaming_frog'>Screaming frog</Option>
                      <Option value='duckduckgo'>Duckduckgo</Option>
                      <Option value='searchatlas'>Searchatlas</Option>
                    </StyledSelect>
                  </div>
                  {/* <CommonInputField placeholder='Mozila (mobile)'/> */}
                  <CenterContent style={{marginLeft: 230, marginBottom: 0}}>{userAgentDetail} </CenterContent>
                </Form.Item>
                <Form.Item
                  style={{marginBottom: 14}}
                  name='crawl_concurrency'
                  rules={[
                    {
                      pattern: /^(?:\d*)$/,
                      message: 'Value should contain just number',
                    },
                    {
                      pattern: /^[\d]{0,50}$/,
                      message: 'Value should be less than 50 character',
                    },
                  ]}
                >
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Tooltip
                      overlayInnerStyle={{padding: '10px', backgroundColor: '#121212', borderRadius: '12px'}}
                      title={'Increase speed if you have large site for quick page crawls'}>
                      <p style={{fontSize: 12, color: '#121212', marginBottom: 0}}>Crawl Speed</p>
                    </Tooltip>
                    <StyledSlider
                      style={{width: 258, margin: 0}}
                      value={crawlSpeedVal}
                      className={styles.sliderStyle}
                      defaultValue={data?.crawlConcurrency ?? 5}
                      max={50}
                      onChange={onCrawlSpeedChange}
                      min={1}
                    />
                  </div>
                  <div style={{display: 'flex', marginLeft: 225}}>
                    <span style={{marginTop: 6, fontSize: 12, width: '110px', color: '#4E5156'}}>Pages per second</span>
                    <span style={{width: '34px'}}><div>
                      <RoundInput
                        onChange={onCrawlSpeedInputChange}
                        value={crawlSpeedVal}
                      />
                    </div></span>
                  </div>
                  {/* <CenterContent style={{marginBottom: 0}}>Parallel Requests: {crawlSpeedVal}</CenterContent> */}
                </Form.Item>
                <div><div style={{fontSize: 12, marginBottom: 10, display: 'flex'}}>URL Exclusion conditions<LargeBadge beta/></div>
                  {conditions?.map((condition, index) => (
                    <UrlExclusionSection key={index} style={{display: 'flex', gap: 8}}>
                      <div key={index} className='condition-row'>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: '#4E5156', gap: 8}}>
                          <div style={{display: 'flex', color: '#4E5156', gap: 8}}>
                            <div>#{index+1}</div>
                            <div>
                              <Select dropdownMatchSelectWidth={false}
                                getPopupContainer={node=> node}
                                value={condition.action}
                                className='condition-dropdown'
                                onChange={value => handleConditionChange(index, 'action', value)}
                                suffixIcon={<FontAwesomeIcon color='#4E5156' icon={faCaretDown}/>}
                              >
                                <Option id='testm' value='Exclude'>Exclude</Option>
                                <Option id='testm' value='Include' disabled={!excludeCondition}>
                                  <Tooltip title={!excludeCondition && 'Please an Exclude condition first'}>
                                    Include
                                  </Tooltip>
                                </Option>
                              </Select>
                              <span style={{marginRight: 5}}>all URLs that</span>
                              <Select
                                getPopupContainer={node=> node}
                                dropdownMatchSelectWidth={false}
                                value={condition.rule}
                                className='condition-dropdown'
                                onChange={value => handleConditionChange(index, 'rule', value)}
                                suffixIcon={<FontAwesomeIcon color='#4E5156' icon={faCaretDown}/>}
                              >
                                <Option value='Contain'>contain</Option>
                                <Option value='Start with'>start with</Option>
                              </Select>
                              <Input
                                width={470}
                                placeholder='Type here e.g /blog/ ....'
                                className='condition-input'
                                value={condition.value}
                                onChange={e => handleConditionChange(index, 'value', (e.target.value)?.trim())}
                              />
                            </div>
                            <FontAwesomeIcon icon={faTrash} style={{fontSize: 14, color: '#A3A4A4', marginLeft: -25, marginTop: 2, cursor: 'pointer'}} onClick={() => handleDeleteCondition(index)}/>
                          </div>
                        </div>
                      </div>
                    </UrlExclusionSection>
                  ))}
                  <div onClick={handleAddCondition} style={{color: '#2D6CCA', fontSize: 12, cursor: 'pointer'}}>
                    + Add condition
                  </div></div>
              </Collapse.Panel>
            </CollapseDiv>
          </div>
          {isSAPublicPage() && <KeywordBanner>
            <span onClick={() => {
              setShowCrawlModal(false);
              setAuthModal(true, false);
            }}>Start a free trial</span>&nbsp;
            to customize crawl settings.</KeywordBanner>}

          <ButtonWrapper>
            <StyledButton
              onClick={() => {
                onCloseModal();
                setCrawlBudgetError('');
                setConditions([
                  {action: 'Exclude', rule: 'Contain', value: ''},
                ]);
              }}
              color='white'
              textColor='#4E5156'>
              Cancel
            </StyledButton>
            <Form.Item style={{marginBottom: 0}}>
              <Button
                disabled={Number(crawlFrequency) <= -1}

                htmlType='submit'
                loading={loadingCrawlUpdate}>
                Update
              </Button>
            </Form.Item>
          </ButtonWrapper>
        </Form>
      </div>
    </StyledModal>
  );
});

const QuotaContainer = styled.div`
  display:flex;
  gap: 25px;
`;

const QuotaDivSA = styled.div`
  display:flex;
  flex-direction:column;
  font-size:12px;
  font-weight:400;
  color: ${COLORS.gray};
`;

const QuotaValues = styled.div`
  margin-left:5px;
  font-size:12px;
`;

const QuotaSubValue = styled.div`
  font-size:12px;
  display: flex;
  align-items: flex-end;
`;

const TooltipTitle = styled.div`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
color: #FFFFFF;
`;


const ProgressStyled = styled(Progress)`
  margin-top:-7px;
    .ant-progress-inner {
      height: 2px;
    }
`;

const StyledPopover = styled(Popover)`
  .ant-popover-arrow {
      display: none !important;
    }
`;

const RoundInput = styled(Input) <{ crawlFrequency?: string }>`
  height: 100% !important;
  border-radius: 5px;
  font-size: 12px;
  padding: 4px 8px;
  border: ${p => p.crawlFrequency == '' || p.crawlFrequency == '0' ? '1px solid #FF0000' : ''};
    box-shadow:   ${p => p.crawlFrequency == '' || p.crawlFrequency == '0' ? ' 0 0 0 1px #FF0000' : ''};
    &:focus {
      border: ${p => p.crawlFrequency == '' || p.crawlFrequency == '0' ? '1px solid #FF0000' : ''};
      box-shadow:   ${p => p.crawlFrequency == '' || p.crawlFrequency == '0' ? ' 0 0 0 1px #FF0000' : ''};
    }
    &:hover {
      border: ${p => p.crawlFrequency == '' || p.crawlFrequency == '0' ? '1px solid #FF0000' : ''};
      box-shadow:   ${p => p.crawlFrequency == '' || p.crawlFrequency == '0' ? ' 0 0 0 1px #FF0000' : ''};

    }
`;

const CollapseDiv = styled(Collapse)`
.ant-collapse-header {
  width: fit-content;
  padding: 0px 40px 0px 0px !important;
  margin-bottom: 6px;
  font-size: 12px;
  .ant-collapse-arrow {
    color: #2D6CCA;
    font-size: 14px !important;
  }
}
.ant-collapse-content-box {
  padding: 0 !important;
  min-height: 320px !important;
}
`;

const KeywordBanner = styled.div`
    width: 100%;
    height:40px;
    background:#2D6CCA26;
    box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    color:#121212 !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
  span {
    color: #2D6CCA;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  bottom: 30px;
  right: 30px;
`;

const StyledButton = styled(Button)`
  &:hover {
    color: #121212;
  }
`;

const CenterContent = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: #4E5156;
`;
