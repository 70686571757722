import {observer} from 'mobx-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ButtonStyled} from '../../styledComponents';
import {faEnvelope, faListCheck} from '@fortawesome/pro-solid-svg-icons';
import {useStore} from '@/store/root-store';
import {LoadingOutlined} from '@ant-design/icons';
import {Dropdown, Menu, Spin, Tooltip} from 'antd';
import {useRouter} from 'next/router';
import {ButtonsWrapperInner, PrevNextButton, StyledMenu} from '../styledComponents';
import {faBan, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {toJS} from 'mobx';

interface Props {
  showEmail?: (value: boolean) => void;
  rowSelect?: any;
  setIndex?: any;
  result?: any;
  setRowSelect?: any;
  campaignsId?: any;
  onStatusUpdate?: () => void;
}

export const Footer = observer(({showEmail, rowSelect, setIndex, result, setRowSelect, onStatusUpdate, campaignsId}: Props) => {
  const {campaigns: {
    updateOpportunityStatusLoading,
    updateOpportunityStatus,
    updateCampaignBlockList,
    getChartData,
    campaignBlocklist,
    loadingUpdateCampaignBlockList,
    addGlobalBlockList,
    globalBlocklist,
    loadingAddGlobalBlockList,
    deleteOpportunity,
    deletingOpportunity,
  }} = useStore('');
  const router = useRouter();
  const id = router?.query?.id ? router?.query?.id[0] : '0';

  const handleAddToBlockList = async () => {
    const prePayload = {
      domains_blacklist: [...campaignBlocklist, toJS(rowSelect)?.domain],
    };
    await updateCampaignBlockList(id, prePayload);
    await getChartData(Number(id));
  };

  const handleAddCampaignToGlobalBlacklist = async () => {
    const prePayload = {
      domains_blacklist: [...globalBlocklist, toJS(rowSelect)?.domain],
    };
    await addGlobalBlockList(prePayload);
  };

  const handleDeleteOpportunity = async () => {
    await deleteOpportunity(toJS(rowSelect)?.id, id);
    const currentIndex = toJS(result)?.findIndex(i => i?.id === rowSelect?.id);
    setIndex(currentIndex);
    if (currentIndex + 1 < result?.length - 1) {
      setRowSelect(result[currentIndex + 1]);
    }
  };

  return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
    <ButtonsWrapperInner key='wrapper' style={{gap: '15px'}}>
      <ButtonStyled className='firstBtn' type='primary' onClick={() => showEmail(true)}>
        <FontAwesomeIcon icon={faEnvelope} />
        Email contacts now
      </ButtonStyled>
      {rowSelect?.isScheduled ? null :
        <div style={{display: 'flex', alignItems: 'center', gap: 20}}>
          <ButtonStyled className='lastBtn' isCancel={true} onClick={async () => {
            await updateOpportunityStatus(rowSelect?.id, Number(router?.query?.id?.[0] ?? campaignsId), false);
            onStatusUpdate();
          }}>
            {updateOpportunityStatusLoading && <Spin indicator={<LoadingOutlined style={{fontSize: 18}} spin />} />}
            <FontAwesomeIcon icon={faListCheck} />
            Add to “Ready for Outreach” list
          </ButtonStyled>
        </div>
      }
    </ButtonsWrapperInner>
    <div style={{display: 'flex', alignItems: 'center', gap: '9px', marginTop: '15px'}}>
      <Dropdown
        trigger={['click']}
        overlay={(
          <StyledMenu>
            <Tooltip title={campaignBlocklist?.includes(toJS(rowSelect)?.domain) ? 'Opportunity is already added to campaign blacklist' : ''} overlayInnerStyle={{width: 'fit-content', background: '#000', borderRadius: '12px'}}>
              <div>
                <Menu.Item onClick={handleAddToBlockList} disabled={!!campaignBlocklist?.includes(toJS(rowSelect)?.domain) || loadingUpdateCampaignBlockList}>Add to Campaign Blacklist</Menu.Item>
              </div>
            </Tooltip>
            <div className='divider'/>
            <Tooltip title={globalBlocklist?.includes(toJS(rowSelect)?.domain) ? 'Opportunity is already added to global blacklist' : ''} overlayInnerStyle={{width: 'fit-content', background: '#000', borderRadius: '12px'}}>
              <div>
                <Menu.Item onClick={handleAddCampaignToGlobalBlacklist} disabled={!!globalBlocklist?.includes(toJS(rowSelect)?.domain) || loadingAddGlobalBlockList}>Add to Global Blacklist</Menu.Item>
              </div>
            </Tooltip>
          </StyledMenu>
        )}>
        <Tooltip title={'Add to Global Blacklist'} overlayInnerStyle={{width: 'fit-content', background: '#000', borderRadius: '12px'}}>
          <PrevNextButton height='38px'>
            <FontAwesomeIcon icon={faBan} />
          </PrevNextButton>
        </Tooltip>
      </Dropdown>
      <Tooltip title='Delete Opportunity' overlayInnerStyle={{width: 'fit-content', background: '#000', borderRadius: '12px'}}>
        <PrevNextButton height='38px' loading={deletingOpportunity} onClick={handleDeleteOpportunity}>
          <FontAwesomeIcon icon={faTrash} />
        </PrevNextButton>
      </Tooltip>
    </div>
  </div>;
});
