import {analyticsApi} from '@/api/gsc';
import {apiError} from '@/utils/api';
import {notification, useErrorNotification} from '@/utils/notification-v2';
import {AxiosError} from 'axios';
import {toJS} from 'mobx';
import {cast, flow, Instance, types} from 'mobx-state-tree';

const GaDataModalModel = types.model({
  visible: types.boolean,
  siteUrl: types.maybeNull(types.string),
  gaConnected: types.maybeNull( types.boolean),
  settingId: types.maybeNull(types.number),
  settingsEnabled: types.maybeNull(types.boolean),
  hasSiteGaCredentials: types.maybeNull(types.boolean),
  saProjectId: types.maybeNull(types.union(types.string, types.number)),
  activatedGaPropertyId: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  fromOttoUuidParam: types.maybeNull(types.string),
});

export type GaDataModalType = Instance<typeof GaDataModalModel>;


// SINGLE GA ACCOUNTS SETTINGS MODELS START
const AllAccountsSettingsAccountsSPViewsModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
});

const AllAccountsSettingsAccountsSPModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  gaViews: types.maybeNull(types.array(AllAccountsSettingsAccountsSPViewsModel)),
  hostname: types.maybeNull(types.string),
});


const AllAccountsSettingsAccountsModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  siteproperties: types.maybeNull(types.array(AllAccountsSettingsAccountsSPModel)),
});

const AllAccountModel = types.model({
  count: types.maybeNull(types.number),
  next: types.maybeNull(types.number),
  previous: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  totalPages: types.maybeNull(types.number),
  results: types.maybeNull(types.array(AllAccountsSettingsAccountsModel)),
});
// SINGLE GA ACCOUNTS SETTINGS MODELS END

// SINGLE GA SETTINGS LIST MODELS START
const SavedSettingsAccountModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
});
const SavedSettingsPropertyModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  hostname: types.maybeNull(types.string),
});

const SavedSettingsViewModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
});

const SavedGaSettingModel = types. model({
  id: types.maybeNull(types.number),
  account: types.maybeNull(SavedSettingsAccountModel),
  gaProperty: types.maybeNull(SavedSettingsPropertyModel),
  gupEmail: types.maybeNull(types.string),
  view: types.maybeNull(SavedSettingsViewModel),
  isActive: types.maybeNull(types.boolean),
});
// SINGLE GA SETTINGS LIST MODELS START


// ALL GA SETTINGS LIST MODELS START
const GaAllSettingsSingleGaSettingAccount = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
});

const GaAllSettingsSingleGaSettingProperty = types.model({
  id: types.maybeNull(types.number),
  isActive: types.maybeNull(types.boolean),
});

const GaAllSettingsSingleGaSettingModel = types. model({
  account: types.maybeNull(GaAllSettingsSingleGaSettingAccount),
  gaProperty: types.maybeNull(GaAllSettingsSingleGaSettingProperty),
  id: types.maybeNull(types.number),
  isActive: types.maybeNull(types.boolean),
});

const GaAllSettingsModel = types.model({
  count: types.maybeNull(types.number),
  next: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  previous: types.maybeNull(types.number),
  totalPages: types.maybeNull(types.number),
  results: types.maybeNull(types.array(GaAllSettingsSingleGaSettingModel)),
});
// ALL GA SETTINGS LIST MODELS END

const AvailableAccounts = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  siteproperties: types.maybeNull(types.array(AllAccountsSettingsAccountsSPModel)),
});
export type AccountsType = Instance<typeof AvailableAccounts>;

const AvailableViewsModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
});
export type ViewsType = Instance<typeof AvailableViewsModel>;


const AvailablePropertiesModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  gaViews: types.maybeNull(types.array(AvailableViewsModel)),
  hostname: types.maybeNull(types.string),
});

const sitepropertiesModelGlobalData = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
});
const recommendedData = types.model({
  gscSiteId: types.maybeNull(types.number),
  gscSiteUrl: types.maybeNull(types.string),
  viewId: types.maybeNull(types.number),
  viewName: types.maybeNull(types.string),
});
const sitepropertiesModel = types.model({
  gaViews: types.maybeNull(types.array(sitepropertiesModelGlobalData)),
  id: types.maybeNull(types.number),
  isV4: types.maybeNull(types.boolean),
  name: types.maybeNull(types.string),
  propertyId: types.maybeNull(types.string),
  recommended: types.maybeNull(recommendedData),
  hostname: types.maybeNull(types.string),
});

const availableAccountModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  siteproperties: types.maybeNull(types.array(sitepropertiesModel)),
  accountId: types.maybeNull(types.string),
});


const AllAccountV2Model = types.model({
  availableAccounts: types.maybeNull(types.array(availableAccountModel)),
  email: types.maybeNull(types.string),
});

// Side drawer GA Settings model start
const SitePropertiesDrawerDataModel = types.model({
  selectedGaPropertyId: types.maybeNull(types.string),
  selectedGaPropertyName: types.maybeNull(types.string),
  selectedGaViewId: types.maybeNull(types.union(types.string, types.number)),
  selectedGaViewName: types.maybeNull(types.string),
  selectedGscSiteProperty: types.maybeNull(types.string),
  selectedGscSitePropertyId: types.maybeNull(types.union(types.string, types.number)),
  connectedSiteAudits: types.maybeNull(types.array(types.model({
    id: types.maybeNull(types.number),
    propertyUrl: types.maybeNull(types.string),
  }))),
  connectedGscProperties: types.maybeNull(types.array(types.model({
    id: types.maybeNull(types.number),
    isActive: types.maybeNull(types.boolean),
    url: types.maybeNull(types.string),
  }))),
});

const availableAccountModelGlobal = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  siteproperties: types.maybeNull(types.array(SitePropertiesDrawerDataModel)),
  accountId: types.maybeNull(types.string),
});

const gaGlobalSettingModel = types.model({
  availableAccounts: types.maybeNull(types.array(availableAccountModelGlobal)),
  email: types.maybeNull(types.string),
});
// Side drawer GA Settings model end

const gmbAddressModel = types.model({
  locality: types.maybeNull(types.string),
  regionCode: types.maybeNull(types.string),
  addressLines: types.maybeNull(types.array(types.string)),
  languageCode: types.maybeNull(types.string),
});

const gmbMoreHoursTypeModel = types.model({
  displayName: types.maybeNull(types.string),
  hoursTypeId: types.maybeNull(types.string),
  localizedDisplayName: types.maybeNull(types.string),
});

const gmbPrimaryCategoryModel = types.model({
  name: types.maybeNull(types.string),
  displayName: types.maybeNull(types.string),
  moreHoursTypes: types.maybeNull(types.array(gmbMoreHoursTypeModel)),
});

const gmbCategoryModel = types.model({
  primaryCategory: types.maybeNull(gmbPrimaryCategoryModel),
});

const gmbOpenHoursModel = types.model({
  hours: types.maybeNull(types.number),
});

const gmbPeriodsModel = types.model({
  openDay: types.maybeNull(types.string),
  closeDay: types.maybeNull(types.string),
  openTime: types.maybeNull(gmbOpenHoursModel),
  closeTime: types.maybeNull(gmbOpenHoursModel),
});

const gmbWorkingHoursModel = types.model({
  periods: types.maybeNull(types.array(gmbPeriodsModel)),
});

const gmbLocations = types.model({
  locationId: types.maybeNull(types.string),
  websiteUrl: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  address: types.maybeNull(gmbAddressModel),
  isVerified: types.maybeNull(types.boolean),
  description: types.maybeNull(types.string),
  categories: types.maybeNull(gmbCategoryModel),
  workingHours: types.maybeNull(gmbWorkingHoursModel),
  googlemapsUrl: types.maybeNull(types.string),
  totalReviews: types.maybeNull(types.union(types.string, types.number)),
  avgRating: types.maybeNull(types.union(types.string, types.number)),
  placeId: types.maybeNull(types.string),
  isServiceAreaBusiness: types.maybeNull(types.boolean),
});

const gmbAccountData = types.model({
  name: types.maybeNull(types.string),
  locations: types.maybeNull(types.array(gmbLocations)),
  accountId: types.maybeNull(types.string),
});

const fbAccountData = types.model({
  id: types.maybeNull(types.number),
  business: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  accountStatus: types.maybeNull(types.string),
});

const googleAccountData = types.model({
  id: types.maybeNull(types.number),
  accountId: types.maybeNull(types.number),
  descriptiveName: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
});

const gmbModel = types.model({
  businessAccounts: types.maybeNull(types.array(gmbAccountData)),
  email: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
});

const fbModel = types.model({
  facebookAdAccounts: types.maybeNull(types.array(fbAccountData)),
  email: types.maybeNull(types.string),
});

const googleModel = types.model({
  googleAdsAccounts: types.maybeNull(types.array(googleAccountData)),
  email: types.maybeNull(types.string),
});

export type PropertiesType = Instance<typeof AvailablePropertiesModel>;

export const GoogleAnalyticsStore = types.model({
  gaDataModal: GaDataModalModel,
  gaLoading: types.boolean,
  // model for GA Settings Modal
  accountsDataV2: types.maybeNull(types.array(AllAccountV2Model)),
  // model for GA global settings Side drawer
  gaGlobalSettingData: types.maybeNull(types.array(gaGlobalSettingModel)),
  gmbAccountData: types.maybeNull(types.array(gmbModel)),
  fbAccountData: types.maybeNull(types.array(fbModel)),
  googleAccountData: types.maybeNull(types.array(googleModel)),
  gaAccounts: types.maybeNull(AllAccountModel),
  allGaSettings: types.maybeNull(GaAllSettingsModel),
  singleGaSetting: types.maybeNull(SavedGaSettingModel),
  availableAccounts: types.maybeNull(types.array(AvailableAccounts)),
  availableProperties: types.maybeNull(types.array(sitepropertiesModel)),
  availableViews: types.maybeNull(types.array(AvailableViewsModel)),
  gsGlobalSettingLoading: types.boolean,
  gmbDataLoading: types.boolean,
  refreshingAccount: types.boolean,
  fbAdsDataLoading: types.boolean,
  googleAdsDataLoading: types.boolean,
}).views(self => ({
  get getAccountsDataV2() {
    return toJS(self.accountsDataV2);
  },
  get getGlobalSettingsData() {
    return toJS(self.gaGlobalSettingData);
  },
  get getGMBData() {
    return toJS(self.gmbAccountData);
  },
  get getFBAdsData() {
    return toJS(self.fbAccountData);
  },
  get getGoogleAdsData() {
    return toJS(self.googleAccountData);
  },

})).actions(self => {
  const dataCleanup = () => {
    if (self.gaAccounts?.results?.length) self.gaAccounts.results.length = 0;
  };

  const getAccounts = flow(function* () {
    try {
      const response = yield analyticsApi.getAccounts();
      if (response.isCancel) return;
      self.accountsDataV2 = cast(response);

      return response;
    } catch (e) {
      if (e instanceof AxiosError) {
        /*
        * HANDLE AXIOS EXCEPTIONS (quota 429 also goes here)
        * update notification with status and request url
        * status = e?.response?.status
        * url = e?.request?.responseURL
        */
        notification.error('Failed to fetch GA accounts', e.response?.data?.message, false, 'Refresh page', e.response?.status, e.request?.responseURL);
      } else {
        /*
        * HANDLE OTHER EXCEPTIONS
        * show regular notifications
        */
        notification.error('Failed to fetch GA accounts', 'Please try again later.');
      }
      self.gaLoading = false;
      return Promise.reject(e);
    }
  });

  const getGAGlobalSetting = flow(function* (isRepoll = false) {
    self.gsGlobalSettingLoading = true;
    try {
      const response = yield analyticsApi.getGlobalSetting();
      if (response.isCancel) return;
      self.gaGlobalSettingData = cast(response);
      if (isRepoll) {
        yield new Promise(r => setTimeout(r, 5000));
        const now = new Date().getTime();
        const timeStamp:any = localStorage.getItem('connectGARepollTime');
        if (now - Number(timeStamp) < 1 * 60 * 1000) {
          getGAGlobalSetting(true);
        } else {
          localStorage.removeItem('connectGARepollTime');
        }
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.gsGlobalSettingLoading = false;
    }
  });

  const getGMBSetting = flow(function* (isRepoll = false) {
    self.gmbDataLoading = true;
    try {
      const response = yield analyticsApi.getGMBSetting();
      if (response.isCancel) return;
      self.gmbAccountData = cast(response);
      if (isRepoll) {
        yield new Promise(r => setTimeout(r, 5000));
        const now = new Date().getTime();
        const timeStamp:any = localStorage.getItem('connectGBPRepollTime');
        if (now - Number(timeStamp) < 1 * 60 * 1000) {
          return getGMBSetting(true);
        } else {
          localStorage.removeItem('connectGBPRepollTime');
        }
      }
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
    } finally {
      self.gmbDataLoading = false;
    }
  });

  const loadFBAdsSetting = flow(function* () {
    self.fbAdsDataLoading = true;
    try {
      const response = yield analyticsApi.getFBAdsSetting();
      if (response.isCancel) return;
      self.fbAccountData = cast(response);
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
    } finally {
      self.fbAdsDataLoading = false;
    }
  });

  const loadGoogleAdsSetting = flow(function* () {
    self.googleAdsDataLoading = true;
    try {
      const response = yield analyticsApi.getGoogleAdsSetting();
      if (response.isCancel) return;
      self.googleAccountData = cast(response);
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
    } finally {
      self.googleAdsDataLoading = false;
    }
  });

  const getAccountsV2 = flow(function* () {
    try {
      const response = yield analyticsApi.getAccountsV2Api();
      if (response.isCancel) return;
      self.accountsDataV2 = cast(response);

      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  });

  const refreshAccount = flow(function* (email: string) {
    self.refreshingAccount = true;
    try {
      const response = yield analyticsApi.refreshAccount(email);
      if (response.isCancel) return;

      return response;
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Failed to refresh GA account',
        desc: 'Please try again later',
      });
      return Promise.reject(e);
    } finally {
      self.refreshingAccount = false;
    }
  });

  const getSingleSettings = flow(function* (settingId: number, selectedEmail: string) {
    self.gaLoading = true;
    try {
      const response = yield analyticsApi.getSingleSetting(settingId, selectedEmail);
      if (response.isCancel) return;
      self.singleGaSetting = cast(response);

      self.gaLoading = false;

      return response;
    } catch (e) {
      if (e instanceof AxiosError) {
        /*
        * HANDLE AXIOS EXCEPTIONS (quota 429 also goes here)
        * update notification with status and request url
        * status = e?.response?.status
        * url = e?.request?.responseURL
        */
        notification.error('Failed to fetch GA settings', e.response?.data?.message, false, 'Refresh page', e.response?.status, e.request?.responseURL);
      } else {
        /*
        * HANDLE OTHER EXCEPTIONS
        * show regular notifications
        */
        notification.error('Failed to fetch GA settings', 'Please try again later.');
      }
      self.gaLoading = false;

      return Promise.reject(e);
    }
  });

  const getAllSettings = flow(function* () {
    self.gaLoading = true;
    try {
      const response = yield analyticsApi.getAllSettings(self.gaDataModal.siteUrl);
      if (response.isCancel) return;
      self.allGaSettings = cast(response);

      self.gaLoading = false;

      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  });

  interface SettingsProps {
    accountId: number;
    propertyId: string;
    selectedEmail?: string;
  }

  const postAllSettings = flow(function* ({accountId, propertyId, selectedEmail}: SettingsProps) {
    self.gaLoading = true;
    try {
      const response = yield analyticsApi.postSettings(accountId, propertyId, self.gaDataModal.siteUrl, selectedEmail);
      if (response.isCancel) return;
      const responseArray = [];
      responseArray.push(response);
      self.allGaSettings = cast(response);

      self.gaLoading = false;

      return response;
    } catch (e) {
      if (e instanceof AxiosError) {
        /*
        * HANDLE AXIOS EXCEPTIONS (quota 429 also goes here)
        * update notification with status and request url
        * status = e?.response?.status
        * url = e?.request?.responseURL
        */
        notification.error('Failed to submit GA settings', e.response?.data[0], false, 'Refresh page', e.response?.status, e.request?.responseURL);
      } else {
        /*
        * HANDLE OTHER EXCEPTIONS
        * show regular notifications
        */
        notification.error('Failed to submit GA settings', 'Please try again later.');
      }
      self.gaLoading = false;
      return Promise.reject(e);
    }
  });

  const activateGa = flow(function* () {
    self.gaLoading = true;
    try {
      const response = yield analyticsApi.activateGaSite(self.gaDataModal?.settingId);
      if (response.isCancel) return;

      self.gaLoading = false;

      return response;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.gaLoading = false;
    }
  });

  const deactivateGa = flow(function* () {
    self.gaLoading = true;
    try {
      const response = yield analyticsApi.deactivateGaSite(self.gaDataModal?.settingId);
      if (response.isCancel) return;

      self.gaLoading = false;

      return response;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.gaLoading = false;
    }
  });

  const disconnectGMB = flow(function* (payload, callback) {
    try {
      const response = yield analyticsApi.disconnectGMBSetting(payload);
      if (response.isCancel) return;
      getGMBSetting();
      callback();
      notification.success('', 'Account disconnected successfully.');
    } catch (e) {
      notification.error('Failed to disconnect GMB settings', 'Please try again later.');
    }
  });

  const setGaDataModal = (value: GaDataModalType) => {
    self.gaDataModal = value;
  };
  const setGaLoading = (value: boolean) => self.gaLoading = value;
  const setAvailableAccounts = (value: any) => self.availableAccounts = cast(toJS(value));
  const setAvailableProperties = (value: any) => {
    self.availableProperties = cast(toJS(value));
  };
  const setAvailableViews = (value: ViewsType[] ) => self.availableViews = cast(value);

  return {
    getAccounts,
    getAccountsV2,
    getGAGlobalSetting,
    getGMBSetting,
    getAllSettings,
    postAllSettings,
    dataCleanup,
    setGaDataModal,
    activateGa,
    deactivateGa,
    setGaLoading,
    getSingleSettings,
    setAvailableProperties,
    setAvailableViews,
    setAvailableAccounts,
    refreshAccount,
    disconnectGMB,
    loadFBAdsSetting,
    loadGoogleAdsSetting,
  };
});

export const initGoogleAnalyticsStore = () => {
  return {
    gaDataModal: {
      visible: false,
      siteUrl: '',
      gaConnected: false,
      settingId: null,
      isInTab: false,
      countryCode: '',
    },
    gaLoading: false,
    allGaSettings: null,
    singleGaSetting: null,
    gsGlobalSettingLoading: true,
    gmbDataLoading: true,
    gaAccounts: null,
    availableAccounts: [],
    availableProperties: [],
    availableViews: [],
    refreshingAccount: false,
    fbAdsDataLoading: false,
    googleAdsDataLoading: false,
  };
};
