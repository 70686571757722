import {Modal, NoSsr} from '@/components/common-components/components/index';
import styles from './styles.module.scss';
import React, {ReactNode, useEffect, useRef, useState} from 'react';
import useWindowSize from '@/utils/hooks/useWindowSize';
import {useStore} from '@/store/root-store';
import {notification as notify} from '@/utils/notification-v2';
import {useRouter} from 'next/router';
// import {faArrowLeft, faArrowRight} from '@fortawesome/pro-solid-svg-icons';
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import styled from 'styled-components';

interface Props {
  setShowConsultationModal?: (value) => void;
  setShowDaModal?: (value) => void;
}

export const MeetingModal: React.FC<Props> = () => {
  const modalRef = useRef<HTMLInputElement>(null);
  const footerModalRef = useRef<HTMLInputElement>(null);
  const [shakingEffect, setShakingEffect] = useState(false);
  const [display, setDisplay] = useState('none');
  const router = useRouter();
  const urlFromRouter=typeof window !== 'undefined' && localStorage.getItem('domain');
  const {settings: {customer: {profile: {isWhitelabel, setIsDaFlowCompleted}}}} = useStore('');
  // const [linkIndex, setLinkIndex] = useState(0);

  const {width}=useWindowSize();
  const showConsultationModal=typeof window !== 'undefined' && localStorage.getItem('showConsultationModal') == 'true' && !isWhitelabel;
  const onMeetingModalClose=async ()=>{
    localStorage.setItem('showConsultationModal', 'false');
    const checkDaFlow = await typeof window !== 'undefined' && localStorage.getItem('isDaFlow');
    if (checkDaFlow) {
      await setIsDaFlowCompleted(false);
    }
    if (router?.pathname !== '/orders' && checkDaFlow) {
      setTimeout(() => {
        notify.success('Project successfully created', `11 main reports for ${urlFromRouter} will soon be ready.`);
      }, 1500);
    }
    // const checkPostOnbaordingModal= typeof window !== 'undefined' && localStorage.getItem('showPostOnboarding');
    // if (localStorage.getItem('signedInFrom') === 'regularOnboarding' && (!projectCount || projectCount === 0) ) {
    //   if (checkPostOnbaordingModal!=='false') {
    //     setShowPostOnboardingModal(true);
    //   }
    // } else {
    //   setTimeout(()=>{
    //     if (checkPostOnbaordingModal!=='false') {
    //       setShowPostOnboardingModal(true);
    //     }
    //   }, 5000);
    // }
    localStorage.removeItem('isDaFlow');
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && (!modalRef.current.contains(event.target) && !footerModalRef.current.contains(event.target))) {
        setShakingEffect(true);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);
  useEffect(() => {
    setTimeout(() => {
      shakingEffect && setShakingEffect(false);
    }, 900);
  }, [shakingEffect]);
  const modalFooter: ReactNode = (
    <div ref={footerModalRef}>
    </div>
  );
  // const handleArrowClick = direction => {
  //   setLinkIndex(prevIndex => {
  //     if (direction === 'right') {
  //       return prevIndex < calendarLinks.length - 1 ? prevIndex + 1 : prevIndex;
  //     } else if (direction === 'left') {
  //       return prevIndex > 0 ? prevIndex - 1 : prevIndex;
  //     }
  //     return prevIndex;
  //   });
  // };
  // const calendarLinks = [
  //   'https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0Eap2j21ZWdSzk81mKjhKkNwpFZ4TRyxi12uLe2C7PvfPydpIW4PJkgligyC92Rt7xwh96TIxJ?gv=true',
  //   'https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ049R9asK4o-EGxqelWr3FKCom4jGRPMihWRTjMttGa2MA9DM0mxQWFl-uKqKXjWoczvizfTWO4',
  // ];
  return (
    <NoSsr>
      <Modal
        maskStyle={{backgroundColor: 'rgba(17, 18, 24, 0.97) ', zIndex: 99, display: display}}
        style={{marginTop: '6%', display: display}}
        visible={showConsultationModal}
        width={width < 768 ? 390 : 881}
        height={10}
        closable={true}
        onClose={onMeetingModalClose}
        footer={modalFooter}
        className={styles.meetingModal}
      >
        <div>
          {/* <ArrowsWrapper>
            <FontAwesomeIconStyled
              disabled={linkIndex === 0}
              color={linkIndex == 0 ? 'lightgrey' : '#000000'}
              icon={faArrowLeft}
              onClick={() => handleArrowClick('left')}
            />
            <FontAwesomeIconStyled
              disabled={linkIndex === 1}
              color={linkIndex == 1 ? 'lightgrey' : '#000000'}
              icon={faArrowRight}
              onClick={() => handleArrowClick('right')}
            />
          </ArrowsWrapper> */}
          {!isWhitelabel &&
          <div ref={modalRef}>
            <iframe
              onLoad={()=>{
                setDisplay('block');
              }}
              width={width < 768 ? 370 :'800'}
              height={width < 768 ? 610 :'600'}
              src={'https://calendly.com/searchatlas-seo/agency-group-onboarding'}
              frameBorder='0'
              // allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              // allowFullScreen
              title='Embedded youtube'
            />
          </div>
          }
        </div>

      </Modal>
    </NoSsr>
  );
};

// const FontAwesomeIconStyled = styled(FontAwesomeIcon)<{disabled: boolean}>`
//   cursor: ${p => p?.disabled ? 'not-allowed' : 'pointer'};
//   &:hover {
//     color: ${p => !p?.disabled && 'rgb(127, 78, 173)'};
//   }
// `;

// const ArrowsWrapper = styled.div`
//   display: flex;
//   gap: 10px;
//   margin-left: 15px;
// `;
