import React, {useEffect, useRef, useState} from 'react';
import {Button, Modal} from '@/components/common-components/components';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Checkbox, Dropdown, Menu, Spin, Tooltip} from 'antd';
import {faTrash, faListTree, faArrowUpRightFromSquare, faChevronRight, faArrowRight, faPlus} from '@fortawesome/pro-regular-svg-icons';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam} from '@/utils/url';
import {openUrl} from '@/utils/router';
import {faArrowUpFromLine, faChevronDown, faChevronUp, faCircleCheck, faFileLines, faWandMagicSparkles} from '@fortawesome/pro-solid-svg-icons';
import {faCircleCheck as faDuotoneCircleCheck, faCirclePlus} from '@fortawesome/pro-duotone-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import {notification as notificationV2} from '@/utils/notification-v2';
import {apiError} from '@/utils/api';
import {OneClickPublisher} from '../../../../landing-page-optimizer/page-editor/LeftSide/TopSection/title-editor/OneClickPublisher/OneClickPublisher';
import {convertToTransparent} from '@/utils/colors';
import {topicalMapStepsTitles} from '../../../Constants';
import {BackButton, DeleteButton, GenerateArticleMenu, ModalWrapper, PluginButton, StyledEmpty, TopicalMapStepsWrapper, Wrapper} from '../../../style';
import {StyledSpin} from '../../../Utils/styledSpin';
import {GenerateButton} from '..';
import FreezeWrapper from '../../freezTooltip';
import {observer} from 'mobx-react-lite';
import {isNodeSelected} from '../../../Utils/helper-func';
import {flattenChildren} from './topicalMapModalContent';
import {useRouter} from 'next/router';
import {NextImg} from '@/utils/nextImg';

interface TopicalMapModalTableContentProps {
  keysWithChildren: any;
  topicMapDataList: any;
  generatingBulkArticles: any;
  selectedArticlesToGenerate: any;
  setSelectedArticlesToGenerate: any;
  topicalMapArticle: any;
  setDataLoader: any;
  topicalMap: any;
  ExportButton: any;
  searchIntentDropdown: any;
  openTopicalMapModal?: boolean;
  setOpenTopicalMapModal?: (value: boolean) => void;
  componentIssueType: string;
  isInContentTopicMap?: boolean;
  tableDataFilter: any;
  categoryIntentDropdown: any;
  searchInput: any;
  func: any;
  topicalMapDataState: any;
  setKeysWithChildren: any;
  setAddingIdeaInTopicMap: any;
  setClickedValue: any;
  clickedValue: any;
  UnSelectedTooltipContent: any;
  setDeleteModalData: any;
  deleteModalData: any;
  searchIntentListLength?: number;
  onGenerateArticle: any;
  setSelectedCountry: any;
  onOutline: any;
  setErrorId: any;
  errorId: any;
  wpContainerRef: any;
  topicId?: number;
  setSelectedTab?: React.Dispatch<React.SetStateAction<string>>;
}

export const TopicalMapModalTableContent: React.FC<TopicalMapModalTableContentProps> = observer((
  {
    keysWithChildren,
    topicMapDataList,
    generatingBulkArticles,
    selectedArticlesToGenerate,
    setSelectedArticlesToGenerate,
    topicalMapArticle,
    setDataLoader,
    topicalMap,
    componentIssueType,
    isInContentTopicMap,
    tableDataFilter,
    ExportButton,
    searchIntentDropdown,
    categoryIntentDropdown,
    searchInput,
    func,
    topicalMapDataState,
    setKeysWithChildren,
    setAddingIdeaInTopicMap,
    searchIntentListLength,
    setClickedValue,
    clickedValue,
    UnSelectedTooltipContent,
    setDeleteModalData,
    deleteModalData,
    onGenerateArticle,
    setSelectedCountry,
    onOutline,
    setErrorId,
    errorId,
    wpContainerRef,
    topicId,
    setSelectedTab,
  }) => {
  const {ottoV2Store: {loadIssueTableData, ottoUrls, deletingTopicalMap, topicalMapUpdateArticle, getOttoV2Project},
    settings: {customer: {profile: {address}}},
    contentOptimizer: {
      wpPublish: {wpArray, setShowInstructionsModal},
      contentIdeas: {getTopicalMapData, loadSingleTopicMapData, getSingleTopicalMapData},
    },
  } = useStore('');
  const router = useRouter();

  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState<any>('');
  const [showClass, setShowClass] = useState(false);
  const [selectedCount, setSelectedCount] = useState(null);
  const tableRef = useRef(null);

  const uuid = getSingleUrlParam('uuid');
  const topicMapId = getSingleUrlParam('topicId');
  const mode = getSingleUrlParam('mode');

  useEffect(() => {
    if (getSingleTopicalMapData && !mode) {
      if (Array.isArray(getSingleTopicalMapData?.formattedData)) {
        const selected = getSingleTopicalMapData?.formattedData?.flatMap((item: any) => item?.keywords?.flatMap(keyword => keyword?.titles?.filter(title => title?.isSelected)));
        setSelectedCount(selected?.length);
      }
    }
  }, [deleteModalData, router]);
  const generateColor = si => {
    if (si === 'informational') {
      return '#34AEF3';
    } else if (si === 'commercial') {
      return '#F1AA3E';
    } else if (si === 'transactional') {
      return '#1F9068';
    } else if (si === 'navigational') {
      return '#B98CE4';
    } else {
      return '#474545';
    }
  };

  const updateScrollClass = () => {
    const width = window.innerWidth;
    const scrollLeft = tableRef.current.scrollLeft;
    const scrollWidth = tableRef.current.scrollWidth;
    const clientWidth = tableRef.current.clientWidth;

    const isCompletelyScrolled = scrollLeft + clientWidth >= scrollWidth;

    if (width < 1872 && !isCompletelyScrolled) {
      setShowClass(true);
    } else if (width < 1872 && isCompletelyScrolled) {
      setShowClass(false);
    } else {
      setShowClass(false);
    }
  };

  useEffect(() => {
    updateScrollClass();

    window.addEventListener('resize', updateScrollClass);
    tableRef.current.addEventListener('scroll', updateScrollClass);

    return () => {
      window.removeEventListener('resize', updateScrollClass);
      tableRef.current && tableRef.current.removeEventListener('scroll', updateScrollClass);
    };
  }, [tableRef]);

  const scrollToEnd = () => {
    if (tableRef.current) {
      tableRef.current.scrollTo({
        left: tableRef.current.scrollWidth,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (address?.defaultLocation) {
      setSelectedCountry({
        countryCode: address?.defaultLocation,
        location: address?.countryName,
        locationId: address?.locationId,
      });
    }
  }, [address?.defaultLocation]);

  useEffect(() => {
    if (clickedValue) {
      const updatedData = isNodeSelected(clickedValue, {...topicalMapDataState}, isInContentTopicMap);
      func(updatedData);
      if (isInContentTopicMap) {
        setAddingIdeaInTopicMap(true);
      }
      setClickedValue(0);
    }
  }, [clickedValue]);

  const deleteArticle = async (contentTopic, topicalMapArticleDataParam) => {
    setOpenModal(false);
    if (isInContentTopicMap && !mode) {
      setClickedValue(contentTopic);
      setOpenModal(false);
    } else {
      try {
        const selectedArticle = topicalMapArticleDataParam?.find(i => i?.title === contentTopic);
        setDeleteId(selectedArticle?.titleUuid);
        const filter = ottoUrls?.filter(d => d.issueType === componentIssueType);
        const payload = {
          id: selectedArticle?.id,
          titleUuid: selectedArticle?.titleUuid,
          shouldDelete: true,
          publicShareHash: getOttoV2Project?.publicShareHash,
        };
        await topicalMapUpdateArticle(payload);
        setDataLoader(true);
        const params = {
          uuid,
          issue_type: componentIssueType,
          page_size: filter?.length ? filter[0].pageSize : 10,
          page: filter?.length ? filter[0].page : 1,
          is_loading: false,
        };
        if (isInContentTopicMap) {
          await loadSingleTopicMapData(Number(topicMapId), true);
          await getTopicalMapData();
        } else {
          await loadSingleTopicMapData(topicId, true);
          await loadIssueTableData(params, false, true);
        }
        notificationV2.success('', 'Article deleted successfully', 'Close');
        setDeleteId('');
        setOpenModal(false);
        setDeleteModalData(null);
      } catch (e) {
        setDeleteId('');
        setOpenModal(false);
        setDeleteModalData(null);
        const errorMessage = apiError(e);
        notificationV2.error('', errorMessage);
      }
    }
  };

  const onChangeActive = (checked: boolean, value: string) => {
    let updateList = [...keysWithChildren];
    if (checked) {
      updateList.push(value);
    } else {
      updateList = updateList.filter(key => key !== value);
    }
    setKeysWithChildren(updateList);
  };
  const getSILength = (length: number, usingFor: string) => {
    if (usingFor === 'data') {
      if (isInContentTopicMap && mode !== 'edit') {
        return '140px';
      }
      if (length === 4) {
        return '114px';
      } else if (length === 3) {
        return '84px';
      } else if (length === 2) {
        return '60px';
      } else if (length === 1) {
        return '44px';
      } else {
        return '44px';
      }
    } else {
      if (isInContentTopicMap && mode !== 'edit') {
        return '146px';
      }
      if (length === 4) {
        return '116px';
      } else if (length === 3) {
        return '86px';
      } else if (length === 2) {
        return '66px';
      } else if (length === 1) {
        return '48px';
      } else {
        return '48px';
      }
    }
  };
  const getTableBodyWidth = (intentLength: number) => {
    if (intentLength === 4) {
      return '1748px';
    } else if (intentLength === 3) {
      return '1658px';
    } else if (intentLength === 2) {
      return '1640px';
    } else if (intentLength === 1) {
      return '1652px';
    } else {
      return '1726px';
    }
  };

  const getDeleteWidth = (intentLength: number) => {
    if (intentLength === 4) {
      return '36px';
    } else if (intentLength === 3) {
      return '54px';
    } else if (intentLength === 2) {
      return '60px';
    } else if (intentLength === 1) {
      return '94px';
    } else {
      return '94px';
    }
  };
  const getActionHeaderWidth = (intentLength: number) => {
    if (intentLength === 4) {
      return '530px';
    } else if (intentLength === 3) {
      return '536px';
    } else if (intentLength === 2) {
      return '536px';
    } else if (intentLength === 1) {
      return '574px';
    } else {
      return '574px';
    }
  };
  const addQueryParameter = () => {
    setSelectedTab && setSelectedTab('diagram');
    const currentQuery = {...router.query};
    currentQuery.mode = 'edit';
    router.replace({
      pathname: router.pathname,
      query: currentQuery,
    }, undefined, {shallow: true});
  };

  const IdeaRow = ({child}) => {
    const hasLength = !!topicalMapArticle?.filter(i => i?.titleUuid === child?.titleUuid)?.length;
    const isLoading = topicalMapArticle?.find(i => i?.titleUuid === child?.titleUuid)?.isLoading;
    const isGenerating = (hasLength && isLoading) || (!child?.pageUuid && child?.aiGenerationStatus === 'PENDING') || (selectedArticlesToGenerate?.filter(article => article?.titleUuid === child?.titleUuid)?.length && generatingBulkArticles);
    return (
      <div key={child?.name || Math.random()} style={{width: '100%', display: 'flex', boxShadow: '0px 1px 0px 0px #E7E7E7', alignItems: 'flex-start', padding: '4px 0px'}}>
        {isInContentTopicMap ? (
          mode && mode === 'edit' ? (
            <CheckboxWrapper style={{minWidth: '28px', maxWidth: '28px'}}>
              {isInContentTopicMap ? <>
                <FontAwesomeIcon icon={child?.isSelected ? faDuotoneCircleCheck: faCirclePlus} style={{cursor: 'pointer'}} color={child?.isSelected ? '#1FAC47': '#A3A4A4'} fontSize={16} onClick={() => setClickedValue(child?.value)}/>
              </> : <TableCheckbox defaultChecked={child?.isSelected} onClick={() => setClickedValue(child?.value)} />}
            </CheckboxWrapper>
          ) : <></>
        ) : (
          <CheckboxWrapper style={{minWidth: '28px', maxWidth: '28px'}}>
            {isInContentTopicMap ? <>
              <FontAwesomeIcon icon={child?.isSelected ? faDuotoneCircleCheck: faCirclePlus} style={{cursor: 'pointer'}} color={child?.isSelected ? '#1FAC47': '#A3A4A4'} fontSize={16} onClick={() => setClickedValue(child?.value)}/>
            </> : <TableCheckbox defaultChecked={child?.isSelected} onClick={() => setClickedValue(child?.value)} />}
          </CheckboxWrapper>
        )}
        <div style={{minWidth: getSILength(searchIntentListLength, 'data'), maxWidth: getSILength(searchIntentListLength, 'data'), display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'center'}}>
          {child?.searchIntent?.length ?
            child?.searchIntent?.map((si, index) => (
              <StyledTag key={index} style={{color: generateColor(si?.toLowerCase()), backgroundColor: convertToTransparent(generateColor(si?.toLowerCase()), '10%')}}>
                {si?.toLowerCase() === 'informational' ? 'I' : si?.toLowerCase() === 'commercial' ? 'C' : si?.toLowerCase() === 'transactional' ? 'T' : si?.toLowerCase() === 'navigational' ? 'N' : 'NA'}
              </StyledTag>
            )):
            null}
        </div>
        <Keyword style={{minWidth: (isInContentTopicMap && mode !== 'edit') ? '350px' : '290px', maxWidth: (isInContentTopicMap && mode !== 'edit') ? '350px' : '290px'}}>{child?.name}</Keyword>
        <Title style={{minWidth: (isInContentTopicMap && mode !== 'edit') ? '546px' : '470px', maxWidth: (isInContentTopicMap && mode !== 'edit') ? '546px' : '470px', fontWeight: 400}}>{child?.titleName}</Title>
        <ActionsWrapper>
          <Tooltip title={(isInContentTopicMap && !child?.isSelected) ? <UnSelectedTooltipContent value={child?.value} /> : ''} overlayInnerStyle={{width: '336px', borderRadius: '8px'}}>
            <TopicalMapStepsWrapper style={{marginTop: '0px'}}>
              <div className='steps' style={{maxHeight: '34px'}}>
                {topicalMapStepsTitles.map((title, index) => (
                  <div
                    key={index}
                    className={`section ${
                      (index === 0) ? 'active-section ' :
                        (index === 1 && !isLoading && child?.pageUuid) ? 'active-section ' :
                          (index === 2 && !isLoading && child?.wpPublishedAtUrl) ? 'active-section ' : ''
                    } ${(index !== 0) ? '' : ' restricted'} ${!(topicalMap?.project === null || topicalMap?.project === 0 || topicalMap?.project) || (isInContentTopicMap && !child?.isSelected) && 'disabled-btn'}`}
                  >
                    <div className={`step ${(index === 0 && !isLoading && child?.pageUuid) ? 'active' : ''}`}>
                      {index === 0 ?
                        (hasLength && isLoading) || (!child?.pageUuid && child?.aiGenerationStatus === 'PENDING') || (selectedArticlesToGenerate?.filter(article => article?.titleUuid === child?.titleUuid)?.length && generatingBulkArticles) ?
                          <FreezeWrapper>
                            <ViewButton style={{color: '#7F4EAD', backgroundColor: 'rgba(127, 78, 173, 0.15)', width: '162px', padding: '3px 12px'}}>
                              <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#7F4EAD'}} spin />} />Generating...
                            </ViewButton>
                          </FreezeWrapper> :
                          child?.pageUuid ? <div style={{display: 'flex', alignItems: 'center', gap: '5px', cursor: (isInContentTopicMap && !child?.isSelected) ? 'not-allowed' : ''}}>
                            <FontAwesomeIcon icon={faCircleCheck} color={(isInContentTopicMap && !child?.isSelected) ? '#A3A4A4':'#7F4EAD'} fontSize={14} />
                            <GenerateButton style={{color: (isInContentTopicMap && !child?.isSelected) ? '#A3A4A4' : '#4E5156', cursor: (isInContentTopicMap && !child?.isSelected) ? 'not-allowed' : '', width: '84px'}} onClick={() => openUrl(`/content/seo-content-assistant/${child?.pageUuid}`, '_blank')}>View content</GenerateButton>
                          </div> : <FreezeWrapper><Dropdown trigger={['click']}
                            disabled={!(topicalMap?.project === null || topicalMap?.project === 0 || topicalMap?.project) || ( isInContentTopicMap && !child?.isSelected)}
                            overlayStyle={{top: '974px', left: '972px'}}
                            overlay={<GenerateArticleMenu>
                              <Menu.Item className='menu-item'
                                onClick={() => {
                                  setSelectedArticlesToGenerate([{id: child?.id, titleUuid: child?.titleUuid}]);
                                  onGenerateArticle([{id: child?.id, titleUuid: child?.titleUuid}], true);
                                }}>
                                <FontAwesomeIcon icon={faFileLines} color='#121212' fontSize={14}/>Full Article with AI
                              </Menu.Item>
                              <Menu.Item className='menu-item'
                                onClick={() => {
                                  setSelectedArticlesToGenerate([{id: child?.id, titleUuid: child?.titleUuid}]);
                                  onOutline(child);
                                }}><FontAwesomeIcon icon={faListTree} color='#121212' fontSize={14}/>Headings Outline with AI</Menu.Item>
                            </GenerateArticleMenu>}>
                            <Tooltip title={!(topicalMap?.project === null || topicalMap?.project === 0 || topicalMap?.project) && 'No project folder attached to this topical map'} overlayInnerStyle={{borderRadius: '10px'}}>
                              <ViewButton
                                style={{
                                  cursor: !(topicalMap?.project === null || topicalMap?.project === 0 || topicalMap?.project) || (isInContentTopicMap && !child?.isSelected) ? 'not-allowed' : 'pointer',
                                  color: !(topicalMap?.project === null || topicalMap?.project === 0 || topicalMap?.project) || (isInContentTopicMap && !child?.isSelected) ? '#A3A4A4' : '#7F4EAD',
                                  backgroundColor: !(topicalMap?.project === null || topicalMap?.project === 0 || topicalMap?.project) || (isInContentTopicMap && !child?.isSelected) ? '#F9F9FB' : 'rgba(127, 78, 173, 0.15)', width: '162px',
                                  padding: '5px 12px',
                                }}>
                                <FontAwesomeIcon icon={faWandMagicSparkles} color={!(topicalMap?.project === null || topicalMap?.project === 0 || topicalMap?.project) || (isInContentTopicMap && !child?.isSelected) ? '#A3A4A4' : '#7F4EAD'} fontSize={14} />{title}
                              </ViewButton>
                            </Tooltip>
                          </Dropdown> </FreezeWrapper>: index === 1 ? (<FreezeWrapper>
                          {!isLoading && child?.pageUuid && child?.wpPublishedAtUrl ? (!isInContentTopicMap || child.isSelected) ? (
                            <OneClickPublisher
                              getPopupContainer={() => wpContainerRef?.current || document.body}
                              errorId={errorId}
                              setErrorId={setErrorId}
                              topicalMapName={child?.wpPublishedAtUrl}
                              titleUuid={child?.titleUuid}
                              uuid={child?.pageUuid}
                              isInContentTopicMap={isInContentTopicMap}
                              setDataLoader={setDataLoader}
                              topicId={topicId}
                              componentIssueType={componentIssueType}
                              ProjectUuid={uuid}
                            />
                          ) :
                            (
                              <PluginButton style={{cursor: 'not-allowed', color: '#A3A4A4'}}><FontAwesomeIcon icon={faCircleCheck} color={'#A3A4A4'} fontSize={14} />Published</PluginButton>
                            ): (
                            <>
                              {
                                !isLoading && child?.pageUuid && (!isInContentTopicMap || child.isSelected) ?
                                  !wpArray?.length ? (
                                    <PluginButton onClick={() => setShowInstructionsModal(true)}><FontAwesomeIcon icon={faArrowUpFromLine} color={'#7F4EAD'} fontSize={14} />{title}</PluginButton>
                                  ) : (
                                    <OneClickPublisher
                                      getPopupContainer={() => wpContainerRef?.current || document.body}
                                      errorId={errorId}
                                      setErrorId={setErrorId}
                                      topicalMapName={child?.wpPublishedAtUrl}
                                      titleUuid={child?.titleUuid}
                                      uuid={child?.pageUuid}
                                      isInContentTopicMap={isInContentTopicMap}
                                      setDataLoader={setDataLoader}
                                      topicId={topicId}
                                      componentIssueType={componentIssueType}
                                      ProjectUuid={uuid}
                                    />
                                  ) : (
                                    <PluginButton style={{cursor: 'not-allowed', color: '#A3A4A4'}}><FontAwesomeIcon icon={faArrowUpFromLine} color={'#A3A4A4'} fontSize={14} />{title}</PluginButton>
                                  )
                              }
                            </>
                          )}
                        </FreezeWrapper>) : (<FreezeWrapper><ViewButton
                          onClick={() => {
                            if (!isLoading && child?.pageUuid && child?.wpPublishedAtUrl) {
                              openUrl(child?.wpPublishedAtUrl, '_blank');
                            } else {
                              return;
                            }
                          }
                          }
                          style={{color: !isLoading && child?.pageUuid && child?.wpPublishedAtUrl && (!isInContentTopicMap || child.isSelected) ? '#7F4EAD' : '#A3A4A4',
                            backgroundColor: !isLoading && child?.pageUuid && child?.wpPublishedAtUrl && (!isInContentTopicMap || child.isSelected) ? 'rgba(127, 78, 173, 0.15)' : 'rgba(127, 78, 173, 0)',
                            width: '162px',
                            padding: '5px 8px',
                            cursor: (!isLoading && child?.pageUuid && child?.wpPublishedAtUrl && (!isInContentTopicMap || child.isSelected)) ? 'pointer' : 'not-allowed',
                          }}>
                          {!isLoading && child?.pageUuid && child?.wpPublishedAtUrl ? (<>
                            <FontAwesomeIcon icon={faCircleCheck} color={(!isInContentTopicMap || child.isSelected) ?'#7F4EAD' : '#A3A4A4'} fontSize={14} />{title}
                          </>) : (<>
                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} color='#A3A4A4' fontSize={14} />{title}
                          </>)}
                        </ViewButton></FreezeWrapper>)}
                    </div>
                    {index !== topicalMapStepsTitles.length -1 && <FontAwesomeIcon icon={faChevronRight} color='#A3A4A4' fontSize={14} />}
                  </div>
                ))}
              </div>
            </TopicalMapStepsWrapper>
          </Tooltip>
        </ActionsWrapper>
        <DeleteRapper style={{display: 'flex', alignItems: 'center', minWidth: getDeleteWidth(searchIntentListLength), maxWidth: getDeleteWidth(searchIntentListLength), justifyContent: 'center', paddingTop: '0.625rem'}} isGenerating={isGenerating}>
          {child?.titleUuid === deleteId ? (
            <StyledSpin color='#7F4EAD' />
          ) : (
            <FreezeWrapper removeTooltip={!isLoading && child?.pageUuid && child?.wpPublishedAtUrl}>
              <Tooltip title={isGenerating ? `Can't delete while generating` : ''}>
                <FontAwesomeIcon
                  icon={faTrash}
                  className='trash'
                  fontSize={16}
                  onClick={() => {
                    if (isGenerating) return;
                    if (isInContentTopicMap && !mode) {
                      setOpenModal(true);
                      setDeleteModalData({value: child?.value, name: child?.name, titleUuid: child?.titleUuid});
                    } else {
                      setOpenModal(true);
                      setDeleteModalData({id: child?.id, name: child?.name, titleUuid: child?.titleUuid});
                    }
                  }}
                />
              </Tooltip>
            </FreezeWrapper>
          )}
        </DeleteRapper>
      </div>
    );
  };

  return (
    <>
      <div style={{height: '100%'}} ref={wpContainerRef}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
            {!isInContentTopicMap && tableDataFilter}
            {!isInContentTopicMap && <FiltersDropdownWrapper>
              {categoryIntentDropdown}
              {searchIntentDropdown}
              {searchInput}
            </FiltersDropdownWrapper>}
          </div>
          {!isInContentTopicMap && <ExportButton modalStyle={'ottoModalTable'} topicalMap={topicalMap} disabled={!topicMapDataList.length}/>}
        </div>
        <TableWrapper style={{height: '94%'}} className={showClass ? 'show-right-to-left' : ''}>
          <div className={`scroll-arrow ${showClass ? 'visible-scroll' : ''}`} onClick={scrollToEnd}>
            Scroll <FontAwesomeIcon icon={faArrowRight} color='#7F4EAD' fontSize={14}/>
          </div>
          <TableSectionWrapper ref={tableRef}>
            <div style={{marginBottom: '6px', display: 'flex', width: '100%'}}>
              {isInContentTopicMap ? (
                mode && mode === 'edit' ? (
                  <CategoryTitle className='p-hr p-vr' style={{borderRadius: '8px 0px 0px 8px', minWidth: '200px', maxWidth: '200px'}}>CATEGORY</CategoryTitle>
                ) : <></>) : (
                <CategoryTitle className='p-hr p-vr' style={{borderRadius: '8px 0px 0px 8px', minWidth: '200px', maxWidth: '200px'}}>CATEGORY</CategoryTitle>
              )}
              {isInContentTopicMap ? (
                mode && mode === 'edit' ? (
                  <CategoryTitle className='p-vr' style={{minWidth: '78px', maxWidth: '78px', textAlign: 'start'}}>ADD TO<br />TOPIC MAP</CategoryTitle>
                ) : <></>) : (
                <CategoryTitle className='p-vr' style={{minWidth: '78px', maxWidth: '78px', textAlign: 'start'}}>ADD TO<br />TOPIC MAP</CategoryTitle>
              )}
              <CategoryTitle className='p-vr justifyContentCenter' style={{borderRadius: (isInContentTopicMap && mode !== 'edit') && '8px 0px 0px 8px', minWidth: getSILength(searchIntentListLength, 'header'), maxWidth: getSILength(searchIntentListLength, 'header')}}>SI</CategoryTitle>
              <CategoryTitle className='p-vr' style={{minWidth: (isInContentTopicMap && mode !== 'edit') ? '350px' : '290px', maxWidth: (isInContentTopicMap && mode !== 'edit') ? '350px' : '290px'}}>KEYWORD</CategoryTitle>
              <CategoryTitle className='p-vr' style={{minWidth: (isInContentTopicMap && mode !== 'edit') ? '546px' : '470px', maxWidth: (isInContentTopicMap && mode !== 'edit') ? '546px' : '470px'}}>TITLE</CategoryTitle>
              <CategoryTitle
                className='p-hr p-vr'
                style={{
                  borderRadius: '0px 8px 8px 0px',
                  minWidth: getActionHeaderWidth(searchIntentListLength),
                  maxWidth: getActionHeaderWidth(searchIntentListLength),
                }}>{isInContentTopicMap ? 'PUBLISHING PROGRESS' : 'ACTIONS'}</CategoryTitle>
            </div>
            <BodyWrapper className='ttt' style={{height: isInContentTopicMap ? '734px' : '98%', width: (isInContentTopicMap && mode !== 'edit') ? '1580px' : getTableBodyWidth(searchIntentListLength)}}>
              {(isInContentTopicMap && !mode && selectedCount <= 0) ? <div style={{height: '35%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <StyledSelectedEmpty>
                  <NextImg src='/img/cube-plus.svg' width={32} height={32} alt='Cube Plus Icon'/>
                  <div className='text'>Start building your topical map by selecting your favorite ideas!</div>
                  <ButtonStyled
                    buttonType='transparent'
                    onClick={addQueryParameter}
                  >
                    <FontAwesomeIcon icon={faPlus} fontSize={14} color='#121212'/> Add ideas to Topical Map
                  </ButtonStyled>
                </StyledSelectedEmpty>
              </div> :
                !topicalMapDataState?.children?.length ?
                  <div style={{height: '70%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><StyledEmpty /></div> :
                  topicMapDataList.map((data, index) => {
                    const isExpanded = keysWithChildren.includes(data.key);
                    return (<>{
                      <StyledSingleData key={index}>
                        {isInContentTopicMap ? (
                          mode && mode === 'edit' ? (
                            <div style={{minWidth: '250px', maxWidth: '250px'}}>
                              <SectionTitle onClick={() => onChangeActive(!isExpanded, data?.key)}>
                                <FontAwesomeIcon
                                  style={{cursor: 'pointer', marginTop: '4px'}}
                                  icon={isExpanded ? faChevronUp : faChevronDown}
                                  color='#000000'
                                  fontSize={14}
                                />
                                {data?.name}
                              </SectionTitle>
                            </div>
                          ) : <></>
                        ) : (
                          <div style={{minWidth: '250px', maxWidth: '250px'}}>
                            <SectionTitle onClick={() => onChangeActive(!isExpanded, data?.key)}>
                              <FontAwesomeIcon
                                style={{cursor: 'pointer', marginTop: '4px'}}
                                icon={isExpanded ? faChevronUp : faChevronDown}
                                color='#000000'
                                fontSize={14}
                              />
                              {data?.name}
                            </SectionTitle>
                          </div>
                        )}
                        <div style={{display: !isExpanded ? 'flex' : 'block', alignItems: !isExpanded ? 'center' : 'flex-start'}}>
                          {!isExpanded ? (
                            <ExpandableCount onClick={() => onChangeActive(!isExpanded, data?.key)}>Expand {flattenChildren(data?.children)?.length} article idea{flattenChildren(data?.children)?.length > 1 ? 's': ''}</ExpandableCount>
                          ) : (
                            data?.children.map(child =>
                              child?.children?.length ? (
                                child?.children?.filter(item => (isInContentTopicMap && mode !== 'edit') ? item?.isSelected : true)?.map((grandChild, grandChildIndex) => <IdeaRow key={grandChildIndex} child={grandChild} />)
                              ) : (
                                <IdeaRow key={child?.name || Math.random()} child={child} />
                              ),
                            )
                          )}
                        </div>
                      </StyledSingleData>
                    }
                    </>);
                  })}
            </BodyWrapper>
          </TableSectionWrapper>
        </TableWrapper>
      </div>
      {openModal && <Wrapper>
        <Modal
          onClose={() => {
            setOpenModal(false);
            setDeleteModalData(null);
          }}
          visible={openModal}
          className={'customConfirmationModal'}
        >
          <ModalWrapper>
            <p>{(isInContentTopicMap && !mode) ? 'Are you sure you want to remove idea from topic map?' : 'Are you sure you want to delete this article?'}</p>
            <p className={'wadeleteArticleIdearningText'}>{(isInContentTopicMap && !mode) ? 'This action can be undone.' : 'This action cannot be undone.'}</p>
            <DeleteButton
              buttonType='transparent' size='lg'
              loading={deletingTopicalMap}
              onClick={async () => {
                if (isInContentTopicMap && !mode) {
                  deleteArticle(deleteModalData?.value, [...topicalMapArticle, {
                    id: deleteModalData?.id,
                    titleUuid: deleteModalData?.titleUuid,
                    title: deleteModalData?.name,
                    isLoading: true,
                    pageUuid: '',
                  }]);
                } else {
                  deleteArticle(deleteModalData?.name, [...topicalMapArticle, {
                    id: deleteModalData?.id,
                    titleUuid: deleteModalData?.titleUuid,
                    title: deleteModalData?.name,
                    isLoading: true,
                    pageUuid: '',
                  }]);
                }
              }
              }>
              {(isInContentTopicMap && !mode) ? 'Remove' : 'Delete'}
            </DeleteButton>
            <BackButton onClick={() => {
              setOpenModal(false);
              setDeleteModalData(null);
            }}
            buttonType='transparent'
            size='lg'>
            Go Back
            </BackButton>
          </ModalWrapper>
        </Modal>
      </Wrapper>}
    </>
  );
});

const FilterButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .ant-dropdown-trigger {
    border: 1px solid #CDCDCD;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #121212;
    padding: 5px 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 6px;
    svg {
      margin-top: -8px;
    }
  }
`;

const FiltersDropdownWrapper = styled(FilterButtons)`
  .ant-dropdown-trigger {
    padding: 3px 10px;
  }
`;

const StyledTag = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`;

const Keyword = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #121212;
  display: flex;
  align-items: center;
  padding: 0px 4px;
  line-height: 18px;
`;

const Title = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #121212;
  display: flex;
  align-items: center;
  padding: 0px 4px;
  line-height: 18px;
`;

const ViewButton = styled.div`
  width: 80px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  padding: 8px 12px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #7F4EAD;
  background-color: rgba(127, 78, 173, 0.15);
  line-height: normal;
`;

const TableCheckbox = styled(Checkbox)`
  .ant-checkbox {
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #7F4EAD !important;
    }
    .ant-checkbox-inner {
      border-radius: 4px !important;
      &::after {
        top: 42%;
      }
    }
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #7F4EAD;
        border-color: #7F4EAD;
      }
    }
    &:hover {
      .ant-checkbox-inner {
        border-color: #7F4EAD !important;
    }
    }
  }
`;

const ExpandableCount = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #2D6CCA;
  line-height: normal;
  cursor: pointer;
`;

const SectionTitle = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #121212;
  padding: 4px 6px;
  display: flex;
  gap: 8px;
  cursor: pointer;
`;

const CategoryTitle = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #121212;
  background-color: #F2F2F5;
  display: flex;
  align-items: center;
  &.p-hr {
    padding-left: 12px;
    padding-right: 12px;
  }
  &.p-vr {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  &.justifyContentCenter {
    justify-content: center;
  }
`;

const CheckboxWrapper = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #121212;
  padding: 0px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ActionsWrapper = styled.div`
  display: flex; 
  align-items: center; 
  gap: 14px; 
  min-width: 476px;
  &.disabled {
    cursor: not-allowed;
    background-color: #f1f1f1;
    pointer-events: none;
  }
`;

const BodyWrapper = styled.div`
  overflow: hidden;
  overflow-y: auto;
  height: 410px;
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.32);
  }

  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
  }
`;

const StyledSingleData = styled.div`
  width: 100%;
  display: flex;
  box-shadow: 0px 1px 0px 0px #E7E7E7;
`;

export const DeleteRapper = styled.div<{isGenerating: boolean}>`
  .trash {
    color: ${p => p.isGenerating ? '#A3A4A4' : '#A3A4A4'};
    :hover {
      color: ${p => p.isGenerating ? '#A3A4A4' : 'red'};
      cursor: ${p => p.isGenerating ? 'not-allowed' : 'pointer'};
    }
  }
`;
const TableWrapper = styled.div`
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0px;
    z-index: 1100;
    width: 20px;
    box-shadow: inset -20px 0 7px -4px rgba(5, 5, 5, 0.099);
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none; /* Prevent interaction */
  }
  .scroll-arrow {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    color:#7F4EAD;
    position: absolute;
    width: 58px;
    height: 22px;
    top: 2px;
    right: 22px;
    z-index: 1100;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .scroll-arrow {
    &.visible-scroll {
      opacity: 1 !important;
    }
  }
  &.show-right-to-left::after {
    opacity: 1;
  }
`;
const TableSectionWrapper = styled.div`
  margin-top: 10px;
  height: 100%;
  overflow: hidden;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.32);
  }

  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
  }
`;
const StyledSelectedEmpty = styled.div`
  .text {
    width: 248px;
    margin: 23px 0px 14px 0px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
`;
const ButtonStyled = styled(Button)`
  background-color: #f7f7f8;
  border: 1px solid #E8E8E8 !important;
  padding: 7px 15px;
  color: #121212 !important;
  font-size: 14px;
  font-weight: 400 !important;
  outline: none !important;
  width: 220px;
  border-radius: 6px;
`;
