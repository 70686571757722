import React from 'react';
import ConfigrationFields from './configrationFields';
import {BackButton, ConnectButton, Footer, Title} from './styles';
import {faArrowLeft} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Form} from 'antd';
import {observer} from 'mobx-react';
import {useStore} from '@/store/root-store';
import {apiError} from '@/utils/api';
import {notification} from '@/utils/notification-v2';
import {faInfoCircle} from '@fortawesome/pro-duotone-svg-icons';

const StepTwo = observer(({setCurrentStep, onClose, form}) => {
  const {campaigns: {
    getCampaignsCustomerEmails,
    addEmailConfigration,
    addingEmail,
  }} = useStore('');
  const [submittable, setSubmittable] = React.useState<boolean>(false);

  const configration = [
    {
      title: '',
      fields: [
        {label: 'Email', field: 'email', fieldKey: 'email_address', placeholder: 'Email', notRequired: false},
        {label: 'SMTP Server', field: 'text', fieldKey: 'smtp_server', placeholder: 'smtp.google.com', icon: faInfoCircle, disabled: true},
        {label: 'IMAP Server', field: 'text', fieldKey: 'imap_server', placeholder: 'imap.google.com', icon: faInfoCircle, disabled: true},
        {label: 'Password', field: 'password', fieldKey: 'smtp_app_password', placeholder: 'Password', notRequired: false},
      ],
    },
  ];
  const handleFormSubmit = async values => {
    try {
      if (!values?.imap_server?.length) delete values['imap_server'];
      if (!values?.smtp_server?.length) delete values['smtp_server'];

      await addEmailConfigration(values);
      onClose();
      getCampaignsCustomerEmails();
    } catch (error) {
      const errorMessage = apiError(error) as string;
      notification.error('', errorMessage, false, 'OK');
    }
  };


  // Watch all values
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form
      .validateFields({validateOnly: true})
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  return (
    <div>
      <BackButton onClick={() => {
        setCurrentStep('stepOne');
        form.resetFields();
      }}>
        <FontAwesomeIcon icon={faArrowLeft} color='#4E5156' /> Go back
      </BackButton>
      <Title>Configure SMTP/IMAP</Title>
      <Form form={form} onFinish={handleFormSubmit}>
        {configration?.map((item, index) => <ConfigrationFields key={index} fields={item?.fields} title={item?.title} />)}
        <Footer>
          <button className='cancel-button' onClick={onClose}>Cancel</button>
          <ConnectButton disabled={!submittable} loading={addingEmail} htmlType='submit' style={{background: submittable ? '#2D6CCA' : '#2d6ccaa6'}}>Connect</ConnectButton>
        </Footer>
      </Form>
    </div>
  );
});

export default StepTwo;
