/* eslint-disable max-len */
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faComments, faMagnifyingGlass} from '@fortawesome/pro-solid-svg-icons';
import {NextImg} from '@/utils/nextImg';

import {
  faBagShopping,
  faForkKnife,
  faImage,
  faLightbulb,
  faMapPin, faNewspaper,
  faScissors,
  faSquareQuestion,
  faStarSharpHalfStroke,
  faVideo,
} from '@fortawesome/pro-regular-svg-icons';
import {faTwitter} from '@fortawesome/free-brands-svg-icons';

import {
  faLink,
  faMessage,
  faVideo as duotoneFaVideo,
  faImage as duotoneFaImage,
  faMapPin as duotoneFaMapPin,
  faScissors as duotoneFaScissors,
  faComments as duotoneFaComments,
  faForkKnife as duotoneFaForkKnife,
  faLightbulb as duotoneFaLightbulb,
  faNewspaper as duotoneFaNewspaper,
  faBagShopping as duotoneFaBagShopping,
  faMagnifyingGlass as duotoneFaMagnifyingGlass,
  faStarSharpHalfStroke as duotoneFaStarSharpHalfStroke,
  faSparkles,
} from '@fortawesome/pro-duotone-svg-icons';

export const externalLinkIcon = () => {
  return (
    <svg viewBox='0 0 16 16' width='16px' height='16px' style={{marginBottom: '-4px'}}>
      <g id='surface13455468'>
        <path d='M 3.332031 2 C 2.605469 2 2 2.605469 2 3.332031 L 2 12.667969 C 2 13.394531 2.605469 14 3.332031 14 L 12.667969 14 C 13.394531 14 14 13.394531 14 12.667969 L 14 8 L 12.667969 8 L 12.667969 12.667969 L 3.332031 12.667969 L 3.332031 3.332031 L 8 3.332031 L 8 2 Z M 9.332031 2 L 9.332031 3.332031 L 11.722656 3.332031 L 5.527344 9.527344 L 6.472656 10.472656 L 12.667969 4.277344 L 12.667969 6.667969 L 14 6.667969 L 14 2 Z M 9.332031 2 ' />
      </g>
    </svg>
  );
};

export const externalLinkIconExclamation = ()=> {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' style={{color: 'white', margin: 10}}>
      <path d='M15.0875 4.90938L11.1156 0.9125C10.825 0.65 10.4406 0.5 10.0938 0.5H5.90312C5.53312 0.5 5.17438 0.650156 4.91062 0.91375L0.91375 4.9125C0.65 5.175 0.5 5.53125 0.5 5.87813V10.0719C0.5 10.4419 0.650156 10.8006 0.91375 11.0644L4.88562 15.0612C5.175 15.35 5.53125 15.5 5.87813 15.5H10.0719C10.4419 15.5 10.8006 15.3498 11.0644 15.0863L15.0612 11.1144C15.35 10.825 15.5 10.4406 15.5 10.0938V5.90312C15.5 5.53125 15.35 5.17188 15.0875 4.90938ZM7.25 4.75C7.25 4.3375 7.5875 4 8 4C8.4125 4 8.75 4.33594 8.75 4.75V8.75C8.75 9.16406 8.41406 9.5 8 9.5C7.58594 9.5 7.25 9.16562 7.25 8.75V4.75ZM8 12.5C7.4575 12.5 7.0175 12.06 7.0175 11.5175C7.0175 10.975 7.45719 10.535 8 10.535C8.54281 10.535 8.9825 10.975 8.9825 11.5175C8.98125 12.0594 8.54375 12.5 8 12.5Z' fill='#F44343' />
    </svg>
  );
};
export const externalLinkIconExclamationBad = ()=> {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' style={{color: 'white', margin: 10}}>
      <path d='M15.0875 4.90938L11.1156 0.9125C10.825 0.65 10.4406 0.5 10.0938 0.5H5.90312C5.53312 0.5 5.17438 0.650156 4.91062 0.91375L0.91375 4.9125C0.65 5.175 0.5 5.53125 0.5 5.87813V10.0719C0.5 10.4419 0.650156 10.8006 0.91375 11.0644L4.88562 15.0612C5.175 15.35 5.53125 15.5 5.87813 15.5H10.0719C10.4419 15.5 10.8006 15.3498 11.0644 15.0863L15.0612 11.1144C15.35 10.825 15.5 10.4406 15.5 10.0938V5.90312C15.5 5.53125 15.35 5.17188 15.0875 4.90938ZM7.25 4.75C7.25 4.3375 7.5875 4 8 4C8.4125 4 8.75 4.33594 8.75 4.75V8.75C8.75 9.16406 8.41406 9.5 8 9.5C7.58594 9.5 7.25 9.16562 7.25 8.75V4.75ZM8 12.5C7.4575 12.5 7.0175 12.06 7.0175 11.5175C7.0175 10.975 7.45719 10.535 8 10.535C8.54281 10.535 8.9825 10.975 8.9825 11.5175C8.98125 12.0594 8.54375 12.5 8 12.5Z' fill='#B62222' />
    </svg>
  );
};

export const externalLinkIconAlert = () => {
  return (
    <svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg' style={{color: 'white', margin: 10}}>
      <path d='M15.8223 12.0312L9.15665 0.65625C8.64634 -0.21875 7.35852 -0.21875 6.84477 0.65625L0.182257 12.0312C-0.330556 12.9031 0.308164 14 1.33601 14H14.6673C15.691 14 16.3317 12.9062 15.8223 12.0312ZM7.2504 4.25C7.2504 3.83594 7.58633 3.5 8.0004 3.5C8.41446 3.5 8.7504 3.8375 8.7504 4.25V8.25C8.7504 8.66406 8.41446 9 8.02852 9C7.64258 9 7.2504 8.66562 7.2504 8.25V4.25ZM8.0004 12C7.4579 12 7.0179 11.56 7.0179 11.0175C7.0179 10.475 7.45758 10.035 8.0004 10.035C8.54321 10.035 8.9829 10.475 8.9829 11.0175C8.98165 11.5594 8.54415 12 8.0004 12Z' fill='#FF8536' />
    </svg>
  );
};
export const externalLinkIconInfo = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' style={{color: 'white', margin: 10}}>
      <path d='M8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0ZM8 4C8.55219 4 9 4.44781 9 5C9 5.55219 8.55219 6 8 6C7.44781 6 7 5.55312 7 5C7 4.44688 7.44688 4 8 4ZM9.25 12H6.75C6.3375 12 6 11.6656 6 11.25C6 10.8344 6.33594 10.5 6.75 10.5H7.25V8.5H7C6.58594 8.5 6.25 8.16406 6.25 7.75C6.25 7.33594 6.5875 7 7 7H8C8.41406 7 8.75 7.33594 8.75 7.75V10.5H9.25C9.66406 10.5 10 10.8359 10 11.25C10 11.6641 9.66562 12 9.25 12Z' fill='#00D1ED' />
    </svg>
  );
};
export const externalLinkIconCheck = () => {
  return (<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' style={{color: 'white', margin: 10}}>
    <path d='M8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0ZM11.6187 6.61875L7.61875 10.6187C7.44688 10.7906 7.225 10.875 7 10.875C6.775 10.875 6.55188 10.79 6.38094 10.6191L4.38094 8.61913C4.04 8.27725 4.04 7.72256 4.38094 7.38069C4.72281 7.03881 5.2775 7.03881 5.61938 7.38069L7 8.7625L10.3813 5.38125C10.7231 5.03937 11.2778 5.03937 11.6197 5.38125C11.9594 5.72187 11.9594 6.27813 11.6187 6.61875Z' fill='#1FAC47'/>
  </svg>);
};
export const externalLinkIconCheckLight = () => {
  return (<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' style={{color: 'white', margin: 10}}>
    <path d='M8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0ZM11.6187 6.61875L7.61875 10.6187C7.44688 10.7906 7.225 10.875 7 10.875C6.775 10.875 6.55188 10.79 6.38094 10.6191L4.38094 8.61913C4.04 8.27725 4.04 7.72256 4.38094 7.38069C4.72281 7.03881 5.2775 7.03881 5.61938 7.38069L7 8.7625L10.3813 5.38125C10.7231 5.03937 11.2778 5.03937 11.6197 5.38125C11.9594 5.72187 11.9594 6.27813 11.6187 6.61875Z' fill='#BFE15E'/>
  </svg>);
};
export const externalLinkIconCheckTechnical = () => {
  return (
    <div style={{width: '12px', height: '12px', marginRight: '10px', display: 'flex'}}><svg width='12' height='12' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0ZM11.6187 6.61875L7.61875 10.6187C7.44688 10.7906 7.225 10.875 7 10.875C6.775 10.875 6.55188 10.79 6.38094 10.6191L4.38094 8.61913C4.04 8.27725 4.04 7.72256 4.38094 7.38069C4.72281 7.03881 5.2775 7.03881 5.61938 7.38069L7 8.7625L10.3813 5.38125C10.7231 5.03937 11.2778 5.03937 11.6197 5.38125C11.9594 5.72187 11.9594 6.27813 11.6187 6.61875Z' fill='#BFE15E'/>
    </svg></div>);
};

export const externalLinkIconAlertTechnical = () => {
  return (
    <div style={{width: '12px', height: '12px', marginRight: '10px', display: 'flex'}}><svg width='12' height='12' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg' >
      <path d='M15.8223 12.0312L9.15665 0.65625C8.64634 -0.21875 7.35852 -0.21875 6.84477 0.65625L0.182257 12.0312C-0.330556 12.9031 0.308164 14 1.33601 14H14.6673C15.691 14 16.3317 12.9062 15.8223 12.0312ZM7.2504 4.25C7.2504 3.83594 7.58633 3.5 8.0004 3.5C8.41446 3.5 8.7504 3.8375 8.7504 4.25V8.25C8.7504 8.66406 8.41446 9 8.02852 9C7.64258 9 7.2504 8.66562 7.2504 8.25V4.25ZM8.0004 12C7.4579 12 7.0179 11.56 7.0179 11.0175C7.0179 10.475 7.45758 10.035 8.0004 10.035C8.54321 10.035 8.9829 10.475 8.9829 11.0175C8.98165 11.5594 8.54415 12 8.0004 12Z' fill='#FF8536' />
    </svg></div>
  );
};

export const fbImage = () => {
  return <NextImg height={20} src='/img/icon/fb.png' />;
};

export const getFeatureIcons = iconName => {
  switch (iconName) {
    case 'related_searches':
      return (<FontAwesomeIcon icon={faMagnifyingGlass} />);
    case 'people_also_ask':
      return (<FontAwesomeIcon icon={faComments} />);
    case 'knowledge_graph':
      return (<FontAwesomeIcon icon={faLightbulb} />);
    case 'paid':
      return (<FontAwesomeIcon icon={faBagShopping} />);
    case 'video':
      return (<FontAwesomeIcon icon={faVideo} />);
    case 'featured_snippet':
      return (<FontAwesomeIcon icon={faScissors} />);
    case 'twitter':
      return (<FontAwesomeIcon icon={faTwitter} />);
    case 'image':
      return (<FontAwesomeIcon icon={faImage} />);
    case 'images':
      return (<FontAwesomeIcon icon={faImage} />);
    case 'local_pack':
      return (<FontAwesomeIcon icon={faMapPin} />);
    case 'google_reviews':
      return (<FontAwesomeIcon icon={faStarSharpHalfStroke} />);
    case 'top_stories':
      return (<FontAwesomeIcon icon={faNewspaper} />);
    case 'recipes':
      return (<FontAwesomeIcon icon={faForkKnife} />);
    case 'stories':
      return (<FontAwesomeIcon icon={faNewspaper} />);
    case 'local':
      return (<FontAwesomeIcon icon={faMapPin} />);
    case 'shopping':
      return (<FontAwesomeIcon icon={faBagShopping} />);
    case 'answer_box':
      return (<FontAwesomeIcon icon={faSquareQuestion} />);
  }
};

export const getColorSpamLinks = value=>{
  if (value == '1-10') {
    return '#0C872F';
  } else if (value == '11-20') {
    return '#1FAC47';
  } else if (value == '21-30') {
    return '#1FAC47';
  } else if (value == '31-40') {
    return '#BFE15E ';
  } else if (value == '41-50') {
    return '#F1AA3E';
  } else if (value == '51-60') {
    return '#F1AA3E';
  } else if (value == '61-70') {
    return '#FF8536';
  } else if (value == '71-80') {
    return '#F44343';
  } else if (value == '81-90') {
    return '#F44343 ';
  } else if (value == '91-100') {
    return '#B62222';
  } else {
    return '';
  }
};

export const copyIcon = () => {
  return (<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_744_35490)'>
      <path d='M13.7423 1.93129L12.0675 0.256484C11.9048 0.0921758 11.6805 0 11.4481 0H6.97469C6.00809 0 5.22469 0.783672 5.22469 1.75L5.22522 8.75C5.2493 9.71797 6.03406 10.5 6.9993 10.5H12.2493C13.2118 10.5 13.9993 9.7125 13.9993 8.75V2.5498C13.9993 2.31793 13.9063 2.09535 13.7423 1.93129ZM12.6868 8.75C12.6868 8.99161 12.4909 9.1875 12.2493 9.1875H6.97469C6.73302 9.1875 6.53719 8.99161 6.53719 8.75V1.75355C6.53719 1.51195 6.73308 1.31605 6.97469 1.31605H10.4747L10.4993 2.625C10.4993 3.10816 10.8911 3.5 11.3743 3.5H12.6622V8.75H12.6868ZM7.4368 12.25C7.4368 12.4916 7.24091 12.6875 6.9993 12.6875H1.72469C1.48302 12.6875 1.28719 12.4916 1.28719 12.25L1.31125 5.25273C1.31125 5.01113 1.50714 4.81523 1.74875 4.81523H4.3743V3.5H1.74902C0.782422 3.5 -0.000976562 4.2834 -0.000976562 5.25L-0.000708594 12.25C-0.000648438 13.2152 0.782969 14 1.7493 14H6.9993C7.9618 14 8.7493 13.2125 8.7493 12.25V11.375H7.46141L7.4368 12.25Z' fill='#4E5156'/>
    </g>
    <defs>
      <clipPath id='clip0_744_35490'>
        <rect width='14' height='14' fill='white'/>
      </clipPath>
    </defs>
  </svg>
  );
};

export const copyIconWhite = () => {
  return (<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_692_384)'>
      <path d='M13.7423 1.93129L12.0675 0.256484C11.9048 0.0921758 11.6805 0 11.4481 0H6.97469C6.00809 0 5.22469 0.783672 5.22469 1.75L5.22522 8.75C5.2493 9.71797 6.03406 10.5 6.9993 10.5H12.2493C13.2118 10.5 13.9993 9.7125 13.9993 8.75V2.5498C13.9993 2.31793 13.9063 2.09535 13.7423 1.93129ZM12.6868 8.75C12.6868 8.99161 12.4909 9.1875 12.2493 9.1875H6.97469C6.73302 9.1875 6.53719 8.99161 6.53719 8.75V1.75355C6.53719 1.51195 6.73308 1.31605 6.97469 1.31605H10.4747L10.4993 2.625C10.4993 3.10816 10.8911 3.5 11.3743 3.5H12.6622V8.75H12.6868ZM7.4368 12.25C7.4368 12.4916 7.24091 12.6875 6.9993 12.6875H1.72469C1.48302 12.6875 1.28719 12.4916 1.28719 12.25L1.31125 5.25273C1.31125 5.01113 1.50714 4.81523 1.74875 4.81523H4.3743V3.5H1.74902C0.782422 3.5 -0.000976562 4.2834 -0.000976562 5.25L-0.000708594 12.25C-0.000648438 13.2152 0.782969 14 1.7493 14H6.9993C7.9618 14 8.7493 13.2125 8.7493 12.25V11.375H7.46141L7.4368 12.25Z' fill='white'/>
    </g>
    <defs>
      <clipPath id='clip0_692_384'>
        <rect width='14' height='14' fill='white'/>
      </clipPath>
    </defs>
  </svg>
  );
};

export const svg = '<svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>';

export const reGenerateIcon = '<svg width="13px" height="16px" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.02383 4.02371C7.21055 1.83699 10.7438 1.82645 12.9445 3.98856L11.4961 5.43348C11.2535 5.67606 11.1832 6.03817 11.3133 6.35457C11.4434 6.67098 11.7527 6.87488 12.0938 6.87488H16.2949H16.5938C17.0613 6.87488 17.4375 6.49871 17.4375 6.03113V1.53114C17.4375 1.19012 17.2336 0.880745 16.9172 0.750667C16.6008 0.620589 16.2387 0.690901 15.9961 0.933479L14.5336 2.39598C11.4539 -0.645036 6.49336 -0.634489 3.43125 2.43114C2.57344 3.28895 1.95469 4.29793 1.575 5.37723C1.36758 5.96434 1.67695 6.60418 2.26055 6.8116C2.84414 7.01903 3.4875 6.70965 3.69492 6.12606C3.96563 5.35965 4.40508 4.63895 5.02383 4.02371ZM0.5625 9.96863V10.2358V10.2604V14.4686C0.5625 14.8097 0.766406 15.119 1.08281 15.2491C1.39922 15.3792 1.76133 15.3089 2.00391 15.0663L3.46641 13.6038C6.54609 16.6448 11.5066 16.6343 14.5687 13.5686C15.4266 12.7108 16.0488 11.7018 16.4285 10.6261C16.6359 10.0389 16.3266 9.3991 15.743 9.19168C15.1594 8.98426 14.516 9.29363 14.3086 9.87723C14.0379 10.6436 13.5984 11.3643 12.9797 11.9796C10.793 14.1663 7.25977 14.1768 5.05898 12.0147L6.50391 10.5663C6.74648 10.3237 6.8168 9.9616 6.68672 9.6452C6.55664 9.32879 6.24727 9.12488 5.90625 9.12488H1.70156H1.67695H1.40625C0.938672 9.12488 0.5625 9.50106 0.5625 9.96863Z" fill="black"/></svg>';

export const editAltIcon = '<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_18651_1298)"><path d="M9.91739 0.52832L8.59395 1.85176L12.1486 5.40645L13.4721 4.08301C14.1557 3.39941 14.1557 2.29199 13.4721 1.6084L12.3947 0.52832C11.7111 -0.155273 10.6037 -0.155273 9.92012 0.52832H9.91739ZM7.97598 2.46973L1.60215 8.84629C1.31778 9.13066 1.10997 9.4834 0.995123 9.86895L0.0271542 13.1584C-0.0412051 13.3908 0.0216855 13.6396 0.191217 13.8092C0.360748 13.9787 0.609576 14.0416 0.839264 13.976L4.12872 13.008C4.51426 12.8932 4.867 12.6854 5.15137 12.401L11.5307 6.02441L7.97598 2.46973Z" fill="black"/></g><defs><clipPath id="clip0_18651_1298"><rect width="14" height="14" fill="white"/></clipPath></defs></svg>';

export const greenCheckMarkFilled = () => {
  return (<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <g clipPath='url(#clip0_832_37620)'>
      <path d='M12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24ZM17.2969 9.79688L11.2969 15.7969C10.8562 16.2375 10.1438 16.2375 9.70781 15.7969L6.70781 12.7969C6.26719 12.3562 6.26719 11.6438 6.70781 11.2078C7.14844 10.7719 7.86094 10.7672 8.29688 11.2078L10.5 13.4109L15.7031 8.20312C16.1437 7.7625 16.8562 7.7625 17.2922 8.20312C17.7281 8.64375 17.7328 9.35625 17.2922 9.79219L17.2969 9.79688Z' fill='#1FAC47'/>
    </g>
    <defs>
      <clipPath id='clip0_832_37620'>
        <rect width='24' height='24' fill='white'/>
      </clipPath>
    </defs>
  </svg>
  );
};

export const heroImageCheck = () => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1264_279)'>
        <path
          d='M7 14C8.85652 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85652 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14ZM10.0898 5.71484L6.58984 9.21484C6.33281 9.47188 5.91719 9.47188 5.66289 9.21484L3.91289 7.46484C3.65586 7.20781 3.65586 6.79219 3.91289 6.53789C4.16992 6.28359 4.58555 6.28086 4.83984 6.53789L6.125 7.82305L9.16016 4.78516C9.41719 4.52812 9.83281 4.52812 10.0871 4.78516C10.3414 5.04219 10.3441 5.45781 10.0871 5.71211L10.0898 5.71484Z'
          fill='#A3A4A4'
        />
      </g>
      <defs>
        <clipPath id='clip0_1264_279'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const viewImageCheck = () => {
  return (
    <svg
      width='18'
      height='17'
      viewBox='0 0 14 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1264_273)'>
        <path
          d='M5.44404 6.22212C6.30203 6.22212 6.9996 5.52455 6.9996 4.66656C6.9996 4.49399 6.97043 4.32871 6.91939 4.17316C6.87564 4.03948 6.95828 3.88392 7.09925 3.88878C8.09092 3.9301 8.98293 4.60823 9.25272 5.61691C9.58571 6.86135 8.84682 8.14226 7.60237 8.47524C6.35793 8.80823 5.07703 8.06934 4.74404 6.82489C4.69786 6.65719 4.67355 6.48705 4.66626 6.32177C4.65897 6.1808 4.81453 6.09816 4.95064 6.14191C5.10619 6.19295 5.27147 6.22212 5.44404 6.22212Z'
          fill='white'
        />
        <path
          opacity='0.4'
          d='M2.31912 2.73686C3.46391 1.67228 5.03648 0.777832 7.00037 0.777832C8.96426 0.777832 10.5368 1.67228 11.6816 2.73686C12.8191 3.79415 13.5799 5.05561 13.942 5.92332C14.0222 6.11533 14.0222 6.32922 13.942 6.52123C13.5799 7.38894 12.8191 8.65283 11.6816 9.70769C10.5368 10.7723 8.96426 11.6667 7.00037 11.6667C5.03648 11.6667 3.46391 10.7723 2.31912 9.70769C1.18162 8.65283 0.420855 7.38894 0.0611328 6.52123C-0.0190755 6.32922 -0.0190755 6.11533 0.0611328 5.92332C0.420855 5.05561 1.18162 3.79172 2.31912 2.73686ZM7.00037 9.72228C7.92863 9.72228 8.81887 9.35353 9.47524 8.69715C10.1316 8.04077 10.5004 7.15053 10.5004 6.22228C10.5004 5.29402 10.1316 4.40378 9.47524 3.7474C8.81887 3.09103 7.92863 2.72228 7.00037 2.72228C6.07211 2.72228 5.18187 3.09103 4.5255 3.7474C3.86912 4.40378 3.50037 5.29402 3.50037 6.22228C3.50037 7.15053 3.86912 8.04077 4.5255 8.69715C5.18187 9.35353 6.07211 9.72228 7.00037 9.72228Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_1264_273'>
          <rect width='14' height='12.4444' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const viewImageCheck2 = () => {
  return (
    <svg
      width='12'
      height='11'
      viewBox='0 0 14 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1264_273)'>
        <path
          d='M5.44404 6.22212C6.30203 6.22212 6.9996 5.52455 6.9996 4.66656C6.9996 4.49399 6.97043 4.32871 6.91939 4.17316C6.87564 4.03948 6.95828 3.88392 7.09925 3.88878C8.09092 3.9301 8.98293 4.60823 9.25272 5.61691C9.58571 6.86135 8.84682 8.14226 7.60237 8.47524C6.35793 8.80823 5.07703 8.06934 4.74404 6.82489C4.69786 6.65719 4.67355 6.48705 4.66626 6.32177C4.65897 6.1808 4.81453 6.09816 4.95064 6.14191C5.10619 6.19295 5.27147 6.22212 5.44404 6.22212Z'
          fill='white'
        />
        <path
          opacity='0.4'
          d='M2.31912 2.73686C3.46391 1.67228 5.03648 0.777832 7.00037 0.777832C8.96426 0.777832 10.5368 1.67228 11.6816 2.73686C12.8191 3.79415 13.5799 5.05561 13.942 5.92332C14.0222 6.11533 14.0222 6.32922 13.942 6.52123C13.5799 7.38894 12.8191 8.65283 11.6816 9.70769C10.5368 10.7723 8.96426 11.6667 7.00037 11.6667C5.03648 11.6667 3.46391 10.7723 2.31912 9.70769C1.18162 8.65283 0.420855 7.38894 0.0611328 6.52123C-0.0190755 6.32922 -0.0190755 6.11533 0.0611328 5.92332C0.420855 5.05561 1.18162 3.79172 2.31912 2.73686ZM7.00037 9.72228C7.92863 9.72228 8.81887 9.35353 9.47524 8.69715C10.1316 8.04077 10.5004 7.15053 10.5004 6.22228C10.5004 5.29402 10.1316 4.40378 9.47524 3.7474C8.81887 3.09103 7.92863 2.72228 7.00037 2.72228C6.07211 2.72228 5.18187 3.09103 4.5255 3.7474C3.86912 4.40378 3.50037 5.29402 3.50037 6.22228C3.50037 7.15053 3.86912 8.04077 4.5255 8.69715C5.18187 9.35353 6.07211 9.72228 7.00037 9.72228Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_1264_273'>
          <rect width='14' height='12.4444' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const selectedImageCheck = () => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1264_227)'>
        <path
          d='M7 14C8.85652 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85652 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14ZM10.0898 5.71484L6.58984 9.21484C6.33281 9.47188 5.91719 9.47188 5.66289 9.21484L3.91289 7.46484C3.65586 7.20781 3.65586 6.79219 3.91289 6.53789C4.16992 6.28359 4.58555 6.28086 4.83984 6.53789L6.125 7.82305L9.16016 4.78516C9.41719 4.52812 9.83281 4.52812 10.0871 4.78516C10.3414 5.04219 10.3441 5.45781 10.0871 5.71211L10.0898 5.71484Z'
          fill='#7F4EAD'
        />
      </g>
      <defs>
        <clipPath id='clip0_1264_227'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const premiumAiQuota = () => {
  return (
    <svg
      width='43'
      height='43'
      viewBox='0 0 43 43'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1511_1068)'>
        <path
          d='M25.5 15.9375C25.5 17.1328 24.5504 18.2352 22.95 19.125C20.6258 20.4133 16.9203 21.25 12.75 21.25C8.79219 21.25 5.25008 20.4996 2.91125 19.3176C2.78707 19.2512 2.66621 19.1316 2.54934 19.125C0.948281 18.2352 0 17.1328 0 15.9375C0 13.0023 5.70828 10.625 12.75 10.625C19.7891 10.625 25.5 13.0023 25.5 15.9375ZM12.75 23.375C16.482 23.375 19.9617 22.7508 22.5848 21.6551C23.6672 21.2035 24.6699 20.6457 25.5 19.9617V22.3125C25.5 23.5078 24.5504 24.6102 22.95 25.5C22.8305 25.5066 22.7109 25.6262 22.5914 25.6926C20.2473 26.8746 16.7078 27.625 12.75 27.625C8.57969 27.625 4.87488 26.7883 2.54934 25.5C0.948281 24.6102 0 23.5078 0 22.3125V19.9617C0.826758 20.6457 1.83414 21.2035 2.91723 21.6551C5.54094 22.7508 9.01797 23.375 12.75 23.375ZM25.5 26.3367V28.6875C25.5 31.6227 19.7891 34 12.75 34C5.70828 34 0 31.6227 0 28.6875V26.3367C0.826758 27.0207 1.83414 27.5785 2.91723 28.0301C5.54094 29.1258 9.01797 29.75 12.75 29.75C16.482 29.75 19.9617 29.1258 22.5848 28.0301C23.6672 27.5785 24.6699 27.0207 25.5 26.3367Z'
          fill='#2AC155'
        />
        <path
          opacity='0.4'
          d='M34 5.3125C34 6.50848 33.0504 7.61016 31.45 8.5C29.5176 9.56914 26.6422 10.3262 23.3285 10.552C23.0895 10.4391 22.8371 10.3262 22.5848 10.2199C19.9617 9.12422 16.482 8.5 12.75 8.5C12.1988 8.5 11.6609 8.51328 11.123 8.53984C11.0965 8.52656 11.0766 8.51328 11.05 8.5C9.44961 7.61016 8.5 6.50848 8.5 5.3125C8.5 2.37867 14.1512 0 21.25 0C28.2891 0 34 2.37867 34 5.3125ZM27.625 18.4676C28.827 18.1355 30.0488 17.8367 31.0848 17.4051C32.1672 16.9535 33.1699 16.3957 34 15.7117V18.0625C34 20.0281 31.4367 21.748 27.625 22.6645V18.4676ZM27.625 15.9375C27.625 14.4832 26.9211 13.2879 26.0246 12.3914C27.9039 12.0992 29.6238 11.6344 31.0848 11.0301C32.1672 10.5785 33.1699 10.0207 34 9.33672V11.6875C34 12.8828 33.0504 13.9852 31.45 14.875C31.3305 14.8816 31.2109 15.0012 31.0914 15.0676C30.1219 15.5523 28.9465 15.9707 27.625 16.2895V15.9375Z'
          fill='#2AC155'
        />
      </g>
      <defs>
        <clipPath id='clip0_1511_1068'>
          <rect width='34' height='34' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const exportLinkIcon = () => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_4081_4748)'>
        <path
          d='M5.6082 2.0043C5.53711 1.84844 5.38125 1.75 5.21172 1.75C5.04219 1.75 4.88633 1.84844 4.81523 2.0043L3.37422 5.12422L0.254297 6.5625C0.0984375 6.63359 0 6.78945 0 6.96172C0 7.13398 0.0984375 7.28711 0.254297 7.3582L3.37422 8.79922L4.8125 11.9164C4.88359 12.0723 5.03945 12.1707 5.20898 12.1707C5.37852 12.1707 5.53437 12.0723 5.60547 11.9164L7.04648 8.79648L10.1664 7.35547C10.3223 7.28437 10.4207 7.12852 10.4207 6.95898C10.4207 6.78945 10.3223 6.63359 10.1664 6.5625L7.04922 5.12422L5.6082 2.0043Z'
          fill='#34AEF3'
        />
        <path
          opacity='0.4'
          d='M8.95508 2.32969L10.5 1.75L11.0797 0.205078C11.1262 0.0820312 11.2438 0 11.375 0C11.5062 0 11.6238 0.0820312 11.6703 0.205078L12.25 1.75L13.7949 2.32969C13.918 2.37617 14 2.49375 14 2.625C14 2.75625 13.918 2.87383 13.7949 2.92031L12.25 3.5L11.6703 5.04492C11.6238 5.16797 11.5062 5.25 11.375 5.25C11.2438 5.25 11.1262 5.16797 11.0797 5.04492L10.5 3.5L8.95508 2.92031C8.83203 2.87383 8.75 2.75625 8.75 2.625C8.75 2.49375 8.83203 2.37617 8.95508 2.32969ZM8.95508 11.0797L10.5 10.5L11.0797 8.95508C11.1262 8.83203 11.2438 8.75 11.375 8.75C11.5062 8.75 11.6238 8.83203 11.6703 8.95508L12.25 10.5L13.7949 11.0797C13.918 11.1262 14 11.2437 14 11.375C14 11.5063 13.918 11.6238 13.7949 11.6703L12.25 12.25L11.6703 13.7949C11.6238 13.918 11.5062 14 11.375 14C11.2438 14 11.1262 13.918 11.0797 13.7949L10.5 12.25L8.95508 11.6703C8.83203 11.6238 8.75 11.5063 8.75 11.375C8.75 11.2437 8.83203 11.1262 8.95508 11.0797Z'
          fill='#34AEF3'
        />
      </g>
      <defs>
        <clipPath id='clip0_4081_4748'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const positionSVG = color => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='8' height='11' viewBox='0 0 8 11' fill='none'>
      <path d='M7.5 4.25C7.5 6.4375 3.75 10.5 3.75 10.5C3.75 10.5 0 6.4375 0 4.25C0 2.17969 1.67969 0.5 3.75 0.5C5.82031 0.5 7.5 2.17969 7.5 4.25Z' fill={color}/>
    </svg>
  );
};

export const pinPoint = (
  color,
  strokeColor,
) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='54' height='68' viewBox='0 0 54 68' fill='none'>
      <g filter='url(#filter0_d_2475_2431)'>
        <path d='M45.5 24.75C45.5 35.6875 26.75 56 26.75 56C26.75 56 8 35.6875 8 24.75C8 14.3984 16.3984 6 26.75 6C37.1016 6 45.5 14.3984 45.5 24.75Z' fill={color} shapeRendering='crispEdges'/>
        <path d='M26.75 56L25.2804 57.3566L26.75 58.9486L28.2196 57.3566L26.75 56ZM26.75 56C28.2196 57.3566 28.2199 57.3563 28.2202 57.3559L28.2212 57.3549L28.2245 57.3513L28.2361 57.3387L28.2792 57.2916C28.3167 57.2507 28.3714 57.1907 28.4421 57.1127C28.5836 56.9566 28.7895 56.7282 29.0505 56.4346C29.5724 55.8474 30.3153 54.9992 31.2058 53.948C32.9849 51.8477 35.3614 48.9268 37.7425 45.6529C40.1183 42.3861 42.5279 38.7279 44.3515 35.1566C46.1473 31.6399 47.5 27.9715 47.5 24.75C47.5 13.2939 38.2061 4 26.75 4C15.2939 4 6 13.2939 6 24.75C6 27.9715 7.35268 31.6399 9.14848 35.1566C10.9721 38.7279 13.3817 42.3861 15.7575 45.6529C18.1386 48.9268 20.5151 51.8477 22.2942 53.948C23.1847 54.9992 23.9276 55.8474 24.4495 56.4346C24.7105 56.7282 24.9164 56.9566 25.0579 57.1127C25.1286 57.1907 25.1833 57.2507 25.2208 57.2916L25.2639 57.3387L25.2755 57.3513L25.2788 57.3549L25.2798 57.3559C25.2801 57.3563 25.2804 57.3566 26.75 56Z'
          stroke={strokeColor}
          strokeOpacity='0.5'
          strokeWidth='4'
          shapeRendering='crispEdges'
        />
      </g>
      <defs>
        <filter id='filter0_d_2475_2431' x='0' y='0' width='53.5' height='67.8973' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix'/>
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/>
          <feOffset dy='2'/>
          <feGaussianBlur stdDeviation='2'/>
          <feComposite in2='hardAlpha' operator='out'/>
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0'/>
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2475_2431'/>
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_2475_2431' result='shape'/>
        </filter>
      </defs>
    </svg>
  );
};


export const whiteLabelIcon = (
) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <g clipPath='url(#clip0_5393_303)'>
        <path opacity='0.4' d='M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM7.35547 5.74609C7.05859 5.91016 6.875 6.22656 6.875 6.5625V13.4375C6.875 13.7773 7.05859 14.0898 7.35547 14.2539C7.65234 14.418 8.01172 14.4141 8.30469 14.2344L13.9297 10.7969C14.207 10.625 14.3789 10.3242 14.3789 9.99609C14.3789 9.66797 14.207 9.36719 13.9297 9.19531L8.30469 5.75781C8.01562 5.58203 7.65234 5.57422 7.35547 5.73828V5.74609Z' fill='#A3A4A4'/>
        <path d='M8.30078 5.76241C8.01172 5.58663 7.64844 5.57882 7.35156 5.74288C7.05469 5.90694 6.87109 6.22335 6.87109 6.55929V13.4382C6.87109 13.778 7.05469 14.0905 7.35156 14.2546C7.64844 14.4187 8.00781 14.4148 8.30078 14.2351L13.9258 10.7976C14.2031 10.6257 14.375 10.3249 14.375 9.99679C14.375 9.66866 14.2031 9.36788 13.9258 9.19601L8.30078 5.75851V5.76241Z' fill='#A3A4A4'/>
      </g>
      <defs>
        <clipPath id='clip0_5393_303'>
          <rect width='20' height='20' fill='white'/>
        </clipPath>
      </defs>
    </svg>
  );
};

export const gsc4SVG = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26' fill='none'>
      <g clipPath='url(#clip0_4958_1265)'>
        <path d='M17.6035 3.92773V21.8027C17.6035 23.8028 18.9834 24.9173 20.4473 24.9173C21.8014 24.9173 23.291 23.9694 23.291 21.8027V4.06315C23.291 2.22961 21.9368 1.08398 20.4473 1.08398C18.9577 1.08398 17.6035 2.34742 17.6035 3.92773Z' fill='#F9AB00'/>
        <path d='M10.1562 13V21.8021C10.1562 23.8022 11.5361 24.9167 13 24.9167C14.3542 24.9167 15.8438 23.9687 15.8438 21.8021V13.1354C15.8438 11.3019 14.4896 10.1562 13 10.1562C11.5104 10.1562 10.1562 11.4197 10.1562 13Z' fill='#DC7000'/>
        <path d='M5.55176 24.918C7.12232 24.918 8.39551 23.6448 8.39551 22.0742C8.39551 20.5037 7.12232 19.2305 5.55176 19.2305C3.9812 19.2305 2.70801 20.5037 2.70801 22.0742C2.70801 23.6448 3.9812 24.918 5.55176 24.918Z' fill='#DC7000'/>
      </g>
      <defs>
        <clipPath id='clip0_4958_1265'>
          <rect width='26' height='26' fill='white'/>
        </clipPath>
      </defs>
    </svg>
  );
};

export const iconUpward = () => {
  return (
    <svg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M9.83203 2.41797V13.418C9.83203 14.6488 10.6812 15.3346 11.582 15.3346C12.4154 15.3346 13.332 14.7513 13.332 13.418V2.5013C13.332 1.37297 12.4987 0.667969 11.582 0.667969C10.6654 0.667969 9.83203 1.44547 9.83203 2.41797Z' fill='#F9AB00'/>
      <path d='M5.25 8V13.4167C5.25 14.6475 6.09917 15.3333 7 15.3333C7.83333 15.3333 8.75 14.75 8.75 13.4167V8.08333C8.75 6.955 7.91667 6.25 7 6.25C6.08333 6.25 5.25 7.0275 5.25 8Z' fill='#DC7000'/>
      <path d='M2.41602 15.3359C3.38251 15.3359 4.16602 14.5524 4.16602 13.5859C4.16602 12.6194 3.38251 11.8359 2.41602 11.8359C1.44952 11.8359 0.666016 12.6194 0.666016 13.5859C0.666016 14.5524 1.44952 15.3359 2.41602 15.3359Z' fill='#DC7000'/>
    </svg>
  );
};

export const iconReport = () => {
  return (
    <NextImg style={{width: '14px', height: '14px', marginTop: '6px'}} src='/img/iconReport.svg' />
  );
};


export const iconKeywordReport = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_59351_10205)'>
        <path opacity='0.4' d='M13.7063 1.29306C13.4187 1.00556 12.9906 0.921181 12.6156 1.07743C12.2406 1.23368 11.9969 1.59618 11.9969 2.00243V2.99931H11C10.0562 2.99931 9.16563 3.44306 8.6 4.19931L7 6.33368L8.25 7.99931L10.2 5.39931C10.3875 5.14618 10.6844 4.99931 11 4.99931H12V5.99931C12 6.40243 12.2437 6.76806 12.6187 6.92431C12.9937 7.08056 13.4219 6.99306 13.7094 6.70868L15.7094 4.70868C15.8969 4.52118 16.0031 4.26806 16.0031 4.00243C16.0031 3.73681 15.8969 3.48368 15.7094 3.29618L13.7094 1.29618L13.7063 1.29306ZM7 9.66493L5.75 7.99931L3.8 10.5993C3.6125 10.8524 3.31562 10.9993 3 10.9993H1C0.446875 10.9993 0 11.4462 0 11.9993C0 12.5524 0.446875 12.9993 1 12.9993H3C3.94375 12.9993 4.83437 12.5556 5.4 11.7993L7 9.66493Z' fill='#019197'/>
        <path d='M11 13C10.0562 13 9.16563 12.5563 8.6 11.8L3.8 5.4C3.6125 5.14688 3.31562 5 3 5H1C0.446875 5 0 4.55313 0 4C0 3.44688 0.446875 3 1 3H3C3.94375 3 4.83437 3.44375 5.4 4.2L10.2 10.6C10.3875 10.8531 10.6844 11 11 11H12V10C12 9.59688 12.2437 9.23125 12.6187 9.075C12.9937 8.91875 13.4219 9.00625 13.7094 9.29063L15.7094 11.2906C15.8969 11.4781 16.0031 11.7313 16.0031 11.9969C16.0031 12.2625 15.8969 12.5156 15.7094 12.7031L13.7094 14.7031C13.4219 14.9906 12.9937 15.075 12.6187 14.9188C12.2437 14.7625 12 14.4 12 13.9938V13H11Z' fill='#019197'/>
      </g>
      <defs>
        <clipPath id='clip0_59351_10205'>
          <rect width='16' height='16' fill='white'/>
        </clipPath>
      </defs>
    </svg>
  );
};

export const rbSiteExplorer = () => {
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_59369_1475)'>
        <path d='M4.46875 9.28125C4.46875 8.70977 4.92852 8.25 5.5 8.25C6.07148 8.25 6.53125 8.70977 6.53125 9.28125V12.0312C6.53125 12.6027 6.07148 13.0625 5.5 13.0625C4.92852 13.0625 4.46875 12.6027 4.46875 12.0312V9.28125ZM7.90625
             5.15625C7.90625 4.58477 8.36602 4.125 8.9375 4.125C9.50898 4.125 9.96875 4.58477 9.96875 5.15625V12.0312C9.96875 12.6027 9.50898 13.0625 8.9375 13.0625C8.36602 13.0625 7.90625 12.6027 7.90625 12.0312V5.15625ZM11.3438 7.90625C11.3438
              7.33477 11.8035 6.875 12.375 6.875C12.9465 6.875 13.4062 7.33477 13.4062 7.90625V12.0312C13.4062 12.6027 12.9465 13.0625 12.375 13.0625C11.8035 13.0625 11.3438 12.6027 11.3438 12.0312V7.90625ZM19.6539 21.5961L14.2098 16.1562C14.9531
               15.6105 15.6105 14.9531 16.1562 14.2098L21.5961 19.6539C22.1332 20.191 22.1332 21.0633 21.5961 21.6004C21.059 22.1375 20.1867 22.1375 19.6496 21.6004L19.6539 21.5961Z' fill='#2D6CCA'/>
        <path opacity='0.4' d='M8.9375 17.875C11.3079 17.875 13.5812 16.9334 15.2573 15.2573C16.9334 13.5812 17.875 11.3079 17.875 8.9375C17.875 6.56713 16.9334 4.29384 15.2573 2.61773C13.5812 0.941627 11.3079 0 8.9375 0C6.56713 0 4.29384 0.941627
             2.61773 2.61773C0.941627 4.29384 0 6.56713 0 8.9375C0 11.3079 0.941627 13.5812 2.61773 15.2573C4.29384 16.9334 6.56713 17.875 8.9375 17.875ZM4.46875 9.28125C4.46875 8.70977 4.92852 8.25 5.5 8.25C6.07148 8.25 6.53125 8.70977 6.53125 9.28125V12.0312C6.53125
              12.6027 6.07148 13.0625 5.5 13.0625C4.92852 13.0625 4.46875 12.6027 4.46875 12.0312V9.28125ZM7.90625 5.15625C7.90625 4.58477 8.36602 4.125 8.9375 4.125C9.50898 4.125 9.96875 4.58477 9.96875 5.15625V12.0312C9.96875 12.6027 9.50898 13.0625 8.9375 13.0625C8.36602 13.0625 7.90625 12.6027
               7.90625 12.0312V5.15625ZM11.3438 7.90625C11.3438 7.33477 11.8035 6.875 12.375 6.875C12.9465 6.875 13.4062 7.33477 13.4062 7.90625V12.0312C13.4062 12.6027 12.9465 13.0625 12.375 13.0625C11.8035 13.0625 11.3438 12.6027 11.3438 12.0312V7.90625Z' fill='#2D6CCA'/>
      </g>
      <defs>
        <clipPath id='clip0_59369_1475'>
          <rect width='22' height='22' fill='white'/>
        </clipPath>
      </defs>
    </svg>
  );
};

export const rbGA = () => {
  return (
    <svg width='22' height='24' viewBox='0 0 22 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M15.6042 2.92578V20.8008C15.6042 22.8009 16.9841 23.9154 18.4479 23.9154C19.8021 23.9154 21.2917 22.9674 21.2917 20.8008V3.0612C21.2917 1.22766 19.9375 0.0820312 18.4479 0.0820312C16.9583 0.0820312 15.6042 1.34547 15.6042 2.92578Z' fill='#F9AB00'/>
      <path d='M8.15625 12V20.8021C8.15625 22.8022 9.53615 23.9167 11 23.9167C12.3542 23.9167 13.8438 22.9688 13.8438 20.8021V12.1354C13.8438 10.3019 12.4896 9.15625 11 9.15625C9.51042 9.15625 8.15625 10.4197 8.15625 12Z' fill='#DC7000'/>
      <path d='M3.55208 23.9141C5.12264 23.9141 6.39583 22.6409 6.39583 21.0703C6.39583 19.4998 5.12264 18.2266 3.55208 18.2266C1.98152 18.2266 0.708332 19.4998 0.708332 21.0703C0.708332 22.6409 1.98152 23.9141 3.55208 23.9141Z' fill='#DC7000'/>
    </svg>
  );
};

export const rbKRT = () => {
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_59194_625)'>
        <path opacity='0.4' d='M18.8461 1.77795C18.4508 1.38264 17.8621 1.26662 17.3465 1.48147C16.8309 1.69631 16.4957 2.19475 16.4957 2.75334V4.12405H15.125C13.8273 4.12405 12.6027 4.7342 11.825 5.77404L9.625 8.70881L11.3438 10.999L14.025 7.42405C14.2828 7.076 14.691 6.87405 15.125 6.87405H16.5V8.24905C16.5 8.80334 16.8352 9.30608 17.3508 9.52092C17.8664 9.73577 18.4551 9.61545 18.8504 9.22444L21.6004 6.47444C21.8582 6.21662 22.0043 5.86858 22.0043 5.50334C22.0043 5.13811 21.8582 4.79006 21.6004 4.53225L18.8504 1.78225L18.8461 1.77795ZM9.625 13.2893L7.90625 10.999L5.225 14.574C4.96719 14.9221 4.55898 15.124 4.125 15.124H1.375C0.614453 15.124 0 15.7385 0 16.499C0 17.2596 0.614453 17.874 1.375 17.874H4.125C5.42266 17.874 6.64727 17.2639 7.425 16.224L9.625 13.2893Z' fill='#019197'/>
        <path d='M15.125 17.875C13.8273 17.875 12.6027 17.2648 11.825 16.225L5.225 7.425C4.96719 7.07695 4.55898 6.875 4.125 6.875H1.375C0.614453 6.875 0 6.26055 0 5.5C0 4.73945 0.614453 4.125 1.375 4.125H4.125C5.42266 4.125 6.64727 4.73516 7.425 5.775L14.025 14.575C14.2828 14.923 14.691 15.125 15.125 15.125H16.5V13.75C16.5 13.1957 16.8352 12.693 17.3508 12.4781C17.8664 12.2633 18.4551 12.3836 18.8504 12.7746L21.6004 15.5246C21.8582 15.7824 22.0043 16.1305 22.0043 16.4957C22.0043 16.8609 21.8582 17.209 21.6004 17.4668L18.8504 20.2168C18.4551 20.6121 17.8664 20.7281 17.3508 20.5133C16.8352 20.2984 16.5 19.8 16.5 19.2414V17.875H15.125Z' fill='#019197'/>
      </g>
      <defs>
        <clipPath id='clip0_59194_625'>
          <rect width='22' height='22' fill='white'/>
        </clipPath>
      </defs>
    </svg>
  );
};

export const rbDefaultLayout = () => {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path opacity='0.4' d='M40 7.5C40 4.74219 37.7578 2.5 35 2.5H17.5V37.5H35C37.7578 37.5 40 35.2578 40 32.5V7.5Z' fill='#2D6CCA'/>
      <path d='M5 2.5C2.24219 2.5 0 4.74219 0 7.5V32.5C0 35.2578 2.24219 37.5 5 37.5H17.5V2.5H5ZM6.875 7.5H10.625C11.6641 7.5 12.5 8.33594 12.5 9.375C12.5 10.4141 11.6641 11.25 10.625 11.25H6.875C5.83594 11.25 5 10.4141 5 9.375C5 8.33594 5.83594 7.5 6.875 7.5ZM5 16.875C5 15.8359 5.83594 15 6.875 15H10.625C11.6641 15 12.5 15.8359 12.5 16.875C12.5 17.9141 11.6641 18.75 10.625 18.75H6.875C5.83594 18.75 5 17.9141 5 16.875ZM6.875 22.5H10.625C11.6641 22.5 12.5 23.3359 12.5 24.375C12.5 25.4141 11.6641 26.25 10.625 26.25H6.875C5.83594 26.25 5 25.4141 5 24.375C5 23.3359 5.83594 22.5 6.875 22.5Z' fill='#2D6CCA'/>
    </svg>
  );
};

export const customTextIcon = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='30' height='30' rx='8' fill='#656D79' />
      <g clipPath='url(#clip0_59194_6373)'>
        <path d='M10.3125 9.84375V10.875C10.3125 11.4454 9.85166 11.9062 9.28125 11.9062C8.71084 11.9062 8.25 11.4454 8.25 10.875V9.32812C8.25 8.47412 8.94287 7.78125 9.79688 7.78125H15.4688H21.1406C21.9946 7.78125 22.6875 8.47412 22.6875 9.32812V10.875C22.6875 11.4454 22.2267 11.9062 21.6562 11.9062C21.0858 11.9062 20.625 11.4454 20.625 10.875V9.84375H16.5V20.1562H18.0469C18.6173 20.1562 19.0781 20.6171 19.0781 21.1875C19.0781 21.7579 18.6173 22.2188 18.0469 22.2188H12.8906C12.3202 22.2188 11.8594 21.7579 11.8594 21.1875C11.8594 20.6171 12.3202 20.1562 12.8906 20.1562H14.4375V9.84375H10.3125Z' fill='white' />
      </g>
      <defs>
        <clipPath id='clip0_59194_6373'>
          <rect width='14.4375' height='16.5' fill='white' transform='translate(8.25 6.75)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const sAIcon = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='30' height='30' rx='8' fill='#019197' />
      <g clipPath='url(#clip0_59194_6483)'>
        <path d='M21.6979 15.1533C23.1545 13.6967 23.1545 11.3377 21.6979 9.88103C20.4088 8.59197 18.3773 8.42439 16.8948 9.484L16.8536 9.51236C16.4823 9.77791 16.3973 10.2935 16.6628 10.6622C16.9284 11.0309 17.444 11.1185 17.8127 10.853L17.8539 10.8246C18.6815 10.2342 19.8133 10.327 20.53 11.0463C21.3421 11.8585 21.3421 13.1733 20.53 13.9854L17.6373 16.8832C16.8252 17.6953 15.5104 17.6953 14.6983 16.8832C13.979 16.1639 13.8862 15.0321 14.4766 14.2071L14.5049 14.1659C14.7705 13.7946 14.6828 13.279 14.3141 13.016C13.9455 12.7531 13.4273 12.8381 13.1643 13.2068L13.1359 13.2481C12.0738 14.7279 12.2413 16.7595 13.5304 18.0485C14.987 19.5052 17.346 19.5052 18.8027 18.0485L21.6979 15.1533ZM8.30195 14.55C6.84531 16.0067 6.84531 18.3656 8.30195 19.8223C9.59102 21.1113 11.6226 21.2789 13.105 20.2193L13.1463 20.191C13.5175 19.9254 13.6026 19.4098 13.337 19.0411C13.0715 18.6724 12.5559 18.5848 12.1872 18.8503L12.1459 18.8787C11.3184 19.4691 10.1866 19.3763 9.46984 18.657C8.65773 17.8423 8.65773 16.5274 9.46984 15.7153L12.3625 12.8201C13.1746 12.008 14.4895 12.008 15.3016 12.8201C16.0209 13.5394 16.1137 14.6712 15.5233 15.4988L15.4949 15.54C15.2294 15.9113 15.317 16.4269 15.6857 16.6899C16.0544 16.9528 16.5726 16.8678 16.8355 16.4991L16.8639 16.4578C17.9261 14.9754 17.7585 12.9438 16.4695 11.6548C15.0128 10.1981 12.6538 10.1981 11.1972 11.6548L8.30195 14.55Z' fill='white' />
      </g>
      <defs>
        <clipPath id='clip0_59194_6483'>
          <rect width='16.5' height='13.2' fill='white' transform='translate(6.75 8.25)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const autoPIcon = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='30' height='30' rx='8' fill='#1FAC47' />
      <g clipPath='url(#clip0_59194_6382)'>
        <path d='M11.6518 7.97985C11.9708 8.26666 11.9966 8.75328 11.7098 9.07233L9.3895 11.6505C9.2477 11.8084 9.0479 11.9018 8.8352 11.905C8.62251 11.9083 8.41948 11.8277 8.26802 11.6795L6.97573 10.3904C6.67603 10.0875 6.67603 9.59762 6.97573 9.29469C7.27544 8.99176 7.76851 8.99176 8.06821 9.29469L8.78042 10.0069L10.5561 8.03463C10.8429 7.71559 11.3295 7.68981 11.6486 7.97662L11.6518 7.97985ZM11.6518 13.1361C11.9708 13.4229 11.9966 13.9095 11.7098 14.2286L9.3895 16.8067C9.2477 16.9646 9.0479 17.0581 8.8352 17.0613C8.62251 17.0645 8.41948 16.9839 8.26802 16.8357L6.97573 15.5466C6.6728 15.2437 6.6728 14.7539 6.97573 14.4542C7.27866 14.1545 7.76851 14.1512 8.06821 14.4542L8.78042 15.1664L10.5561 13.1941C10.8429 12.8751 11.3295 12.8493 11.6486 13.1361H11.6518ZM13.9689 9.84254C13.9689 9.27213 14.4297 8.81129 15.0001 8.81129H22.2189C22.7893 8.81129 23.2501 9.27213 23.2501 9.84254C23.2501 10.413 22.7893 10.8738 22.2189 10.8738H15.0001C14.4297 10.8738 13.9689 10.413 13.9689 9.84254ZM13.9689 14.9988C13.9689 14.4284 14.4297 13.9675 15.0001 13.9675H22.2189C22.7893 13.9675 23.2501 14.4284 23.2501 14.9988C23.2501 15.5692 22.7893 16.03 22.2189 16.03H15.0001C14.4297 16.03 13.9689 15.5692 13.9689 14.9988ZM11.9064 20.155C11.9064 19.5846 12.3672 19.1238 12.9376 19.1238H22.2189C22.7893 19.1238 23.2501 19.5846 23.2501 20.155C23.2501 20.7255 22.7893 21.1863 22.2189 21.1863H12.9376C12.3672 21.1863 11.9064 20.7255 11.9064 20.155ZM8.29702 18.6082C8.70728 18.6082 9.10073 18.7711 9.39083 19.0612C9.68092 19.3513 9.8439 19.7448 9.8439 20.155C9.8439 20.5653 9.68092 20.9588 9.39083 21.2488C9.10073 21.5389 8.70728 21.7019 8.29702 21.7019C7.88676 21.7019 7.49331 21.5389 7.20322 21.2488C6.91312 20.9588 6.75015 20.5653 6.75015 20.155C6.75015 19.7448 6.91312 19.3513 7.20322 19.0612C7.49331 18.7711 7.88676 18.6082 8.29702 18.6082Z' fill='white' />
      </g>
      <defs>
        <clipPath id='clip0_59194_6382'>
          <rect width='16.5' height='16.5' fill='white' transform='translate(6.75 6.75)' />
        </clipPath>
      </defs>
    </svg>

  );
};

export const oTIcon = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='30' height='30' rx='8' fill='#7F4EAD' />
      <g clipPath='url(#clip0_59857_151)'>
        <path d='M11.6518 7.97985C11.9708 8.26666 11.9966 8.75328 11.7098 9.07233L9.3895 11.6505C9.2477 11.8084 9.0479 11.9018 8.8352 11.905C8.62251 11.9083 8.41948 11.8277 8.26802 11.6795L6.97573 10.3904C6.67603 10.0875 6.67603 9.59762 6.97573 9.29469C7.27544 8.99176 7.76851 8.99176 8.06821 9.29469L8.78042 10.0069L10.5561 8.03463C10.8429 7.71559 11.3295 7.68981 11.6486 7.97662L11.6518 7.97985ZM11.6518 13.1361C11.9708 13.4229 11.9966 13.9095 11.7098 14.2286L9.3895 16.8067C9.2477 16.9646 9.0479 17.0581 8.8352 17.0613C8.62251 17.0645 8.41948 16.9839 8.26802 16.8357L6.97573 15.5466C6.6728 15.2437 6.6728 14.7539 6.97573 14.4542C7.27866 14.1545 7.76851 14.1512 8.06821 14.4542L8.78042 15.1664L10.5561 13.1941C10.8429 12.8751 11.3295 12.8493 11.6486 13.1361H11.6518ZM13.9689 9.84254C13.9689 9.27213 14.4297 8.81129 15.0001 8.81129H22.2189C22.7893 8.81129 23.2501 9.27213 23.2501 9.84254C23.2501 10.413 22.7893 10.8738 22.2189 10.8738H15.0001C14.4297 10.8738 13.9689 10.413 13.9689 9.84254ZM13.9689 14.9988C13.9689 14.4284 14.4297 13.9675 15.0001 13.9675H22.2189C22.7893 13.9675 23.2501 14.4284 23.2501 14.9988C23.2501 15.5692 22.7893 16.03 22.2189 16.03H15.0001C14.4297 16.03 13.9689 15.5692 13.9689 14.9988ZM11.9064 20.155C11.9064 19.5846 12.3672 19.1238 12.9376 19.1238H22.2189C22.7893 19.1238 23.2501 19.5846 23.2501 20.155C23.2501 20.7255 22.7893 21.1863 22.2189 21.1863H12.9376C12.3672 21.1863 11.9064 20.7255 11.9064 20.155ZM8.29702 18.6082C8.70728 18.6082 9.10073 18.7711 9.39083 19.0612C9.68092 19.3513 9.8439 19.7448 9.8439 20.155C9.8439 20.5653 9.68092 20.9588 9.39083 21.2488C9.10073 21.5389 8.70728 21.7019 8.29702 21.7019C7.88676 21.7019 7.49331 21.5389 7.20322 21.2488C6.91312 20.9588 6.75015 20.5653 6.75015 20.155C6.75015 19.7448 6.91312 19.3513 7.20322 19.0612C7.49331 18.7711 7.88676 18.6082 8.29702 18.6082Z' fill='white' />
      </g>
      <defs>
        <clipPath id='clip0_59857_151'>
          <rect width='16.5' height='16.5' fill='white' transform='translate(6.75 6.75)' />
        </clipPath>
      </defs>
    </svg>

  );
};

export const ottoIcon = () => {
  return (
    <svg style={{height: '24px', color: 'black'}} aria-hidden='true' focusable='false' data-prefix='fad' data-icon='microchip-ai' className='svg-inline--fa fa-microchip-ai ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><g className='fa-duotone-group'><path className='fa-secondary' fill='currentColor' d='M0 256c0 8.836 7.164 16 16 16H64v-32H16C7.164 240 0 247.2 0 256zM0 352c0 8.836 7.164 16 16 16H64v-32H16C7.164 336 0 343.2 0 352zM0 160c0 8.836 7.164 16 16 16H64v-32H16C7.164 144 0 151.2 0 160zM512 160c0-8.836-7.162-16-16-16H448v32h48C504.8 176 512 168.8 512 160zM176 16C176 7.164 168.8 0 160 0C151.2 0 144 7.164 144 16V64h32V16zM272 16C272 7.164 264.8 0 256 0C247.2 0 240 7.164 240 16V64h32V16zM144 496C144 504.8 151.2 512 160 512c8.838 0 16-7.164 16-16V448h-32V496zM352 0c-8.836 0-16 7.164-16 16V64h32V16C368 7.164 360.8 0 352 0zM240 496c0 8.836 7.164 16 16 16c8.838 0 16-7.164 16-16V448h-32V496zM496 240H448v32h48C504.8 272 512 264.8 512 256S504.8 240 496 240zM336 496c0 8.836 7.164 16 16 16c8.838 0 16-7.164 16-16V448h-32V496zM496 336H448v32h48c8.838 0 16-7.164 16-16S504.8 336 496 336z'></path><path className='fa-primary' fill='currentColor' d='M190.5 272h35.07L208 231.9L190.5 272zM384 64H128C92.65 64 64 92.65 64 128v256c0 35.35 28.65 64 64 64h256c35.35 0 64-28.65 64-64V128C448 92.65 419.3 64 384 64zM270.4 334.7C268.3 335.6 266.2 336 264 336c-6.156 0-12.04-3.578-14.66-9.594L239.5 304H176.5l-9.805 22.41c-3.531 8.125-12.94 11.83-21.06 8.25C137.5 331.1 133.8 321.7 137.3 313.6l56-128c5.062-11.66 24.25-11.66 29.31 0l56 128C282.2 321.7 278.5 331.1 270.4 334.7zM352 320c0 8.844-7.156 16-16 16S320 328.8 320 320V192c0-8.844 7.156-16 16-16S352 183.2 352 192V320z'></path></g></svg>

  );
};

export const gAIcon = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='30' height='30' rx='8' fill='#F1AA3E' />
      <g clipPath='url(#clip0_59194_6391)'>
        <path d='M18.1875 8.71875V21.0937C18.1875 22.4784 19.1428 23.25 20.1562 23.25C21.0938 23.25 22.125 22.5937 22.125 21.0937V8.8125C22.125 7.54312 21.1875 6.75 20.1562 6.75C19.125 6.75 18.1875 7.62469 18.1875 8.71875Z' fill='white' />
        <path d='M13.0312 15V21.0938C13.0312 22.4784 13.9866 23.25 15 23.25C15.9375 23.25 16.9688 22.5938 16.9688 21.0938V15.0938C16.9688 13.8244 16.0312 13.0312 15 13.0312C13.9688 13.0312 13.0312 13.9059 13.0312 15Z' fill='white' />
        <path d='M9.84375 23.25C10.9311 23.25 11.8125 22.3686 11.8125 21.2812C11.8125 20.1939 10.9311 19.3125 9.84375 19.3125C8.75644 19.3125 7.875 20.1939 7.875 21.2812C7.875 22.3686 8.75644 23.25 9.84375 23.25Z' fill='white' />
      </g>
      <defs>
        <clipPath id='clip0_59194_6391'>
          <rect width='18' height='18' fill='white' transform='translate(6 6)' />
        </clipPath>
      </defs>
    </svg>

  );
};

export const localSeoIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='17' height='22' viewBox='0 0 17 22' fill='none'>
      <path opacity='0.4' d='M9.26836 21.45C11.4727 18.6914 16.5 12.0055 16.5 8.25C16.5 3.69531 12.8047 0 8.25 0C3.69531 0 0 3.69531 0 8.25C0 12.0055 5.02734 18.6914 7.23164 21.45C7.76016 22.1074 8.73984 22.1074 9.26836 21.45ZM8.25 4.8125C9.16168 4.8125 10.036 5.17466 10.6807 5.81932C11.3253 6.46398 11.6875 7.33832 11.6875 8.25C11.6875 9.16168 11.3253 10.036 10.6807 10.6807C10.036 11.3253 9.16168 11.6875 8.25 11.6875C7.33832 11.6875 6.46398 11.3253 5.81932 10.6807C5.17466 10.036 4.8125 9.16168 4.8125 8.25C4.8125 7.33832 5.17466 6.46398 5.81932 5.81932C6.46398 5.17466 7.33832 4.8125 8.25 4.8125Z' fill='#FF8536'/>
    </svg>
  );
};

export const gmbIcon = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='30' height='30' rx='8' fill='#2D6CCA' />
      <g clipPath='url(#clip0_59194_6465)'>
        <path d='M22.4365 10.4734L20.7951 7.87526C20.649 7.64323 20.3883 7.5 20.1104 7.5H9.8896C9.61174 7.5 9.35106 7.64323 9.20497 7.87526L7.5607 10.4734C6.71278 11.8141 7.4633 13.6789 9.04741 13.8937C9.162 13.9081 9.27945 13.9167 9.39403 13.9167C10.1417 13.9167 10.8063 13.5901 11.2617 13.0859C11.7172 13.5901 12.3818 13.9167 13.1294 13.9167C13.8771 13.9167 14.5417 13.5901 14.9972 13.0859C15.4526 13.5901 16.1172 13.9167 16.8649 13.9167C17.6154 13.9167 18.2771 13.5901 18.7326 13.0859C19.1909 13.5901 19.8526 13.9167 20.6003 13.9167C20.7177 13.9167 20.8323 13.9081 20.9469 13.8937C22.5367 13.6818 23.2901 11.8169 22.4393 10.4734H22.4365ZM21.0643 14.8018H21.0615C20.9097 14.8219 20.755 14.8333 20.5974 14.8333C20.2422 14.8333 19.9013 14.7789 19.5834 14.6815V18.5H10.4167V14.6786C10.0959 14.7789 9.7521 14.8333 9.39689 14.8333C9.23934 14.8333 9.08179 14.8219 8.92997 14.8018H8.9271C8.80965 14.7846 8.69507 14.7646 8.58335 14.7359V18.5V20.3333C8.58335 21.3445 9.40549 22.1667 10.4167 22.1667H19.5834C20.5946 22.1667 21.4167 21.3445 21.4167 20.3333V18.5V14.7359C21.3021 14.7646 21.1875 14.7875 21.0643 14.8018Z' fill='white' />
      </g>
      <defs>
        <clipPath id='clip0_59194_6465'>
          <rect width='16.5' height='14.6667' fill='white' transform='translate(6.75 7.5)' />
        </clipPath>
      </defs>
    </svg>

  );
};

export const kRTIcon = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='30' height='30' rx='8' fill='#019197' />
      <g clipPath='url(#clip0_59194_6474)'>
        <path d='M19.7631 7.86039C20.1498 7.69926 20.5913 7.78949 20.8878 8.08275L22.9503 10.1453C23.1437 10.3386 23.2532 10.5996 23.2532 10.8736C23.2532 11.1475 23.1437 11.4085 22.9503 11.6019L20.8878 13.6644C20.5913 13.9609 20.1498 14.0479 19.7631 13.8868C19.3764 13.7256 19.125 13.3518 19.125 12.9328V11.908H18.0938C17.7683 11.908 17.4621 12.0595 17.2688 12.3205L15.9023 14.1413L14.6133 12.4237L15.6188 11.083C16.2021 10.3032 17.1205 9.84554 18.0938 9.84554H19.125V8.81429C19.125 8.39857 19.3764 8.02152 19.7631 7.86039ZM12.0352 15.8622L13.3242 17.5799L12.3187 18.9205C11.7354 19.7004 10.817 20.158 9.84375 20.158H7.78125C7.21084 20.158 6.75 19.6972 6.75 19.1268C6.75 18.5564 7.21084 18.0955 7.78125 18.0955H9.84375C10.1692 18.0955 10.4754 17.9441 10.6688 17.683L12.0352 15.8622ZM20.8846 21.9208C20.5881 22.2173 20.1466 22.3043 19.7599 22.1432C19.3731 21.9821 19.1218 21.6082 19.1218 21.1893V20.158H18.0938C17.1205 20.158 16.2021 19.7004 15.6188 18.9205L10.6688 12.3205C10.4754 12.0595 10.1692 11.908 9.84375 11.908H7.78125C7.21084 11.908 6.75 11.4472 6.75 10.8768C6.75 10.3064 7.21084 9.84554 7.78125 9.84554H9.84375C10.817 9.84554 11.7354 10.3032 12.3187 11.083L17.2688 17.683C17.4621 17.9441 17.7683 18.0955 18.0938 18.0955H19.125V17.0643C19.125 16.6486 19.3764 16.2715 19.7631 16.1104C20.1498 15.9493 20.5913 16.0395 20.8878 16.3328L22.9503 18.3953C23.1437 18.5886 23.2532 18.8496 23.2532 19.1236C23.2532 19.3975 23.1437 19.6585 22.9503 19.8519L20.8878 21.9144L20.8846 21.9208Z' fill='white' />
      </g>
      <defs>
        <clipPath id='clip0_59194_6474'>
          <rect width='16.5' height='16.5' fill='white' transform='translate(6.75 6.75)' />
        </clipPath>
      </defs>
    </svg>

  );
};


export const rbOt = () => {
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_59162_91)'>
        <path d='M6.5355 1.6411C6.96089 2.02352 6.99526 2.67235 6.61284 3.09774L3.51909 6.53524C3.33003 6.74578 3.06362 6.87039 2.78003 6.87469C2.49644 6.87899 2.22573 6.77156 2.02378 6.57391L0.300732 4.85516C-0.098877 4.45125 -0.098877 3.79813 0.300732 3.39422C0.700342 2.99031 1.35776 2.99031 1.75737 3.39422L2.70698 4.34383L5.07456 1.71414C5.45698 1.28875 6.10581 1.25438 6.5312 1.6368L6.5355 1.6411ZM6.5355 8.5161C6.96089 8.89852 6.99526 9.54735 6.61284 9.97274L3.51909 13.4102C3.33003 13.6208 3.06362 13.7454 2.78003 13.7497C2.49644 13.754 2.22573 13.6466 2.02378 13.4489L0.300732 11.7302C-0.103174 11.3263 -0.103174 10.6731 0.300732 10.2735C0.704639 9.87391 1.35776 9.86961 1.75737 10.2735L2.70698 11.2231L5.07456 8.59344C5.45698 8.16805 6.10581 8.13367 6.5312 8.5161H6.5355ZM9.62495 4.12469C9.62495 3.36414 10.2394 2.74969 11 2.74969H20.625C21.3855 2.74969 22 3.36414 22 4.12469C22 4.88524 21.3855 5.49969 20.625 5.49969H11C10.2394 5.49969 9.62495 4.88524 9.62495 4.12469ZM9.62495 10.9997C9.62495 10.2391 10.2394 9.62469 11 9.62469H20.625C21.3855 9.62469 22 10.2391 22 10.9997C22 11.7602 21.3855 12.3747 20.625 12.3747H11C10.2394 12.3747 9.62495 11.7602 9.62495 10.9997ZM6.87495 17.8747C6.87495 17.1141 7.4894 16.4997 8.24995 16.4997H20.625C21.3855 16.4997 22 17.1141 22 17.8747C22 18.6352 21.3855 19.2497 20.625 19.2497H8.24995C7.4894 19.2497 6.87495 18.6352 6.87495 17.8747ZM2.06245 15.8122C2.60946 15.8122 3.13407 16.0295 3.52086 16.4163C3.90765 16.8031 4.12495 17.3277 4.12495 17.8747C4.12495 18.4217 3.90765 18.9463 3.52086 19.3331C3.13407 19.7199 2.60946 19.9372 2.06245 19.9372C1.51544 19.9372 0.990837 19.7199 0.604043 19.3331C0.21725 18.9463 -4.88404e-05 18.4217 -4.88404e-05 17.8747C-4.88404e-05 17.3277 0.21725 16.8031 0.604043 16.4163C0.990837 16.0295 1.51544 15.8122 2.06245 15.8122Z' fill='#7F4EAD' />
      </g>
      <defs>
        <clipPath id='clip0_59162_91'>
          <rect width='22' height='22' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const aiSummaryIcon = () => {
  return (
    <svg
      width='27'
      height='27'
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_11668_91)'>
        <path
          opacity='0.4'
          d='M21.748 5.65781L25.5 4.25L26.9078 0.498047C27.0207 0.199219 27.3063 0 27.625 0C27.9437 0 28.2293 0.199219 28.3422 0.498047L29.75 4.25L33.502 5.65781C33.8008 5.7707 34 6.05625 34 6.375C34 6.69375 33.8008 6.9793 33.502 7.09219L29.75 8.5L28.3422 12.252C28.2293 12.5508 27.9437 12.75 27.625 12.75C27.3063 12.75 27.0207 12.5508 26.9078 12.252L25.5 8.5L21.748 7.09219C21.4492 6.9793 21.25 6.69375 21.25 6.375C21.25 6.05625 21.4492 5.7707 21.748 5.65781ZM21.748 26.9078L25.5 25.5L26.9078 21.748C27.0207 21.4492 27.3063 21.25 27.625 21.25C27.9437 21.25 28.2293 21.4492 28.3422 21.748L29.75 25.5L33.502 26.9078C33.8008 27.0207 34 27.3062 34 27.625C34 27.9438 33.8008 28.2293 33.502 28.3422L29.75 29.75L28.3422 33.502C28.2293 33.8008 27.9437 34 27.625 34C27.3063 34 27.0207 33.8008 26.9078 33.502L25.5 29.75L21.748 28.3422C21.4492 28.2293 21.25 27.9438 21.25 27.625C21.25 27.3062 21.4492 27.0207 21.748 26.9078Z'
          fill='purple'
        />
        <path
          d='M13.6199 4.86758C13.4473 4.48906 13.0688 4.25 12.657 4.25C12.2453 4.25 11.8668 4.48906 11.6941 4.86758L8.19453 12.4445L0.617578 15.9375C0.239063 16.1102 0 16.4887 0 16.907C0 17.3254 0.239063 17.6973 0.617578 17.8699L8.19453 21.3695L11.6875 28.9399C11.8602 29.3184 12.2387 29.5574 12.6504 29.5574C13.0621 29.5574 13.4406 29.3184 13.6133 28.9399L17.1129 21.3629L24.6899 17.8633C25.0684 17.6906 25.3074 17.3121 25.3074 16.9004C25.3074 16.4887 25.0684 16.1102 24.6899 15.9375L17.1195 12.4445L13.6199 4.86758Z'
          fill='purple'
        />
      </g>
      <defs>
        <clipPath id='clip0_11668_91'>
          <rect width='34' height='34' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const serpFeatureIcons = {
  'related_searches': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={duotoneFaMagnifyingGlass} />,
  'people_also_ask': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={duotoneFaComments} />,
  'knowledge_graph': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={duotoneFaLightbulb} />,
  'knowledge_panel': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={duotoneFaLightbulb} />,
  'paid': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={duotoneFaBagShopping} />,
  'video': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={duotoneFaVideo} />,
  'videos': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={duotoneFaVideo} />,
  'video_carousel': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={duotoneFaVideo} />,
  'featured_video': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={duotoneFaVideo} />,
  'featured_snippet': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={duotoneFaScissors} />,
  'twitter': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={faTwitter} />,
  'image': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={duotoneFaImage} />,
  'images': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={duotoneFaImage} />,
  'image_pack': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={duotoneFaImage} />,
  'local_pack': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={duotoneFaMapPin} />,
  'google_reviews': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={duotoneFaStarSharpHalfStroke} />,
  'reviews': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={duotoneFaStarSharpHalfStroke} />,
  'top_stories': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={duotoneFaNewspaper} />,
  'recipes': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={duotoneFaForkKnife} />,
  'stories': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={duotoneFaNewspaper} />,
  'local': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={duotoneFaMapPin} />,
  'shopping': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={duotoneFaBagShopping} />,
  'faq': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={faMessage} />,
  'sitelinks': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={faLink} />,
  'ai_overview': <FontAwesomeIcon color={'#2D6CCA'} fontSize={'18px'} icon={faSparkles} />,
};


export const checkIcon = () => {
  return (
    <svg width='19' height='15' viewBox='0 0 19 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0.424472 9.25634C1.42169 10.0043 1.79566 10.1133 2.62927 11.2508C3.99265 13.105 3.7901 13.8685 4.19522 14.2268C4.60034 14.5774 6.44671 14.8657 7.70881 13.253C8.97091 11.6403 10.9342 7.18401 13.4584 4.51178C15.9826 1.83955 17.159 1.06826 17.9147 0.663143C18.6704 0.258024 16.2786 0.343717 11.5652 4.09107C8.28533 6.69318 5.97149 10.2925 5.97149 10.2925C5.97149 10.2925 5.31708 8.32924 3.85242 7.0126C2.37218 5.70376 -1.22717 8.00982 0.424472 9.25634Z' fill='#2AC155'/>
    </svg>
  );
};

export const faSwapIcon = (color = '#2AC155') => {
  return (
    <svg width='30' height='24' viewBox='0 0 30 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.20293 0.328027C5.64356 -0.112598 6.35606 -0.112598 6.79199 0.328027L10.917 4.45303C11.3576 4.89365 11.3576 5.60615 10.917 6.04209C10.4764 6.47803 9.76387 6.48271 9.32793 6.04209L7.12481 3.83896V16.8749C7.12481 18.7405 8.63418 20.2499 10.4998 20.2499C12.3654 20.2499 13.8748 18.7405 13.8748 16.8749V7.1249C13.8748 4.01709 16.392 1.4999 19.4998 1.4999C22.6076 1.4999 25.1248 4.01709 25.1248 7.1249V20.1608L27.3279 17.9577C27.7686 17.5171 28.4811 17.5171 28.917 17.9577C29.3529 18.3983 29.3576 19.1108 28.917 19.5468L24.792 23.6718C24.3514 24.1124 23.6389 24.1124 23.2029 23.6718L19.0779 19.5468C18.6373 19.1062 18.6373 18.3937 19.0779 17.9577C19.5186 17.5218 20.2311 17.5171 20.667 17.9577L22.8701 20.1608L22.8748 7.1249C22.8748 5.25928 21.3654 3.7499 19.4998 3.7499C17.6342 3.7499 16.1248 5.25928 16.1248 7.1249V16.8749C16.1248 19.9827 13.6076 22.4999 10.4998 22.4999C7.39199 22.4999 4.87481 19.9827 4.87481 16.8749V3.83896L2.67168 6.04678C2.23106 6.4874 1.51856 6.4874 1.08262 6.04678C0.646682 5.60615 0.641995 4.89365 1.08262 4.45772L5.20293 0.328027Z'
        fill={color}
      />
    </svg>
  );
};
