import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import styles from '../styles.module.scss';
import {iconKeywordReport} from '@/utils/icons';
import {faArrowDown, faArrowUp, faInfoCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TableStyled} from '../../../home-page-main/components/table-styled';
import {DeltaForSingleValue} from '@/components/common-components/components/delta';
import {SkeletonHorizontalLoaderGray} from '@/components/common-components';
import {formatLargeNumber} from '@/utils/number';
import {useStore} from '@/store/root-store';
import {addProtocolToDomain} from '@/utils/url';
import {SortOrder} from 'antd/lib/table/interface';
import _, {isNil} from 'lodash';
import {Tooltip} from 'antd';
import {LargeBadge} from '@/components/common-components/components/badge';
import {CompetitorsTableV2} from '../../../keyword-rank-tracker/keyword-rank-details/competitors-by-search-visibility/competitorsTable';
import moment from 'moment';


export const TrackedKeyword = observer((props:any) => {
  const {reportBuilder: {details: {getKRTReportDataValue, isConfig, loadingKrtTableDetail}}} = useStore('');
  const chartData = getKRTReportDataValue(props.index)?.chartData;

  return <div className={`${styles.mainInner} mainInner`} style={{width: isConfig ? '250px' : '285px'}}>
    <p>{iconKeywordReport()}<span style={{marginLeft: '7px'}}>Tracked Keyword</span></p>
    {!loadingKrtTableDetail ?
      <>
        <FlexWithMargin>
          <TitleBlack>{formatLargeNumber(chartData?.trackedKeywordsCount, 1)?.toString()?.replace('.0', '') || '-'}</TitleBlack>
        </FlexWithMargin>
      </> :
      <>
        <SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' />
        <SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' />
      </>}
  </div>;
});

export const SearchVisibility = observer((props:any) => {
  const {reportBuilder: {details: {getKRTReportDataValue, isConfig, loadingKrtTableDetail}}} = useStore('');
  const chartData = getKRTReportDataValue(props.index)?.chartData;

  return <div className={`${styles.mainInner} mainInner`} style={{width: isConfig ? '250px' : '285px'}}>
    <p> {iconKeywordReport()}<span style={{marginLeft: '7px'}}>Search Visibility</span></p>
    {!loadingKrtTableDetail ?
      <>
        <FlexWithMargin>
          <TitleBlack>{formatLargeNumber(chartData?.avgSvHistDelta?.currentSv, 1)?.toString()?.replace('.0', '') || '-'}%</TitleBlack>
          {chartData?.avgSvHistDelta?.svDelta ? <DeltaForSingleValue format={chartData?.avgSvHistDelta?.svDelta}/> : ''}
        </FlexWithMargin>
      </> :
      <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></>}
  </div>;
});

export const AvgrPositionKrt = observer((props:any) => {
  const {reportBuilder: {details: {getKRTReportDataValue, isConfig, loadingKrtTableDetail}}} = useStore('');
  const chartData = getKRTReportDataValue(props.index)?.chartData;

  return <div className={`${styles.mainInner} mainInner`} style={{width: isConfig ? '250px' : '285px'}}>
    <p>{iconKeywordReport()}<span style={{marginLeft: '7px'}}>Average Position</span></p>
    {!loadingKrtTableDetail ?
      <>
        <FlexWithMargin>
          <TitleBlack>{formatLargeNumber(chartData?.avgPositionHistDelta?.currentAvgPosition, 1)?.toString()?.replace('.0', '') || '-'}</TitleBlack>
          {chartData?.avgPositionHistDelta?.avgPositionDelta ? <DeltaForSingleValue format={chartData?.avgPositionHistDelta?.avgPositionDelta}/> : ''}
        </FlexWithMargin>
      </> :
      <>
        <SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' />
        <SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' />
      </>}
  </div>;
});

export const TopTenKeyword = observer((props:any) => {
  const {reportBuilder: {details: {getKRTReportDataValue, isConfig, loadingKrtTableDetail}}} = useStore('');
  const chartData = getKRTReportDataValue(props.index)?.chartData;

  return <div className={`${styles.mainInner} mainInner`} style={{width: isConfig ? '250px' : '285px'}}>
    <p>{iconKeywordReport()}<span style={{marginLeft: '7px'}}>Keywords In Top 10</span></p>
    {!loadingKrtTableDetail ?
      <>
        <FlexWithMargin>
          <TitleBlack>{formatLargeNumber(chartData?.top10PositionHistDelta?.currentKwCount, 1)?.toString()?.replace('.0', '') || '-'}</TitleBlack>
          {chartData?.top10PositionHistDelta?.kwCountDelta ? <DeltaForSingleValue format={chartData?.top10PositionHistDelta?.kwCountDelta}/> : ''}
        </FlexWithMargin>
      </> :
      <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' />
        <SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' />
      </>}
  </div>;
});

export const TrackedKeyTable = observer(({loader, index}: any) => {
  const {reportBuilder: {details: {loadingKrtTableDetail, getKRTReportDataValue, loadKrtDetailsData, singleProjectData}}} = useStore('');
  const [activeSort, setActiveSort] = useState('');

  const columns = [
    {
      title: 'KEYWORD',
      dataIndex: 'keyword',
      key: 'keyword',
      align: 'left' as 'left',
      sortFieldName: 'keyword',
      sorter: true,
      sortOrder: (activeSort?.includes('keyword') ? `${activeSort?.startsWith('-') ? 'descend' : 'ascend'}` : false) as SortOrder,
      width: 220,
      render: (data, record) => {
        return <div style={{whiteSpace: 'pre-wrap'}}>
          {_.capitalize(record?.keyword) || '-'}
        </div>;
      },
    },
    {
      title: 'HIGHEST RANKING URL',
      dataIndex: 'topUrl',
      key: 'topUrl',
      align: 'left' as 'left',
      sortFieldName: 'url',
      sorter: true,
      sortOrder: (activeSort?.includes('url') ? `${activeSort?.startsWith('-') ? 'descend' : 'ascend'}` : false) as SortOrder,
      width: 220,
      render: (_, record) => {
        return <a title={record?.topUrl} href={addProtocolToDomain(record?.topUrl)} target='_blank' rel='noopener noreferrer'
          style={{color: '#2D6CCA', whiteSpace: 'pre-wrap'}}>{record?.topUrl || ''}</a>;
      },
    },
    {
      title: <div>PREVIOUS <br/>POSITION</div>,
      dataIndex: 'previousAvgPosition',
      key: 'previousAvgPosition',
      sortFieldName: 'previous_avg_position',
      sorter: true,
      sortOrder: (activeSort?.includes('previous_avg_position') ? `${activeSort?.startsWith('-') ? 'descend' : 'ascend'}` : false) as SortOrder,
      align: 'left' as 'left',
      width: 70,
      render: (_, record) => {
        const filteredNum = !['', null, undefined].includes(record?.previousAvgPosition) ? formatLargeNumber(record?.previousAvgPosition, 1)?.toString()?.replace('.0', '') : <div style={{opacity: '0.4'}}>{'NR'}</div>;
        const negativeValue = () => {
          if (filteredNum != 'NR') {
            if (Number(filteredNum) < 0) {
              return 'red';
            } else {
              return 'black';
            }
          }
        };
        return (
          <div style={{color: `${negativeValue()}`}}>{filteredNum}</div>
        );
      },
    },
    {
      title: <div>CHANGE</div>,
      dataIndex: 'avgPositionDelta',
      key: 'avgPositionDelta',
      align: 'left' as 'left',
      sortFieldName: 'change',
      sorter: true,
      sortOrder: (activeSort?.includes('change') ? `${activeSort?.startsWith('-') ? 'descend' : 'ascend'}` : false) as SortOrder,
      width: 60,
      render: (_, record) => {
        const filteredNum = !['', null, undefined].includes(record?.avgPositionDelta) ? formatLargeNumber(record?.avgPositionDelta, 1)?.toString()?.replace('.0', '') : <div style={{opacity: '0.4'}}>{'-'}</div>;
        const negativeValue = () => {
          if (Number(record?.avgPositionDelta) < 0) {
            return 'red';
          } else if (Number(record?.avgPositionDelta) != 0) {
            return '#58c358';
          } else {
            return 'black';
          }
        };
        return (
          <div style={{color: `${negativeValue()}`}}>{typeof filteredNum == 'string' ? Number(filteredNum) > 0 ? '+' : '' : ''}{filteredNum}</div>
        );
      },
    },
    {
      title: <div>CURRENT<br/>POSITION</div>,
      dataIndex: 'currentAvgPosition',
      key: 'currentAvgPosition',
      align: 'left' as 'left',
      sortFieldName: 'current_avg_position',
      sorter: true,
      sortOrder: (activeSort?.includes('current_avg_position') ? `${activeSort?.startsWith('-') ? 'descend' : 'ascend'}` : false) as SortOrder,
      width: 70,
      render: (_, record) => {
        const filteredNum = !['', null, undefined].includes(record?.currentAvgPosition) ? formatLargeNumber(record?.currentAvgPosition, 1)?.toString()?.replace('.0', '') : <div style={{opacity: '0.4'}}>{'NR'}</div>;
        const negativeValue = () => {
          if (filteredNum != 'NR') {
            if (Number(filteredNum) < 0) {
              return 'red';
            } else {
              return 'black';
            }
          }
        };
        return (
          <div style={{color: `${negativeValue()}`}}>{filteredNum}</div>
        );
      },
    },
    {
      title: <div>MONTHLY <br/>SEARCH VOLUME</div>,
      dataIndex: 'searchVolume',
      key: 'searchVolume',
      align: 'left' as 'left',
      sortFieldName: 'search_volume',
      sorter: true,
      sortOrder: (activeSort?.includes('search_volume') ? `${activeSort?.startsWith('-') ? 'descend' : 'ascend'}` : false) as SortOrder,
      width: 90,
      render: (_, record) => {
        const filteredNum = !['', null, undefined].includes(record?.searchVolume) ? formatLargeNumber(record?.searchVolume, 1)?.toString()?.replace('.0', '') : <div style={{opacity: '0.4'}}>{'NR'}</div>;
        const negativeValue = () => {
          if (filteredNum != 'NR') {
            if (Number(filteredNum) < 0) {
              return 'red';
            } else {
              return 'black';
            }
          }
        };
        return (
          <div style={{color: `${negativeValue()}`}}>{filteredNum}</div>
        );
      },
    },
  ];

  const dateRangeObj = {
    last_1_month: 'oneMonth',
    last_3_months: 'threeMonth',
    last_6_months: 'sixMonth',
    last_year: 'oneYear',
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    const {column, order} = sorter;
    const {sortFieldName} = column || {};
    const sorterKey = order ? `${order == 'descend' ? '-' : ''}${sortFieldName}` : '';
    setActiveSort(sorterKey);
    const dateRange = singleProjectData?.dateRangeStart ? {startDate: singleProjectData?.dateRangeStart, endDate: singleProjectData?.dateRangeEnd} : dateRangeObj[`${singleProjectData?.dateRange}`];
    loadKrtDetailsData(singleProjectData?.reportsData?.rankTracker?.rankTrackerId, dateRange, false, sorterKey);
  };

  return <div className='krtTable'>
    <div className={styles.textBlack} style={{margin: '10px'}}>{iconKeywordReport()}&nbsp;&nbsp;Tracked Keywords Data</div>
    {getKRTReportDataValue(index)?.keywordsDetail?.results?.find(z => !z?.previousAvgPosition) ? <NRBanner>
      <FontAwesomeIcon icon={faInfoCircle}/>&nbsp;{`Not Ranking (NR) for Previous Position could mean the keyword was added later than the Start date of selected Date Range.`}
    </NRBanner> : <></>}
    <TableStyled
      style={{height: '390px', background: 'white'}}
      columns={columns}
      dataSource={getKRTReportDataValue(index)?.keywordsDetail?.results || []}
      loading={loader || loadingKrtTableDetail}
      pagination={false}
      sticky={true}
      onChange={handleTableChange}
      loadingRows={5}
    />
  </div>;
});

export const KRTCompetitorsTable = observer(({loader, index}: any) => {
  const {reportBuilder:
    {
      details: {
        singleProjectData,
        loadingKrtTableDetail,
        getKRTReportDataValue,
      },
    },
  } = useStore('');
  const {
    settings: {customer: {profile: {isLinkgraph}}},
  } = useStore('');
  // const [activeSort, setActiveSort] = useState('');

  const svDeltaGenerator = (cur, prev) => {
    let deltaIcon = <></>;
    let deltaValue;

    if (cur > prev) {
      deltaIcon = <FontAwesomeIcon icon={faArrowUp} style={{fontSize: 10, marginLeft: 5}} color='rgb(82, 213, 119)'/>;
      const diff = cur - prev;
      deltaValue = <span style={{color: 'rgb(82, 213, 119)', fontSize: 12, fontWeight: 500}}>{diff.toFixed(2)}%</span>;
    }
    if (cur < prev) {
      deltaIcon = <FontAwesomeIcon icon={faArrowDown} style={{fontSize: 10, marginLeft: 5}} color='#F44343'/>;
      const diff = prev - cur;
      deltaValue = <span style={{color: '#F44343', fontSize: 12, fontWeight: 500}}>{diff.toFixed(2)}%</span>;
    }

    const delta = <DeltaStyled>
      {deltaIcon}
      {deltaValue}
    </DeltaStyled>;

    return delta;
  };

  const columns = [
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      align: 'left' as 'left',
      // sortFieldName: 'url',
      // sorter: true,
      // sortOrder: (activeSort?.includes('url') ? `${activeSort?.startsWith('-') ? 'descend' : 'ascend'}` : false) as SortOrder,
      width: 220,
      render: (_, record) => {
        return (<div style={{display: 'flex', alignItems: 'center'}}>
          <Dot color={record.color} />
          <Tooltip title={record.url}>
            <span style={{width: 190, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
              {record.url}
            </span>
          </Tooltip>

        </div> );
      },
    },
    {
      title: 'POSITION',
      dataIndex: 'avgPosition',
      key: 'avgPosition',
      align: 'center' as 'center',
      // sortFieldName: 'avg_position',
      // sorter: true,
      // sortOrder: (activeSort?.includes('avgPosition') ? `${activeSort?.startsWith('-') ? 'descend' : 'ascend'}` : false) as SortOrder,
      width: 100,
      render: (_, record) => {
        return (<div>
          {record.avgPosition ? formatLargeNumber(record.avgPosition) : record.avgPosition ?? '-'}
        </div>
        );
      },
    },
    {
      title: <><div>KEYWORDS</div><div>IN TOP 10</div></>,
      dataIndex: 'keywordsInTop10',
      key: 'keywordsInTop10',
      // sortFieldName: 'keywords_in_top_10',
      // sorter: true,
      // sortOrder: (activeSort?.includes('keywordsInTop10') ? `${activeSort?.startsWith('-') ? 'descend' : 'ascend'}` : false) as SortOrder,
      align: 'center' as 'center',
      width: 100,
      render: (_, record) => {
        return (
          <div>
            {record.keywordsInTop10 ? formatLargeNumber(record.keywordsInTop10) : record.keywordsInTop10 ?? '-'}
          </div>
        );
      },
    },
    {
      title: <><div>SEARCH</div><div>VISIBILITY</div></>,
      dataIndex: 'currentSearchVisibility',
      key: 'currentSearchVisibility',
      align: 'left' as 'left',
      // sortFieldName: 'current_search_visibility',
      // sorter: true,
      // sortOrder: (activeSort?.includes('currentSearchVisibility') ? `${activeSort?.startsWith('-') ? 'descend' : 'ascend'}` : false) as SortOrder,
      width: 200,
      render: (_, record) => {
        return (
          <div className='last-col' style={{display: 'flex'}}>
            <SearchVisibilityProgress value={record.currentSearchVisibility ?? 0}>
              {!isNil(record.currentSearchVisibility) ? `${record.currentSearchVisibility}%` : '-'}
            </SearchVisibilityProgress>
            {(!isNil(record.previousSearchVisibility) && !isNil(record.currentSearchVisibility)) ? svDeltaGenerator(record.currentSearchVisibility, record.previousSearchVisibility) : null}
          </div>

        );
      },
    },
  ];

  // const dateRangeObj = {
  //   last_1_month: 'oneMonth',
  //   last_3_months: 'threeMonth',
  //   last_6_months: 'sixMonth',
  //   last_year: 'oneYear',
  // };

  // const handleTableChange = async (pagination, filters, sorter) => {
  //   const {column, order} = sorter;
  //   const {sortFieldName} = column || {};
  //   const sorterKey = order ? `${order == 'descend' ? '-' : ''}${sortFieldName}` : '';
  //   setActiveSort(sorterKey);
  //   const dateRange = singleProjectData?.dateRangeStart ? {startDate: singleProjectData?.dateRangeStart, endDate: singleProjectData?.dateRangeEnd} : dateRangeObj[`${singleProjectData?.dateRange}`];
  //   loadKrtDetailsData(singleProjectData?.reportsData?.rankTracker?.rankTrackerId, dateRange, false, sorterKey);
  // };
  const start = singleProjectData?.dateRangeStart || (
    singleProjectData?.dateRange === 'last_year' ?
      moment().subtract(1, 'years').format('YYYY-MM-DD') :
      moment().subtract(singleProjectData?.dateRange?.split('_')?.[1], 'months').format('YYYY-MM-DD')
  );
  const end = singleProjectData?.dateRangeEnd || moment().format('YYYY-MM-DD');


  return <div className='krtTable'>
    <div className={styles.textBlack} style={{display: 'flex', alignItems: 'center', margin: '10px'}}>{iconKeywordReport()}&nbsp;&nbsp;Competitors&nbsp;<LargeBadge alpha/></div>
    {isLinkgraph ? <CompetitorsTableV2 id={Number(index)} hideTableHeader={true} currentPeriodStart={start} currentPeriodEnd={end} /> :
      <TableStyled
        style={{height: '390px', background: 'white'}}
        columns={columns}
        dataSource={getKRTReportDataValue(index)?.competitors || []}
        loading={loader || loadingKrtTableDetail}
        pagination={false}
        sticky={true}
        // onChange={handleTableChange}
        loadingRows={5}
      />}
  </div>;
});

const TitleBlack = styled.div<{fontSize?: string}>`
font-weight: 600;
font-size: ${p=> p?.fontSize ? p?.fontSize : '24px'};
line-height: 22px;
color: #121212;
margin-right: 10px;
`;

const FlexWithMargin = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const NRBanner = styled.div`
  background: #F2F2F5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  padding: 5px;
  margin: 5px 10px;
  font-size: 13px;
`;

const Dot = styled.div<{color: string}>`
  width: 6px;
  height: 6px;
  background-color: ${p => p.color ? p.color : '#121212'};
  border-radius: 100%;
  margin-right: 10px;
`;

const SearchVisibilityProgress = styled.div<{value: number}>`
  width: auto;
  padding: 4px 16px;
  background-color: #F2F2F5;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: ${p => `${p.value}%`};
    height: 30px;
    background-color: #34AEF340;
  }
`;

const DeltaStyled = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
`;
