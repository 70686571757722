import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {
  Empty,
  notification,
  Dropdown,
  Row,
  Col,
  Button as AntDButton,
} from 'antd';
import styled from 'styled-components';
import modalStyles from '@/components/dashboard/settings/styles.module.scss';
import {useStore} from '@/store/root-store';
import {toJS} from 'mobx';
import {MixPanel} from '@/utils/mixpanel';
import {GaSettingsData} from './GaSettingsData';
import {useTranslation} from 'next-i18next';
import {Button} from '@/components/common-components/v2/Button';
import {integrationApi} from '@/api/gsc';
import {
  Typography,
  Button as ButtonV1,
  Modal,
  SkeletonRingLoader,
} from '@/components/common-components/components/index';
import {NextImg} from '@/utils/nextImg';
import {SETTINGS_DISCONNECT_GA_ACCOUNT, SETTINGS_GSC_AUTHORIZE_CLICKED} from '@/constants/events';
import {LoadingOutlined, MoreOutlined} from '@ant-design/icons';
import {GmbSettingsData} from './GmbSettingsData';
import {Pill} from '@/components/layoutV2/user-menu/styles';
import {FBSettingsData} from './FBSettingsData';
import {GoogleSettingsData} from './GoogleSettingsData';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faPlus} from '@fortawesome/pro-solid-svg-icons';
import {GSCSettingsV3} from '../GSCIntegration/gscSettingsV3';
interface Props {
  profile?: any;
  setShowConnectAccountHeader?: React.Dispatch<React.SetStateAction<boolean>>;
  showConnectAccountHeader?: boolean;
}
const initialViewState = {
  gsc: {state: false},
  gbp: {state: false},
  ga4: {state: false},
  gAd: {state: false},
  fAd: {state: false},
};
export const GASettingsV2: React.FC<Props> = observer(() => {
  const [isGaDetail, setIsGaDetail] = useState(false);
  const [isGmbDetail, setIsGmbDetail] = useState(false);
  const [isFBAdsDetail, setIsFBAdsDetail] = useState(false);
  const [isGoogleAdsDetail, setIsGoogleAdsDetail] = useState(false);
  const [gaDetailData, setGaDetailData] = useState(null);
  const [gmbDetailData, setGmbDetailData] = useState(null);
  const [fbAdsDetailData, setFBAdsDetailData] = useState(null);
  const [googleAdsDetailData, setGoogleAdsDetailData] = useState(null);
  const [deleteEmail, setDeleteEmail] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const {t} = useTranslation('common');
  const [deleteGMBModal, setGMBDeleteModal] = useState(null);
  const [fbAdsDeleteModal, setFbAdsDeleteModal] = useState(null);
  const [googleAdsDeleteModal, setGoogleAdsDeleteModal] = useState(null);
  const [viewConnectSections, setViewConnectSections] =
    useState(initialViewState);
  const [showConnectAccountHeader, setShowConnectAccountHeader] =
    useState(true);
  const {
    settings: {integration},
  } = useStore('');
  const {
    gsc: {
      googleAnalytics: {
        // getAccountsV2,
        // getAccountsDataV2,
        getGAGlobalSetting,
        getGMBSetting,
        loadFBAdsSetting,
        loadGoogleAdsSetting,
        gsGlobalSettingLoading,
        gmbDataLoading,
        fbAdsDataLoading,
        googleAdsDataLoading,
        getGlobalSettingsData,
        getGMBData,
        disconnectGMB,
        getFBAdsData,
        getGoogleAdsData,
      },
    },
  } = useStore('');
  useEffect(() => {
    // getAccountsV2();
    getGAGlobalSetting();
    getGMBSetting();
    loadFBAdsSetting();
    loadGoogleAdsSetting();
  }, []);

  const openDeleteModal = (profile: string) => {
    setDeleteModal(true);
    setDeleteEmail(profile);
  };

  const deleteAccount = () => {
    setDeleteModal(false);
    notification.success({
      placement: 'bottomRight',
      bottom: 70,
      duration: 3,
      style: {
        zIndex: 2,
      },
      message: t('account-disconnected-successfully'),
    });
  };

  const {
    reportBuilder: {
      details: {
        facebookAdsDisconnects,
        googleAdsDisconnects,
        fbLoader,
        glLoader,
      },
    },
  } = useStore('');

  const onDisconnectEmail = async (email: any) => {
    await facebookAdsDisconnects(email);
  };

  const onDisconnectEmailGoogle = async (email: any) => {
    await googleAdsDisconnects(email);
  };

  const onDisconnectConfirm = async () => {
    await onDisconnectEmail(fbAdsDeleteModal?.email);
    await loadFBAdsSetting();
    setFbAdsDeleteModal(null);
  };

  const onDisconnectConfirmGoogle = async () => {
    await onDisconnectEmailGoogle(googleAdsDeleteModal?.email);
    await loadGoogleAdsSetting();
    setGoogleAdsDeleteModal(null);
  };

  const onViewConnect = (sectionKey: string) => {
    setViewConnectSections({
      ...viewConnectSections,
      [sectionKey]: {...viewConnectSections[sectionKey], state: true},
    });
  };
  const handleAuthorizeConnection = () => {
    MixPanel.track(SETTINGS_GSC_AUTHORIZE_CLICKED);
    window.open(integrationApi.getGoogleConnectUrl());
  };
  return (
    <MainContainer>
      {Object.keys(viewConnectSections).filter(
        key => viewConnectSections[key].state === true,
      )?.length ?
        showConnectAccountHeader && (
          <>
            <StyledBackButton style={{cursor: 'default'}}>
              <div className='back-button' style={{width: 'fit-content', cursor: 'pointer'}} onClick={() => setViewConnectSections(initialViewState)}>
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  color='#000000'
                  fontSize={14}
                />
                Back to Integrations
              </div>
            </StyledBackButton>
          </>
        ) :
        null}
      {viewConnectSections.gsc.state ? (
        <GSCSettingsV3
          setShowConnectAccountHeader={setShowConnectAccountHeader}
          showConnectAccountHeader={showConnectAccountHeader}
        />
      ) : viewConnectSections.gbp.state ? (
        <>
          {isGmbDetail ? (
            <GmbSettingsData
              setIsGmbDetail={setIsGmbDetail}
              setShowConnectAccountHeader={setShowConnectAccountHeader}
              onDeleteAccount={value => setGMBDeleteModal(value)}
              gmbDetailData={gmbDetailData}
            />
          ) : (
            <div style={{overflow: 'auto'}}>
              <div className='nested-Heading'>Google Business Profile </div>
              <div className='description'>
              Manage your online presence and track customer engagement directly from your dashboard.
              </div>
              <GaSettingWrapper>
                {!getGMBData?.length ? (
                  gmbDataLoading ? (
                    <SkeletonRingLoader />
                  ) : (
                    <Empty description='No Data' style={{color: '#a3a4a4'}} />
                  )
                ) : (
                  getGMBData?.map((item, idx) => {
                    return (
                      <GaAccountWrapper key={`gmbAccount-${idx}`}>
                        <NameWrapper>{item?.email}</NameWrapper>
                        <PropertiesWrapper>
                          {item?.businessAccounts?.length === 0 ? (
                            <ViewProperties
                              style={{color: '#A3A4A4', cursor: 'auto'}}
                            >
                              {' '}
                              No accounts found
                            </ViewProperties>
                          ) : (
                            <ViewProperties
                              onClick={() => {
                                setIsGmbDetail(true);
                                setShowConnectAccountHeader(false);
                                setGmbDetailData(item);
                              }}
                            >
                              {' '}
                              View {item?.businessAccounts?.length} account
                              {item?.businessAccounts?.length > 1 ? 's' : ''}
                            </ViewProperties>
                          )}
                          <span
                            style={{paddingLeft: '8px', cursor: 'pointer'}}
                          >
                            <Dropdown
                              placement={'bottomRight'}
                              overlayStyle={{
                                background: '#fff',
                                border: '1px solid #e8e8e8',
                                padding: '5px 8px',
                                borderRadius: '8px',
                              }}
                              overlay={
                                <div
                                  style={{
                                    color: '#F44343',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => setGMBDeleteModal(item)}
                                >
                                  Disconnect
                                </div>
                              }
                            >
                              <MoreOutlined style={{color: '#121212'}} />
                            </Dropdown>
                          </span>
                        </PropertiesWrapper>
                      </GaAccountWrapper>
                    );
                  })
                )}
              </GaSettingWrapper>
            </div>
          )}
          {!isGmbDetail && (
            <Button
              style={{
                marginTop: '15px',
                width: 'fit-content',
                marginBottom: '15px',
              }}
              variant='ghost'
              onClick={() =>
                window.open(
                  integrationApi.getGoogleMyBusinessConnectUrl(),
                  'myLogin',
                  'height=600,width=600,left=650,top=200',
                )
              }
              color='blue'
            >
              {'+ Connect new GBP account'}
            </Button>
          )}
        </>
      ) : viewConnectSections.ga4.state ? (
        <StyledWrapper>
          {isGaDetail ? (
            <GaSettingsData
              setGaDetail={setIsGaDetail}
              setShowConnectAccountHeader={setShowConnectAccountHeader}
              onDeleteAccount={email => openDeleteModal(email)}
              gaDetailData={gaDetailData}
            />
          ) : (
            <div style={{overflow: 'auto'}}>
              <div className='nested-Heading'>Google Analytics</div>
              <div className='description'>
              Analyze user behavior and website traffic for data-driven decision-making.
              </div>
              <GaSettingWrapper>
                {!getGlobalSettingsData?.length ? (
                  gsGlobalSettingLoading ? (
                    <SkeletonRingLoader />
                  ) : (
                    <Empty description='No Data' style={{color: '#a3a4a4'}} />
                  )
                ) : (
                  getGlobalSettingsData?.map((item, idx) => {
                    return (
                      <GaAccountWrapper key={`gaAccount-${idx}`}>
                        <NameWrapper>{item?.email}</NameWrapper>
                        <PropertiesWrapper>
                          {item?.availableAccounts?.length === 0 ? (
                            <ViewProperties
                              style={{color: '#A3A4A4', cursor: 'auto'}}
                            >
                              No accounts found
                            </ViewProperties>
                          ) : (
                            <ViewProperties
                              onClick={() => {
                                setIsGaDetail(true);
                                setShowConnectAccountHeader(false);
                                setGaDetailData(item);
                              }}
                            >
                              {' '}
                              View {item?.availableAccounts?.length}{' '}
                              {item?.availableAccounts?.length === 1 ?
                                t('account') :
                                t('accounts')}
                            </ViewProperties>
                          )}
                          <span
                            style={{paddingLeft: '8px', cursor: 'pointer'}}
                          >
                            <Dropdown
                              placement={'bottomRight'}
                              overlayStyle={{
                                background: '#fff',
                                border: '1px solid #e8e8e8',
                                padding: '5px 8px',
                                borderRadius: '8px',
                              }}
                              overlay={
                                <div
                                  style={{
                                    color: '#F44343',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => openDeleteModal(item?.email)}
                                >
                                  Disconnect
                                </div>
                              }
                            >
                              <MoreOutlined style={{color: '#121212'}} />
                            </Dropdown>
                          </span>
                        </PropertiesWrapper>
                      </GaAccountWrapper>
                    );
                  })
                )}
              </GaSettingWrapper>{' '}
            </div>
          )}
          <Button
            style={{marginTop: '15px', width: 'fit-content'}}
            variant='ghost'
            onClick={() =>
              window.open(
                integrationApi.getGoogleAnalyticsConnectUrl(),
                '_self',
              )
            }
            color='blue'
          >
            {'+ Connect new GA account'}
          </Button>
        </StyledWrapper>
      ) : viewConnectSections.gAd.state ? (
        <>
          {isGoogleAdsDetail ? (
            <GoogleSettingsData
              setIsGoogleAdsDetail={setIsGoogleAdsDetail}
              onDisconnect={onDisconnectEmailGoogle}
              setShowConnectAccountHeader={setShowConnectAccountHeader}
              googleAdsData={googleAdsDetailData}
            />
          ) : (
            <div style={{overflow: 'auto'}}>
              <div className='nested-Heading'>Google Ads</div>
              <div className='description'>
              Monitor ad performance, conversions, and optimize campaigns from a single view.
              </div>
              <FBSettingWrapper>
                {!getGoogleAdsData?.length ? (
                  googleAdsDataLoading ? (
                    <SkeletonRingLoader />
                  ) : (
                    <Empty description='No Data' style={{color: '#a3a4a4'}} />
                  )
                ) : (
                  getGoogleAdsData?.map((item, idx) => {
                    return (
                      <FBAccountWrapper key={`gmbAccount-${idx}`}>
                        <NameWrapper>{item?.email}</NameWrapper>
                        <PropertiesWrapper>
                          {item?.googleAdsAccounts?.length === 0 ? (
                            <ViewProperties
                              style={{color: '#A3A4A4', cursor: 'auto'}}
                            >
                              {' '}
                              No accounts found
                            </ViewProperties>
                          ) : (
                            <ViewProperties
                              onClick={() => {
                                setIsGoogleAdsDetail(true);
                                setShowConnectAccountHeader(false);
                                setGoogleAdsDetailData(item);
                              }}
                            >
                              {' '}
                              View {item?.googleAdsAccounts?.length} account
                              {item?.googleAdsAccounts?.length > 1 ? 's' : ''}
                            </ViewProperties>
                          )}
                          <span
                            style={{paddingLeft: '8px', cursor: 'pointer'}}
                          >
                            <Dropdown
                              placement={'bottomRight'}
                              overlayStyle={{
                                background: '#fff',
                                border: '1px solid #e8e8e8',
                                padding: '5px 8px',
                                borderRadius: '8px',
                              }}
                              overlay={
                                <div
                                  style={{
                                    color: '#F44343',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => setGoogleAdsDeleteModal(item)}
                                >
                                  Disconnect
                                </div>
                              }
                            >
                              <MoreOutlined style={{color: '#121212'}} />
                            </Dropdown>
                          </span>
                        </PropertiesWrapper>
                      </FBAccountWrapper>
                    );
                  })
                )}
              </FBSettingWrapper>
            </div>
          )}
          {!isGoogleAdsDetail && (
            <Button
              style={{marginTop: '15px', width: 'fit-content'}}
              variant='ghost'
              onClick={() =>
                window.open(
                  integrationApi.getGoogleConnectUrl(),
                  'myLogin',
                  'height=600,width=600,left=650,top=200',
                )
              }
              color='blue'
            >
              {'+ Connect new Google account'}
            </Button>
          )}
        </>
      ) : viewConnectSections.fAd.state ? (
        <>
          {isFBAdsDetail ? (
            <FBSettingsData
              setShowConnectAccountHeader={setShowConnectAccountHeader}
              setIsFBAdsDetail={setIsFBAdsDetail}
              onDisconnect={onDisconnectEmail}
              fbAdsData={fbAdsDetailData}
            />
          ) : (
            <div style={{overflow: 'auto'}}>
              <div className='nested-Heading'>Facebook Ads</div>
              <div className='description'>
              Track ad effectiveness, audience engagement, and refine targeting within your dashboard.
              </div>
              <FBSettingWrapper>
                {!getFBAdsData?.length ? (
                  fbAdsDataLoading ? (
                    <SkeletonRingLoader />
                  ) : (
                    <Empty description='No Data' style={{color: '#a3a4a4'}} />
                  )
                ) : (
                  getFBAdsData?.map((item, idx) => {
                    return (
                      <FBAccountWrapper key={`gmbAccount-${idx}`}>
                        <NameWrapper>{item?.email}</NameWrapper>
                        <PropertiesWrapper>
                          {item?.facebookAdAccounts?.length === 0 ? (
                            <ViewProperties
                              style={{color: '#A3A4A4', cursor: 'auto'}}
                            >
                              {' '}
                              No accounts found
                            </ViewProperties>
                          ) : (
                            <ViewProperties
                              onClick={() => {
                                setIsFBAdsDetail(true);
                                setShowConnectAccountHeader(false);
                                setFBAdsDetailData(item);
                              }}
                            >
                              {' '}
                              View {item?.facebookAdAccounts?.length} account
                              {item?.facebookAdAccounts?.length > 1 ? 's' : ''}
                            </ViewProperties>
                          )}
                          <span
                            style={{paddingLeft: '8px', cursor: 'pointer'}}
                          >
                            <Dropdown
                              placement={'bottomRight'}
                              overlayStyle={{
                                background: '#fff',
                                border: '1px solid #e8e8e8',
                                padding: '5px 8px',
                                borderRadius: '8px',
                              }}
                              overlay={
                                <div
                                  style={{
                                    color: '#F44343',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => setFbAdsDeleteModal(item)}
                                >
                                  Disconnect
                                </div>
                              }
                            >
                              <MoreOutlined style={{color: '#121212'}} />
                            </Dropdown>
                          </span>
                        </PropertiesWrapper>
                      </FBAccountWrapper>
                    );
                  })
                )}
              </FBSettingWrapper>
            </div>
          )}
          {!isFBAdsDetail && (
            <Button
              style={{marginTop: '15px', width: 'fit-content'}}
              variant='ghost'
              onClick={() =>
                window.open(
                  integrationApi.getFacebookConnectUrl(),
                  'myLogin',
                  'height=600,width=600,left=650,top=200',
                )
              }
              color='blue'
            >
              {'+ Connect new Facebook account'}
            </Button>
          )}
        </>
      ) : (
        <div className='integration-container'>
          <Row gutter={[8, 16]}>
            <Col span={16}>
              {' '}
              <div className='main-Heading'>Integrations</div>
            </Col>
            <Col span={24}>
              <Row gutter={[0, 0]}>
                <Col span={16}>
                  <div className='main-pargraph'>
                    Integrate your key accounts to unlock a comprehensive view
                    of your digital performance. Access insights, manage
                    campaigns, and optimize strategies—all from one streamlined
                    dashboard.
                  </div>
                </Col>
                {/* <Col span={8}> <div className='share'>abc.</div></Col> */}
              </Row>
            </Col>
            <Col sm={24} md={24} lg={12} xl={8} xxl={6}>
              <PanelWrapper>
                <NextImg height={33} width={33} src='/img/gscicon.svg' />
                <div className='text-content'>
                  <div className='heading'>Google Search Console</div>
                  <div className='sub-heading'>Gain insights into your website’s search performance, keyword rankings, and visibility.</div>
                </div>
                <ConnectButton backgroundColor='#2D6CCA' onClick={handleAuthorizeConnection}>
                  <FontAwesomeIcon
                    icon={faPlus}
                    color='#ffffff'
                    fontSize={14}
                  />{' '}
                  Connect account
                </ConnectButton>
                {
                  integration?.googleProfiles?.length ?
                    <ConnectWrapper onClick={() => onViewConnect('gsc')}>
                      View&nbsp;
                      {toJS(integration?.googleProfiles)?.filter(acc => acc?.isAuthorizedForGsc)?.length}&nbsp;
                      {toJS(integration?.googleProfiles)?.filter(acc => acc?.isAuthorizedForGsc)?.length > 1 ?
                        'connected accounts' :
                        'connected account'}
                    </ConnectWrapper> :
                    <div className='no-connected'>No account connected</div>
                }
              </PanelWrapper>
              {/* <GSCSettingsV2 profiles={profile}/> */}
            </Col>
            <Col sm={24} md={24} lg={12} xl={8} xxl={6}>
              <PanelWrapper>
                <NextImg width={33} height={33} src='/img/gmbIcon.svg' />
                <div className='text-content'>
                  <div className='heading'>
                    Google Business Profile <Pill>Beta</Pill>
                  </div>
                  <div className='sub-heading'>
                    Manage your online presence and track customer engagement directly from your dashboard.
                  </div>
                </div>
                <ConnectButton backgroundColor='#2D6CCA' onClick={() =>
                  window.open(
                    integrationApi.getGoogleAnalyticsConnectUrl(),
                    '_self',
                  )
                }>
                  <FontAwesomeIcon
                    icon={faPlus}
                    color='#ffffff'
                    fontSize={14}
                  />{' '}
                  Connect account
                </ConnectButton>
                {
                  getGMBData?.length ?
                    <ConnectWrapper onClick={() => onViewConnect('gbp')}>
                      View {getGMBData?.length} connected account
                      {getGMBData?.length > 1 ? 's' : ''}
                    </ConnectWrapper> :
                    <div className='no-connected'>No account connected</div>
                }
              </PanelWrapper>
            </Col>
            <Col sm={24} md={24} lg={12} xl={8} xxl={6}>
              <PanelWrapper>
                <NextImg
                  width={33}
                  height={33}
                  src='/img/icon/analytics_icon.svg'
                />
                <div className='text-content'>
                  <div className='heading'> Google Analytics</div>
                  <div className='sub-heading'>Analyze user behavior and website traffic for data-driven decision-making.</div>
                </div>
                <ConnectButton backgroundColor='#F1AA3E' onClick={() =>
                  window.open(
                    integrationApi.getGoogleMyBusinessConnectUrl(),
                    'myLogin',
                    'height=600,width=600,left=650,top=200',
                  )
                }>
                  <FontAwesomeIcon
                    icon={faPlus}
                    color='#ffffff'
                    fontSize={14}
                  />{' '}
                  Connect account
                </ConnectButton>
                {
                  getGlobalSettingsData?.length ?
                    <ConnectWrapper onClick={() => onViewConnect('ga4')}>
                      View {getGlobalSettingsData?.length || 0} connected account
                      {getGlobalSettingsData?.length > 1 ? 's' : ''}
                    </ConnectWrapper> :
                    <div className='no-connected'>No account connected</div>
                }
              </PanelWrapper>
            </Col>
            <Col sm={24} md={24} lg={12} xl={8} xxl={6}>
              <PanelWrapper>
                <NextImg src='/img/googleIcon.svg' width={33} height={33} />
                <div className='text-content'>
                  <div className='heading'>
                    Google Ads <Pill>Alpha</Pill>{' '}
                  </div>
                  <div className='sub-heading'>
                    Monitor ad performance, conversions, and optimize campaigns from a single view.
                  </div>
                </div>
                <ConnectButton backgroundColor='#34A853' onClick={() => window.open(integrationApi.getGoogleConnectUrl(), 'myLogin', 'height=600,width=600,left=650,top=200')}>
                  <FontAwesomeIcon
                    icon={faPlus}
                    color='#ffffff'
                    fontSize={14}
                  />{' '}
                  Connect account
                </ConnectButton>
                {
                  getGoogleAdsData?.length ?
                    <ConnectWrapper onClick={() => onViewConnect('gAd')}>
                      View {getGoogleAdsData?.length} connected account
                      {getGoogleAdsData?.length > 1 ? 's' : ''}
                    </ConnectWrapper> :
                    <div className='no-connected'>No account connected</div>
                }
              </PanelWrapper>
            </Col>
            <Col sm={24} md={24} lg={12} xl={8} xxl={6}>
              <PanelWrapper>
                <NextImg src='/img/icon/fb.png' width={33} height={33} />
                <div className='text-content'>
                  <div className='heading'>Facebook Ads</div>
                  <div className='sub-heading'>Track ad effectiveness, audience engagement, and refine targeting within your dashboard.</div>
                </div>
                <ConnectButton backgroundColor='#485A96' onClick={() =>
                  window.open(
                    integrationApi.getFacebookConnectUrl(),
                    'myLogin',
                    'height=600,width=600,left=650,top=200',
                  )
                }>
                  <FontAwesomeIcon
                    icon={faPlus}
                    color='#ffffff'
                    fontSize={14}
                  />{' '}
                  Connect account
                </ConnectButton>
                {
                  getFBAdsData?.length ?
                    <ConnectWrapper onClick={() => onViewConnect('fAd')}>
                      View {getFBAdsData?.length} connected account
                      {getFBAdsData?.length > 1 ? 's' : ''}
                    </ConnectWrapper> :
                    <div className='no-connected'>No account connected</div>
                }
              </PanelWrapper>
            </Col>
          </Row>
        </div>
      )}
      <Modal
        className={modalStyles.Modal}
        isDark
        visible={deleteModal}
        onClose={() => setDeleteModal(false)}
      >
        <div className={modalStyles.ConfirmationModal}>
          <Typography tag='h2'>{t('disconnect-account-question')}</Typography>
          <span>
            {t('all-gsc-projects-imported')}{' '}
            <span style={{fontWeight: 600}}>{deleteEmail}</span> will be
            disconnect.
          </span>
        </div>
        <div className={modalStyles.ButtonDisplay}>
          <ButtonV1
            className={modalStyles.Back}
            onClick={() => setDeleteModal(false)}
          >
            {t('cancel')}
          </ButtonV1>
          <ButtonV1
            className={modalStyles.Danger}
            onClick={async () => {
              try {
                await integration.deleteGoogleAnalyticsProfile(deleteEmail);
                getGAGlobalSetting();
                deleteAccount();
                const detail = {
                  profile: deleteEmail,
                };

                MixPanel.track(SETTINGS_DISCONNECT_GA_ACCOUNT, {
                  disconnectingAccount: `${detail}`,
                });
              } catch (e) {
                return Promise.reject(e);
              }
            }}
          >
            {t('disconnect-account')}
          </ButtonV1>
        </div>
      </Modal>
      <Modal
        className={modalStyles.Modal}
        isDark
        visible={deleteGMBModal?.email}
        onClose={() => setDeleteModal(false)}
      >
        <div className={modalStyles.ConfirmationModal}>
          <Typography tag='h2'>{t('disconnect-account-question')}</Typography>
          <span>
            Are you sure, you want to disconnect{' '}
            <span style={{fontWeight: 600}}>{deleteGMBModal?.email}?</span>
          </span>
        </div>
        <div className={modalStyles.ButtonDisplay}>
          <ButtonV1
            className={modalStyles.Back}
            onClick={() => setGMBDeleteModal(null)}
          >
            {t('cancel')}
          </ButtonV1>
          <ButtonV1
            className={modalStyles.Danger}
            onClick={() =>
              disconnectGMB({googleprofile_id: deleteGMBModal.id}, () =>
                setGMBDeleteModal(null),
              )
            }
          >
            {t('disconnect-account')}
          </ButtonV1>
        </div>
      </Modal>
      <Modal
        className={modalStyles.Modal}
        isDark
        visible={googleAdsDeleteModal?.email}
        onClose={() => setGoogleAdsDeleteModal(null)}
      >
        <div className={modalStyles.ConfirmationModal}>
          <Typography tag='h2'>{t('disconnect-account-question')}</Typography>
          <span>
            Are you sure, you want to disconnect{' '}
            <span style={{fontWeight: 600}}>
              {googleAdsDeleteModal?.email}?
            </span>
          </span>
        </div>
        <div className={modalStyles.ButtonDisplay}>
          <ButtonV1
            className={modalStyles.Back}
            onClick={() => setGoogleAdsDeleteModal(null)}
          >
            {t('cancel')}
          </ButtonV1>
          <ButtonV1
            className={modalStyles.Danger}
            onClick={() => onDisconnectConfirmGoogle()}
          >
            {t('disconnect-account')}
            {glLoader && (
              <LoadingOutlined
                style={{color: '#4e5156', fontSize: '15px'}}
                spin
              />
            )}
          </ButtonV1>
        </div>
      </Modal>
      <Modal
        className={modalStyles.Modal}
        isDark
        visible={fbAdsDeleteModal?.email}
        onClose={() => setFbAdsDeleteModal(null)}
      >
        <div className={modalStyles.ConfirmationModal}>
          <Typography tag='h2'>{t('disconnect-account-question')}</Typography>
          <span>
            Are you sure, you want to disconnect{' '}
            <span style={{fontWeight: 600}}>{fbAdsDeleteModal?.email}?</span>
          </span>
        </div>
        <div className={modalStyles.ButtonDisplay}>
          <ButtonV1
            className={modalStyles.Back}
            onClick={() => setFbAdsDeleteModal(null)}
          >
            {t('cancel')}
          </ButtonV1>
          <ButtonV1
            className={modalStyles.Danger}
            onClick={() => onDisconnectConfirm()}
          >
            {t('disconnect-account')}
            {fbLoader && (
              <LoadingOutlined
                style={{color: '#4e5156', fontSize: '15px'}}
                spin
              />
            )}
          </ButtonV1>
        </div>
      </Modal>
    </MainContainer>
  );
});

const PropertiesWrapper = styled.div`
  display: flex;
`;

const ViewProperties = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #2d6cca;
  cursor: pointer;
`;
const GaSettingWrapper = styled.div`
  background: #fff;
  border: 1px solid #e8e8e8;
  padding: 10px 15px;
  border-radius: 7px;
  @media only screen and (max-width: 800px) {
    width: 580px !important;
    overflow: auto;
  }
`;
const FBSettingWrapper = styled.div`
  background: #fff;
  border: 1px solid #e8e8e8;
  padding: 10px 15px;
  border-radius: 7px;
  @media only screen and (max-width: 800px) {
    width: 580px !important;
    overflow: auto;
  }
`;

const GaAccountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 42px;
  align-items: center;
`;
const FBAccountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 42px;
  align-items: center;
`;
const NameWrapper = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
`;
const PanelWrapper = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  background-color: #f9f9fb;
  padding: 23px 14px 15px 18px;
  .text-content {
    margin: 12px 0px;
    .heading {
      font-weight: 700;
    }
    .sub-heading {
      width: 220px;
      color: #4e5156;
    }
  }
  .no-connected {
    color: #A3A4A4;
  }
`;
const ConnectWrapper = styled.div`
  color: #2d6cca;
  font-weight: 400;
  font-size: 14px;
  margin-top: 13px;
  line-height: 22px;
  cursor: pointer;
  @media only screen and (max-width: 569px) {
    margin-left: 28px;
  }
`;
const hexToRgb = (hex: string | any[]) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }

  return `rgb(${r}, ${g}, ${b}, 0.80)`;
};
const ConnectButton = styled(AntDButton)<{ backgroundColor?: string }>`
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: ${p =>
    p.backgroundColor ?
      `1px solid ${p?.backgroundColor}` :
      '1px solid #2D6CCA'};
  background-color: ${p =>
    p.backgroundColor ? p?.backgroundColor : '#2D6CCA'};
  transition: background-color 0.3s ease;
  &:hover,
  &:active,
  &:focus {
    color: #ffffff !important;
    border: ${p =>
    p.backgroundColor ?
      `1px solid ${hexToRgb(p.backgroundColor)}` :
      `1px solid ${hexToRgb('#2D6CCA')}`} !important;
    background-color: ${p =>
    p.backgroundColor ?
      hexToRgb(p.backgroundColor) :
      hexToRgb('#2D6CCA')} !important;
  }
`;
const StyledBackButton = styled.div`
  cursor: pointer;
  margin-bottom: 18px;
  .back-button {
    margin-top: 33px;
    display: flex;
    align-items: center;
    gap: 13px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
`;
const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .main-Heading {
    font-family: "Inter", sans-serif;
    font-size: 24px;
    padding-top: 35px;
    padding-left: 13px;
    font-weight: 600;
    line-height: normal;
    color: #121212;
  }
  .nested-Heading {
    font-family: "Inter", sans-serif;
    font-size: 24px;
    padding: 0px 28px;
    font-weight: 600;
    line-height: normal;
    color: #121212;
  }
  .main-pargraph {
    font-family: "Inter" sans-serif;
    padding-top: 10px;
    padding-left: 13px;
    font-size: 14px;
    color: #4e5156;
    font-weight: 400;
    line-height: "normal";
  }
  .share {
    text-align: end;
    background-color: red;
  }
  .description {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    padding: 0px 28px;
    font-weight: 400;
    max-width: 680px;
    margin-bottom: 18px;
  }
  .styles_TabPanContainer__m_BTZ {
    padding: 0px 28px;
  }
  .integration-container {
    &::-webkit-scrollbar {
      height: 5px;
      margin: 20px 0px;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(138, 138, 138, 0.32);
    }

    &::-webkit-scrollbar-track {
      background: rgba(138, 138, 138, 0.12);
    }
  }
`;
const StyledWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
      height: 5px;
      margin: 20px 0px;
      width: 5px;
  }
    &::-webkit-scrollbar-thumb {
      background: rgba(138, 138, 138, 0.32);
    }

    &::-webkit-scrollbar-track {
      background: rgba(138, 138, 138, 0.12);
    }
`;
