import {Collapse, Dropdown, Menu, Spin, Tooltip} from 'antd';
import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRotate, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {faPen, faTimes, faSortDown} from '@fortawesome/pro-solid-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam} from '@/utils/url';
import {notification} from '@/utils/notifications';
import {notification as notificationV2} from '@/utils/notification-v2';
import {openUrl} from '@/utils/router';
import {CloseButton, DescriptionWrapper, PaginationStyled, StyledInput, StyledIssuesTable, ModalWrapper, Wrapper, DeleteButton, BackButton, ImageWrapper, ReviewerName, StyledReviewsRating, StyledIssuesCollapse, StatusDropdown} from '../../../../style';
import {canDeploy, getFilteredDataForOtto, StyledMenu} from '../../../tableIssuesCollapse';
import {Button} from '@/components/common-components';
import useWindowSize from '@/utils/hooks/useWindowSize';
import {Modal} from '@/components/common-components/components';
import {newNotification} from '@/utils/notification-v3';
import {qAndAStatusStyles} from '../../../../Constants';
import {TableHeader} from './tableHeader';
import {getOttoTablePageSize} from '@/api/common-utils';
import {saveOttoTablePageSize} from '@/api/common-utils';
import FreezeWrapper from '../../../freezTooltip';


interface Props {
  setIssueTable: (value: string) => void;
  setPageChanged: (value: boolean) => void;
  selectedRatings?: string[];
  componentIssueType: string;
  issueTable: string;
}

export const UnansweredReviews = observer(({setPageChanged, setIssueTable, componentIssueType, issueTable}: Props) => {
  const {ottoV2Store: {
    getOttoUrls,
    getOttoV2Project,
    loadIssueTableData,
    issueTypeArray,
    ottoUrlLoader,
    ottoIssueType,
    publishReview,
    unPublishReview,
    setIssueTypeSelected,
    setIsDeploying,
    loadOttoV2Project,
    deployOttoUrls,
    selectedIssue,
    selectedCategory,
    setSelectedIssue,
    setSelectedCategory,
    setLoadingDetail,
    setOttoSearchTerm,
    generatingAiProposedFix,
    aiGenerateGBPReview,
    deployingProposedFix,
    editOttoGBPReview,
    selectedReviewFilters,
  }, settings: {customer: {getCustomerQuota, profile: {whitelabelOtto}}},
  } = useStore('');
  const {width} = useWindowSize();
  const uuid = getSingleUrlParam('uuid');
  const [ottoUrls, setOttoUrls] = useState([]);
  const issueTypesFrontendMappingV2 = getOttoV2Project?.issueTypesFrontendMappingV2;

  const [selectedIssueType, setSelectedIssueType] = useState('');
  const [currentProject, setCurrentProject] = useState(null);
  const [sectionCount, setSectionCount] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [urlId, setUrlId] = useState<any>(-1);
  const [proposedFix, setProposedFix] = useState('');
  const [editDescription, setEditDescription] = useState<any>(-1);
  const [proposedFixId, setProposedFixId] = useState([]);
  const [statusLoading, setStatusLoading] = useState(false);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isTableOpen, setIsTableOpen] = useState<any>('open');
  const [isActiveFilter, setIsActiveFilter] = useState('all');
  const [pageSize, setPageSize] = useState(() => getOttoTablePageSize(componentIssueType));


  // const antUrlIcon = <LoadingOutlined style={{fontSize: 16, color: '#2AC155'}} spin />;

  useEffect(() => {
    if (getOttoUrls) {
      setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);


  useEffect(() => {
    setCurrentProject(getOttoV2Project?.uuid == uuid && getOttoV2Project);
  }, [getOttoV2Project]);

  const [currentRecord, setCurrentRecord] = useState<any>({});
  const [openModal, setOpenModal] = useState(false);

  const showConfirmationPopup = record => {
    setCurrentRecord(record);
    setOpenModal(true);
  };

  const loadIssueTables = async (issueArray: string[], issue: string, category: string, page: number, pageSize: number, stopLoading?: boolean, search?: string, rating?: string) => {
    setSelectedIssue(issue);
    setSelectedCategory(category);
    if (!stopLoading) {
      setLoadingDetail(true);
    }
    const categories = issue == 'All' ? issueTypesFrontendMappingV2.find(group=> group.subGroups.filter(g=> g.group == selectedCategory).length).group : issue;
    const issueObject = issueTypesFrontendMappingV2?.find(issueObj => issueObj.group === categories || issueObj.label === categories)?.subGroups?.find(categoryObj => categoryObj?.group === category);
    for (let index = 0; index < issueArray.length; index++) {
      const issueType = issueArray[index];
      const itemObject: any = issueObject?.subGroups?.find((item: any) => item?.group === issueType);
      if (issueTypeArray.includes(issueType)) {
        const params = {
          uuid,
          otto_project: getOttoV2Project?.id,
          issue_type: issueType,
          page_size: pageSize,
          page,
          is_loading: false,
        };
        // if (activeKey !== 'all') {
        //   params['is_replied'] = getDeployStatusForGbp(activeKey, issueType);
        // }
        if (selectedReviewFilters?.star_rating__in) {
          params['star_rating__in'] = selectedReviewFilters?.star_rating__in;
        }
        if (selectedReviewFilters?.is_replied) {
          params['is_replied'] = selectedReviewFilters?.is_replied;
        }
        if (selectedReviewFilters?.reply__status__in) {
          params['reply__status__in'] = selectedReviewFilters?.reply__status__in;
        }
        if (rating) {
          params['star_rating__in'] = rating;
        }
        if (search) {
          params['search'] = search;
          setOttoSearchTerm(search);
        } else {
          setOttoSearchTerm('');
        }
        await loadIssueTableData(params, itemObject?.isSitewide);
      }
    }
    setLoadingDetail(false);
  };

  const publishGBPReview = async (id, record, index) => {
    setStatusLoading(true);
    try {
      setUrlId(index);
      if (record?.reply?.status === 'Pending Review' || record?.reply?.status === 'Unpublished' || record?.reply?.status === 'Scheduled') {
        await publishReview(id);
        notification.success('Review published successfully.', '', 2);
      } else {
        await unPublishReview(id);
        notification.success('Review unpublished successfully.', '', 2);
      }
      setStatusLoading(false);
      setPageChanged(true);
      setIssueTypeSelected(record?.issueType);
      setIsDeploying(true);
      // if (uuid) {
      //   loadOttoV2Project(uuid, true);
      // }
      setUrlId(-1);
    } catch (error) {
      setUrlId(-1);
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
      setStatusLoading(false);
    }
  };

  const deployOttoSection = async (toDeploy: boolean) => {
    if (currentProject && (currentProject?.pixelTagState == 'not_installed' || currentProject?.pixelTagState == 'wrong_uuid')) {
      notificationV2.warning('', `To deploy changes, you must install the ${whitelabelOtto} Pixel`);
      return;
    }
    try {
      await deployOttoUrls({toDeploy, issueType: selectedIssueType, uuid});
      setPageChanged(true);
      setIssueTypeSelected(selectedIssueType);
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        newNotification(`${sectionCount ?? 0} Changes Deployed`, 2, 'deploy');
      } else {
        newNotification(`${sectionCount ?? 0} Changes Rolled Back`, 2, 'rollback');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
    }
  };

  const handlePaginationChange = async (issueArray: string[], page, pageSize) => {
    const ratingString = selectedReviewFilters?.star_rating__in;
    loadIssueTables(issueArray, selectedIssue, selectedCategory, page, pageSize, true, searchText, ratingString);
  };

  const generateNewProposedFix = async (id, issueType, record) => {
    const fixId = record?.id;
    try {
      setProposedFixId(prev => [...prev, fixId]);
      await aiGenerateGBPReview(id);
      getCustomerQuota();
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setProposedFixId(prev => prev.filter(id => id != fixId));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
      setUrlId(-1);
      setProposedFixId(prev => prev.filter(id => id != fixId));
    }
  };

  const onChangeReply = async (id, proposedAnswer, record) => {
    try {
      const data = {
        'content': proposedAnswer,
      };
      await editOttoGBPReview(id, data);
      if (record?.reply?.status === 'Published') {
        await unPublishReview(id);
      }
      setPageChanged(true);
      setIssueTypeSelected(record?.issueType);
      setEditDescription(-1);
      setTimeout(() => {
        notification.success('Reply updated successfully', '', 2);
      }, 400);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
      setUrlId(-1);
      setEditDescription(-1);
    }
  };

  const descriptionContainer = (record, index) => {
    return (
      <DescriptionWrapper>
        {record?.recommendedValue}
        {
          record?.recommendedValue ?
            <span className='icons-wrapper'>
              {(deployingProposedFix && index == selectedIndex) ? <></> : <FreezeWrapper removeTooltip={record?.reply?.status === 'Published'}><Tooltip overlayInnerStyle={{background: '#121212'}} title={'Edit'}><FontAwesomeIcon icon={faPen} fontSize={12} color='#4E5156' onClick={() => {
                setEditDescription(index);
                setProposedFix(record?.recommendedValue);
              }} />
              </Tooltip></FreezeWrapper>}
              {(deployingProposedFix && index == selectedIndex) ? <></> : <FreezeWrapper><Tooltip title={<><span style={{fontWeight: '600', fontSize: 12}}>AI Generate a new suggestion.</span><br></br><span style={{fontSize: 12}}>Cost: 1 AI Generation Point</span></>} placement='bottom' overlayInnerStyle={{borderRadius: '6px', background: 'black'}}>
                <FontAwesomeIcon icon={faRotate} fontSize={12} color='#4E5156' className={generatingAiProposedFix && proposedFixId.includes(record?.id) ? 'fa-spin' : ''} onClick={() => {
                  if (!proposedFixId.includes(record?.id)) generateNewProposedFix(record?.id, record?.issueType, record);
                }} /></Tooltip></FreezeWrapper>}
            </span> :
            <span className='icons-wrapper' style={{display: 'flex', gap: 10}}>
              <FreezeWrapper><Tooltip overlayInnerStyle={{background: '#121212'}} title={'Edit'}>
                <div className='content-wrapper' onClick={() => {
                  setEditDescription(index);
                  setProposedFix(record?.recommendedValue);
                }}>
                  <FontAwesomeIcon icon={faPen} fontSize={12} color='#4E5156' />
              Write
                </div>
              </Tooltip></FreezeWrapper>
              {
                <FreezeWrapper><Tooltip title={<><span style={{fontWeight: '600', fontSize: 12}}>AI Generate a new suggestion.</span><br></br><span style={{fontSize: 12}}>Cost: 1 AI Generation Point</span></>} placement='bottom' overlayInnerStyle={{borderRadius: '6px', background: 'black'}}>
                  <div className='content-wrapper' onClick={() => {
                    if (!proposedFixId.includes(record?.id)) generateNewProposedFix(record?.id, record?.issueType, record);
                  }}>
                    <FontAwesomeIcon icon={faRotate} fontSize={12} color='#4E5156' className={generatingAiProposedFix && proposedFixId.includes(record?.id) ? 'fa-spin' : ''} />
                    AI Generate
                  </div>
                </Tooltip></FreezeWrapper>
              }
            </span>
        }
        {(deployingProposedFix && index == selectedIndex) ? <></> :
          (record?.issueType === 'unanswered_reviews' && record?.reply?.content) && <FreezeWrapper removeTooltip={record?.reply?.status === 'Published'}><FontAwesomeIcon icon={faTrash} fontSize={12} color='#4E5156' style={{marginLeft: '10px', cursor: 'pointer'}} onClick={() => !statusLoading && showConfirmationPopup(record)}/></FreezeWrapper>}
      </DescriptionWrapper>
    );
  };

  const inputDescriptionContainer = record => {
    return (
      <div style={{width: '100%'}}>
        <div style={{display: 'flex', gap: '10px', width: record?.issueType == 'nlp_faq' ? '107%' : '100%', alignItems: 'center', flexWrap: 'wrap', minWidth: 150}}>
          <StyledInput rows={6} value={proposedFix} onChange={e => setProposedFix(e.target.value)}/>
          <Button
            buttonType='green'
            loading={deployingProposedFix}
            disabled={!proposedFix?.trim()}
            style={{
              height: '30px',
              fontSize: '14px',
              padding: '0px 15px',
            }}
            onClick={() => onChangeReply(record?.id, proposedFix, record)}
          >
            {!deployingProposedFix ? 'Add': ''}
          </Button>
          <CloseButton style={{
            height: '26px',
            width: '32px',
          }}>
            <FontAwesomeIcon icon={faTimes} fontSize={20} color='white' onClick={() => {
              setEditDescription(-1);
              setProposedFix('');
            }} />
          </CloseButton>
        </div>
      </div>
    );
  };

  const addDefaultSrc = e => {
    e.target.src = '/img/icon/broken-image.svg';
  };

  const disableStatusMenu = true;
  const columns = [
    {
      title: <div className='column-title'>NAME</div>,
      dataIndex: 'name',
      key: 'name',
      width: '160px',
      fixed: 'left' as 'left',
      render: (_, record) => {
        return (
          <div style={{display: 'flex', gap: '10px'}}>
            <ImageWrapper>
              {record?.reviewerPhotoUrl ?
                <img src={record?.reviewerPhotoUrl} onError={e => addDefaultSrc(e)} referrerPolicy='no-referrer' className='image' style={{border: 'none'}} onClick={() => openUrl(record?.reviewerPhotoUrl, '_blank')} /> :
                <Tooltip title={'Image not available'}>
                  <img src='/img/icon/broken-image.svg' className='image' style={{cursor: 'not-allowed'}}/>
                </Tooltip>}
            </ImageWrapper>
            <ReviewerName>{record?.reviewerName || record?.createdBy || '-'}</ReviewerName>
          </div>
        );
      },
    },
    {
      title: <div className='column-title'>RATING</div>,
      dataIndex: 'rating',
      key: 'rating',
      width: '122px',
      fixed: 'left' as 'left',
      render: (_, record) => {
        return (
          <StyledReviewsRating disabled value={record?.starRating ? record?.starRating : 0}/>
        );
      },
    },
    {
      title: <div className='column-title'>REVIEW</div>,
      dataIndex: 'review',
      key: 'review',
      width: '435px',
      render: (_, record) => {
        return (
          <div>{record?.content ?? '-'}</div>
        );
      },
    },
    {
      title: <div className='column-title'>REPLY</div>,
      dataIndex: 'proposedAnswer',
      key: 'proposedAnswer',
      className: 'unanswered-proposed-answer',
      width: '500px',
      render: (_, record, index) => {
        return (
          <div style={{display: 'flex', alignItems: 'center'}}>
            {
              editDescription !== index ?
                <>
                  {
                    descriptionContainer({...record, recommendedValue: record?.reply?.content}, index)
                  }
                </> :
                <>
                  {
                    inputDescriptionContainer({...record, recommendedValue: record?.reply?.content})
                  }
                </>
            }
          </div>
        );
      },
    },
    {
      title: (<div className='column-title'>
        {disableStatusMenu ? <span>STATUS</span> :
          <Dropdown overlay={<StyledMenu selectedIssueType={selectedIssueType} deployOttoSection={deployOttoSection} currentProject={currentProject} />} trigger={['click']} placement='bottomLeft' overlayStyle={{top: '798px', boxShadow: '0px 1px 0px 0px #F4F4F4'}}>
            <div style={{display: 'flex', gap: '6px'}}>
              <span>STATUS</span>
              <FontAwesomeIcon icon={faSortDown} color='#121212' fontSize={12} />
            </div>
          </Dropdown>
        }
      </div>),
      dataIndex: 'status',
      key: 'status',
      width: '170px',
      render: (_, record, index) => {
        const defaultStyle = {backgroundColor: '', textColor: '', icon: null, label: ''};
        const {backgroundColor, textColor, icon, label} = qAndAStatusStyles[record?.reply?.status] || defaultStyle;
        return (
          // <Tooltip title={canDeploy(record, record?.issueType) ? '' : 'Empty fields cannot be deployed.'}>
          //   <StatusWrapper isDisabled={!canDeploy(record, record?.issueType)} status={record?.reply ? record?.reply?.status === 'Published' ? true : false : false} onClick={() => canDeploy(record, record?.issueType) && publishGBPReview(record?.id, record, index)}>
          //     {urlId === index ? <Spin indicator={antUrlIcon} /> :
          //       <FontAwesomeIcon icon={faCheckCircle} fontSize={20} color={record?.reply ? record?.reply?.status === 'Published' ? '#2AC155' : '#A3A4A4' : '#A3A4A4'} />
          //     }
          //     <span>{record?.reply ? record?.reply?.status === 'Published' ? 'Answered' : 'Un Answered' : 'Un Answered'}</span>
          //   </StatusWrapper>
          // </Tooltip>
          record?.reply?.content ? <FreezeWrapper placement='topRight' removeTooltip={record?.reply?.status === 'Published'}><Dropdown
            trigger={['click']}
            overlay={
              <Menu style={{borderRadius: 8}}>
                <Menu.Item style={{textAlign: 'center', padding: 0}}
                  onClick={()=> {
                    canDeploy(record, record?.issueType) && publishGBPReview(record?.id, record, index);
                    setSelectedIndex(index);
                  }}
                >
                  <StatusDropdown backgroundColor='' textColor={qAndAStatusStyles[record?.reply?.status === 'Published' ? 'Unpublished': 'Published']?.textColor} style={{width: '100%'}}>
                    <FontAwesomeIcon icon={qAndAStatusStyles[record?.reply?.status === 'Published' ? 'Unpublished': 'Published']?.icon} color={qAndAStatusStyles[record?.reply?.status === 'Published' ? 'Unpublished': 'Published']?.textColor} fontSize={14} style={{marginRight: '5px'}}/>
                    {qAndAStatusStyles[record?.reply ? record?.reply?.status === 'Published' ? 'Unpublished': 'Published' : 'Unpublished']?.label}
                  </StatusDropdown>
                </Menu.Item>
              </Menu>
            }
          >
            <StatusDropdown backgroundColor={backgroundColor} textColor={textColor}>
              <div className='status-text'>
                {icon && (
                  <div style={{display: 'flex', alignItems: 'center', gap: 5, cursor: 'pointer'}}>
                    {
                      urlId === index ? <Spin indicator={<LoadingOutlined style={{fontSize: 16, color: textColor}} spin />} />:
                        <FontAwesomeIcon icon={icon} color={textColor} fontSize={14} />
                    }
                    {label}
                    <FontAwesomeIcon icon={faSortDown} fontSize={12} color={textColor} />
                  </div>
                )}
              </div>
            </StatusDropdown>
          </Dropdown></FreezeWrapper> : <></>
        );
      },
    },
  ];

  const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
  const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);
  const data = url?.issueTable?.results ?? [];
  const filteredData = getFilteredDataForOtto(data, url?.issueType, false);

  return (
    <>

      <StyledIssuesCollapse
        ghost
        expandIconPosition='right'
        defaultActiveKey={[`${isTableOpen}`]}
        activeKey={[`${isTableOpen}`]}
        onChange={() => {
          if (isTableOpen === 'close') {
            setIsTableOpen('open');
            setIsOpenSearch(false);
          } else {
            setIsTableOpen('close');
            setIsOpenSearch(false);
          }
        }}
      >
        <TableHeader
          setPageChanged={setPageChanged}
          setIssueTable={setIssueTable}
          issueTable={issueTable}
          setSearchText={setSearchText}
          searchText={searchText}
          setIsOpenSearch={setIsOpenSearch}
          isOpenSearch={isOpenSearch}
          setIsTableOpen={setIsTableOpen}
          isTableOpen={isTableOpen}
          setIsActiveFilter={setIsActiveFilter}
          isActiveFilter={isActiveFilter}
          componentIssueType={componentIssueType}
        />
        <Collapse.Panel key='open' header={<></>}>
          <StyledIssuesTable
            loading={ottoUrlLoader && ottoIssueType === componentIssueType}
            columns={columns}
            dataSource={filteredData}
            pagination={false}
            scroll={width < 1400 ? {x: 'auto'} : {}}
            onHeaderRow={() => {
              return {
                onClick: () => {
                  setSectionCount((url?.issueTable?.count || url?.issueTable?.results?.length) ?? 0);
                  setSelectedIssueType(url?.issueType);
                },
              };
            }}
          />
          <PaginationStyled
            onChange={(page, pageSize) => {
              setPageChanged(true);
              setIssueTable(url?.issueType);
              handlePaginationChange([url?.issueType], page, pageSize);
              saveOttoTablePageSize(componentIssueType, pageSize);
              setPageSize(pageSize);
            }}
            total={url?.issueTable?.count}
            pageSize={pageSize}
            current={url?.page ?? 1}
            showSizeChanger

            pageSizeOptions={['5', '10', '20', '50', '100']}
          />
        </Collapse.Panel>
      </StyledIssuesCollapse>

      {openModal && <Wrapper>
        <Modal
          onClose={() => setOpenModal(false)}
          visible={openModal}
          className={'customConfirmationModal'}
        >
          <ModalWrapper>
            <p>Are you sure you want to delete this record? </p>
            <p className={'warningText'}>This action cannot be undone.</p>

            <DeleteButton
              buttonType='transparent' size='lg'
              onClick={async () => {
                onChangeReply(currentRecord?.id, '', currentRecord);
                setOpenModal(false);
              }}>
            Delete
            </DeleteButton>
            <BackButton onClick={() => setOpenModal(false)} buttonType='transparent' size='lg'>
            Go Back
            </BackButton>

          </ModalWrapper>
        </Modal>
      </Wrapper>}
    </>
  );
});
