import React, {useEffect, useState} from 'react';
import {Col, Form, Row, Input, Select, DatePicker, Spin} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {Modal} from '@/components/common-components';
import styles from './style.module.scss';
import {loadStripe} from '@stripe/stripe-js';
import {
  // CardNumberElement,
  Elements,
} from '@stripe/react-stripe-js';
import {NextImg} from '@/utils/nextImg';

import {faCaretDown, faXmark} from '@fortawesome/pro-solid-svg-icons';
import CommonButton from './CommonButton';
import TextArea from 'antd/lib/input/TextArea';
import SelectDate from './SelectDate';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import {notification} from '@/utils/notification-v2';
import moment from 'moment';
import {LOCAL_SEO_SCAN_API} from '@/api/local-seo';
import CheckoutOptions from './checkoutOptions';
import {apiError} from '@/utils/api';
// import {updateGTMDatalayer} from '@/utils/gtm';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import PhoneInput from 'react-phone-input-2';
import {LoadingOutlined} from '@ant-design/icons';
import {urlValidationRegex, urlWithHttp} from '@/constants/regex';
import {addProtocolToDomainHttps} from '@/utils/url';
import ImagesForm from '../../otto-page-detail/Component/imagesForm';
import {convertData} from './localCitationCommonUtils';
import {Button} from '@/components/common-components/v2';
import {convertObjToErrorString} from '../../otto-page-detail/Component/GbpAudit/AggregatorNetwork/AggregatorNetwork/businessInfoModal';
import {faCircleInfo} from '@fortawesome/pro-duotone-svg-icons';

const tabsTitle = [
  'Business Location',
  'Media',
  'Contact Info',
  'About the Business',
  'Social',
  // 'Checkout',
];

const daysName = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

// const days = [
//   {id: 0, label: 'Mon', name: 'mon', type: 'open', row: 1},
//   {id: 1, label: 'Tue', name: 'tue', type: 'open', row: 1},
//   {id: 2, label: 'Wed', name: 'wed', type: 'open', row: 1},
//   {id: 3, label: 'Thu', name: 'thu', type: 'open', row: 1},
//   {id: 4, label: 'Fri', name: 'fri', type: 'open', row: 1},
//   {id: 5, label: 'Sat', name: 'sat', type: 'open', row: 2},
//   {id: 6, label: 'Sun', name: 'sun', type: 'open', row: 2},
// ];

const aggregatorsAvailable = [
  'foursquare',
  'gpsnetwork',
];

export const countries = [
  {countryCode: 'USA', label: 'United States', code: 'US', aggregatorsAvailable: [
    'dataaxle',
    'neustar',
    'foursquare',
    'gpsnetwork',
    'ypnetwork',
  ]},
  {countryCode: 'AUS', label: 'Australia', code: 'AU', aggregatorsAvailable: [
    'foursquare',
    'gpsnetwork',
  ]},
  {countryCode: 'AUT', label: 'Austria', code: 'AT', aggregatorsAvailable},
  {countryCode: 'CAN', label: 'Canada', code: 'CA', aggregatorsAvailable: [
    'dataaxle',
    'foursquare',
    'gpsnetwork',
  ]},
  {countryCode: 'CZE', label: 'Czechia', code: 'CZ', aggregatorsAvailable},
  {countryCode: 'DNK', label: 'Denmark', code: 'DK', aggregatorsAvailable},
  {countryCode: 'FRA', label: 'France', code: 'FR', aggregatorsAvailable},
  {countryCode: 'DEU', label: 'Germany', code: 'DE', aggregatorsAvailable},
  {countryCode: 'HKG', label: 'Hong Kong', code: 'HK', aggregatorsAvailable},
  {countryCode: 'IRL', label: 'Ireland', code: 'IE', aggregatorsAvailable},
  {countryCode: 'ITA', label: 'Italy', code: 'IT', aggregatorsAvailable},
  {countryCode: 'LUX', label: 'Luxembourg', code: 'LU', aggregatorsAvailable},
  {countryCode: 'NLD', label: 'Netherlands', code: 'NL', aggregatorsAvailable},
  {countryCode: 'NZL', label: 'New Zealand', code: 'NZ', aggregatorsAvailable},
  {countryCode: 'NOR', label: 'Norway', code: 'NO', aggregatorsAvailable},
  {countryCode: 'ESP', label: 'Spain', code: 'ES', aggregatorsAvailable},
  {countryCode: 'SWE', label: 'Sweden', code: 'SE', aggregatorsAvailable},
  {countryCode: 'CHE', label: 'Switzerland', code: 'CH', aggregatorsAvailable},
  {countryCode: 'PHL', label: 'Philippines', code: 'PH', aggregatorsAvailable},
  {countryCode: 'POL', label: 'Poland', code: 'PL', aggregatorsAvailable},
  {countryCode: 'PRT', label: 'Portugal', code: 'PT', aggregatorsAvailable},
  {countryCode: 'TWN', label: 'Taiwan', code: 'TW', aggregatorsAvailable},
  {countryCode: 'GBR', label: 'United Kingdom', code: 'GB', aggregatorsAvailable},
  {countryCode: 'UMI', label: 'United States Minor Outlying Islands', code: 'UM', aggregatorsAvailable},
];

const sociaLinks = [
  {key: 'facebook', title: 'Facebook URL (optional)'},
  {key: 'twitter', title: 'X (Twitter) URL (optional)'},
  {key: 'linkedin', title: 'Linkedin URL (optional)'},
  {key: 'instagram', title: 'Instagram URL (optional)'},
  {key: 'pinterest', title: 'Pinterest URL (optional)'},
];

export const weekDaysInitialData = [
  {
    dayNumber: 1,
    name: 'Mon',
    value: 'Closed',
    parsed: [],
    row: 'Monday',
  },
  {
    dayNumber: 2,
    name: 'Tue',
    value: 'Closed',
    parsed: [],
    row: 'Tuesday',
  },
  {
    dayNumber: 3,
    name: 'Wed',
    value: 'Closed',
    parsed: [],
    row: 'Wednesday',
  },
  {
    dayNumber: 4,
    name: 'Thu',
    value: 'Closed',
    parsed: [],
    row: 'Thursday',
  },
  {
    dayNumber: 5,
    name: 'Fri',
    value: 'Closed',
    parsed: [],
    row: 'Friday',
  },
  {
    dayNumber: 6,
    name: 'Sat',
    value: 'Closed',
    parsed: [],
    row: 'Saturday',
  },
  {
    dayNumber: 7,
    name: 'Sun',
    value: 'Closed',
    parsed: [],
    row: 'Sunday',
  },
];

interface Props {
  visible?: boolean;
  setVisible?: (boolean) => void;
  aggregators: string[];
  total: number;
  businessName?: string;
  selectedBusiness?: any;
  selectedId?: number;
  selectedAggregator?: any;
  isEdit?: boolean;
}

export const BusinessInfoModal = observer((props: Props) => {
  const {visible, setVisible, aggregators, businessName, total, selectedBusiness, selectedId, isEdit} = props;
  const [images, setImages] = useState([]);
  const [logo, setLogo] = useState(null);
  const {
    settings: {
      customer: {
        profile: {
          useTestStripeKey,
        },
      },
    },
  } = useStore('');
  const [stripePromise] = useState(() =>
    loadStripe(
      useTestStripeKey ?
        process.env.STRIPE_TEST_PUBLIC_KEY :
        process.env.STRIPE_LIVE_PUBLIC_KEY,
    ),
  );

  const {
    // plans: {
    //   postServicesList,
    //   orderCheckoutWithNewCard,
    //   postCheckoutCart,
    //   stopLoading,
    //   startLoading,
    // },
    // settings: {
    //   customer: {
    //     profile: {
    //       emailAddress,
    //     },
    //   },
    // },
    localSeoScan: {loadAggregatorNetwork, addingAggregatorNetwork, addAggregatorNetwork, updateAggregatorNetwork, loadAggregatorNetworkDetails, loadingAggregatorNetworkDetails, aggregatorNetworkDetails},
  } = useStore('');
  const [activeTab, setActiveTab] = useState(0);
  const [maxAccessTab, setMaxAccessTab] = useState(0);
  const [formData, setFormData] = useState<any>({});
  const [form] = Form.useForm();
  const [addAdditionalCategory, setAddAdditionalCategory] = useState([]);
  const [businesses, setBusinesses] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [previousCard, setPreviousCard] = useState(-1);
  const [newCard, setNewCard] = useState(true);
  const [loadingBusinesses, setLoadingBusinesses] = useState(false);
  const [websiteAddress, setWebsiteAddress] = useState('');
  const [weekDays, setWeekDays] = useState(weekDaysInitialData);
  // eslint-disable-next-line
  const [stripe, setStripe] = useState(null);
  // eslint-disable-next-line
  const [elements, setElements] = useState(null);
  const [location, setLocation] = useState({
    city: '',
    state: '',
    postCode: '',
    address1: '',
    address2: '',
  });

  const loadBusinessCategories = async () => {
    setLoadingBusinesses(true);
    try {
      const business = await LOCAL_SEO_SCAN_API.getBusinessCategories();
      setCategories(business);
    } catch (error) {
      setCategories([]);
      notification.error('Failed', 'Failed to load business categories');
    } finally {
      setLoadingBusinesses(false);
    }
  };
  useEffect(() => {
    if (visible) {
      setActiveTab(0);
      setMaxAccessTab(0);
      loadBusinessCategories();
      if (isEdit) {
        loadAggregatorNetworkDetails(selectedId);
      }
    }
  }, [visible]);

  useEffect(() => {
    if (isEdit) {
      setAddAdditionalCategory(aggregatorNetworkDetails?.extra_business_categories_ids);
    }
  }, [JSON.stringify(aggregatorNetworkDetails)]);

  useEffect(() => {
    const convertedData = convertData(aggregatorNetworkDetails?.opening_hours as any);
    const selectedBusinessHours = selectedBusiness?.businessMetadata?.openingHours?.perDay;
    const data = (isEdit ? convertedData?.length ? convertedData : weekDaysInitialData : selectedBusinessHours?.length ? selectedBusinessHours : weekDaysInitialData)
      ?.map(item => ({
        id: item?.dayNumber,
        label: convertToShortName(item?.name),
        name: convertToShortName(item?.name)?.toLowerCase(),
        type: item?.value === 'Closed' && !item?.parsed?.length ? 'closed' : item?.value === '24hrs' && !item?.parsed?.length ? '24hrs' : 'open',
        from: item?.parsed?.length ? convertTimeForRange(item?.parsed[0]?.open) : '09:00',
        to: item?.parsed?.length ? convertTimeForRange(item?.parsed[0]?.close) : '09:00',
        row: (item?.name === 'Saturday' || item?.name === 'Sunday') ? 2 : 1,
      }))
      ?.sort((a, b) => {
        const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        return daysOfWeek.indexOf(a.label) - daysOfWeek.indexOf(b.label);
      });
    setWeekDays(data);

    if (isEdit && !aggregatorNetworkDetails) {
      return;
    }
    const weekDaysV2 = {};
    (isEdit ? convertedData?.length ? convertedData : weekDaysInitialData : selectedBusinessHours?.length ? selectedBusinessHours : weekDaysInitialData)?.forEach(item => {
      weekDaysV2[`${convertToShortName(item?.name)?.toLowerCase()}_start`] = [moment(item?.parsed?.length ? convertTimeForRange(item?.parsed[0]?.open) : '09:00', 'hh:mm'), moment(item?.parsed?.length ? convertTimeForRange(item?.parsed[0]?.close) : '09:00', 'hh:mm')];
      weekDaysV2[convertToShortName(item?.name)?.toLowerCase()] = item?.value === 'Closed' ? 'closed' : item?.value === '24hrs' ? '24hrs' : 'open';
    });
    const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
    daysOfWeek.forEach(day => {
      if (!weekDaysV2[`${day}_start`]) {
        weekDaysV2[day] = 'closed';
        weekDaysV2[`${day}_start`] = [moment('09:00', 'hh:mm'), moment('09:00', 'hh:mm')];
      }
    });
    const selectedCountry = isEdit ? aggregatorNetworkDetails?.campaign_country?.toLowerCase() === 'us' ? 'USA' : aggregatorNetworkDetails?.campaign_country : selectedBusiness?.businessMetadata?.address?.country?.toLowerCase() === 'us' ? 'USA' : selectedBusiness?.businessMetadata?.address?.country;
    const filteredCountries = renderFilteredCountries();
    const campaignCountry = filteredCountries.find(country => [country?.label?.toLowerCase(), country?.value?.toLowerCase].includes(selectedCountry?.toLowerCase()));
    const fullFormData = {
      campaign_name: isEdit ? aggregatorNetworkDetails?.campaign_name : selectedBusiness?.businessName,
      website_address: isEdit ? aggregatorNetworkDetails?.website_address : selectedBusiness?.businessMetadata?.url,
      mobile_number: isEdit ? aggregatorNetworkDetails?.mobile_number : selectedBusiness?.businessMetadata?.phoneNumber ? selectedBusiness?.businessMetadata?.phoneNumber : '+1',
      campaign_country: campaignCountry?.value || filteredCountries?.[0]?.value,
      campaign_city: isEdit ? aggregatorNetworkDetails?.campaign_city : selectedBusiness?.businessMetadata?.address?.city,
      campaign_state: isEdit ? aggregatorNetworkDetails?.campaign_state : '',
      postcode: isEdit ? aggregatorNetworkDetails?.postcode : selectedBusiness?.businessMetadata?.address?.zipcode,
      address1: isEdit ? aggregatorNetworkDetails?.address1 : selectedBusiness?.businessMetadata?.address?.address1,
      address2: isEdit ? aggregatorNetworkDetails?.address2 : '',
      full_description: isEdit ? aggregatorNetworkDetails?.full_description : selectedBusiness?.businessMetadata?.description,
      contact_telephone: isEdit ? aggregatorNetworkDetails?.contact_telephone : '',
      contact_firstname: isEdit ? aggregatorNetworkDetails?.contact_firstname : '',
      contact_lastname: isEdit ? aggregatorNetworkDetails?.contact_lastname : '',
      fax_number: isEdit ? aggregatorNetworkDetails?.fax_number : '',
      business_category_id: isEdit ? aggregatorNetworkDetails?.business_category_id : null,
      employees_number: isEdit ? aggregatorNetworkDetails?.employees_number : null,
      brief_description: isEdit ? aggregatorNetworkDetails?.brief_description : '',
      formation_date: isEdit ? moment(aggregatorNetworkDetails?.formation_date, 'MM-YYYY') : '',
      social_profile_links: isEdit ? aggregatorNetworkDetails?.social_profile_links : null,
      facebook: isEdit ? aggregatorNetworkDetails?.social_profile_links?.facebook : '',
      twitter: isEdit ? aggregatorNetworkDetails?.social_profile_links?.twitter : '',
      linkedin: isEdit ? aggregatorNetworkDetails?.social_profile_links?.linkedin : '',
      instagram: isEdit ? aggregatorNetworkDetails?.social_profile_links?.instagram : '',
      pinterest: isEdit ? aggregatorNetworkDetails?.social_profile_links?.pinterest : '',
      extra_business_categories_ids: isEdit ? aggregatorNetworkDetails?.extra_business_categories_ids : null,
      contact_email: isEdit ? aggregatorNetworkDetails?.contact_email : '',
      ...[1, 2, 3, 4, 5].reduce((acc, value) => {
        if (isEdit && aggregatorNetworkDetails[`service_name_${value}`]) {
          acc[`service_name_${value}`] = aggregatorNetworkDetails[`service_name_${value}`];
        }
        return acc;
      }, {}),
      ...weekDaysV2,
    };
    setWebsiteAddress(selectedBusiness?.businessMetadata?.url);
    if (isEdit) {
      let imagesArr: any[] = [aggregatorNetworkDetails?.image1, aggregatorNetworkDetails?.image2, aggregatorNetworkDetails?.image3];
      imagesArr = imagesArr?.filter(item => item)?.map((item, idx) => ({id: idx, type: 'photos', url: item}));
      if (imagesArr?.length) setImages(imagesArr);
      if (aggregatorNetworkDetails?.logo) setLogo(aggregatorNetworkDetails?.logo);
    }
    form.setFieldsValue(fullFormData);
    setFormData(fullFormData);
    setLocation({
      city: isEdit ? aggregatorNetworkDetails?.campaign_city : selectedBusiness?.businessMetadata?.address?.city,
      state: isEdit ? aggregatorNetworkDetails?.campaign_state : '',
      postCode: isEdit ? aggregatorNetworkDetails?.postcode : selectedBusiness?.businessMetadata?.address?.zipcode,
      address1: isEdit ? aggregatorNetworkDetails?.address1 : selectedBusiness?.businessMetadata?.address?.address1,
      address2: isEdit ? aggregatorNetworkDetails?.address2 : '',
    });
  }, [selectedBusiness, JSON.stringify(aggregatorNetworkDetails), visible]);

  useEffect(() => {
    if (activeTab === 2) {
      !businesses?.length && loadBusiness();
    }
  }, [activeTab]);

  const loadBusiness = async () => {
    setLoadingBusinesses(true);
    try {
      const business = await LOCAL_SEO_SCAN_API.getBusinessCategories();
      setBusinesses(business);
    } catch (error) {
      setBusinesses([]);
      notification.error('Failed', 'Failed to load business categories');
    } finally {
      setLoadingBusinesses(false);
    }
  };

  const createOptions = () => {
    return {
      base: {
        'fontSize': '14px',
        'color': '#121212',
        'font': {
          family: 'Inter, sans-serif',
          src: "url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap')",
          weight: '400',
        },
        'letterSpacing': '0.025em',
        '::placeholder': {
          color: '#A3A4A4',
          font: {
            family: 'Inter, sans-serif',
            src: "url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap')",
            weight: '400',
          },
        },
      },
      invalid: {
        color: '#F44343',
      },
    };
  };

  const nextStep = async (values?: any) => {
    try {
      setFormData(prev => ({...prev, ...values}));
      setMaxAccessTab(activeTab + 1);
      setActiveTab(activeTab + 1);
    } catch (error) {
      notification.error('Invalid', 'Invalid form field values');
    }
  };

  const validateFaxNumber = (_, value) => {
    if (/^[\d+\-()]+$/.test(value) || !value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please enter a valid fax number'));
  };

  const handleKeyDown = e => {
    if (e.key === '-') e.preventDefault();
  };

  const onChangeForm = () => maxAccessTab !== activeTab && setMaxAccessTab(activeTab);


  function convertToErrorString(obj) {
    let errorString = '';

    function traverse(obj, prefix = '') {
      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          errorString += `${prefix}: ${obj[key].join(', ')}\n`;
        } else if (typeof obj[key] === 'object') {
          traverse(obj[key], prefix ? `${prefix}.${key}` : key);
        }
      }
    }

    traverse(obj);
    return errorString.trim();
  }

  const handleComfirmAndPay = async () => {
    try {
      const allKeys = Object.keys(formData);
      const payload = {
        aggregators: aggregators,
        business_name: businessName,
        campaign_name: formData.campaign_name,
        website_address: websiteAddress,
        mobile_number: formData.mobile_number,
        campaign_country: formData.campaign_country,
        campaign_state: formData.campaign_state,
        campaign_city: formData.campaign_city,
        postcode: formData.postcode,
        address1: formData.address1,
        ...(formData.address2 && {address2: formData.address2}),
        contact_firstname: formData.contact_firstname,
        contact_lastname: formData.contact_lastname,
        contact_name: `${formData.contact_firstname || ''} ${formData.contact_lastname || ''}`,
        ...(formData.contact_email && {contact_email: formData.contact_email}),
        contact_telephone: formData.contact_telephone,
        ...(formData.fax_number && {fax_number: formData.fax_number}),
        ...(formData.formation_date && {formation_date: moment(formData.formation_date).format('MM-YYYY')}),
        business_category_id: formData.business_category_id,
        employees_number: formData.employees_number,
        full_description: formData.full_description,
        brief_description: formData.brief_description,
      };
      [1, 2, 3, 4, 5].forEach(value => {
        if (formData[`service_name_${value}`]) payload[`service_name_${value}`] = formData[`service_name_${value}`];
      });
      if (formData?.additional_business_category_0) {
        payload['extra_business_categories_ids'] = [];
        allKeys.forEach(key => {
          if (key.includes('additional_business')) payload['extra_business_categories_ids'].push(formData[key]);
        });
      }
      const regular = {};
      daysName.forEach(day => {
        const detailObject = {status: 'open', hours: []};
        allKeys.forEach(key => {
          if (key.includes(day) && formData[key]) {
            if ((key === `${day}_start`) || (key === `${day}_end`)) {
              formData[key] && detailObject.hours.push({
                start: formData[key][0] ? moment(formData[key][0]).format('HH:mm') : '',
                end: formData[key][1] ? moment(formData[key][1]).format('HH:mm') : '',
              });
            }
            if (key === day) {
              detailObject.status = formData[key];
            }
          }
        });
        if (!detailObject.hours?.length) {
          detailObject.status = 'closed';
        }
        if (['24hrs', 'closed'].includes(detailObject.status)) detailObject['hours'] = [];
        regular[day] = detailObject;
      });
      payload['opening_hours'] = {regular};

      const links = {};
      sociaLinks.forEach(({key}) => {
        if (formData[key]) links[key] = formData[key];
      });
      payload['social_profile_links'] = links;
      const data = new FormData();
      Object.entries(payload || {})?.forEach(([key, value]) => {
        if ((Array.isArray(value) || typeof value === 'object')) {
          data.append(key, JSON.stringify(value));
        } else {
          data.append(key, value);
        }
      });
      if (logo && typeof logo !== 'string') {
        data.append('logo', logo);
      }
      images?.slice(0, 3)?.forEach((image, idx) => {
        if (image?.url && typeof image?.url !== 'string') data.append(`image${idx + 1}`, image?.url);
      });

      if (isEdit) {
        await updateAggregatorNetwork(selectedId, data);
      } else {
        await addAggregatorNetwork(data);
        notification.success('Success', 'Business info added successfully');
      }

      loadAggregatorNetwork();
      setFormData({});
      setAddAdditionalCategory([]);
      setVisible(false);
    } catch (e) {
      if (e?.response?.data?.payment) {
        const errorMessage = apiError(e) as string;
        notification.error(errorMessage, 'Can not proceed at the moment, Please try again later', false, 'OK');
      } else if (e?.response?.data?.openingHours) {
        const errorMessage = convertObjToErrorString(e?.response?.data);
        notification.error('', typeof errorMessage === 'string' ? errorMessage : 'Something went wrong.', false, 'OK');
      } else {
        const errorMessage = apiError(e, '', true);
        notification.error('', typeof errorMessage === 'string' ? errorMessage : convertToErrorString(errorMessage), false, 'OK');
      }
      setVisible(true);
    }
    // await postServicesList();
    // updateGTMDatalayer({
    //   event: 'begin_checkout',
    //   ecommerce: {
    //     value: String(1?.toFixed(2))?.replace('.00', ''),
    //     items: []?.map(item => {
    //       return {
    //         item_name: item?.title,
    //         price: item?.amount,
    //         quantity: item?.links,
    //         item_variant: item?.variantId,
    //         item_category: item?.subTitle,
    //       };
    //     }),
    //   },
    // });
    // const response = await postServicesList();
    // if (response?.id) {
    //   try {
    //     startLoading();
    //     if (newCard) {
    //       const data = {
    //         emailAddress: emailAddress,
    //       };
    //       const cardNumElement = elements.getElement(CardNumberElement);
    //       const getCartId = localStorage.getItem('servicesListId');
    //       const result = await orderCheckoutWithNewCard(data, cardNumElement, stripe, getCartId);
    //       if (result?.error) {
    //         const errorMessage = apiError(result.error) as string;
    //         notification.error('', errorMessage || 'Something went wrong please try again', false, 'OK');
    //       }
    //     } else {
    //       const getCartId = localStorage.getItem('servicesListId');
    //       await postCheckoutCart(getCartId, {payment_method_id: previousCard});
    //     }
    //   } catch (e) {
    //     console.error(e);
    //   } finally {
    //     stopLoading();
    //   }
    // }
  };

  const renderFilteredCountries = () => {
    const filteredCountry = countries.filter(country => aggregators.every(aggr => country.aggregatorsAvailable.includes(aggr))) || [];
    return filteredCountry?.map(country => ({
      value: country.countryCode, label: country.label,
    }));
  };

  function convertToShortName(fullName) {
    const weekdays = {
      'Monday': 'Mon',
      'Tuesday': 'Tue',
      'Wednesday': 'Wed',
      'Thursday': 'Thu',
      'Friday': 'Fri',
      'Saturday': 'Sat',
      'Sunday': 'Sun',
    };

    return weekdays[fullName] || fullName;
  }

  const convertTimeForRange = time => {
    const timeString = time;
    const timeFormat = 'h A';
    const timeMoment = moment(timeString, timeFormat);

    return timeMoment.format('HH:mm');
  };

  const UrlError = ({value}) => {
    return <GrayError>Please enter a valid domain name like
      <span className='url-btn' onClick={() => {
        setWebsiteAddress(addProtocolToDomainHttps(value));
        form.setFields([{name: 'website_address', touched: false, validating: false, errors: []}]);
      }}>{addProtocolToDomainHttps(value)}</span></GrayError>;
  };

  const urlValidator = (_, _2, callback) => {
    const value = websiteAddress;
    if (value?.match(urlValidationRegex) && value?.match(urlWithHttp) || !value) {
      callback();
    } else if (addProtocolToDomainHttps(value)?.match(urlWithHttp)) {
      callback(<UrlError value={value} />);
    } else {
      callback(<ErrorMessage text='Please enter a valid URL' />);
    }
  };
  const ErrorMessage = ({text}) => <StylesError>{text}</StylesError>;

  const handleBackClick = () => {
    setMaxAccessTab(activeTab <= 0 ? 0 : activeTab - 1);
    setActiveTab(activeTab <= 0 ? 0 : activeTab - 1);
  };

  const steps = [
    <Form
      key='step1'
      layout='vertical'
      autoComplete='off'
      name='step1'
      onFinish={nextStep}
      form={form}
      onChange={onChangeForm}
      initialValues={formData}
    >
      <Row gutter={[20, 5]}>
        <Col xs={12}>
          <Form.Item
            name='campaign_name'
            label={<span className='label'>Business Name</span>}
            rules={[{required: true, whitespace: true, message: <ErrorMessage text='Please enter business name' />}]}
          >
            <CustomInput>
              <Input defaultValue={formData?.campaign_name || selectedBusiness?.businessName} bordered={false} />
            </CustomInput>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name='website_address'
            label={<span className='label'>Business Website URL</span>}
            rules={[
              {required: true, message: <ErrorMessage text='Please enter website URL' />},
              {validator: urlValidator},
            ]}
          >
            <CustomInput>
              <Input defaultValue={isEdit ? formData?.website_address : websiteAddress} onChange={e => setWebsiteAddress(e.target.value)} bordered={false} />
            </CustomInput>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name='mobile_number'
            label={<span className='label'>Business Phone Number1</span>}
            rules={[
              {required: true, message: <ErrorMessage text='Please enter phone number' />},
            ]}
          >
            <StyledPhoneInput
              inputStyle={{width: '100%'}}
              country='us'
              placeholder='Phone Number'
            />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name='campaign_country'
            label={<span className='label'>Country</span>}
            rules={[
              {required: true, message: <ErrorMessage text='Please select your country' />},
            ]}
          >
            <Select
              placeholder='Select Country'
              defaultValue={formData?.campaign_country}
              options={renderFilteredCountries()}
              suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#000000' />}
              style={{maxHeight: 32}}
              onChange={() => {
                setFormData(prev => ({...prev, campaign_state: '', campaign_city: '', postcode: ''}));
                form.setFieldsValue({
                  campaign_state: '',
                  campaign_city: '',
                  postcode: '',
                });
                setLocation({
                  city: '',
                  state: '',
                  postCode: '',
                  address1: '',
                  address2: '',
                });
              }}
              className={styles.countryDropdown}
            />
          </Form.Item>
        </Col>
        <Col xs={8}>
          <Form.Item
            name='campaign_city'
            label={<span className='label'>Town / City</span>}
            rules={[
              {required: true, whitespace: true, message: <ErrorMessage text='Please enter your city' />},
            ]}
          >
            <CustomInput>
              <Input value={location.city} onChange={e => setLocation({...location, city: e.target.value})} bordered={false} />
            </CustomInput>
          </Form.Item>
        </Col>
        <Col xs={8}>
          <Form.Item
            name='campaign_state'
            label={<span className='label'>State / County / Region</span>}
            rules={[
              {required: true, whitespace: true, message: <ErrorMessage text='Please enter your State / County / Region' />},
            ]}
          >
            <CustomInput>
              <Input value={location.state} onChange={e => setLocation({...location, state: e.target.value})} bordered={false} />
            </CustomInput>
          </Form.Item>
        </Col>
        <Col xs={8}>
          <Form.Item
            name='postcode'
            label={<span className='label'>Zip / PostCode</span>}
            rules={[
              {required: true, message: <ErrorMessage text='Please enter ZipCode / PostCode!' />},
              {pattern: /^[a-zA-Z0-9\s-]+$/, message: <ErrorMessage text='Please enter a valid zip code' />},
            ]}
          >
            <CustomInput>
              <Input bordered={false} value={location.postCode} onChange={e => setLocation({...location, postCode: e.target.value})} />
            </CustomInput>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name='address1'
            label={<span className='label'>Address Line 1</span>}
            className={styles.addressItem}
            rules={[
              {required: true, whitespace: true, message: <ErrorMessage text='Please enter your address' />},
              {max: 100, message: <ErrorMessage text='Address must be less than 100 characters' />},
            ]}
            extra={(
              <span className={styles.addressNote}>
                <FontAwesomeIcon icon={faCircleInfo} color='#1890ff' fontSize={14} />
                Do not include <span style={{fontWeight: 700}}>PO BOX</span> in the address because it {`won't`} be accepted by the Aggregators.
              </span>
            )}
          >
            <CustomInput>
              <Input value={location.address1} onChange={e => setLocation({...location, address1: e.target.value})} bordered={false} />
            </CustomInput>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name='address2'
            label={<span className='label'>Address Line 2 (Optional)</span>}
            rules={[
              {max: 100, message: <ErrorMessage text='Address must be less than 100 characters' />},
            ]}
          >
            <CustomInput>
              <Input value={location.address2} onChange={e => setLocation({...location, address2: e.target.value})} bordered={false} />
            </CustomInput>
          </Form.Item>
        </Col>
      </Row>
      <ButtonSection>
        <ButtonWrapper>
          <CommonButton text='Next Step' small />
        </ButtonWrapper>
      </ButtonSection>
    </Form>,
    <div key='step2'>
      <ImagesForm
        images={images}
        setImages={setImages}
        logo={logo}
        setLogo={setLogo}
        maxLimit={3}
        showUrlSection={false}
        width='180px'
        photosSectionTitle='Business Photos'
        logoPlaceholder='Format: JPG, PNGs'
      />
      <ButtonSection>
        <ButtonWrapper>
          <CancelButton variant='ghost' background='transparent' color='black' onClick={()=> {
            handleBackClick();
          }}>Back</CancelButton>
          <CommonButton action={() => nextStep()} text='Next Step' small />
        </ButtonWrapper>
      </ButtonSection>
    </div>,
    <Form
      key='step3'
      layout='vertical'
      autoComplete='off'
      name='step2'
      onFinish={nextStep}
      initialValues={formData}
      onChange={onChangeForm}
      form={form}
    >
      <Row gutter={[20, 5]}>
        <Col xs={12}>
          <Form.Item
            name='contact_firstname'
            label={<span className='label'>Contact First Name</span>}
            rules={[
              {required: true, message: 'Please enter first name'},
              {whitespace: true, message: 'Please enter first name'},
            ]}
          >
            <CustomInput>
              <Input defaultValue={isEdit ? formData?.contact_firstname : formData.contact_firstname} bordered={false} />
            </CustomInput>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name='contact_lastname'
            label={<span className='label'>Contact Last Name</span>}
            rules={[
              {required: true, message: 'Please enter last name'},
              {whitespace: true, message: 'Please enter last name'},
            ]}
          >
            <CustomInput>
              <Input defaultValue={isEdit ? formData?.contact_lastname : formData.contact_lastname} bordered={false} />
            </CustomInput>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name='contact_email'
            label={<span className='label'>Contact Email (Optional)</span>}
            rules={[{type: 'email', message: <ErrorMessage text='Please enter a valid email' />}]}
          >
            <CustomInput>
              <Input defaultValue={isEdit ? formData?.contact_email : formData.contact_email} bordered={false} />
            </CustomInput>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name='contact_telephone'
            label={<span className='label'>Contact Phone Number2</span>}
            rules={[
              {required: true, message: 'Please enter phone number'},
            ]}
          >
            <StyledPhoneInput
              inputStyle={{width: '100%'}}
              country='us'
              placeholder='Phone Number'
              value={formData.contact_telephone}
            />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name='fax_number'
            label={<span className='label'>Fax number (Optional)</span>}
            rules={[{validator: validateFaxNumber}]}
          >
            <CustomInput>
              <Input defaultValue={formData.fax_number} bordered={false} />
            </CustomInput>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name='formation_date'
            label={<span className='label'>Opening Date</span>}
            rules={[
              {required: true, message: 'Please select an opening date'},
            ]}
          >
            <DatePicker
              picker='month'
              format='MM-YYYY'
            />
          </Form.Item>
        </Col>
      </Row>
      <ButtonSection>
        <ButtonWrapper>
          <CancelButton variant='ghost' background='transparent' color='black' onClick={()=> {
            handleBackClick();
          }}>Back</CancelButton>
          <CommonButton text='Next Step' small />
        </ButtonWrapper>
      </ButtonSection>
    </Form>,
    <Form
      key='step4'
      layout='vertical'
      autoComplete='off'
      name='step3'
      onFinish={nextStep}
      initialValues={formData}
      onChange={onChangeForm}
      form={form}
    >
      <Row gutter={[20, 5]}>
        <Col xs={16}>
          <Form.Item
            name='business_category_id'
            label={<span className='label'>Business Category</span>}
            rules={[
              {required: true, message: <ErrorMessage text='Please select a business category' />},
            ]}
          >
            <Select
              showSearch
              placeholder='Select Category'
              defaultValue={formData.business_category_id}
              filterOption={(input, option) => {
                if (!option || !option.props || !option.props.children) {
                  return false;
                }
                const optionName = String(option.props.children).toLowerCase();
                return optionName.includes(input.toLowerCase());
              }}
              suffixIcon={
                loadingBusinesses ?
                  <Spin indicator={<LoadingOutlined style={{fontSize: 20}} spin />} /> :
                  <FontAwesomeIcon icon={faCaretDown} color='#000000' />
              }
              disabled={loadingBusinesses}
            >
              {Array.isArray(categories) && categories.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={8}>
          <Form.Item
            name='employees_number'
            label={<span className='label'>Number of Employees</span>}
            rules={[{required: true, message: <ErrorMessage text='Please enter number of employees' />}]}
          >
            <CustomInput>
              <StyledInput
                defaultValue={formData.employees_number}
                bordered={false}
                min={0}
                type='number'
                onKeyDown={handleKeyDown}
              />
            </CustomInput>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name='full_description'
            label={<span className='label'>Full Business Description</span>}
            rules={[
              {required: true, whitespace: true, message: <ErrorMessage text='Please enter full description' />},
              {
                max: 500,
                message: (
                  <ErrorMessage text='Full business description must be less than 500 characters' />
                ),
              },
            ]}
          >
            <CustomInput>
              <StyledTextArea defaultValue={formData?.full_description} bordered={false} />
            </CustomInput>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name='brief_description'
            label={<span className='label'>Brief Business Description</span>}
            rules={[
              {required: true, whitespace: true, message: <ErrorMessage text='Please enter brief description' />},
              {
                max: 200,
                message: (
                  <ErrorMessage text='Brief business description must be less than 200 characters' />
                ),
              },
            ]}
          >
            <CustomInput>
              <StyledTextArea defaultValue={formData.brief_description} bordered={false} />
            </CustomInput>
          </Form.Item>
        </Col>
      </Row>
      {addAdditionalCategory?.length ?
        <Row>
          <AdditonalFields>
            <Labels>Additional Business Category (optional)</Labels>
            <Labels style={{marginLeft: '61px'}}>List of Services/Products (optional)</Labels>
          </AdditonalFields>
        </Row> :
        <></>
      }
      <StyledRow>
        {addAdditionalCategory?.length ?
          addAdditionalCategory?.map((i, idx) => {
            return (
              <AddAdditionalCategorySection key={idx}>
                <Index>#{idx + 1}</Index>
                <Form.Item key={idx} name={`additional_business_category_${idx}`}>
                  <Select
                    placeholder='Select Category'
                    style={{width: '236px'}}
                    defaultValue={formData[`additional_business_category_${idx}`]}
                    options={businesses?.map(business => ({value: business.id, label: business.name})) || []}
                    suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#000000' />}
                  />
                </Form.Item>
                <Form.Item
                  style={{marginLeft: '16px'}}
                  name={`service_name_${idx + 1}`}
                >
                  <CustomInput>
                    <Input style={{width: '250px', height: '33px'}} defaultValue={formData[`service_name_${idx + 1}`]} bordered={false} />
                  </CustomInput>
                </Form.Item>
                <StyledFontAwesomeIcon icon={faTrash} color='#A3A4A4' fontSize={18} onClick={() => setAddAdditionalCategory(addAdditionalCategory.filter(value => value !== i))} />
              </AddAdditionalCategorySection>
            );
          }) : <></>
        }
      </StyledRow>
      <Row>
        <AddAdditionalCategory isDisabled={addAdditionalCategory?.length >= 5} onClick={() => addAdditionalCategory.length < 5 && setAddAdditionalCategory([...addAdditionalCategory, addAdditionalCategory.length + 1])}>+ Add additional category</AddAdditionalCategory>
      </Row>
      <Row gutter={[20, 7]} className={styles.workingHours}>
        <Col span={24}>
          <div className={styles.label}>Working Hours</div>
        </Col>
        <DateWrapper>
          <div className='wrapper'>
            {weekDays?.filter((item, index) => index<5).map((item, id) => <SelectDate key={id} item={item} formData={formData}/>)}
          </div>
          <div className='wrapper'>
            {weekDays?.filter((item, index) => index>=5).map((item, id) => <SelectDate key={id} item={item} formData={formData}/>)}
          </div>
        </DateWrapper>
      </Row>
      <ButtonSection>
        <ButtonWrapper>
          <CancelButton variant='ghost' background='transparent' color='black' onClick={()=> {
            handleBackClick();
          }}>Back</CancelButton>
          <CommonButton text='Next Step' small />
        </ButtonWrapper>
      </ButtonSection>
    </Form>,
    <Form
      key='step5'
      layout='vertical'
      autoComplete='off'
      name='step4'
      onFinish={handleComfirmAndPay}
      initialValues={formData}
      form={form}
    >
      <Row gutter={[20, 5]}>
        {sociaLinks.map((item, i) => (<Col key={i} xs={12}>
          <Form.Item
            name={item.key}
            label={<span className='label'>{item.title}</span>}
            rules={[{type: 'url', message: <ErrorMessage text='Please enter a valid URL' />}]}
          >
            <CustomInput>
              <Input defaultValue={formData[item.key]} onChange={e => setFormData(prev => {
                prev[item.key] = e.target.value;
                return prev;
              })} bordered={false} />
            </CustomInput>
          </Form.Item>
        </Col>))}
      </Row>
      <div style={{flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
        <TotalAmount className={styles.amountWrapper}>
          Total amount: <span className={styles.total}>{isEdit ? 'Free' : `${total || 0} Credits`}</span>
        </TotalAmount>
        <ButtonWrapper>
          <CancelButton variant='ghost' background='transparent' color='black' onClick={()=> {
            handleBackClick();
          }}>Back</CancelButton>
          <CommonButton
            text={isEdit ? 'Confirm and Submit' : 'Confirm and Pay'}
            loading={addingAggregatorNetwork}
            disabled={addingAggregatorNetwork}
            isLock
          />
        </ButtonWrapper>
      </div>
    </Form>,
    <Elements stripe={stripePromise} key='step5'>
      <div className={styles.emailWrapper}>
      </div>
      <div className={styles.modalPaymentCard}>
        <div className={styles.label}>
        Card information
          <div className={styles.extraConten}>
            <img src='/img/icon/visa-card.svg' />
            <img src='/img/icon/master-card-new.svg' />
            <img src='/img/icon/american_express-card.svg' />
            <img src='/img/icon/discover-card.svg' />
          </div>
        </div>
        <CheckoutOptions
          setElements={setElements}
          setStripe={setStripe}
          newCard={newCard}
          createOptions={createOptions}
          setNewCard={setNewCard}
          previousCard={previousCard}
          setPreviousCard={setPreviousCard}
        />
      </div>
      <div className={styles.description}>
        <NextImg height={16} width={16} src='/img/icon/lock-icon.svg' />
        <span>
        All your information is encrypted and. We use SSL encryption and are
        PCI DSS-compliant
        </span>
      </div>
      <TotalAmount className={styles.amountWrapper}>
        Total amount: <span className={styles.total}>{total || 0} Credits</span>
      </TotalAmount>
      <ButtonWrapper>
        <CancelButton variant='ghost' background='transparent' color='black' onClick={()=> {
          handleBackClick();
        }}>Back</CancelButton>
        <CommonButton
          text='Confirm and Pay'
          loading={addingAggregatorNetwork}
          disabled={addingAggregatorNetwork}
          action={handleComfirmAndPay}
          isLock
        />
      </ButtonWrapper>
    </Elements>,
  ];

  return (
    <ModalStyled
      visible={visible}
      destroyOnClose={true}
      onClose={() => setVisible(false)}
      closable={false}
      isLast={activeTab === tabsTitle.length - 1 || tabsTitle[activeTab] === 'Media'}
    >
      <div className='header'>
        <Title>Submit Business Info</Title>
        <span onClick={() => setVisible(false)}>
          <FontAwesomeIcon className='close-icon' icon={faXmark} />
        </span>
      </div>
      {loadingAggregatorNetworkDetails ?
        <LoadingContainer>
          <Spin indicator={<LoadingOutlined style={{fontSize: '40px'}}/>} />
        </LoadingContainer> : <>
          <div className='steps-wrapper'>
            <div className='steps'>
              {tabsTitle.map((_, index) => (
                <div
                  key={index}
                  className={`section ${
                    index <= activeTab ? 'active-section ' : ''} ${
                    index <= maxAccessTab ? '' : ' restricted'
                  }`}
                  onClick={() => index <= maxAccessTab && setActiveTab(index)}
                >
                  <div className='step'>{`Step ${index + 1}`}</div>
                  <div className={`step ${index <= activeTab ? 'active' : ''}`}>
                    {tabsTitle[index]}
                  </div>
                </div>
              ))}
            </div>
            <div className='border-line' />
          </div>
          <div className={`${styles.formwrapper} checkout-scroll`}>{steps[activeTab]}</div>
        </>}
    </ModalStyled>
  );
});

const StyledTextArea = styled(TextArea)`
  &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }
            
    &::-webkit-scrollbar-thumb {
      background: rgba(138,138,138,0.32);
    }
            
    &::-webkit-scrollbar-track {
      background: rgba(138,138,138,0.12);
    }
`;

const CancelButton = styled(Button)`
  margin: 10px !important;
  padding: 0px 5px !important;
  color: #4E5156;

  &:hover {
    background-color: transparent !important;
  }
`;

const ModalStyled = styled(Modal)<{isLast: boolean}>`
  max-width: 620px;
  width: 100%;
  padding-bottom: 0;
  min-height: ${p=> p.isLast ? '400px' : '680px'};

  .rc-dialog-content {
    min-height: 100%;
    width: 100%;
    padding: 0;
  }

  .checkout-scroll {
    ${p => p.isLast ? `
      @media screen and (max-width: 650px) {
      max-height: calc(100vh - 200px);
      overflow-y: scroll;
      overflow-x: hidden;
      min-height: 400px;
    }
    ` : ''}
  }

  .rc-dialog-body {
    padding: 25px 25px;
    min-height: ${p=> p.isLast ? '400px' : '680px'};
  }

  .placeholder-txt {
    color: #4E5156 !important;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;

    span {
      cursor: pointer;
    }
  }
  .drag-text {
    font-size: 12px !important;
    line-height: 14.52px !important;
    margin-top: 13px;
  }

  .steps-wrapper {
    position: relative;

    .steps {
      display: flex;
    }

    .border-line {
      bottom: 3px;
      position: relative;
      width: 100%;
      height: 2px;
      border-bottom: 2px solid #e8e8e8 !important;
    }
    .section {
      flex-grow: 1;
      cursor: pointer;

      &.active-section {
        z-index: 1;
        padding-bottom: 8px;
        border-bottom: 4px solid #1fac47;
      }
      &.restricted {
        cursor: default;
      }
      .step {
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        color: #4e5156;

        &.active {
          font-weight: 500;
          color: #121212;
        }
      }
    }
  }

  .close-icon {
    color: #a3a4a4;
    font-size: 28px;
  }
`;

const TotalAmount = styled.div`
  margin-left: auto !important;
  padding-right: 0 !important;
  width: 228px !important;
  justify-content: start !important;
  align-items: center !important;
`;

const GrayError = styled.p`
  color: #4e5156;
  line-height: 1;
  font-weight: 400;
  margin-top: 6px;
  font-size: 10px;
  .url-btn {
    color: #2D6CCA;
    cursor: pointer;
    word-break: break-all;
    margin-left: 6px;
  }
`;

const ButtonSection = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
`;

const Title = styled.div`
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`;
const CustomInput = styled.div`
  padding: 0px 0px;
  border-radius: 8px;
  border: 1px solid #d2d2d2;
  box-shadow: 0px 3px 2px 0px #00000005;
`;

const AddAdditionalCategory = styled.span<{isDisabled: boolean}>`
  margin-top: 10px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #2D6CCA;
  cursor: ${p => p.isDisabled ? 'not-allowed' : 'pointer'};
`;

const AdditonalFields = styled.div`
  display: flex;
  margin-top: 18px;
  margin-bottom: 8px;
`;

const Labels = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #4E5156;
`;

const AddAdditionalCategorySection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  .ant-select-selection-item {
    max-width: 90% !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-left: 16px;
  cursor: pointer;
  &:hover {
    color: red;
  }

`;

const Index = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #4E5156;
  width: 30px;
`;

const StyledPhoneInput = styled(PhoneInput)`
  .form-control {
    height: 32px !important;
    border-radius: 8px !important;
  }
  .flag-dropdown {
    border-radius: 8px 0 0 8px !important;
  }
  .flag-dropdown.open .selected-flag {
    border-radius: 8px 0 0 8px !important;
  }
  .selected-flag:hover {
    border-radius: 8px 0 0 8px !important;
  }
`;

const StyledInput = styled(Input)`
  height: 33px;
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .wrapper {
    width: 50%;
  }
  .ant-picker-range {
    padding-inline: 4px !important;
  }
  .ant-picker-clear {
    right: 3px !important;
  }
  .ant-picker-input {
    max-height: 22px !important;
    &:first-child {
      padding-left: 2px !important;
    }
    &:last-child {
      padding-right: 2px !important;
    }
  }
`;

const StyledRow = styled(Row)`
  max-height: 190px;
  overflow-y: auto;
  min-width: 585px;
`;

export const StylesError = styled.p`
  font-size: 10px;
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
