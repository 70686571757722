import {observer} from 'mobx-react';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Spin} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';
import {openUrl} from '@/utils/router';
import {LoadingOutlined} from '@ant-design/icons';
import {copyToClipBoard} from '@/utils/clipboard';
import {faEnvelope} from '@fortawesome/pro-light-svg-icons';
import {Button} from '@/components/common-components/v2/Button';
import {faCircleCheck, faCircleExclamation} from '@fortawesome/pro-solid-svg-icons';
import FreezeWrapper from '../../otto-page-detail/Component/freezTooltip';
import {addProtocolToDomainHttps} from '@/utils/url';

interface Props {
  widgetStatus: number;
  setWidgetStatus: Dispatch<SetStateAction<number>>;
  existSiteAudit: string;
  inputVal: string;
  inputValue: string;
}
export const StepThree: React.FC<Props> = observer((
  {
    widgetStatus,
    setWidgetStatus,
    existSiteAudit,
    inputVal,
    inputValue,
  }) => {
  const {ottoV2Store: {
    checkScriptFromUrl,
    scriptStatusLoading,
    ottoV2Project,
  }, settings: {customer: {profile: {whitelabelOtto, isWhitelabel}}}} = useStore('');

  const [activeVideoTab, setActiveVideoTab] = useState<string>('word-press');
  const renderVideo = key => {
    const videoContainer = document.getElementById('otto-install-video');
    if (videoContainer && videoContainer?.children?.length > 1) {
      videoContainer.removeChild(videoContainer.childNodes[1]);
    }
    const videoElement = document.createElement('video');
    videoElement.autoplay = false;
    videoElement.controls = true;
    videoElement.style.borderRadius = '12px';
    videoElement.style.width = '100%';
    videoElement.style.height = '100%';
    videoElement.muted = true;
    if (key === 'word-press' && videoContainer) {
      videoElement.src = 'https://searchatlas.com/wp-content/uploads/2024/07/Creating-a-New-Project-on-OTTO-1.mp4';
      videoContainer?.appendChild(videoElement);
    } else if (key === 'shopify') {
      videoElement.src = 'https://searchatlas.com/wp-content/uploads/2024/05/Adding-OTTO-Pixel-via-Shopify-3.mp4';
      videoContainer?.appendChild(videoElement);
    } else if (key === 'webflow') {
      videoElement.src = 'https://searchatlas.com/wp-content/uploads/2024/05/Installing-AutoPixel-to-Your-Webflow-Site-1.mp4';
      videoContainer?.appendChild(videoElement);
    } else if (key === 'squarespace') {
      videoElement.src = 'https://searchatlas.com/wp-content/uploads/2024/06/squarespace.mp4';
      videoContainer?.appendChild(videoElement);
    } else {
      videoElement.src = 'https://searchatlas.com/wp-content/uploads/2024/06/squarespace.mp4';
      videoContainer?.appendChild(videoElement);
    }
    setActiveVideoTab(key);
  };

  useEffect(() => {
    renderVideo('word-press');
  }, []);
  const checkScript = async () => {
    let val = '';
    if (existSiteAudit === 'new-site') {
      val = inputVal;
    } else {
      val = inputValue;
    }
    if (!val) {
      val = ottoV2Project?.hostname;
    }
    const response = await checkScriptFromUrl(addProtocolToDomainHttps(val));
    if (response?.pixelState === 'installed') {
      setWidgetStatus(1);
    } else if (response?.pixelState === 'not_installed') {
      setWidgetStatus(2);
    } else if (response?.pixelState === 'wrong_uuid') {
      setWidgetStatus(3);
    }
  };

  return ( <>
    {!isWhitelabel ? <VideosWrapper id='otto-install-video'>
      <VideoChanger>
        Platform:
        <div
          className={`button ${activeVideoTab === 'word-press' ? 'active' : ''}`}
          onClick={() => renderVideo('word-press')}
        >WordPress</div>
        <div
          className={`button ${activeVideoTab === 'shopify' ? 'active' : ''}`}
          onClick={() => renderVideo('shopify')}
        >Shopify</div>
        <div
          className={`button ${activeVideoTab === 'webflow' ? 'active' : ''}`}
          onClick={() => renderVideo('webflow')}
        >Webflow</div>
        <div
          className={`button ${activeVideoTab === 'squarespace' ? 'active' : ''}`}
          onClick={() => renderVideo('squarespace')}
        >Squarespace</div>
        <div
          className={`button ${activeVideoTab === 'custom' ? 'active' : ''}`}
          onClick={() => renderVideo('custom')}
        >Custom</div>
      </VideoChanger>
    </VideosWrapper> : ''}
    <div className={'inputs-wrapper'} style={{marginTop: '16px'}}>
      <div className='label-wrapper'>
        <div className={'label'}>Embed widget on your side</div>
        {!isWhitelabel ? <div className='heading' onClick={() => openUrl('https://searchatlas.com/otto-pixel/', '_blank')}>Installation guide</div> : ''}
      </div>
      <div className={'snippet-wrapper'}>
        <div style={{marginBottom: '10px', overflowWrap: 'break-word', fontSize: 12}}>
          {ottoV2Project?.pixelHtml}
        </div>
        <div className={'snippet-buttons'}>
          <Button background='#121212' textColor='#FFFFFF' onClick={()=>copyToClipBoard(ottoV2Project?.pixelHtml)}>Copy code</Button>
          <FreezeWrapper>
            <a
              className={'email-button'}
              href={`mailto:?subject=Install this Widget on my site&body=Installation guide: https://searchatlas.com/otto-pixel/ \n${ottoV2Project?.pixelHtml}`}
            >
              <FontAwesomeIcon color={'#2D6CCA'} fontSize={16} icon={faEnvelope} />Email my developer
            </a>
          </FreezeWrapper>
        </div>
      </div>
    </div>
    {
      widgetStatus == -1 && <div className={'already-embed'} style={{border: 'none'}}>
        <div className={'text'}>Already embed the widget?&nbsp;<span onClick={checkScript} className={'link'}
        >Check widget status</span>{scriptStatusLoading && <Spin indicator={<LoadingOutlined style={{fontSize: 12, marginLeft: 10, display: 'flex', alignItems: 'center'}} spin />} />}</div>
      </div>
    }
    {
      widgetStatus === 1 && <div className={'embed-successfully'}>
        <FontAwesomeIcon icon={faCircleCheck} fontSize={16} color='#2AC155'/>
        <div className={'text'}>{`${whitelabelOtto} SEO Pixel was Installed Correctly.`}</div>
      </div>
    }
    {widgetStatus === 3 && <Banner>
      <FontAwesomeIcon icon={faCircleExclamation} fontSize={16} color='#F44343'/>
      <div className='banner-text'>{`${whitelabelOtto} SEO Pixel was not installed correctly. Copy & Paste the script to your website again.`}
        <span onClick={checkScript}>Check status</span>{scriptStatusLoading && <Spin indicator={<LoadingOutlined style={{fontSize: 12, marginLeft: 10, display: 'flex', alignItems: 'center'}} spin />} />}</div>
    </Banner>}
    {
      widgetStatus === 2 && <div className={'not-embed'}>
        <FontAwesomeIcon icon={faCircleExclamation} fontSize={16} color='#F44343'/>
        <div className={'text'}>{`${whitelabelOtto} SEO Pixel not Installed.`}
          <div className='check-again-button' onClick={checkScript}>Check again</div>{scriptStatusLoading && <Spin indicator={<LoadingOutlined style={{fontSize: 12, marginLeft: 10, display: 'flex', alignItems: 'center'}} spin />} />}</div>
      </div>
    }
  </>);
});

const VideosWrapper = styled.div`
  height: 240px;
  position: relative;
`;
const VideoChanger = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #E8E8E8;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 6px 14px;
  width: 100%;
  border-radius: 12px 12px 0px 0px;
  z-index: 1;
  .button {
    padding: 4px 8px;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #E8E8E8;
    cursor: pointer;
    &.active {
      font-weight: 600;
      background-color: rgba(255, 255, 255, 0.35);
      border-radius: 6px;
      transition: background-color 0.15s ease-in-out, font-weight 0.15s ease-in-out;
    }
  }
`;
const Banner = styled.div`
  padding: 11px 15px;
  border-radius: 8px;
  background-color: rgba(244, 67, 67, 0.15);
  display: flex;
  align-items: center;
  gap: 6px;

  .banner-text {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;

    span {
      color: #2D6CCA;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
