import {SEMANTIC_GRADER_API} from '@/api/content-optimizer/semantic-grader';
import {apiError} from '@/utils/api';
import {notification, useErrorNotification} from '@/utils/notification-v2';
import {toJS} from 'mobx';
import {Instance, types, flow, cast} from 'mobx-state-tree';


const LlmKeysModel = types.model({
  // openaiSecretKey: types.maybeNull(KeyModel),
  // anthropicSecretKey: types.maybeNull(KeyModel),
});

const HtmlAnalysis = types.model({
  id: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
});

const SvTopCountries = types.model({
  countryCode: types.maybeNull(types.string),
  searchVolume: types.maybeNull(types.number),
});

const TargetKeyword = types.model({
  id: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  locationId: types.maybeNull(types.number),
  rankingPotential: types.maybeNull(types.string),
  sv: types.maybeNull(types.number),
  globalSv: types.maybeNull(types.number),
  totalSearchResults: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  kd: types.maybeNull(types.number),
  svTopCountries: types.maybeNull(types.array(SvTopCountries)),
  variations: types.maybeNull(types.array(types.string)),
  serpTaskStatus: types.maybeNull(types.string),
  serpUpdatedAt: types.maybeNull(types.string),
  serpFailureCount: types.maybeNull(types.number),
  kdTaskStatus: types.maybeNull(types.string),
  kdErrorMessage: types.maybeNull(types.string),
  kdUpdatedAt: types.maybeNull(types.string),
  contentAnalysisProcessedAt: types.maybeNull(types.string),
  contentAnalysisTaskStatus: types.maybeNull(types.string),
  processingTimeSeconds: types.maybeNull(types.number),
  processingStartedAt: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  serpTaskStartedAt: types.maybeNull(types.string),
  serpTaskFinishedAt: types.maybeNull(types.string),
  entityTaskStartedAt: types.maybeNull(types.string),
  entityTaskFinishedAt: types.maybeNull(types.string),
});

const Details = types.model({
  entityScore: types.maybeNull(types.number),
  freshnessScore: types.maybeNull(types.number),
  humanEffortScore: types.maybeNull(types.number),
  pronounReviewScore: types.maybeNull(types.number),
  contentClarityScore: types.maybeNull(types.number),
  informationGainScore: types.maybeNull(types.number),
  numericalContentScore: types.maybeNull(types.number),
  semanticRelevanceScore: types.maybeNull(types.number),
  wordOrderSalienceScore: types.maybeNull(types.number),
  userIntentAlignmentScore: types.maybeNull(types.number),
  overallContextualFlowScore: types.maybeNull(types.number),
});

const EnsembleScore = types.model({
  details: types.maybeNull(Details),
  overallScore: types.maybeNull(types.number),
});

const SemanticModal = types.model({
  id: types.maybeNull(types.number),
  htmlAnalysis: types.maybeNull(HtmlAnalysis),
  targetKeyword: types.maybeNull(TargetKeyword),
  taskStatus: types.maybeNull(types.string),
  overallScore: types.maybeNull(types.number),
  ensembleScore: types.maybeNull(EnsembleScore),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
});

const SemanticModalList = types.model({
  count: types.maybeNull(types.number),
  next: types.maybeNull(types.string),
  pageSize: types.maybeNull(types.number),
  previous: types.maybeNull(types.string),
  results: types.maybeNull(types.array(SemanticModal)),
  totalPages: types.maybeNull(types.number),
});

export const SemanticGrader = types
  .model({
    semanticGraderLoader: types.boolean,
    llmApiKeys: types.maybeNull(LlmKeysModel),
    semanticGraderList: types.maybeNull(SemanticModalList),
    pageSize: types.maybeNull(types.number),
    page: types.maybeNull(types.number),
    createSemanticGraderLoader: types.boolean,
    semanticGraderDeleting: types.boolean,
  })
  .views(self => ({
    get getSemanticGrader() {
      return toJS(self.semanticGraderList);
    },
  }))
  .actions(self => {
    const getSemanticGraderList = flow(function* (noLoading?: boolean) {
      if (!noLoading) {
        self.semanticGraderLoader = true;
      }
      try {
        const response = yield SEMANTIC_GRADER_API.getSemanticGraderList(self.pageSize, self.page);
        if (response.isCancel) return;
        self.semanticGraderList = cast(response);
        const isRepulling = response.results.filter(item=> item.taskStatus != 'SUCCESS' && item?.taskStatus != 'FAILURE');
        if (isRepulling?.length > 0) {
          self.semanticGraderLoader = false;
          yield new Promise(r => setTimeout(r, 10000));
          return getSemanticGraderList(true);
        }
        self.semanticGraderLoader = false;
      } catch (e) {
        self.semanticGraderLoader = false;
        useErrorNotification({
          e,
          msg: 'Something went wrong.',
          desc: '',
          permanent: false,
        });
        return Promise.resolve(e);
      }
    });

    const deleteSemanticGrader = flow(function* (id) {
      self.semanticGraderDeleting = true;
      try {
        const response = yield SEMANTIC_GRADER_API.deleteSemanticGrader(id);
        if (response.isCancel) return;
        notification.success('Success', 'Page Deleted Successfully');
      } catch (e) {
        useErrorNotification({
          e,
          msg: 'Something went wrong.',
          desc: '',
          permanent: false,
        });
        return Promise.resolve(e);
      } finally {
        self.semanticGraderDeleting = false;
      }
    });

    const getSemanticGraderDetails = flow(function* (id) {
      self.semanticGraderLoader = true;
      try {
        const response = yield SEMANTIC_GRADER_API.getSemanticGraderDetail(id);
        if (response.isCancel) return;
        return response;
      } catch (e) {
        useErrorNotification({
          e,
          msg: 'Something went wrong.',
          desc: '',
          permanent: false,
        });
        return Promise.resolve(e);
      } finally {
        self.semanticGraderLoader = false;
      }
    });

    const createSemanticGrader = flow(function* (params) {
      self.createSemanticGraderLoader = true;
      try {
        yield SEMANTIC_GRADER_API.createSemanticGrader(params);
        self.createSemanticGraderLoader = false;
      } catch (e) {
        useErrorNotification({
          e,
          msg: 'Something went wrong.',
          desc: '',
          permanent: false,
          handleStatuses: [
            {
              statuses: [400],
              msg: apiError(e) as string,
              ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
              showDetails: false,
            },
          ],
        });
        return Promise.resolve(e);
      } finally {
        self.createSemanticGraderLoader = false;
      }
    });
    // task_status
    const setSemanticGraderParams = value => {
      self.pageSize = value.pageSize;
      self.page = value.page;
    };


    return {
      getSemanticGraderList,
      getSemanticGraderDetails,
      setSemanticGraderParams,
      createSemanticGrader,
      deleteSemanticGrader,
    };
  });

export type SemanticGraderStoreInstance = Instance<typeof SemanticGrader>;

export const initSemanticGraderStore = () => {
  return SemanticGrader.create({
    semanticGraderLoader: false,
    pageSize: 10,
    page: 1,
    createSemanticGraderLoader: false,
    semanticGraderDeleting: false,
  });
};
