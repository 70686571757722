import {Tooltip, Tabs, Rate, Menu, Dropdown} from 'antd';
import React, {useState, useEffect} from 'react';
import {TabPane} from 'rc-tabs';
import {observer} from 'mobx-react';
import {toJS} from 'mobx';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';
import {faChevronDown} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {useRouter} from 'next/router';

export const Preview = observer(({optimizationData, isInLocalSeo}) => {
  const {ottoV2Store: {
    getStarRatingCount,
    setSelectedCategory,
    setActiveReviewsTab,
    setActiveReviewsKey,
  },
  } = useStore('');
  const [reviewCount, setReviewCount] = useState(0);
  const [reviewRating, setReviewRating] = useState(0);
  const [status, setStatus] = useState('');
  const [nextTime, setNextTime] = useState('');
  const [schemaData, setSchemaData] = useState<any>(null);
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const [reviewRatings, setReviewRatings] = useState([]);

  const router = useRouter();

  useEffect(() => {
    if (optimizationData?.length) {
      const ottoData = optimizationData.filter(data => data.source == 'OTTO');
      const gbpData = optimizationData.filter(data => data.source == 'GBP');

      const schemaDataObj = {};
      ottoData?.forEach(item => {
        schemaDataObj[item.fieldName] = item.value;
      });
      gbpData?.forEach(item => {
        if (!Object.keys(schemaDataObj)?.includes(item.fieldName)) {
          schemaDataObj[item.fieldName] = item.value;
        }
      });
      setSchemaData(schemaDataObj);
    }
  }, [JSON.stringify(optimizationData)]);

  useEffect(() => {
    const fetchRating = async () => {
      const reviews = await getStarRatingCount(isInLocalSeo);
      setReviewRatings(reviews);
    };
    fetchRating();
  }, []);

  useEffect(() => {
    if (reviewRatings?.length) {
      const totalReviews = toJS(reviewRatings)?.reduce((accu, current) => ({count: accu?.count + current?.count, starRating: 0}));
      setReviewRating(calculateRating(reviewRatings));
      setReviewCount(totalReviews?.count);
    }
  }, [reviewRatings]);

  const calculateRating = ratings => {
    let totalRating = 0;
    let totalCount = 0;
    ratings.forEach(({starRating, count}) => {
      totalRating += starRating * count;
      totalCount += count;
    });
    return totalCount === 0 ? 0 : parseFloat((totalRating / totalCount).toFixed(1));
  };

  const getData = (fieldName: string) => {
    const data = schemaData?.[fieldName];
    if (data?.length) {
      if (Array.isArray(data)) {
        return data.join('/n');
      }
      return data;
    } else {
      return `Add ${fieldName.replaceAll('_', ' ')}`;
    }
  };

  const actions = [
    {name: 'Directions', icon: 'direction'},
    {name: 'Save', icon: 'save'},
    {name: 'Nearby', icon: 'share-location'},
    {name: 'Send to phone', icon: 'send'},
    {name: 'Share', icon: 'share'},
  ];

  const overview = [
    {field: 'address_lines', icon: 'location'},
    {field: 'open_hours', icon: 'clock'},
    {field: 'booking_link', icon: 'calendar'},
    {field: 'website_uri', icon: 'earth'},
    {field: 'phone_numbers', icon: 'phone'},
    {value: 'Send to your phone', icon: 'send'},
    {value: 'Your Maps Activity', icon: 'history'},
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const day = now.toLocaleDateString('en-US', {weekday: 'long'}).toLowerCase();
      const time = now.toTimeString().split(' ')[0].slice(0, 5);
      updateStatus(day, time);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [schemaData]);

  const updateStatus = (day, time) => {
    if (schemaData) {
      const intervals = schemaData?.open_hours && schemaData?.open_hours[day]?.intervals || [];

      if (intervals.length === 0) {
        setStatus('Closed');
        return;
      }

      const {open, close} = intervals[0];

      if (time >= open && time < close) {
        setStatus(`Open`);
        setNextTime(` - Closes ${moment(close, 'HH:mm:ss').format('hh:mm A')}`);
      } else if (time >= close) {
        const nextDay = getNextDay(day);
        const nextOpen = schemaData?.open_hours[nextDay]?.intervals[0]?.open;
        setStatus(`Closed`);
        if (nextOpen) {
          setNextTime(` - Opens ${moment(nextOpen, 'HH:mm:ss').format('hh:mm A')}`);
        } else {
          setNextTime(null);
        }
      } else {
        setStatus(`Closed`);
        setNextTime(` - Opens ${moment(open, 'HH:mm:ss').format('hh:mm A')}`);
      }
    }
  };

  const getNextDay = day => {
    const index = days.findIndex(d => d.toLowerCase() == day);
    return days[(index + 1) % 7];
  };

  const openHoursInfo = () => {
    const hoursArray = [];
    days.map(day => {
      const intervals = schemaData?.open_hours?.[day.toLowerCase()]?.intervals;
      if (intervals?.length) {
        hoursArray.push({
          day,
          time: intervals.map(({open, close}) => close == '24' ? '24 hours' : `${moment(open, 'HH:mm:ss').format('hh:mm A')} - ${moment(close, 'HH:mm:ss').format('hh:mm A')}`).join(','),
        });
      } else {
        hoursArray.push({
          day,
          time: 'Closed',
        });
      }
    });
    const menu = (
      <Menu>
        {hoursArray.map(({day, time}) => (
          <Menu.Item key={day}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <span style={{width: 100}}>{day}</span>
              <div>
                {time.split(',').map(item => (<div key={item}>{item}</div>))}
              </div>
            </div>
          </Menu.Item>
        ))}
      </Menu>
    );
    return (
      <div>
        <Dropdown overlay={schemaData?.open_hours ? menu : <></>}>
          <div><span style={{color: status == 'Open' ? 'green' : 'red'}}>{status}</span>{nextTime && <span>{nextTime}</span>}{faChevronDown && <FontAwesomeIcon style={{marginLeft: 20}} icon={faChevronDown} />}</div>
        </Dropdown>
      </div>
    );
  };
  const handleTabClick = key => {
    if (key === '2') {
      if (isInLocalSeo) {
        router.push('/research/gbp-automations/reviews');
      } else {
        setSelectedCategory('unanswered_reviews');
        setActiveReviewsTab('all');
        setActiveReviewsKey(Math.floor(Math.random() * 1000));
      }
    }
  };

  const openLink = (field, item) => {
    if (field == 'website_uri') {
      window.open(item, '_blank');
    }
  };

  return (
    <div className='preview'>
      <div style={{width: '100%', height: 'fit-content', borderRadius: 8}}>
        <div>{schemaData?.PROFILE?.url ? <img style={{borderRadius: 8}} width={'100%'} height={250} src={schemaData?.PROFILE?.url} /> : <span style={{width: '100%', height: 250, display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'rgb(112, 117, 122)', fontSize: 20}}>No Preview Available</span>}</div>
        <div style={{padding: '10px 20px'}}>
          <div style={{fontSize: 28, padding: 5}}>{getData('business_name')}</div>
          <div>
            {reviewRating}
            <Rate allowHalf className='ratings' value={reviewRating} disabled style={{margin: '0 15px'}} />
            ({reviewCount})
          </div>
          <div style={{color: '#70757a', fontSize: 14, padding: 5}}>{schemaData?.categories?.[0]?.label ?? schemaData?.categories?.[0]}</div>
        </div>
        <Tabs onTabClick={handleTabClick} style={{color: '#70757a', width: '100%'}} defaultActiveKey='1' centered>
          <TabPane tab='Overview' key='1'>
            <div className='actions'>
              <ActionsContainer>
                {actions.map((action, index) => (
                  <Action key={index}>
                    <IconWrapper style={{background: index == 0 && 'rgb(26,115,232)'}}>
                      <img width={16} className={index == 0 && 'first-icon'} src={'/icons/gbp-' + action.icon + '.svg'} />
                    </IconWrapper>
                    <ActionName>{action.name}</ActionName>
                  </Action>
                ))}
              </ActionsContainer>
            </div>
            {overview?.map((info, index) => {
              if (schemaData && (!info?.field || schemaData[info?.field])) {
                return (
                  <OverviewInfoContainer key={index}>
                    <img src={'/icons/gbp-' + info.icon + '.svg'} width={20}/>
                    <Tooltip title={info?.field != 'open_hours' && (info?.field ? getData(info?.field).replaceAll('/n', ', ') : info?.value)}>
                      <InfoSpan style={{cursor: info?.field == 'open_hours' && 'pointer'}}>
                        {info?.field == 'open_hours' ? openHoursInfo() : info?.field ? getData(info?.field)?.split('/n')?.map(item => (<div key={item} className={info?.field == 'website_uri' && 'clickable'} onClick={() => openLink(info?.field, item)}>{item}</div>)) : info?.value}
                      </InfoSpan>

                    </Tooltip>
                  </OverviewInfoContainer>
                );
              }
            })}
          </TabPane>
          <TabPane tab={
            <Tooltip title='Redirecting to Reviews Tab'>
              Reviews
            </Tooltip>
          } key='2'>
          </TabPane>
          <TabPane tab={
            <span>About</span>
          } key='3'>
            <DescriptionContainer>
              <h3>Description</h3>
              <p>{schemaData?.business_description}</p>
            </DescriptionContainer>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
});

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 60px;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border: 2px solid rgb(26,115,232);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, color 0.3s;
  color: rgb(26,115,232);

  .first-icon {
    background: white;
    border-radius: 43%;
    clip-path: polygon(52% 7%, 93% 48%, 51% 92%, 6% 50%);
  }
`;

const ActionName = styled.span`
  margin-top: 10px;
  font-size: 14px;
  color: rgb(26,115,232);
  text-align: center;
`;

const OverviewInfoContainer = styled.div`
  color: rgba(0, 0, 0, 0.85); 
  padding: 10px 40px; 
  display: flex; 
  gap: 20px;
  align-items: flex-start;
`;

const InfoSpan = styled.span`
  overflow-x: clip;
  max-width: 90%;
  text-overflow: ellipsis;
  word-wrap: inherit;
  .clickable {
  color: #2D6CCA;
  cursor: pointer;
  }
`;

const DescriptionContainer = styled.div`
  padding: 5px 25px;
  height: 100%;
  max-height: fit-content;
`;
