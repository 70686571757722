import {Tooltip, Spin, Dropdown, Collapse, Menu} from 'antd';
import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {faPen, faTimes, faCheckCircle, faSortDown} from '@fortawesome/pro-solid-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam} from '@/utils/url';
// import {notification} from '@/utils/notifications';
import {notification as notificationV2} from '@/utils/notification-v2';
import {TableTopBar} from '../../../tableTopBar';
import {openUrl} from '@/utils/router';
import {canDeploy, getFilteredDataForOtto} from '../../../tableIssuesCollapse';
import {CloseButton, DescriptionWrapper, PaginationStyled, StatusMenu, StyledInput, StyledIssuesCollapse, StyledIssuesTable, StyledTrashIcon} from '../../../../style';
import {Button} from '@/components/common-components';
import useWindowSize from '@/utils/hooks/useWindowSize';
import {newNotification} from '@/utils/notification-v3';
import {getOttoTablePageSize} from '@/api/common-utils';
import {saveOttoTablePageSize} from '@/api/common-utils';
import FreezeWrapper from '../../../freezTooltip';
import {StyledMenu} from '../../../../Utils/styledMenu';
import {faCircleInfo} from '@fortawesome/pro-duotone-svg-icons';

interface Props {
  setIssueTable: (value: string) => void;
  setPageChanged: (value: boolean) => void;
  deleteItem: number;
  showConfirmationPopup: (value: any) => void;
  componentIssueType: string;
  issueTable: string;
}

export const Images = observer(({setPageChanged, setIssueTable, deleteItem, showConfirmationPopup, componentIssueType, issueTable}: Props) => {
  const {ottoV2Store: {
    getOttoUrls,
    getOttoV2Project,
    getStatusKeyValue,
    loadIssueTableData,
    issueTypeArray,
    ottoUrlLoader,
    ottoIssueType,
    setIssueTypeSelected,
    setIsDeploying,
    loadOttoV2Project,
    deployOttoUrls,
    selectedIssue,
    selectedCategory,
    setSelectedIssue,
    setSelectedCategory,
    setLoadingDetail,
    setOttoSearchTerm,
    deployingProposedFix,
    deployImageAltText,
  }, settings: {customer: {profile: {whitelabelOtto}}},
  } = useStore('');
  const {width} = useWindowSize();
  const uuid = getSingleUrlParam('uuid');
  const [ottoUrls, setOttoUrls] = useState([]);
  const issueTypesFrontendMappingV2 = getOttoV2Project?.issueTypesFrontendMappingV2;

  const [selectedIssueType, setSelectedIssueType] = useState('');
  const [currentProject, setCurrentProject] = useState(null);
  const [sectionCount, setSectionCount] = useState(0);
  const [urlId, setUrlId] = useState<any>(-1);
  const [proposedFix, setProposedFix] = useState('');
  const [editDescription, setEditDescription] = useState<any>(-1);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [tableData, setTableData] = useState([]);
  const [isTableOpen, setIsTableOpen] = useState<any>('open');
  const [isActiveFilter, setIsActiveFilter] = useState('all');
  const [pageSize, setPageSize] = useState(() => getOttoTablePageSize(componentIssueType));


  const antUrlIcon = <LoadingOutlined style={{fontSize: 16, color: '#2AC155'}} spin />;

  useEffect(() => {
    if (getOttoUrls) {
      setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);

  useEffect(() => {
    const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
    const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);
    const data = url?.issueTable?.results ?? [];
    const filteredData = getFilteredDataForOtto(data, url?.issueType, false);
    setTableData(filteredData);
  }, [ottoUrls]);

  useEffect(() => {
    setCurrentProject(getOttoV2Project?.uuid == uuid && getOttoV2Project);
  }, [getOttoV2Project]);


  const loadIssueTables = async (issueArray: string[], issue: string, category: string, page: number, pageSize: number, stopLoading?: boolean, search?: string, rating?: string) => {
    setSelectedIssue(issue);
    setSelectedCategory(category);
    if (!stopLoading) {
      setLoadingDetail(true);
    }
    const categories = issue == 'All' ? issueTypesFrontendMappingV2.find(group=> group.subGroups.filter(g=> g.group == selectedCategory).length).group : issue;
    const issueObject = issueTypesFrontendMappingV2?.find(issueObj => issueObj.group === categories || issueObj.label === categories)?.subGroups?.find(categoryObj => categoryObj?.group === category);
    for (let index = 0; index < issueArray.length; index++) {
      const issueType = issueArray[index];
      const itemObject: any = issueObject?.subGroups?.find((item: any) => item?.group === issueType);
      const params = {
        uuid,
        otto_project: getOttoV2Project?.id,
        issue_type: issueType,
        page_size: pageSize,
        page,
        is_loading: false,
        deploy_status: getStatusKeyValue() ? getStatusKeyValue() : 'all',
      };
      if (rating) {
        params['star_rating'] = rating;
      }
      if (searchText) {
        params['search'] = searchText;
        setOttoSearchTerm(searchText);
      } else {
        setOttoSearchTerm('');
      }
      await loadIssueTableData(params, itemObject?.isSitewide);
    }
    setLoadingDetail(false);
  };

  const showNotificationMess = () => {
    if (currentProject && (currentProject?.pixelTagState == 'not_installed' || currentProject?.pixelTagState == 'wrong_uuid')) {
      notificationV2.warning('', `To enable changes, you must install the ${whitelabelOtto} Pixel`);
      return false;
    } else return true;
  };

  const deployOttoSection = async (toDeploy: boolean) => {
    if (!showNotificationMess()) {
      return;
    }
    try {
      await deployOttoUrls({toDeploy, issueType: selectedIssueType, uuid});
      setPageChanged(true);
      setIssueTypeSelected(selectedIssueType);
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        newNotification(`${sectionCount ?? 0} Changes Deployed`, 2, 'deploy');
      } else {
        newNotification(`${sectionCount ?? 0} Changes Rolled Back`, 2, 'rollback');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
    }
  };

  const handlePaginationChange = async (issueArray: string[], page, pageSize) => {
    loadIssueTables(issueArray, selectedIssue, selectedCategory, page, pageSize, true, searchText);
  };

  const onChangeProposedFix = async (id, issueType) => {
    try {
      await deployImageAltText({id, issueType, proposedFix, uuid});
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setEditDescription(-1);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
      setUrlId(-1);
      setEditDescription(-1);
    }
  };

  const descriptionContainer = (record, index) => {
    return (
      <DescriptionWrapper>
        {record?.recommendedValue}
        {
          record?.recommendedValue ?
            <span className='icons-wrapper'>
              <FreezeWrapper removeTooltip={record?.isApproved}>
                <Tooltip overlayInnerStyle={{background: '#121212'}} title={'Edit'}>
                  <FontAwesomeIcon
                    icon={faPen}
                    fontSize={12}
                    color='#4E5156'
                    onClick={() => {
                      setEditDescription(index);
                      setProposedFix(record?.recommendedValue);
                    }} />
                </Tooltip>
              </FreezeWrapper>
            </span> :
            <span className='icons-wrapper' style={{display: 'flex', gap: 10}}>
              <FreezeWrapper removeTooltip={record?.isApproved}>
                <Tooltip overlayInnerStyle={{background: '#121212'}} title={'Edit'}>
                  <div className='content-wrapper' onClick={() => {
                    setEditDescription(index);
                    setProposedFix(record?.recommendedValue);
                  }}>
                    <FontAwesomeIcon icon={faPen} fontSize={12} color='#4E5156' />
                  Write
                  </div>
                </Tooltip>
              </FreezeWrapper>
            </span>
        }
      </DescriptionWrapper>
    );
  };

  const inputDescriptionContainer = record => {
    return (
      <div style={{display: 'flex', gap: '10px', width: record?.issueType == 'nlp_faq' ? '107%' : '100%', alignItems: 'center', minWidth: 150, flexWrap: 'wrap'}}>
        <StyledInput rows={6} value={proposedFix} onChange={e => setProposedFix(e.target.value)}/>
        <Button
          buttonType={'green'}
          loading={deployingProposedFix}
          disabled={!proposedFix}
          style={{
            display: deployingProposedFix && 'flex',
            alignItems: deployingProposedFix && 'center',
            height: '30px',
            fontSize: '14px',
            padding: '0px 15px',
          }}
          onClick={() => {
            onChangeProposedFix(record?.itemIndex, record?.issueType);
          }}
        >
          {!deployingProposedFix ? 'Save' : ''}
        </Button>
        <CloseButton style={{
          height: '26px',
          width: '32px',
        }}>
          <FontAwesomeIcon icon={faTimes} fontSize={20} color='white' onClick={() => {
            setEditDescription(-1);
            setProposedFix('');
          }} />
        </CloseButton>
      </div>
    );
  };

  const deployOttoUrl = async (id, toDeploy, issueType) => {
    if (!showNotificationMess()) {
      return;
    }
    setUrlId(id);
    try {
      const data = {toDeploy, issueType, uuid};
      data['itemIndex'] = id;
      await deployOttoUrls(data);
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        newNotification('1 Change deployed', 2, 'deploy');
      } else {
        newNotification('1 Change Rolled Back', 2, 'rollback');
      }
      setUrlId(-1);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
      setUrlId(-1);
    }
  };

  const suggestedColumnTooltipContent = (
    <TooltipContentWrapper>
      <div className='heading'>
        {`Why didn't OTTO suggest any keywords for the Missing Alt Text issue?`}
      </div>
      <div className='reasons-container'>
        <div>There are two possible reasons:</div>
        <div className='reasons'>
          <div>
            <span style={{fontWeight: 700}}>1-</span>{` OTTO couldn't identify relevant information in the file or surrounding text to generate suggested keywords. Make sure your images and adjacent content contain the terms you want to rank for.`}
          </div>
          <div>
            <span style={{fontWeight: 700}}>2-</span> Your AI generation quota may be depleted. Check your account to see if you need to purchase more credits.
          </div>
        </div>
        <div className='description'>
          {`If OTTO doesn't provide suggestions, you can manually add the keywords you want to rank for and deploy them through OTTO. Simply enter your desired keywords in the writing field before deploying your changes.`}
        </div>
      </div>
    </TooltipContentWrapper>
  );


  const columns = (dataIssueType, dataLength) => [
    {
      title: (<div className='column-title'>
        <Dropdown overlay={
          <StyledMenu disabled={dataLength == 0} selectedIssueType={dataIssueType} deployOttoSection={deployOttoSection} currentProject={currentProject} />
        } trigger={['click']} placement='bottomLeft' overlayStyle={{top: '798px', boxShadow: '0px 1px 0px 0px #F4F4F4'}}>
          <div>
            <span>STATUS</span>
            <FontAwesomeIcon icon={faSortDown} color='#121212' fontSize={12}/>
          </div>
        </Dropdown>
      </div>),
      dataIndex: 'status',
      key: 'status',
      width: '125px',
      render: (_, record, index) => (
        <FreezeWrapper removeTooltip={record?.isApproved}>
          <Tooltip title={canDeploy(record, record?.issueType) ? '' : 'Empty fields cannot be deployed.'}>
            <StatusWrapper
              isDisabled={!canDeploy(record, record?.issueType)} status={record?.isApproved} onClick={() => (canDeploy(record, record?.issueType)) && deployOttoUrl(record?.itemIndex, !record?.isApproved, record?.issueType)}>
              {urlId === index ? <Spin indicator={antUrlIcon} /> :
                <FontAwesomeIcon icon={faCheckCircle} fontSize={20} color={record?.isApproved ? `#2AC155` : '#A3A4A4'} />
              }
              <span>{record?.isApproved ? 'Deployed' : 'Deploy'}</span>
            </StatusWrapper>
          </Tooltip>
        </FreezeWrapper>
      ),
    },
    {
      title: <div className='column-title'>IMAGE</div>,
      dataIndex: 'image',
      key: 'image',
      render: (_, record) => {
        const decodedUri = decodeURIComponent(record?.absoluteUrl);
        return (<ImageWrapper>
          {
            record?.absoluteUrl ?
              <img src={decodedUri} className='image' onClick={() => openUrl(record?.absoluteUrl, '_blank')} /> :
              <img src={decodedUri} className='image' style={{cursor: 'not-allowed'}} />
          }{
            record?.absoluteUrl ?
              <div className='url' onClick={() => openUrl(record?.absoluteUrl, '_blank')} >{decodedUri || '' }</div>:
              <div className='url' style={{cursor: 'not-allowed', color: '#A3A4A4'}}>{decodedUri || '' }</div>
          }
        </ImageWrapper>);
      },
    },
    {
      title: <div className='column-title'>FOUND IN</div>,
      dataIndex: 'url',
      key: 'url',
      width: '280px',
      className: 'images-fadin',
      render: (_, record) => {
        return (<FoundInWrapper>
          {
            record?.foundIn?.length ?
              record?.foundIn?.length > 1 ?
                <Dropdown overlay={<StatusMenu style={{padding: '5px 10px', width: 500, maxHeight: '470px', overflow: 'hidden', overflowY: 'auto'}}>
                  {
                    record?.foundIn.map(page => (
                      <Menu.Item
                        key={page}
                        style={{borderBottom: '1px solid #dfdfdf', padding: '6px 0px', color: '#2D6CCA', fontSize: '13px'}}
                        onClick={() => {
                          if (page) {
                            openUrl(page, '_blank');
                          }
                        }}
                      >{page}</Menu.Item>
                    ))
                  }
                </StatusMenu>} trigger={['click']} placement='bottomLeft'>
                  <div>
                    <span>{record?.foundIn?.length} pages</span>
                    <FontAwesomeIcon icon={faSortDown} color='#2D6CCA' fontSize={12}/>
                  </div>
                </Dropdown>:
                <UrlWrapper onClick={() => {
                  if (record?.foundIn?.length) {
                    openUrl(record?.foundIn[0], '_blank');
                  }
                }}
                >
                  {record?.foundIn[0]}
                </UrlWrapper> :
              '-'
          }
        </FoundInWrapper>);
      },
    },
    {
      title: (
        <div className='column-title'>{`${whitelabelOtto} SUGGESTED FIX`}
          {' '} <Tooltip title={suggestedColumnTooltipContent} overlayStyle={{maxWidth: 490}} overlayInnerStyle={{backgroundColor: '#000', borderRadius: 6}}>
            <FontAwesomeIcon icon={faCircleInfo} style={{cursor: 'pointer'}} />
          </Tooltip>
        </div>
      ),
      dataIndex: 'proposedFix',
      key: 'proposedFix',
      className: 'greenish-column',
      render: (id, record, index) => (
        <>
          {
            editDescription !== index ?
              <>
                {
                  descriptionContainer(record, index)
                }
              </> :
              <>
                {
                  inputDescriptionContainer(record)
                }
              </>
          }
        </>
      ),
    },
    {
      title: '',
      dataIndex: 'trashIcon',
      key: 'trashIcon',
      className: 'greenish-column',
      render: (_, record) => {
        return (
          <FreezeWrapper placement='topRight' removeTooltip={record?.isApproved}>
            <Tooltip title={deleteItem === record?.itemIndex ? 'Deleting': 'Delete'}>
              {
                deleteItem === record?.itemIndex ?
                  <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#A3A4A4'}} spin />}/>:
                  <StyledTrashIcon icon={faTrash} onClick={()=> showConfirmationPopup(record)} fontSize={14} color='#A3A4A4'/>
              }
            </Tooltip>
          </FreezeWrapper>
        );
      },
    },
  ];

  const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
  const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);
  const data = url?.issueTable?.results ?? [];
  const filteredData = getFilteredDataForOtto(data, url?.issueType, false);

  useEffect(() => {
    if (filteredData && deleteItem == -1) {
      setTableData(filteredData);
    } else {
      const newData = filteredData.filter(val=> val.itemIndex !== deleteItem);
      setTableData(newData);
    }
  }, [deleteItem]);

  return (
    <StyledIssuesCollapse
      ghost
      expandIconPosition='right'
      defaultActiveKey={[`${isTableOpen}`]}
      activeKey={[`${isTableOpen}`]}
      onChange={() => {
        if (isTableOpen === 'close') {
          setIsTableOpen('open');
          setIsOpenSearch(false);
        } else {
          setIsTableOpen('close');
          setIsOpenSearch(false);
        }
      }}
    >
      <TableTopBar
        componentIssueType={componentIssueType}
        setPageChanged={setPageChanged}
        setIssueTable={setIssueTable}
        issueTable={issueTable}
        setSearchText={setSearchText}
        searchText={searchText}
        setIsOpenSearch={setIsOpenSearch}
        isOpenSearch={isOpenSearch}
        setIsTableOpen={setIsTableOpen}
        isTableOpen={isTableOpen}
        setIsActiveFilter={setIsActiveFilter}
        isActiveFilter={isActiveFilter} />
      <Collapse.Panel key='open' header={<></>}>
        <StyledIssuesTable
          loading={ottoUrlLoader && ottoIssueType === componentIssueType}
          columns={columns(url?.issueType, tableData?.length)}
          dataSource={tableData}
          pagination={false}
          scroll={width < 1100 ? {x: 'auto'} : {}}
          onHeaderRow={() => {
            return {
              onClick: () => {
                setSectionCount((url?.issueTable?.count || url?.issueTable?.results?.length) ?? 0);
                setSelectedIssueType(url?.issueType);
              },
            };
          }}
        />
        <PaginationStyled
          onChange={(page, pageSize) => {
            setPageChanged(true);
            setIssueTable(url?.issueType);
            handlePaginationChange([url?.issueType], page, pageSize);
            saveOttoTablePageSize(componentIssueType, pageSize);
            setPageSize(pageSize);
          }}
          total={url?.issueTable?.count}
          pageSize={pageSize}
          current={url?.page ?? 1}
          showSizeChanger
          pageSizeOptions={['5', '10', '20', '50', '100']}
        />
      </Collapse.Panel>
    </StyledIssuesCollapse>
  );
});

const StatusWrapper = styled.div<{status: boolean; isDisabled?: boolean}>`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  ${p => p?.isDisabled ? 'cursor: not-allowed !important;': ''}
  span {
    color: ${p => p.status ? '#219843' : '#A3A4A4'};
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;

  }
`;

const TooltipContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 13px;
  .heading {
    font-weight: 700;
  }
  .reasons-container {
    display: flex;
    flex-direction: column;
    gap: 2px;
    .reasons  {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
  }
  .description {
    margin-top: 6px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  gap: 10px;

.image {
  height: 32px; 
  width: 32px; 
  border-radius: 6px; 
  cursor: pointer;
  border: 1px solid #bfbebe;
}

.url {
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 400;
  max-width: 260px;
  color: #2D6CCA;
  line-height: normal;
  cursor: pointer;
}
`;

const generateLinkConnectionHeight = (index, total) => {
  let output = '50%';

  if (total > 1) {
    output = '95%';
  }

  if (index == 0 && index == total - 1) {
    output = '60%';
  }

  if (index == total - 1) {
    output = '10%';
  }

  if (index === 0) {
    output = '91%';
  }

  return output;
};

export const ItemWrapper = styled.div<{itemIndex: number; totalItems: number}>`
  display: flex;
  position: relative;

  .customConnection {
    border-right: 3px dashed rgba(78, 81, 86, 50%);
    color: transparent;
    height: ${p => generateLinkConnectionHeight(p.itemIndex, p.totalItems)} !important;
  }
  .keywords {
    max-width: 390px;
    flex-wrap: wrap;
    color: #4E5156;
    display: flex;
    gap: 4px;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
  }
`;

export const StyledItemData = styled.div<{itemIndex: number; totalItems: number}>`
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #121212;
  position: relative;
  height: 100%;
  margin-left: 8px;
  padding-bottom: 16px;

  .first-item-arrow {
    position: absolute;
    left: -50px;
    top: 3px;
  }
  .link-wrapper {
    position: absolute;
    left: -36px;
    top: 0px;
    width: 20px;
    height: 20px;
    background-color: #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    z-index: 1;
  }
  &::before {
    display: ${p => (p.itemIndex === p.totalItems - 1) && 'none'};
    content: '';
    position: absolute;
    width: 1px;
    top: 11px;
    bottom: -11px;
    left: -26px;
    border: 1px dashed #4E5156;
  }
  &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: ${p => p.itemIndex === 0 ? '38px' : '20px'};
    top: 9px;
    left: ${p => p.itemIndex === 0 ? '-44px' : '-25px'};
    border: 1px dashed #4E5156;
  }
`;

export const GenerateButton = styled.div`
  font-size: 13px;
  color: #2D6CCA;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  height: 18px;
  padding: 5px 0px;
`;

export const AIGenerateButton = styled.div`
  width: fit-content;
  font-size: 13px;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  padding: 5px 10px;
  gap: 10px;
  background-color: #2D6CCA;
`;
const UrlWrapper = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 400;
  max-width: 260px;
  color: #2D6CCA;
  cursor: pointer;
  .source-delete-icon {
    display: none;
    cursor: pointer;
  }
  &:hover {
    .source-delete-icon {
      display: inline-block;
    }
  }
`;

const FoundInWrapper = styled.div`
  .ant-dropdown-trigger {
    display: flex;
    gap: 5px;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-weight: 400;
    max-width: 260px;
    color: #2D6CCA;
    cursor: pointer;
  }
`;
