import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {useEffect, useState} from 'react';
import {Table} from '@/components/common-components';
import styles from './styles.module.scss';
import {useStore} from '@/store/root-store';
import {isNil} from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUp} from '@fortawesome/free-solid-svg-icons';
import {faArrowDown} from '@fortawesome/pro-solid-svg-icons';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';
import {SmallBadge} from '@/components/common-components/components/badge';
import {faCircleInfo} from '@fortawesome/pro-regular-svg-icons';
import {EditCompetitorsModal} from './editCompetitorsModal';
import {Button} from '@/components/common-components/v2';

interface Props {
  id: number;
  hideTableHeader?: boolean;
  currentPeriodStart?: string;
  currentPeriodEnd?: string;
}

export const CompetitorsTableV2 = observer(({id, hideTableHeader=false, currentPeriodStart, currentPeriodEnd}: Props) => {
  const {
    keywordResearcherV2: {
      keywordRankTracker: {
        getProjectCompetitorsV2,
        competitorsLoading,
      },
    },
    settings: {customer: {profile: {isLinkgraph}}},
  } = useStore('');
  const [projectCompetitorsV2, setProjectCompetitorsV2] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [contentDataList, setContentDataList] = useState([
    {
      idx: 0,
      competitorUrl: '',
      businessName: '',
      competitorUrlError: '',
    },
  ]);

  useEffect(() => {
    const load = async () => {
      const res = await getProjectCompetitorsV2(id, {currentPeriodStart, currentPeriodEnd});
      setProjectCompetitorsV2(res);
    };

    load();
  }, [id]);

  const groupByKeyword = inputArray => {
    const keywordMap = {};

    inputArray?.forEach(item => {
      item?.keywordPositionChange?.forEach(change => {
        const {keyword, location, currentAvgPosition, previousAvgPosition, change: positionChange} = change;

        // If keyword doesn't exist in the map, create a new entry
        if (!keywordMap[keyword]) {
          keywordMap[keyword] = {
            keyword: keyword,
            urlPositionChange: [{
              location: location,
              currentAvgPosition: currentAvgPosition,
              previousAvgPosition: previousAvgPosition,
              change: positionChange,
              url: item.url,
              name: item.name,
            }],
          };
        } else {
          // If keyword exists, add new position data for that keyword
          keywordMap[keyword].urlPositionChange.push({
            location: location,
            currentAvgPosition: currentAvgPosition,
            previousAvgPosition: previousAvgPosition,
            change: positionChange,
            url: item.url,
            name: item.name,
          });
        }
      });
    });

    // Convert the map back into an array of grouped keywords
    return Object.values(keywordMap);
  };

  const [columns, setColumns] = useState([]);
  const [record, setRecord] = useState([]);

  useEffect(() => {
    if (projectCompetitorsV2?.competitors) {
      const newDataFormat = groupByKeyword(projectCompetitorsV2?.competitors);
      setRecord(newDataFormat);
      const urlSet: Set<string> = new Set();
      const tmpColumns = [{
        title: <div style={{marginLeft: newDataFormat?.length ? '' : '20px'}}>{'Keyword'?.toUpperCase()}</div>,
        dataIndex: 'keyword',
        key: 'keyword',
        align: 'left',
        width: 400,
        render: (_, record) => {
          return record.keyword;
        }},
      ];

      newDataFormat.forEach(item => {
        // @ts-ignore
        item.urlPositionChange.forEach((urlPosition: {url: string; name: string}) => {
          if (!urlSet.has(urlPosition.url)) {
            urlSet.add(urlPosition.url);
            tmpColumns.push({
              title: <div style={{textAlign: 'left'}}>
                <Title>{urlPosition.name}</Title>
                <a href={urlPosition?.url?.includes('https://') ? urlPosition.url : `https://${urlPosition.url}/`} target='_blank' rel='noreferrer' style={{color: '#2D6CCA', fontWeight: 400}}>
                  {urlPosition.url}
                </a>
              </div>,
              dataIndex: urlPosition.url,
              key: urlPosition.url,
              align: 'center',
              width: 100,
              render: (_, record) => {
                const data = record.urlPositionChange?.filter(i => i.url == urlPosition.url);
                const positionDelta = (data?.length && !isNil(data[0]?.change) && data[0]?.change != 0 ?
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <FontAwesomeIcon
                      fontSize={8} icon={data[0].change > 0 ? faArrowUp : faArrowDown}
                      color={data[0].change > 0 ? '#1FAC47' : '#F44343'}
                      style={{marginRight: 2}}
                    />
                    <div style={{color: data[0].change > 0 ? '#1FAC47' : '#F44343', fontSize: 12, fontWeight: 500, marginTop: '2px'}}>{Math.abs(data[0].change)}</div>
                  </div> : null);
                return <div style={{width: '100%', display: 'flex', alignItems: 'center', gap: '8px'}}>
                  <span>{(data.length && data[0]?.currentAvgPosition) ? data[0].currentAvgPosition : '-'}</span>
                  {data.length && data[0].change !== 0 ? <div style={{width: 30}}>{positionDelta}</div> : null}
                </div>;
              },
            });
          }
        });
      });
      setColumns(tmpColumns);
    }
  }, [JSON.stringify(projectCompetitorsV2)]);

  return <>
    {!hideTableHeader && <>
      <div style={{position: 'relative', marginBottom: '10px', display: 'flex', alignItems: 'center', gap: '10px'}}>
        <div style={{fontSize: '16px', fontWeight: 500, color: '#121212'}}>Competitors</div>
        <FontAwesomeIcon icon={faCircleInfo} color='#A3A4A4' />
        <SmallBadge customStyle={{left: '128px'}} alpha/>
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'end',
        background: '#fff',
        paddingTop: '16px',
        paddingRight: '25px',
      }} className={styles.tableHeader}>
        <ButtonStyled
          // disabled={competitorsLoading}
          background='#FF8536'
          onClick={() => setIsVisible(true)}
        >
          + Edit competitors
        </ButtonStyled>
      </div>
    </>}
    <Wrapper>
      <div className={styles.competitorsTableWrapper}>
        {competitorsLoading ? <RingLoaderV2 height='400px'/> :
          <TableStyled
            columns={columns}
            dataSource={record}
            pagination={false}
            loading={competitorsLoading}
            loadingRows={10}
          />}
      </div>
    </Wrapper>
    {isLinkgraph && isVisible && <EditCompetitorsModal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      contentDataList={contentDataList}
      setContentDataList={setContentDataList}
      projectCompetitorsV2={projectCompetitorsV2}
    />}
  </>;
});

const Wrapper = styled.div`
  margin-bottom: 50px;
  @media screen and (max-width: 860px) {
    padding: 25px 20px;
  }
  @media screen and (max-width: 700px) {
    padding: 20px 15px;
  }
`;

const TableStyled = styled(Table)`
  table .ant-table-thead th:last-child {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: #121212;
  width: 146px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ButtonStyled = styled(Button)`
  height: 28px;
  border-radius: 6px;
`;
